import React, { useEffect, useState } from "react";
import {
	Grid,
	Box,
	makeStyles,
	TableContainer,
	Table,
	TableHead,
	Paper,
	TableRow,
	TableCell,
	Button,
	ButtonGroup,
	Typography,
	TableBody,
	useTheme,
} from "@material-ui/core";
import MainLayout from "../components/main-layout";
import StarsIcon from "@material-ui/icons/Stars";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import VisibilityIcon from "@material-ui/icons/Visibility";
import StatisticsCardWithIcon from "../components/statistics-card-with-icon";
import SectionContainerLayout from "../components/section-container-layout";
import SubSectionContainerLayout from "../components/sub-section-container-layout";
import colors from "../constants/colors";
import moment from "moment";
import axios from "axios";
import { useHistory } from "react-router";
import CustomBarChart from "../components/custom-bar-chart";
import ToolbarLegend from "../components/toolbar-legend";
const useStyles = makeStyles((theme) => ({
	icons: {
		// backgroundColor: theme.palette.success.main,
		backgroundColor: "#A4C627",
		color: "white",
		fontSize: theme.typography.h2.fontSize,
	},
	tableHeader: {
		backgroundColor: colors.darkBlue,
	},
}));

const DashboardPage = () => {
	const classes = useStyles();
	const theme = useTheme();
	const history = useHistory();
	const [userLogs, setUserLogs] = useState();
	const [isDataLoaded, setIsDataLoaded] = useState(false);
	const [selectedYear, setSelectedYear] = useState(2021);

	useEffect(() => {
		try {
			axios.get("/user/user-logs").then((_response) => {
				if (_response.status === 200) {
					setUserLogs(_response.data);
					setIsDataLoaded(true);
				}

				if (_response.status === 404) {
					history.push("/login");
				}
			});
		} catch (err) {}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const [userStatisticsData, setUserStatisticsData] = useState([
		{
			name: "Admin",
			icon: (
				<Box clone p={0.5} borderRadius="borderRadius">
					<StarsIcon className={classes.icons} />
				</Box>
			),
			value: 0,
		},
		{
			name: "Editor",
			icon: (
				<Box clone p={0.5} borderRadius="borderRadius">
					<BorderColorIcon className={classes.icons} />
				</Box>
			),
			value: 0,
		},
		{
			name: "Viewer",
			icon: (
				<Box clone p={0.5} borderRadius="borderRadius">
					<VisibilityIcon className={classes.icons} />
				</Box>
			),
			value: 0,
		},
	]);

	const convertDateFn = (date) => {
		const newDate = moment(date).format("DD MMMM YYYY, hh:MM A ");

		return newDate;
	};

	useEffect(() => {
		try {
			const token = localStorage.getItem("token");
			Promise.all([
				axios.get("/user/user-logs"),
				axios.get("/user", {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}),
			])
				.then((_responses) => {
					if (_responses[0].status === 200) {
						setUserLogs(_responses[0].data);
						setIsDataLoaded(true);
					}

					if (_responses[1].status === 200) {
						let _adminCount = 0;
						let _viewerCount = 0;
						let _editorCount = 0;

						_responses[1].data.forEach((_user) => {
							switch (_user.user_account_type.type) {
								case "Admin": {
									_adminCount++;
									break;
								}
								case "Viewer": {
									_viewerCount++;
									break;
								}
								case "Editor": {
									_editorCount++;
									break;
								}
								default: {
									return;
								}
							}

							setUserStatisticsData([
								{
									name: "Admin",
									icon: (
										<Box
											clone
											p={0.5}
											borderRadius="borderRadius"
										>
											<StarsIcon
												className={classes.icons}
											/>
										</Box>
									),
									value: _adminCount,
								},
								{
									name: "Editor",
									icon: (
										<Box
											clone
											p={0.5}
											borderRadius="borderRadius"
										>
											<BorderColorIcon
												className={classes.icons}
											/>
										</Box>
									),
									value: _editorCount,
								},
								{
									name: "Viewer",
									icon: (
										<Box
											clone
											p={0.5}
											borderRadius="borderRadius"
										>
											<VisibilityIcon
												className={classes.icons}
											/>
										</Box>
									),
									value: _viewerCount,
								},
							]);
						});
						setIsDataLoaded(true);
					}

					if (
						_responses[0].status === 404 ||
						_responses[1].status === 404
					) {
						history.push("/login");
					}
				})
				.catch((err) => console.log(err));
		} catch (err) {
			console.log(err);
		}
	}, [classes.icons, history]);

	// const tabsData = {
	//   tabLabels: ["2020", "2019", "2017"],
	//   tabPanels: [<p>hello 1</p>, <p>hello 2</p>, <p>hello 3</p>],
	// };

	return (
		<MainLayout>
			<SectionContainerLayout title="DASHBOARD">
				{isDataLoaded ? (
					<Grid container spacing={3}>
						<Grid item xs={12} md={8}>
							<SubSectionContainerLayout title="Latest Activities">
								<Grid item xs={12}>
									<TableContainer component={Paper}>
										<Table>
											<TableHead
												className={classes.tableHeader}
											>
												<TableRow>
													<TableCell align="center">
														<Typography
															variant="body2"
															style={{
																color: theme
																	.palette
																	.common
																	.white,
															}}
														>
															Name
														</Typography>
													</TableCell>
													<TableCell align="center">
														<Typography
															variant="body2"
															style={{
																color: theme
																	.palette
																	.common
																	.white,
															}}
														>
															Type
														</Typography>
													</TableCell>
													<TableCell align="center">
														<Typography
															variant="body2"
															style={{
																color: theme
																	.palette
																	.common
																	.white,
															}}
														>
															Section
														</Typography>
													</TableCell>
													<TableCell align="center">
														<Typography
															variant="body2"
															style={{
																color: theme
																	.palette
																	.common
																	.white,
															}}
														>
															Card ID
														</Typography>
													</TableCell>
													<TableCell align="center">
														<Typography
															variant="body2"
															style={{
																color: theme
																	.palette
																	.common
																	.white,
															}}
														>
															Action
														</Typography>
													</TableCell>
													<TableCell align="center">
														<Typography
															variant="body2"
															style={{
																color: theme
																	.palette
																	.common
																	.white,
															}}
														>
															Date and Time
														</Typography>
													</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{userLogs.map(
													(_userLog, index) =>
														index < 5 ? (
															<TableRow
																key={index}
																align="center"
																scope="row"
															>
																<TableCell>
																	<Typography
																		variant="body2"
																		color="textPrimary"
																		align="center"
																	>
																		{_userLog
																			.user
																			.firstName +
																			" " +
																			_userLog
																				.user
																				.lastName}
																	</Typography>
																</TableCell>
																<TableCell>
																	<Typography
																		variant="body2"
																		color="textPrimary"
																		align="center"
																	>
																		{
																			_userLog
																				.user
																				.user_account_type
																				.type
																		}
																	</Typography>
																</TableCell>
																<TableCell>
																	<Typography
																		variant="body2"
																		color="textPrimary"
																		align="center"
																	>
																		{
																			_userLog.section
																		}
																	</Typography>
																</TableCell>
																<TableCell>
																	<Typography
																		variant="body2"
																		color="textPrimary"
																		align="center"
																	>
																		{
																			_userLog.cardCode
																		}
																	</Typography>
																</TableCell>
																<TableCell>
																	<Typography
																		variant="body2"
																		color="textPrimary"
																		align="center"
																	>
																		{
																			_userLog.action
																		}
																	</Typography>
																</TableCell>
																<TableCell>
																	<Typography
																		variant="body2"
																		color="textPrimary"
																		align="center"
																	>
																		{convertDateFn(
																			_userLog.createdAt,
																		)}
																	</Typography>
																</TableCell>
															</TableRow>
														) : null,
												)}
											</TableBody>
										</Table>
									</TableContainer>
								</Grid>
							</SubSectionContainerLayout>
						</Grid>
						<Grid
							item
							container
							xs={12}
							md={4}
							alignItems="flex-start"
						>
							<SubSectionContainerLayout title="User Accounts">
								{userStatisticsData.map((_data, index) => (
									<Grid item key={index}>
										<Box my={index === 0 ? 0 : 3}>
											<StatisticsCardWithIcon
												name={_data.name}
												icon={_data.icon}
												value={_data.value}
											/>
										</Box>
									</Grid>
								))}
							</SubSectionContainerLayout>
						</Grid>
						{/* <Grid item xs={12}>
              <SubSectionContainerLayout title="Content Information">
                <CustomTabs
                  tabLabels={tabsData.tabLabels}
                  tabPanels={tabsData.tabPanels}
                />
              </SubSectionContainerLayout>
            </Grid> */}
						<Grid item container xs={12}>
							<SubSectionContainerLayout title="Content Information">
								<Grid item container xs={12}>
									<Grid item xs={6}>
										<ButtonGroup>
											<Button
												color={
													selectedYear === 2021
														? "primary"
														: null
												}
												onClick={() =>
													setSelectedYear(2021)
												}
												variant="contained"
											>
												2021
											</Button>
											<Button
												color={
													selectedYear === 2020
														? "primary"
														: null
												}
												onClick={() =>
													setSelectedYear(2020)
												}
												variant="contained"
											>
												2020
											</Button>
											<Button
												color={
													selectedYear === 2019
														? "primary"
														: null
												}
												onClick={() =>
													setSelectedYear(2019)
												}
												variant="contained"
											>
												2019
											</Button>
											<Button
												color={
													selectedYear === 2017
														? "primary"
														: null
												}
												onClick={() =>
													setSelectedYear(2017)
												}
												variant="contained"
											>
												2017
											</Button>
										</ButtonGroup>
									</Grid>
									<Grid item xs={6}>
										<ToolbarLegend showGeneral />
									</Grid>
								</Grid>
								<Grid item xs={12}>
									<CustomBarChart
										selectedYear={selectedYear}
									/>
								</Grid>
							</SubSectionContainerLayout>
						</Grid>
					</Grid>
				) : null}
			</SectionContainerLayout>
		</MainLayout>
	);
};

export default DashboardPage;
