import React from "react";
import {
	Card,
	CardContent,
	Grid,
	Typography,
	makeStyles,
	Box,
	Tooltip,
} from "@material-ui/core";
import { fade } from "@material-ui/core/styles/colorManipulator";

const useStyles = makeStyles((theme) => ({
	grayColor: {
		color: fade(theme.palette.text.disabled, 0.5),
	},
}));

const StatisticsCardWithIcon = ({ name, icon, value }) => {
	const classes = useStyles();
	return (
		<Card style={{ backgroundColor: "transparent" }}>
			<CardContent>
				<Grid container alignItems="center">
					<Tooltip title={name} placement="right-start">
						<Grid item xs={6}>
							{icon}
						</Grid>
					</Tooltip>
					<Grid item xs={6}>
						<Typography
							align="right"
							variant="h2"
							className={classes.grayColor}
						>
							<Box fontWeight="fontWeightBold">{value}</Box>
						</Typography>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
};

export default StatisticsCardWithIcon;
