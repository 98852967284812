import React from "react";

import { Box, Grid, Typography } from "@material-ui/core";

const SubSectionContainerLayout = ({ title, children }) => {
	return (
		<Grid container>
			<Grid item xs={12}>
				<Typography variant="h6" color="textPrimary" gutterBottom>
					<Box fontWeight="fontWeightBold">{title}</Box>
				</Typography>
			</Grid>
			<Grid item xs={12}>
				{children}
			</Grid>
		</Grid>
	);
};

export default SubSectionContainerLayout;
