import React from "react";
import {
	Card,
	Box,
	CardContent,
	Typography,
	Grid,
	Checkbox,
	useTheme,
	CardActionArea,
	CardActions,
	IconButton,
} from "@material-ui/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import colors from "../../constants/colors";
import Clamp from "react-multiline-clamp";
import { useStoreState } from "easy-peasy";
import EditIcon from "@material-ui/icons/Edit";
import CircularProgress from "@material-ui/core/CircularProgress";
import DoneIcon from "@material-ui/icons/Done";

const CardImageTitleDescription = ({
	image,
	staticImage = false,
	title,
	description,
	startDateTime,
	endDateTime,
	location,
	status,
	hasStatus = true,
	isChecked = false,
	onItemSelect,
	id = "",
	hasEditIcon = false,
	handleEditClicked,
	isTranscoded,
	onClick,
	...cardActionProps
}) => {
	const theme = useTheme();
	const handleItemChecked = (e) => {
		if (onItemSelect) onItemSelect(e);
	};

	const currentUser = useStoreState((state) => state.user.currentUser);
	return (
		<Card
			id={id}
			style={{
				backgroundColor: "transparent",
				maxWidth: "250px",
				minHeight: "100%",
				maxHeight: "100%",
			}}
		>
			<CardActionArea {...cardActionProps}>
				{isTranscoded === false ? (
					<Box position="absolute" top="10px" right="10px">
						<CircularProgress color="primary" size="20px" />
					</Box>
				) : (
					<Box position="absolute" top="10px" right="10px">
						<DoneIcon color="primary" />
					</Box>
				)}

				{staticImage ? (
					<img style={{ width: "100%" }} src="/default.jpg" alt="" />
				) : image ? (
					<img
						style={{ width: "100%" }}
						src={`${process.env.REACT_APP_API_URL}/static/${image}`}
						alt=""
					/>
				) : null}
			</CardActionArea>
			<CardContent
				onClick={onClick}
				style={{
					minHeight: "150px",
					maxHeight: "150px",
					cursor: "pointer",
				}}
			>
				<Grid container>
					{title && (
						<Grid item xs={12}>
							<Typography
								variant="body1"
								color="textPrimary"
								gutterBottom
							>
								<Clamp lines={2}>{title}</Clamp>
							</Typography>
						</Grid>
					)}
					{description && (
						<Grid item xs={12}>
							<Typography
								variant="body2"
								color="textPrimary"
								gutterBottom
							>
								<Clamp lines={4}>{description}</Clamp>
							</Typography>
						</Grid>
					)}

					{startDateTime && (
						<Grid item xs={12}>
							<Typography
								variant="caption"
								color="textSecondary"
								gutterBottom
							>
								{`Start time: ${startDateTime}`}
							</Typography>
						</Grid>
					)}

					{endDateTime && (
						<Grid item xs={12}>
							<Typography
								variant="caption"
								color="textSecondary"
								gutterBottom
							>
								{`End time: ${endDateTime}`}
							</Typography>
						</Grid>
					)}

					{location && (
						<Grid item xs={12}>
							<Typography
								variant="caption"
								color="textSecondary"
								gutterBottom
							>
								{`Location: ${location}`}
							</Typography>
						</Grid>
					)}
				</Grid>
			</CardContent>
			<CardActions>
				<Grid container alignItems="center">
					{currentUser.user_account_type.type !== "Viewer" ? (
						<Grid item xs={hasStatus || hasEditIcon ? 6 : 12}>
							<Checkbox
								checked={isChecked}
								onChange={handleItemChecked}
							/>
						</Grid>
					) : null}
					{hasStatus ? (
						<Grid item xs={6} align="right">
							<FiberManualRecordIcon
								style={{
									color:
										status === "published"
											? theme.palette.primary.main
											: status === "unpublished"
											? colors.darkGray
											: null,
								}}
							/>
						</Grid>
					) : null}
					{hasEditIcon ? (
						<Grid item xs={6} align="right">
							<IconButton onClick={handleEditClicked}>
								<EditIcon
									style={{
										color: theme.palette.secondary.main,
									}}
								/>
							</IconButton>
						</Grid>
					) : null}
				</Grid>
			</CardActions>
		</Card>
	);
};
export default CardImageTitleDescription;
