import React, { useMemo, useRef } from "react";
import {
  Select,
  InputLabel,
  FormControl,
  MenuItem,
  FormHelperText,
  Typography,
  Box,
  makeStyles,
} from "@material-ui/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
  },
  publishIcon: {
    color: "green",
  },
  unpublishIcon: {
    color: "red",
  },
}));

const CustomSelect = ({
  options,
  label,
  onChange,
  validationMessage,
  name,
  value = [],
  input,
  variant = "outlined",
  hasClearOption = false,
  isMultiple = false,
  is_publish = false,
}) => {
  const labelRef = useRef();
  const labelWidth = labelRef.current ? labelRef.current.clientWidth : 0;
  const classes = useStyles();

  const selectMemo = useMemo(() => {
    return (
      <FormControl variant={variant} fullWidth size="medium">
        <InputLabel ref={labelRef} id={label.toLowerCase()}>
          {label}
        </InputLabel>
        <Select
          labelWidth={labelWidth}
          variant={variant}
          value={value}
          input={input}
          multiple={isMultiple}
          labelId={label.toLowerCase()}
          onChange={(e) => {
            return onChange(e);
          }}
          error={validationMessage && validationMessage !== "" ? true : false}
          style={{ textAlign: "left", backgroundColor: "white" }}
          name={name}
        >
          {options && options.length > 0 ? (
            options.map((_option, index) => (
              <MenuItem key={index} value={_option.code}>
                <Box display="flex" flexWrap="wrap" width={1}>
                  {is_publish && (
                    <Box>
                      <FiberManualRecordIcon
                        fontSize="small"
                        className={
                          _option.code === "1"
                            ? classes.publishIcon
                            : classes.unpublishIcon
                        }
                      />
                    </Box>
                  )}

                  {_option.name.charAt(0).toUpperCase() + _option.name.slice(1)}
                </Box>
              </MenuItem>
            ))
          ) : (
            <MenuItem value="" disabled>
              No options available
            </MenuItem>
          )}
          {hasClearOption ? <MenuItem value="">Clear</MenuItem> : null}
        </Select>
        {validationMessage ? (
          <FormHelperText>
            <Typography variant="caption" color="error">
              {validationMessage}
            </Typography>
          </FormHelperText>
        ) : null}
      </FormControl>
    );
  }, [
    classes,
    hasClearOption,
    input,
    isMultiple,
    is_publish,
    label,
    labelWidth,
    name,
    onChange,
    options,
    validationMessage,
    value,
    variant,
  ]);
  return selectMemo;
};

export default CustomSelect;
