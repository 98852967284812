import { useCallback, useState } from "react";
import MainLayout from "../../../components/main-layout";
import SectionContainerLayout from "../../../components/section-container-layout";
import AgendaForm from "./form";
import { useHistory } from "react-router-dom";
import { map } from "lodash";
import { insert } from "../helpers";

const AgendaAddPage = () => {
	const [isLoading, setIsLoading] = useState(false);
	const history = useHistory();

	const handleSubmit = useCallback(
		async (values) => {
			const { name, description, dateTime, locations } = values;
			const locationCodes =
				locations && locations.length > 0
					? map(locations, (item) => item.code)
					: [];
			setIsLoading(true);
			insert({
				body: {
					name,
					description,
					dateTime: new Date(dateTime).toISOString(),
					locationCodes,
				},
				link: "agenda",
				history,
				setIsLoading,
				path: "agenda",
			});
		},
		[history],
	);
	return (
		<MainLayout>
			<SectionContainerLayout title="Add agenda">
				<AgendaForm
					submitCallBack={(values) => handleSubmit(values)}
					isLoading={isLoading}
					setIsLoading={setIsLoading}
					type="add"
				/>
			</SectionContainerLayout>
		</MainLayout>
	);
};

export default AgendaAddPage;
