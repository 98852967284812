import React from "react";

import {
	Card,
	Checkbox,
	CardActionArea,
	CardMedia,
	Typography,
} from "@material-ui/core";
import { useStoreState } from "easy-peasy";

const CardImage = ({
	image,
	order,
	isChecked = false,
	onItemSelect,
	...cardActionProps
}) => {
	const handleItemChecked = (e) => {
		if (onItemSelect) onItemSelect(e);
	};

	const currentUser = useStoreState((state) => state.user.currentUser);
	return (
		<Card
			style={{
				backgroundColor: "transparent",
			}}
		>
			<CardActionArea {...cardActionProps}>
				<CardMedia
					{...cardActionProps}
					style={{ height: "200px" }}
					image={`${process.env.REACT_APP_API_URL}/static/${image}`}
				/>
			</CardActionArea>
			{currentUser.user_account_type.type !== "Viewer" ? (
				<Checkbox checked={isChecked} onChange={handleItemChecked} />
			) : null}
			<Typography variant="bold" color="textPrimary" gutterBottom>
				Order: {order}
			</Typography>
		</Card>
	);
};

export default CardImage;
