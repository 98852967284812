const colors = {
	white: "#F1F1F2",
	darkWhite: "#ececec",
	lightGreen: "#A4C627",
	darkBlue: "#101944",
	red: "#F44236",
	orange: "#FF9900",
	lightBlue: "#90C9F9",
	darkGray: "#656368",
	lightGray: "#ADAAB1",
	extraLightGray: "#F1F2F2",
};

export default colors;
