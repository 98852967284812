import React, { useEffect, useState, useCallback } from "react";
import ModuleActionBar from "../../../../components/module-action-bar";
import { Grid, Button, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CustomTextField from "../../../../components/custom-text-field";
import * as yup from "yup";
import { useFormik } from "formik";
import Spinner from "../../../../components/spinner";
import moment from "moment";
import CustomAutocomplete from "../../../../components/custom-autocomplete";
import { fetch } from "../../helpers";

const initialValues = {
	name: "",
	description: "",
	dateTime: moment().format("YYYY-MM-DDTHH:mm"),
	locations: [],
};

const AgendaForm = ({
	currentData,
	submitCallBack,
	setIsLoading,
	isLoading = false,
	type,
}) => {
	const history = useHistory();
	const [allLocations, setAllLocations] = useState([]);
	const validationSchema = yup.object().shape({
		name: yup.string().required("required"),
		description: yup.string().required("required"),
		dateTime: yup.string().required("required"),
		locations: yup.array().min(1).required("required"),
	});

	const formik = useFormik({
		initialValues,
		validationSchema,
		enableReinitialize: false,
		onSubmit: (values) => {
			submitCallBack(values);
		},
	});
	const fetchLocations = useCallback(async () => {
		fetch({
			pathname: "location",
			searchText: "",
			setData: setAllLocations,
			setIsLoading,
		});
	}, [setIsLoading]);

	useEffect(() => {
		fetchLocations();
	}, [fetchLocations]);

	useEffect(() => {
		if (currentData && type === "edit") {
			setIsLoading(true);
			formik.setValues({
				name: currentData.name ? currentData.name : "",
				description: currentData.description
					? currentData.description
					: "",
				dateTime: currentData.dateTime
					? moment(currentData.dateTime).format("YYYY-MM-DDTHH:mm")
					: moment().format("YYYY-MM-DDTHH:mm"),

				locations:
					currentData.locations && currentData.locations.length > 0
						? currentData.locations
						: [],
			});
			setTimeout(() => {
				setIsLoading(false);
			}, 300);
		}
		//eslint-disable-next-line
	}, [currentData]);

	return (
		<>
			{!isLoading &&
				((type === "edit" && currentData) || type === "add") && (
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<Button
								color="primary"
								onClick={() => history.push("/agendas/agenda")}
							>
								<ArrowBackIcon />
								<Typography component="span" color="primary">
									{"back"}
								</Typography>
							</Button>
						</Grid>

						<Grid item xs={12} sm={6}>
							<CustomTextField
								label="Name"
								name="name"
								validationMessage={
									formik.touched.name && formik.errors.name
										? formik.errors.name
										: ""
								}
								value={formik.values.name}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
							/>
						</Grid>

						<Grid item xs={12} sm={6}>
							<CustomTextField
								label="Description"
								name="description"
								validationMessage={
									formik.touched.description &&
									formik.errors.description
										? formik.errors.description
										: ""
								}
								value={formik.values.description}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
							/>
						</Grid>

						<Grid item xs={12} sm={6}>
							<CustomTextField
								type="datetime-local"
								label="Date & Time"
								name="dateTime"
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								validationMessage={
									formik.errors.dateTime &&
									formik.touched.dateTime
										? formik.errors.dateTime
										: ""
								}
								value={formik.values.dateTime}
							/>
						</Grid>

						<Grid item xs={12} sm={6}>
							<CustomAutocomplete
								label="Locations"
								name="locations"
								isMultiple={true}
								validationMessage={
									formik.touched.locations &&
									formik.errors.locations
										? formik.errors.locations
										: ""
								}
								onChange={async (e, value) => {
									formik.setFieldValue("locations", value);
								}}
								options={allLocations}
								// defaultValue={formik.values.locations}
								value={formik.values.locations}
								onBlur={formik.handleBlur}
							/>
						</Grid>

						<Grid item xs={12}>
							<ModuleActionBar
								isDelete={false}
								submitLabel={
									type === "add" ? "Add" : "Apply changes"
								}
								cancelLabel="cancel"
								cancelOnClick={() =>
									history.push("/agendas/agenda")
								}
								submitOnClick={formik.handleSubmit}
							/>
						</Grid>
					</Grid>
				)}
			<Spinner open={isLoading} />
		</>
	);
};

export default AgendaForm;
