import {
	Grid,
	TextField,
	Typography,
	Box,
	Button,
	FormHelperText,
	FormControlLabel,
	Checkbox,
} from "@material-ui/core";
import { DropzoneArea } from "material-ui-dropzone";
import React, { useState, useEffect } from "react";
import MainLayout from "../../components/main-layout";
import SectionContainerLayout from "../../components/section-container-layout";
import { Autocomplete } from "@material-ui/lab";
import { createFilterOptions } from "@material-ui/lab/Autocomplete";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { addNewPartner } from "../../validations/partner-validator";
import { LocationSelectMap } from "../../components/location-map";

const DEFAULT_POSITION = {
	lat: 25.276987,
	lng: 55.296249,
};

const AddPartnerPage = () => {
	const [jsonFormData, setJsonFormData] = useState({ isOnHomepage: false });
	const [imageFile, setImageFile] = useState({});
	const [point, setPoint] = useState([
		DEFAULT_POSITION.lat,
		DEFAULT_POSITION.lng,
	]);
	const [partnerCategory, setPartnerCategory] = useState();
	const [partnerCategoryOptions, setPartnerCategoryOptions] = useState([]);
	const [validationErrors, setValidationErrors] = useState([]);
	const filter = createFilterOptions();
	const history = useHistory();

	useEffect(() => {
		try {
			axios
				.get("/utility/partner-categories")
				.then((_response) => {
					if (_response.status === 200) {
						let options = [];

						_response.data.forEach((_data) => {
							options.push({
								code: _data.code,
								name: _data.name,
							});
						});
						setPartnerCategoryOptions(options);
					}
				})
				.catch((err) => console.log(err));
		} catch (err) {
			console.log(err);
		}
	}, []);

	const renderInput = (params) => (
		<TextField
			{...params}
			label="Partner Category"
			variant="outlined"
			required
		/>
	);

	const getOptionLabel = (option) => {
		// Value selected with enter, right from the input
		if (typeof option === "string") {
			return option;
		}
		// Add "xxx" option created dynamically
		if (option.inputValue) {
			return option.inputValue;
		}
		// Regular option
		return option.name;
	};

	const filterOptions = (options, params) => {
		const filtered = filter(options, params);

		// Suggest the creation of a new value
		if (params.inputValue !== "") {
			filtered.push({
				inputValue: params.inputValue,
				name: `Add "${params.inputValue}"`,
			});
		}
		return filtered;
	};

	const handleFormChange = (e) => {
		setJsonFormData({
			...jsonFormData,
			...partnerCategory,
			[e.target.id || e.target.name]: e.target.value || e.target.checked,
		});
	};

	const handleImageUpload = (file) => {
		setImageFile(file[0]);
	};

	const handlePartnerCategoryChange = (event, newValue) => {
		if (typeof newValue === "string") {
			setPartnerCategory({
				name: newValue,
			});
		} else if (newValue && newValue.inputValue) {
			setPartnerCategory({
				name: newValue.inputValue,
			});
		} else {
			setPartnerCategory(newValue);
		}
	};

	const handleSubmit = () => {
		try {
			let errorMessages = {};
			if (
				jsonFormData.isOnHomepage === undefined ||
				jsonFormData.isOnHomepage === null
			) {
				setJsonFormData({
					...jsonFormData,
					isOnHomepage: false,
				});
			}
			addNewPartner
				.validate(jsonFormData, { abortEarly: false })
				.then(() => {
					let formData = new FormData();
					formData.append("imageFile", imageFile);

					for (let i in jsonFormData) {
						formData.append(i, jsonFormData[i]);
					}
					for (const value of point) {
						formData.append("point[]", value);
					}

					axios
						.post("/partner", formData, {
							headers: { "Content-Type": "multipart/form-data" },
						})
						.then((_response) => {
							if (_response.status === 200) {
								history.push("/partners");
							} else {
								console.log("error");
							}
						})
						.catch((err) => console.log(err));
				})
				.catch((err) => {
					err.inner.forEach((e) => {
						errorMessages[e.path] = e.message;
					});
					setValidationErrors(errorMessages);
				});
		} catch (err) {}
	};

	return (
		<MainLayout>
			<SectionContainerLayout title="ADD PARTNER">
				<Grid container spacing={3}>
					<Grid item container xs={12} md={6} spacing={2}>
						<Grid item xs={12}>
							<Autocomplete
								value={partnerCategory}
								onChange={handlePartnerCategoryChange}
								options={partnerCategoryOptions}
								renderInput={renderInput}
								renderOption={(option) => option.name}
								getOptionLabel={getOptionLabel}
								filterOptions={filterOptions}
								selectOnFocus
								clearOnBlur
								handleHomeEndKeys
								freeSolo
							/>
							{validationErrors.name ? (
								<FormHelperText>
									<Typography variant="body2" color="error">
										{validationErrors.name}
									</Typography>
								</FormHelperText>
							) : null}
						</Grid>
						<Grid item xs={12}>
							<TextField
								label="Website URL"
								id="websiteUrl"
								variant="outlined"
								fullWidth
								onChange={handleFormChange}
							/>
						</Grid>
						<Grid item xs={12}>
							<Typography variant="body1" color="textPrimary">
								Details
							</Typography>
							<Typography variant="body2" color="textSecondary">
								Filling up those information will generate an
								inner page
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<TextField
								label="Title"
								id="title"
								variant="outlined"
								error={validationErrors.title}
								helperText={validationErrors.title}
								fullWidth
								required
								onChange={handleFormChange}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								label="Description"
								id="description"
								variant="outlined"
								fullWidth
								multiline
								onChange={handleFormChange}
								rows={4}
							/>
						</Grid>
					</Grid>
					<Grid item container xs={12} md={6} spacing={2}>
						<Box m={1} width={1}>
							<DropzoneArea
								filesLimit={1}
								onChange={(file) => handleImageUpload(file)}
								acceptedFiles={["image/*"]}
							/>
							<FormHelperText>
								Width: 500px, Height: 300px
							</FormHelperText>
						</Box>
					</Grid>
					<Grid item container xs={12} md={6} spacing={2}>
						<Grid item xs={12}>
							<TextField
								label="Linkedin"
								placeholder="URL"
								id="linkedinUrl"
								variant="outlined"
								onChange={handleFormChange}
								fullWidth
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								label="Facebook"
								id="facebookUrl"
								placeholder="URL"
								variant="outlined"
								onChange={handleFormChange}
								fullWidth
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								label="Order"
								placeholder="Order"
								id="order"
								defaultValue="0"
								onChange={handleFormChange}
								variant="outlined"
								fullWidth
								type="number"
								InputProps={{ inputProps: { min: 0 } }}
							/>
						</Grid>
					</Grid>
					<Grid item container xs={12} md={6} spacing={2}>
						<Grid item xs={12}>
							<TextField
								label="Twitter"
								placeholder="URL"
								id="twitterUrl"
								variant="outlined"
								onChange={handleFormChange}
								fullWidth
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								label="Instagram"
								placeholder="URL"
								id="instagramUrl"
								onChange={handleFormChange}
								variant="outlined"
								fullWidth
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								label="Year"
								placeholder="Year"
								id="year"
								onChange={handleFormChange}
								error={validationErrors.year}
								helperText={validationErrors.year}
								variant="outlined"
								fullWidth
							/>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<div
							style={{
								height: "600px",
								width: "100%",
								borderRadius: "5px",
								overflow: "hidden",
								position: "relative",
							}}
						>
							<LocationSelectMap
								onChange={setPoint}
								absoluteMarker
								value={point}
							/>
						</div>
					</Grid>
					<Grid item container xs={12}>
						<Grid item xs={12} md={6}>
							<FormControlLabel
								control={
									<Checkbox
										id="isOnHomepage"
										onChange={handleFormChange}
									/>
								}
								label="Add to homepage"
							/>
						</Grid>
						<Grid item xs={12} md={6} align="right">
							<Button
								onClick={() => history.push("/partners")}
								style={{ textTransform: "capitalize" }}
							>
								Cancel
							</Button>
							<Button
								variant="contained"
								color="primary"
								onClick={handleSubmit}
								style={{ textTransform: "capitalize" }}
							>
								Submit
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</SectionContainerLayout>
		</MainLayout>
	);
};

export default AddPartnerPage;
