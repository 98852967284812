import { useState, useCallback, useEffect } from "react";
import MainLayout from "../../../components/main-layout";
import SectionContainerLayout from "../../../components/section-container-layout";
import ScrollableTabsButtonForce from "../components/tabs";
import { useHistory } from "react-router-dom";
import colors from "../../../constants/colors";
import Clamp from "react-multiline-clamp";
import TableCustomHeader from "../components/table-header";
import {
	Grid,
	Box,
	TableContainer,
	Paper,
	Table,
	TableRow,
	makeStyles,
	useTheme,
	TableCell,
	Typography,
	TableBody,
	Checkbox,
} from "@material-ui/core";
import moment from "moment";
import ModuleToolbar from "../../../components/module-toolbar";
import { deleteData } from "../axios";
import Spinner from "../../../components/spinner";
import { fetch } from "../helpers";
import { useDebounce } from "use-debounce/lib";
import CardImageTitleDescription from "../../../components/card-image-title-description";
import { useStoreState } from "easy-peasy";
const useStyles = makeStyles(() => ({
	tableHeader: {
		backgroundColor: colors.darkBlue,
	},
	rowHover: {
		"&:hover": {
			cursor: "pointer",
		},
	},
}));

const TopicPage = () => {
	const history = useHistory();
	const [selected, setSelected] = useState([]);
	const [data, setData] = useState([]);
	const [search, setSearch] = useState();
	const [filters, setFilters] = useState([]);
	const [filterDataTabs, setFilterDataTabs] = useState({});
	const [searchText] = useDebounce(search, 1000);
	const [isGridView, setIsGridView] = useState(true);
	const [isListView, setListView] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [allLocations, setAllLocations] = useState([]);
	const [isFilterToggled, setIsFilterToggled] = useState(false);
	const currentUser = useStoreState((state) => state.user.currentUser);
	const theme = useTheme();
	const classes = useStyles();

	const toggleGridView = useCallback(() => {
		setIsGridView(true);
		setListView(false);
	}, []);

	const toggleListView = useCallback(() => {
		setIsGridView(false);
		setListView(true);
	}, []);

	const fetchList = useCallback(() => {
		fetch({
			pathname: "topic",
			setIsLoading,
			filters: filters,
			searchText: searchText,
			setData,
		});
	}, [filters, searchText]);

	const fetchLocations = useCallback(() => {
		fetch({
			pathname: "location",
			searchText: "",
			setData: setAllLocations,
			setIsLoading: () => {},
		});
	}, []);

	useEffect(() => {
		fetchLocations();
	}, [fetchLocations]);

	useEffect(() => {
		if (data && data.length > 0) {
			setFilterDataTabs([
				{
					type: "dateTime",
					label: "startDateTime",
				},
				{
					type: "dateTime",
					label: "endDateTime",
				},
				{
					type: "text",
					label: "location",
					data: allLocations,
				},
			]);
		}
		//eslint-disable-next-line
	}, [data]);

	useEffect(() => {
		fetchList();
	}, [fetchList]);

	const handleSearch = (e) => {
		setSearch(e.target.value);
	};

	const handleSelectAllChange = useCallback(
		(event) => {
			if (event.target.checked) {
				const newSelected = data.map((n) => n.code);
				setSelected(newSelected);
				return;
			}
			setSelected([]);
		},
		[data],
	);

	// const handleFilterData = useCallback(
	// 	(value, key) => {
	// 		try {
	// 			setFilters({ ...filters, [key]: value });
	// 		} catch (err) {
	// 			console.log(err);
	// 		}
	// 	},
	// 	[filters],
	// );
	const handleDeleteSelected = useCallback(async () => {
		const response = await deleteData({ path: "topic", selected });

		if (response) {
			setSelected([]);
			fetchList();
		} else {
			console.log("error");
		}
	}, [selected, fetchList]);

	const handleItemSelectChange = (e, data) => {
		const selectedIndex = selected.indexOf(data);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, data);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1),
			);
		}

		setSelected(newSelected);
	};

	const isSelected = (data) => selected.indexOf(data) !== -1;

	const handleFilterChange = useCallback(
		(e, module) => {
			switch (module) {
				case "location":
					setFilters({ ...filters, location: e.target.value });
					break;
				case "startdatetime":
					setFilters({ ...filters, startDateTime: e.target.value });
					break;
				case "enddatetime":
					setFilters({ ...filters, endDateTime: e.target.value });
					break;

				default: {
				}
			}
		},
		[filters],
	);

	return (
		<MainLayout>
			<SectionContainerLayout title="Topics">
				<ScrollableTabsButtonForce />

				<Box mt={5}>
					<Grid container>
						<Grid item xs={12}>
							<ModuleToolbar
								totalSelected={selected.length}
								onSelectAllClick={handleSelectAllChange}
								onDeleteSelected={handleDeleteSelected}
								addModuleUrl="/agendas/topic/add"
								handleGridView={toggleGridView}
								handleSearchChange={handleSearch}
								handleListView={toggleListView}
								showCustomFilter={false}
								showFilters={true}
								filterDataTabs={filterDataTabs}
								isFilterToggled={isFilterToggled}
								// handleFilterData={handleFilterData}
								filterIcon={true}
								changeStatusAction={false}
								handleFilterChange={handleFilterChange}
								onClearFiltersClick={() => setFilters({})}
								theFilters={filters}
								handleToggleFilter={() => {
									if (isFilterToggled) {
										setFilters({});
									}
									setIsFilterToggled(!isFilterToggled);
								}}
							/>
						</Grid>
						{!isLoading ? (
							<Grid item container xs={12} spacing={3}>
								{isGridView && data.length > 0 ? (
									data.map((_item) => (
										<Grid
											item
											xs={12}
											md={4}
											lg={3}
											key={_item.code}
										>
											<CardImageTitleDescription
												hasStatus={true}
												onClick={() =>
													history.push(
														`/agendas/topic/edit/${_item.code}`,
													)
												}
												title={_item.name}
												description={
													_item.description
														? _item.description
														: ""
												}
												startDateTime={moment(
													_item.startDateTime,
												).format("YYYY-MM-DDTHH:mm")}
												endDateTime={moment(
													_item.endDateTime,
												).format("YYYY-MM-DDTHH:mm")}
												location={
													_item.location
														? _item.location.name
														: ""
												}
												isChecked={isSelected(
													_item.code,
												)}
												onItemSelect={(e) =>
													handleItemSelectChange(
														e,
														_item.code,
													)
												}
											/>
										</Grid>
									))
								) : isListView && data.length > 0 ? (
									<TableContainer component={Paper}>
										<Table>
											<TableCustomHeader
												currentUser={currentUser}
												theme={theme}
												classes={classes}
												headers={[
													"Name",
													"Description",
													"Start Date",
													"End Date",
													"Location",
												]}
											/>
											<TableBody>
												{data &&
													data.length > 0 &&
													data.map((_item, index) => (
														<Box
															key={_item.code}
															clone
															className={
																classes.rowHover
															}
														>
															<TableRow
																key={index}
																scope="row"
																hover
															>
																{currentUser
																	.user_account_type
																	.type !==
																"Viewer" ? (
																	<TableCell
																		width={
																			1
																		}
																	>
																		<Checkbox
																			checked={isSelected(
																				_item.code,
																			)}
																			onClick={(
																				e,
																			) =>
																				handleItemSelectChange(
																					e,
																					_item.code,
																				)
																			}
																			inputProps={{
																				"aria-labelledby": 1,
																			}}
																		/>
																	</TableCell>
																) : null}
																<TableCell
																	width={100}
																	align="center"
																	onClick={() =>
																		history.push(
																			`/agendas/topic/edit/${_item.code}`,
																		)
																	}
																>
																	<Typography
																		variant="body1"
																		color="textPrimary"
																	>
																		{
																			_item.name
																		}
																	</Typography>
																</TableCell>

																<TableCell
																	width={100}
																	align="center"
																	onClick={() =>
																		history.push(
																			`/agendas/topic/edit/${_item.code}`,
																		)
																	}
																>
																	<Typography
																		variant="body1"
																		color="textPrimary"
																	>
																		<Clamp
																			lines={
																				2
																			}
																		>
																			{
																				_item.description
																			}
																		</Clamp>
																	</Typography>
																</TableCell>

																<TableCell
																	width={100}
																	align="center"
																	onClick={() =>
																		history.push(
																			`/agendas/topic/edit/${_item.code}`,
																		)
																	}
																>
																	<Typography
																		variant="body1"
																		color="textPrimary"
																	>
																		{moment(
																			_item.startDateTime,
																		).format(
																			"YYYY-MM-DDTHH:mm",
																		)}
																	</Typography>
																</TableCell>

																<TableCell
																	width={100}
																	align="center"
																	onClick={() =>
																		history.push(
																			`/agendas/topic/edit/${_item.code}`,
																		)
																	}
																>
																	<Typography
																		variant="body1"
																		color="textPrimary"
																	>
																		{moment(
																			_item.endDateTime,
																		).format(
																			"YYYY-MM-DDTHH:mm",
																		)}
																	</Typography>
																</TableCell>

																<TableCell
																	width={100}
																	align="center"
																	onClick={() =>
																		history.push(
																			`/agendas/topic/edit/${_item.code}`,
																		)
																	}
																>
																	<Typography
																		variant="body1"
																		color="textPrimary"
																	>
																		{
																			_item
																				.location
																				.name
																		}
																	</Typography>
																</TableCell>
															</TableRow>
														</Box>
													))}
											</TableBody>
										</Table>
									</TableContainer>
								) : (
									<Typography
										variant="body1"
										color="textPrimary"
										align="center"
									>
										There are no results
									</Typography>
								)}
							</Grid>
						) : (
							<Spinner open={isLoading} />
						)}
					</Grid>
				</Box>
			</SectionContainerLayout>
		</MainLayout>
	);
};

export default TopicPage;
