import { Switch, BrowserRouter as Router, Route } from "react-router-dom";
import LoginPage from "./pages/login";
import ChangePasswordPage from "./pages/change-password";
import ResetPasswordPage from "./pages/reset-password/index";
import EmailConfirmedResetPassword from "./pages/reset-password/email-confirmed-reset-password";
import DashboardPage from "./pages/dashboard";
import NewsPage from "./pages/news/index";
import AddNewsPage from "./pages/news/add";
import SpeakersPage from "./pages/speakers/index";
import AddSpeakerPage from "./pages/speakers/add";
import EditSpeakerPage from "./pages/speakers/edit";
import EditNewsPage from "./pages/news/edit";
import KnowledgeHubPage from "./pages/knowledge-hub";
import AddKnowledgeHubPage from "./pages/knowledge-hub/add";
import EditKnowledgeHubPage from "./pages/knowledge-hub/edit";
import PartnersPage from "./pages/partners";
import AddPartnerPage from "./pages/partners/add";
import EditPartnerPage from "./pages/partners/edit";
import AlbumPage from "./pages/album/index";
import AddAlbumPage from "./pages/album/add";
import DigitalSeriesPage from "./pages/digital-series";
import AddDigitalSeriesPage from "./pages/digital-series/add";
import CarouselPage from "./pages/carousels";
import AddCarouselPage from "./pages/carousels/add";
import EditCarouselPage from "./pages/carousels/edit";
import EditDigitalSeriesPage from "./pages/digital-series/edit";
import EditAlbumPage from "./pages/album/edit";
import SupportPage from "./pages/support/index";
import UserLogsPage from "./pages/user-logs";
import UserAccountsPage from "./pages/user-accounts";
import AddUserPage from "./pages/user-accounts/add";
import SettingsPage from "./pages/settings";
import SettingsPageManageCategory from "./pages/settings/manage-categories";
import SettingsPageManageStreamingURL from "./pages/settings/manage-streaming-url";
import InitiativesPage from "./pages/initiatives";
import AddInitiativePage from "./pages/initiatives/add";
import EditInitiativePage from "./pages/initiatives/edit";
import AddInitiativePartnerPage from "./pages/initiative-partner/add";
import EditInitiativePartnerPage from "./pages/initiative-partner/edit";
import AgendaPage from "./pages/agendas/agenda";
import AgendaAddPage from "./pages/agendas/agenda/add";
import AgendaEditPage from "./pages/agendas/agenda/edit";
import TopicPage from "./pages/agendas/topic";
import TopicAddPage from "./pages/agendas/topic/add";
import TopicEditPage from "./pages/agendas/topic/edit";
import LocationPage from "./pages/agendas/location";
import LocationAddPage from "./pages/agendas/location/add";
import LocationEditPage from "./pages/agendas/location/edit";
import GroupPage from "./pages/agendas/group";
import GroupAddPage from "./pages/agendas/group/add";
import GroupEditPage from "./pages/agendas/group/edit";

const App = () => {
	return (
		<Router>
			<Switch>
				<Route exact path="/">
					<LoginPage />
				</Route>
				<Route exact path="/login">
					<LoginPage />
				</Route>
				<Route exact path="/change-password">
					<ChangePasswordPage />
				</Route>
				<Route exact path="/reset-password">
					<ResetPasswordPage />
				</Route>
				<Route exact path="/email-confirmed-reset-password">
					<EmailConfirmedResetPassword />
				</Route>
				<Route exact path="/dashboard">
					<DashboardPage />
				</Route>
				<Route exact path="/news">
					<NewsPage />
				</Route>
				<Route exact path="/news/add">
					<AddNewsPage />
				</Route>
				<Route exact path="/news/edit">
					<EditNewsPage />
				</Route>
				<Route exact path="/speakers">
					<SpeakersPage />
				</Route>
				<Route exact path="/speakers/add">
					<AddSpeakerPage />
				</Route>
				<Route exact path="/speakers/edit">
					<EditSpeakerPage />
				</Route>
				<Route exact path="/knowledge-hub">
					<KnowledgeHubPage />
				</Route>
				<Route exact path="/knowledge-hub/add">
					<AddKnowledgeHubPage />
				</Route>
				<Route exact path="/knowledge-hub/edit">
					<EditKnowledgeHubPage />
				</Route>
				<Route exact path="/initiatives">
					<InitiativesPage />
				</Route>
				<Route exact path="/initiatives/add">
					<AddInitiativePage />
				</Route>
				<Route exact path="/initiatives/edit">
					<EditInitiativePage />
				</Route>

				{/* AGENDA */}
				<Route exact path="/agendas/agenda" component={AgendaPage} />
				<Route
					exact
					path="/agendas/agenda/add"
					component={AgendaAddPage}
				/>
				<Route
					exact
					path="/agendas/agenda/edit/:code"
					component={AgendaEditPage}
				/>
				{/* TOPICS */}
				<Route exact path="/agendas/topic" component={TopicPage} />
				<Route
					exact
					path="/agendas/topic/add"
					component={TopicAddPage}
				/>
				<Route
					exact
					path="/agendas/topic/edit/:code"
					component={TopicEditPage}
				/>

				{/* GROUPS */}
				<Route exact path="/agendas/group" component={GroupPage} />
				<Route
					exact
					path="/agendas/group/add"
					component={GroupAddPage}
				/>
				<Route
					exact
					path="/agendas/group/edit/:code"
					component={GroupEditPage}
				/>
				{/* LOCATION */}
				<Route
					exact
					path="/agendas/location"
					component={LocationPage}
				/>

				<Route
					exact
					path="/agendas/location/add"
					component={LocationAddPage}
				/>

				<Route
					exact
					path="/agendas/location/edit/:code"
					component={LocationEditPage}
				/>

				<Route
					exact
					path="/initiatives/edit/:initiativeCode/initiative-partner/add"
				>
					<AddInitiativePartnerPage />
				</Route>
				<Route
					exact
					path="/initiatives/edit/:initiativeCode/initiative-partner/edit"
				>
					<EditInitiativePartnerPage />
				</Route>
				<Route exact path="/partners">
					<PartnersPage />
				</Route>
				<Route exact path="/partners/add">
					<AddPartnerPage />
				</Route>
				<Route exact path="/partners/edit">
					<EditPartnerPage />
				</Route>
				<Route exact path="/albums">
					<AlbumPage />
				</Route>
				<Route exact path="/albums/add">
					<AddAlbumPage />
				</Route>
				<Route exact path="/albums/edit">
					<EditAlbumPage />
				</Route>
				<Route exact path="/digital-series">
					<DigitalSeriesPage />
				</Route>
				<Route exact path="/digital-series/add">
					<AddDigitalSeriesPage />
				</Route>
				<Route exact path="/carousels">
					<CarouselPage />
				</Route>
				<Route exact path="/carousels/add">
					<AddCarouselPage />
				</Route>
				<Route exact path="/carousels/edit">
					<EditCarouselPage />
				</Route>
				<Route exact path="/digital-series/edit">
					<EditDigitalSeriesPage />
				</Route>
				<Route exact path="/support">
					<SupportPage />
				</Route>
				<Route exact path="/user-logs">
					<UserLogsPage />
				</Route>
				<Route exact path="/users-accounts">
					<UserAccountsPage />
				</Route>
				<Route exact path="/users-accounts/add">
					<AddUserPage />
				</Route>
				<Route exact path="/settings">
					<SettingsPage />
				</Route>
				<Route exact path="/settings/manage-categories">
					<SettingsPageManageCategory />
				</Route>
				<Route exact path="/settings/manage-streaming-url">
					<SettingsPageManageStreamingURL />
				</Route>
			</Switch>
		</Router>
	);
};

export default App;
