import React, { useEffect, useState } from "react";
import { sortableContainer, sortableElement } from "react-sortable-hoc";
import CardImageTitleDescription from "../../../components/card-image-title-description";
import { Grid } from "@material-ui/core";
import { useStoreState } from "easy-peasy";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { forEach } from "lodash";

const PreviousTab = ({ data, isChecked, onItemSelect }) => {
	const currentUser = useStoreState((state) => state.user.currentUser);
	const history = useHistory();
	const [updatedData, setUpdatedData] = useState(data);
	const SortableItem = sortableElement(({ _speaker }) => {
		return (
			<Grid item xs={12} sm={6} lg={3} xl={2}>
				<CardImageTitleDescription
					hasEditIcon
					handleEditClicked={() => {
						if (currentUser.user_account_type.type !== "Viewer") {
							history.push({
								pathname: "/speakers/edit",
								state: {
									speaker: _speaker,
								},
							});
						}
					}}
					id={_speaker.code}
					hasStatus={false}
					image={_speaker.file.code}
					title={
						_speaker.SalutationId === 7
							? _speaker.firstName + " " + _speaker.lastName
							: _speaker.salutation.name +
							  " " +
							  _speaker.firstName +
							  " " +
							  _speaker.lastName
					}
					description={
						_speaker.designation + ", " + _speaker.organization
					}
					isChecked={isChecked(_speaker.code)}
					onItemSelect={(e) => onItemSelect(e, _speaker.code)}
				/>
			</Grid>
		);
	});

	const SortableContainer = sortableContainer(({ children }) => {
		return (
			<Grid container spacing={3}>
				{children}
			</Grid>
		);
	});

	const reorder = (list, startIndex, endIndex) => {
		const result = Array.from(list);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);
		result.forEach((_result, index) => (_result.homepageOrder = index));

		return result;
	};

	const handleDrag = (oldIndex, newIndex) => {
		const speakers = reorder(updatedData, oldIndex, newIndex);

		const _result = [];

		forEach(updatedData, (_dt, idx) => {
			if (speakers[idx] !== _dt)
				_result.push({
					index: idx,
					code: speakers[idx].code,
				});
		});
		try {
			axios
				.post("/speaker/homepage", { newOrder: _result })
				.then((_response) => {
					setUpdatedData(speakers);
				})
				.catch((err) => console.log(err));
		} catch (err) {
			console.log(err);
		}
	};

	useEffect(() => {
		setUpdatedData(data);
	}, [data]);

	return (
		<SortableContainer
			axis={"xy"}
			pressDelay={100}
			onSortEnd={({ oldIndex, newIndex }) =>
				handleDrag(oldIndex, newIndex)
			}
		>
			{updatedData.map((_data, index) => (
				<SortableItem key={index} index={index} _speaker={_data} />
			))}
		</SortableContainer>
	);
};

export default PreviousTab;
