import {
	FormControl,
	Grid,
	InputLabel,
	Select,
	TextField,
	Button,
	Typography,
	FormHelperText,
	MenuItem,
	Box,
	LinearProgress,
} from "@material-ui/core";
import { DropzoneArea } from "material-ui-dropzone";
import React, { useEffect, useState } from "react";
import MainLayout from "../../components/main-layout";
import SectionContainerLayout from "../../components/section-container-layout";
import axios from "axios";
import { useHistory } from "react-router";
import { addNewCarousel } from "../../validations/carousel-validator";
const AddCarouselPage = () => {
	const [limits, setLimits] = useState({
		2021: {
			count: 0,
		},
		2020: {
			count: 0,
		},
		2019: {
			count: 0,
		},
		2017: {
			count: 0,
		},
	});
	const [jsonFormData, setJsonFormData] = useState({ year: "2021" });
	const [carouselLimitErrorMessage, setCarouselLimitErrorMessage] =
		useState();
	const [imageFile, setImageFile] = useState(null);
	const [videoFile, setVideoFile] = useState(null);
	const [validationErrors, setValidationErrors] = useState({});
	const [isSubmitted, setIsSubmitted] = useState(false);
	const history = useHistory();
	const [showProgress, setShowProgress] = useState(false);
	const [progress, setProgress] = useState(0);

	const handleFormChange = (e) => {
		if (e.target.name === "year") {
			if (limits[e.target.value].count >= 6) {
				setCarouselLimitErrorMessage(
					`You can only have up to 6 carousels in the year ${e.target.value}, please delete one to add a new one`,
				);
			} else {
				setCarouselLimitErrorMessage(null);
				setJsonFormData({
					...jsonFormData,
					[e.target.id || e.target.name]: e.target.value,
				});
			}
		} else {
			setJsonFormData({
				...jsonFormData,
				[e.target.id || e.target.name]: e.target.value,
			});
		}

		switch (e.target.name || e.target.id) {
			case "title": {
				setValidationErrors({
					...validationErrors,
					title: null,
				});
				break;
			}
			case "year": {
				setValidationErrors({
					...validationErrors,
					year: null,
				});
				break;
			}
			default: {
				setValidationErrors({ ...validationErrors });
			}
		}
	};

	const handleImageFile = (file) => {
		setImageFile(file[0]);
	};

	const handleVideoFile = (file) => {
		setVideoFile(file[0]);
	};

	useEffect(() => {
		try {
			let carouselLimits = {
				2021: {
					count: 0,
				},
				2020: {
					count: 0,
				},
				2019: {
					count: 0,
				},
				2017: {
					count: 0,
				},
			};

			axios.get("/carousel").then((_response) => {
				if (_response.status === 200) {
					_response.data.forEach((_data) => {
						switch (_data.year) {
							case "2021": {
								carouselLimits["2021"].count++;
								break;
							}
							case "2020": {
								carouselLimits["2020"].count++;
								break;
							}
							case "2019": {
								carouselLimits["2019"].count++;
								break;
							}
							case "2017": {
								carouselLimits["2017"].count++;
								break;
							}
							default: {
							}
						}
						setLimits(carouselLimits);
					});
				} else {
					setLimits(carouselLimits);
				}
			});
		} catch (err) {
			console.log(err);
		}
	}, [setLimits]);

	const handleSubmit = () => {
		try {
			let errorMessages = [];
			addNewCarousel
				.validate(jsonFormData, { abortEarly: false })
				.then(() => {
					if (!imageFile) {
						return;
					}

					if (!videoFile) {
						return;
					}

					let formData = new FormData();
					formData.append("imageFile", imageFile);
					formData.append("videoFile", videoFile);

					for (let i in jsonFormData) {
						formData.append(i, jsonFormData[i]);
					}

					axios
						.post("/carousel", formData, {
							headers: { "Content-Type": "multipart/form-data" },
							onUploadProgress: (data) => {
								setShowProgress(true);
								setProgress(
									Math.round(
										(100 * data.loaded) / data.total,
									),
								);
							},
						})
						.then((_response) => {
							if (_response.status === 200) {
								history.push("/carousels");
							}
						})
						.catch((err) => console.log(err));
				})
				.catch((err) => {
					err.inner.forEach((e) => {
						errorMessages[e.path] = e.message;
					});
					setValidationErrors(errorMessages);
					setIsSubmitted(true);
				});
		} catch (err) {
			console.log(err);
		}
	};

	return (
		<MainLayout>
			<SectionContainerLayout title="ADD CAROUSEL">
				<Grid container spacing={3}>
					<Grid item container xs={12} md={6} spacing={3}>
						<Grid item xs={12} md={4}>
							<FormControl fullWidth variant="outlined">
								<InputLabel id="year">Year *</InputLabel>
								<Select
									name="year"
									labelId="year"
									required
									label="Year"
									onChange={handleFormChange}
									error={validationErrors.year}
								>
									<MenuItem value={"2021"}>2021</MenuItem>
									<MenuItem value={"2020"}>2020</MenuItem>
									<MenuItem value={"2019"}>2019</MenuItem>
									<MenuItem value={"2017"}>2017</MenuItem>
								</Select>
								{validationErrors.year ? (
									<FormHelperText color="error">
										<Typography
											variant="body2"
											color="error"
										>
											{validationErrors.year}
										</Typography>
									</FormHelperText>
								) : null}
								{carouselLimitErrorMessage ? (
									<FormHelperText error>
										{carouselLimitErrorMessage}
									</FormHelperText>
								) : null}
							</FormControl>
						</Grid>
						<Grid item xs={12} md={8}>
							<TextField
								id="title"
								required
								onChange={handleFormChange}
								variant="outlined"
								label="Title"
								error={validationErrors.title}
								helperText={validationErrors.title}
								fullWidth
							/>
						</Grid>
						<Grid item xs={12}>
							<DropzoneArea
								dropzoneText="Drag and drop carousel video here *"
								key={1}
								acceptedFiles={["video/mp4"]}
								filesLimit={1}
								maxFileSize={3221225472}
								onChange={(file) => handleVideoFile(file)}
							/>
							<FormHelperText>Video max size: 2GB</FormHelperText>

							{!videoFile && isSubmitted ? (
								<FormHelperText color="error">
									<Typography variant="body2" color="error">
										Video file is required
									</Typography>
								</FormHelperText>
							) : null}
						</Grid>
					</Grid>
					<Grid item container xs={12} md={6}>
						<Grid item xs={12}>
							<DropzoneArea
								dropzoneText="Drag and drop thumbnail here *"
								acceptedFiles={["image/*"]}
								filesLimit={1}
								onChange={(file) => handleImageFile(file)}
							/>

							<FormHelperText>
								Width: 540px, Height: 910px
							</FormHelperText>
							{!imageFile && isSubmitted ? (
								<FormHelperText color="error">
									<Typography variant="body2" color="error">
										Image file is required
									</Typography>
								</FormHelperText>
							) : null}
						</Grid>
					</Grid>
					<Grid item xs={12} align="right">
						<Button
							variant="text"
							onClick={() => history.push("/carousels")}
							style={{ textTransform: "capitalize" }}
						>
							Cancel
						</Button>
						<Button
							color="primary"
							variant="contained"
							onClick={handleSubmit}
							disabled={carouselLimitErrorMessage ? true : false}
							style={{ textTransform: "capitalize" }}
						>
							Submit
						</Button>
					</Grid>
					<Box clone pt={5}>
						<Grid item xs={12}>
							{showProgress ? (
								<LinearProgress
									variant="determinate"
									value={progress}
								/>
							) : null}
						</Grid>
					</Box>
				</Grid>
			</SectionContainerLayout>
		</MainLayout>
	);
};

export default AddCarouselPage;
