import {
	Grid,
	TextField,
	Box,
	Button,
	FormControlLabel,
	Checkbox,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import MainLayout from "../../components/main-layout";
import SectionContainerLayout from "../../components/section-container-layout";
import axios from "axios";
import { useHistory } from "react-router";

const EditDigitalSeriesPage = () => {
	const [jsonFormData, setJsonFormData] = useState({});
	const [isActive, setIsActive] = useState(false);
	const [isDataLoaded, setIsDataLoaded] = useState(false);
	const history = useHistory();
	// const location = useLocation();

	useEffect(() => {
		axios
			.get("/streamings/VIMEOURL")
			.then((_streamingURL) => {
				setJsonFormData((jsonFormData) => ({
					...jsonFormData,
					name1: _streamingURL.data.name1,
					url1: _streamingURL.data.url1,
					name2: _streamingURL.data.name2,
					url2: _streamingURL.data.url2,
					name3: _streamingURL.data.name3,
					url3: _streamingURL.data.url3,
					name4: _streamingURL.data.name4,
					url4: _streamingURL.data.url4,
					isActive: _streamingURL.data.isActive,
				}));

				setIsActive(_streamingURL.data.isActive);
				setIsDataLoaded(true);
			})
			.catch((err) => console.log(err));
	}, []);

	const handleFormChange = (e) => {
		if (e.target.id && e.target.id === "isActive") {
			setIsActive(e.target.checked);
		}

		setJsonFormData({
			...jsonFormData,
			[e.target.id || e.target.name]: e.target.value || e.target.checked,
		});
	};

	const handleSubmit = () => {
		try {
			axios
				.put("/streamings/VIMEOURL", jsonFormData)
				.then((_response) => {
					if (_response.status === 200) {
						history.push("/settings");
					} else {
						console.log("error");
					}
				})
				.catch((err) => console.log(err));
		} catch (err) {
			console.log(err);
		}
	};

	return (
		<MainLayout>
			{isDataLoaded ? (
				<SectionContainerLayout url={`Vimeo URL`}>
					<Grid container spacing={3}>
						<Grid item container xs={12} spacing={3}>
							<Grid item xs={6}>
								<TextField
									label="Name"
									name="name1"
									variant="outlined"
									required
									placeholder={
										jsonFormData.name1 ? "" : "Name"
									}
									defaultValue={jsonFormData.name1}
									onChange={handleFormChange}
									fullWidth
								/>
							</Grid>
							<Grid item xs={6}>
								<TextField
									label="URL"
									name="url1"
									variant="outlined"
									required
									placeholder={jsonFormData.url1 ? "" : "URL"}
									defaultValue={jsonFormData.url1}
									onChange={handleFormChange}
									fullWidth
								/>
							</Grid>
							<Grid item xs={6}>
								<TextField
									label="Name"
									name="name2"
									variant="outlined"
									required
									placeholder={
										jsonFormData.name2 ? "" : "Name"
									}
									defaultValue={jsonFormData.name2}
									onChange={handleFormChange}
									fullWidth
								/>
							</Grid>
							<Grid item xs={6}>
								<TextField
									label="URL"
									name="url2"
									variant="outlined"
									required
									placeholder={jsonFormData.url2 ? "" : "URL"}
									defaultValue={jsonFormData.url2}
									onChange={handleFormChange}
									fullWidth
								/>
							</Grid>
							<Grid item xs={6}>
								<TextField
									label="Name"
									name="name3"
									variant="outlined"
									required
									placeholder={
										jsonFormData.name3 ? "" : "Name"
									}
									defaultValue={jsonFormData.name3}
									onChange={handleFormChange}
									fullWidth
								/>
							</Grid>
							<Grid item xs={6}>
								<TextField
									label="URL"
									name="url3"
									variant="outlined"
									required
									placeholder={jsonFormData.url3 ? "" : "URL"}
									defaultValue={jsonFormData.url3}
									onChange={handleFormChange}
									fullWidth
								/>
							</Grid>
							<Grid item xs={6}>
								<TextField
									label="Name"
									name="name4"
									variant="outlined"
									required
									placeholder={
										jsonFormData.name4 ? "" : "Name"
									}
									defaultValue={jsonFormData.name4}
									onChange={handleFormChange}
									fullWidth
								/>
							</Grid>
							<Grid item xs={6}>
								<TextField
									label="URL"
									name="url4"
									variant="outlined"
									required
									placeholder={jsonFormData.url4 ? "" : "URL"}
									defaultValue={jsonFormData.url4}
									onChange={handleFormChange}
									fullWidth
								/>
							</Grid>
						</Grid>
						<Box clone pt={2}>
							<Grid
								item
								container
								xs={12}
								spacing={3}
								align="right"
							>
								<Grid item xs={12} md={6} align="left">
									<FormControlLabel
										control={
											<Checkbox
												id="isActive"
												onChange={handleFormChange}
												checked={isActive}
											/>
										}
										label="Activate"
									/>
								</Grid>
								<Grid item xs={12} md={6}>
									<Button
										onClick={() =>
											history.push("/settings")
										}
										style={{ textTransform: "capitalize" }}
									>
										Cancel
									</Button>
									<Button
										variant="contained"
										onClick={handleSubmit}
										color="primary"
										style={{ textTransform: "capitalize" }}
									>
										Submit
									</Button>
								</Grid>
							</Grid>
						</Box>
					</Grid>
				</SectionContainerLayout>
			) : null}
		</MainLayout>
	);
};

export default EditDigitalSeriesPage;
