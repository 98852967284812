import { useCallback, useState, useEffect } from "react";
import MainLayout from "../../../components/main-layout";
import SectionContainerLayout from "../../../components/section-container-layout";
import { useHistory } from "react-router-dom";
import { getByCode, update } from "../helpers";
import GroupForm from "./form";
import { map } from "lodash";
const GroupEditPage = ({ match }) => {
	const history = useHistory();
	const [isLoading, setIsLoading] = useState(true);
	const { code } = match.params;
	const [currentData, setCurrentData] = useState({});
	const [noData, setNoData] = useState(null);

	useEffect(() => {
		getByCode({
			link: "group",
			code,
			setData: setCurrentData,
			setNoData,
			setIsLoading,
		});
	}, [code]);

	const handleSubmit = useCallback(
		async (values) => {
			const { name, topic, speakers } = values;
			const speakerCodes =
				speakers && speakers.length > 0
					? map(speakers, (item) => item.code)
					: [];
			setIsLoading(true);
			update({
				link: "group",
				path: "group",
				body: {
					code: code,
					name,
					topicCode: topic,
					speakerCodes,
				},
				history,
				setIsLoading,
			});
		},
		[code, history],
	);
	return (
		<MainLayout>
			<SectionContainerLayout title="Edit Group">
				{currentData && currentData.code ? (
					<GroupForm
						currentData={currentData}
						submitCallBack={(values) => handleSubmit(values)}
						isLoading={isLoading}
						setIsLoading={setIsLoading}
						type="edit"
					/>
				) : (
					<p>{noData}</p>
				)}
			</SectionContainerLayout>
		</MainLayout>
	);
};

export default GroupEditPage;
