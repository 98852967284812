exports.generatePageLink = (title, modulePath) => {
	const DOMAIN_NAME = "https://gmisummit.com/";
	const allowedLength = 255;
	let domainAndModulePathLength = DOMAIN_NAME.length + modulePath.length;
	const remainingLength = allowedLength - domainAndModulePathLength;

	let titleUrl = title
		.toLowerCase()
		.replace(/[^\w ]+/g, "")
		.replace(/ +/g, "-");

	if (titleUrl.length > remainingLength) {
		titleUrl = titleUrl.substring(0, remainingLength - 1);
	}
	return titleUrl;
};

exports.dataExists = (_data, arrayToCheck) => {
	return arrayToCheck.some((el) => {
		return el.name === _data;
	});
};
