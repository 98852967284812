import {
	Grid,
	Box,
	useTheme,
	CircularProgress,
	Button,
} from "@material-ui/core";
import React from "react";
import CustomButton from "../custom-button";

const ModuleActionBar = ({
	deleteOnClick,
	submitOnClick,
	cancelOnClick,
	isDelete = true,
	isCancel = true,
	isSubmit = true,
	isSubmitting = false,
	submitLabel = "Submit",
	cancelLabel = "Cancel",
	deleteLabel = "Delete",
}) => {
	const theme = useTheme();
	return (
		<Grid item xs={12}>
			<Box
				display="flex"
				flexDirection="row-reverse"
				justifyContent="end"
				my={5}
			>
				{isDelete ? (
					<Box flexGrow={1}>
						<CustomButton
							label={deleteLabel}
							variant="text"
							onClick={deleteOnClick}
							styles={{ color: theme.palette.text.disabled }}
						/>
					</Box>
				) : null}
				{isSubmit ? (
					<Box>
						<CustomButton
							label={submitLabel}
							type="submit"
							onClick={submitOnClick}
							color="primary"
						/>
					</Box>
				) : null}
				{isCancel ? (
					<Box mr={2}>
						<Button
							variant="text"
							onClick={cancelOnClick}
							styles={{
								marginRight: "10px",
							}}
						>
							{cancelLabel}
						</Button>
					</Box>
				) : null}

				{isSubmitting && <CircularProgress size={24} />}
			</Box>
		</Grid>
	);
};
export default ModuleActionBar;
