import ModuleActionBar from "../../../../components/module-action-bar";
import { Grid, Button, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CustomTextField from "../../../../components/custom-text-field";
import * as yup from "yup";
import { useFormik } from "formik";
import Spinner from "../../../../components/spinner";
import moment from "moment";
import { useEffect, useState, useCallback } from "react";
import CustomSelect from "../../../../components/custom-select";
import { fetch } from "../../helpers";

const initialValues = {
	name: "",
	description: "",
	startDateTime: moment().format("YYYY-MM-DDTHH:mm"),
	endDateTime: moment().format("YYYY-MM-DDTHH:mm"),
	location: "",
};

const TopicForm = ({
	currentData,
	submitCallBack,
	setIsLoading,
	isLoading = false,
	type,
}) => {
	const history = useHistory();
	const [allLocations, setAllLocations] = useState([]);
	const validationSchema = yup.object().shape({
		name: yup.string().required("required"),
		description: yup.string().required("required"),
		startDateTime: yup.string().required("required"),
		endDateTime: yup.string().required("required"),
		location: yup.string().required("required"),
	});

	const formik = useFormik({
		initialValues,
		validationSchema,
		enableReinitialize: false,
		onSubmit: (values) => {
			submitCallBack(values);
		},
	});

	const fetchLocations = useCallback(async () => {
		fetch({
			pathname: "location",
			searchText: "",
			setData: setAllLocations,
			setIsLoading: () => {},
		});
	}, []);

	useEffect(() => {
		fetchLocations();
	}, [fetchLocations]);

	useEffect(() => {
		if (currentData && type === "edit") {
			setIsLoading(true);
			formik.setValues({
				name: currentData.name ? currentData.name : "",
				description: currentData.description
					? currentData.description
					: "",
				startDateTime: currentData.startDateTime
					? moment(currentData.startDateTime).format(
							"YYYY-MM-DDTHH:mm",
					  )
					: moment().format("YYYY-MM-DDTHH:mm"),
				endDateTime: currentData.endDateTime
					? moment(currentData.endDateTime).format("YYYY-MM-DDTHH:mm")
					: moment().format("YYYY-MM-DDTHH:mm"),

				location: currentData.location ? currentData.location.code : "",
			});
			setTimeout(() => {
				setIsLoading(false);
			}, 300);
		}
		//eslint-disable-next-line
	}, [currentData]);

	return (
		<>
			{!isLoading &&
				((type === "edit" && currentData) || type === "add") && (
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<Button
								color="primary"
								onClick={() => history.push("/agendas/topic")}
							>
								<ArrowBackIcon />
								<Typography component="span" color="primary">
									{"back"}
								</Typography>
							</Button>
						</Grid>

						<Grid item xs={12} sm={6}>
							<CustomTextField
								label="Name"
								name="name"
								validationMessage={
									formik.touched.name && formik.errors.name
										? formik.errors.name
										: ""
								}
								value={formik.values.name}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
							/>
						</Grid>

						<Grid item xs={12} sm={6}>
							<CustomTextField
								label="Description"
								name="description"
								validationMessage={
									formik.touched.description &&
									formik.errors.description
										? formik.errors.description
										: ""
								}
								value={formik.values.description}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
							/>
						</Grid>

						<Grid item xs={12} sm={6}>
							<CustomTextField
								type="datetime-local"
								label="Start Date & Time"
								name="startDateTime"
								validationMessage={
									formik.errors.startDateTime &&
									formik.touched.startDateTime
										? formik.errors.startDateTime
										: ""
								}
								{...formik.getFieldProps("startDateTime")}
							/>
						</Grid>

						<Grid item xs={12} sm={6}>
							<CustomTextField
								type="datetime-local"
								label="End Date & Time"
								name="endDateTime"
								validationMessage={
									formik.errors.endDateTime &&
									formik.touched.endDateTime
										? formik.errors.endDateTime
										: ""
								}
								{...formik.getFieldProps("endDateTime")}
							/>
						</Grid>

						<Grid item xs={12}>
							<CustomSelect
								fullWidth
								name="location"
								label="Location"
								options={allLocations}
								validationMessage={
									formik.errors.location &&
									formik.touched.location
										? formik.errors.location
										: ""
								}
								onChange={formik.handleChange}
								value={formik.values.location}
							/>
						</Grid>

						<Grid item xs={12}>
							<ModuleActionBar
								isDelete={false}
								submitLabel={
									type === "add" ? "Add" : "Apply changes"
								}
								cancelLabel="cancel"
								cancelOnClick={() =>
									history.push("/agendas/topic")
								}
								submitOnClick={formik.handleSubmit}
							/>
						</Grid>
					</Grid>
				)}
			<Spinner open={isLoading} />
		</>
	);
};

export default TopicForm;
