import React, { useEffect, useState } from "react";
import MainLayout from "../../components/main-layout";
import SectionContainerLayout from "../../components/section-container-layout";
import { Grid, Typography } from "@material-ui/core";
import ModuleToolbar from "../../components/module-toolbar";
import axios from "axios";
import CarouselCard from "../../components/carousel-card";
import { useHistory } from "react-router";
import { dataExists } from "../../helpers/common";
import { useDebounce } from "use-debounce";
import { useStoreState } from "easy-peasy";

const CarouselPage = () => {
	const [carouselData, setCarouselData] = useState([]);
	const [selected, setSelected] = useState([]);
	const [filterData, setFilterData] = useState({});
	const [isDataLoaded, setIsDataLoaded] = useState(false);
	const [years, setYears] = useState([]);
	const history = useHistory();
	const currentUser = useStoreState((state) => state.user.currentUser);

	const handleClick = (carousel) => {
		if (currentUser.user_account_type.type !== "Viewer") {
			history.push({
				pathname: "/carousels/edit",
				state: {
					carousel,
				},
			});
		}
	};

	useEffect(() => {
		try {
			const token = localStorage.getItem("token");

			if (!token) {
				history.push("/login");
			}
			fetchList();
		} catch (err) {
			console.log(err);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const fetchList = () => {
		axios
			.get("/carousel")
			.then((_response) => {
				if (_response.status === 200) {
					let yearArray = [];
					let _yearArray = [];
					_response.data.forEach((_data) => {
						if (!dataExists(_data.year, yearArray)) {
							yearArray.push({
								name: _data.year,
								value: _data.year,
							});
							_yearArray.push(_data.year);
						}
					});

					let _yearFilter = {
						options: yearArray,
						label: "Year",
						idName: "year",
					};

					let allFilters = {
						_yearFilter,
					};

					setFilterData(allFilters);
					setYears(_yearArray);
					setIsDataLoaded(true);
					setCarouselData(_response.data);
				} else if (_response.status === 404) {
					history.push("/login");
				}
			})
			.catch((err) => console.log(err));
	};

	const isSelected = (data) => selected.indexOf(data) !== -1;
	const handleSelectAllChange = (e) => {
		if (e.target.checked) {
			const newSelected = carouselData.map((n) => n.code);
			setSelected(newSelected);
			return;
		}
		setSelected([]);
	};
	const handleItemSelectChange = (e, data) => {
		const selectedIndex = selected.indexOf(data);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, data);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1),
			);
		}

		setSelected(newSelected);
	};
	const handleDeleteSelected = (e) => {
		axios
			.delete("/carousel", { data: { code: selected } })
			.then((_response) => {
				setSelected([]);
				fetchList();
			})
			.catch((err) => console.log(err));
	};

	const previewImage = (data) => {
		if (data.file && data.file.length > 0) {
			const _img = data.file.filter((f) => f.type.includes("image"));
			return _img.length > 0 ? _img[0].code : null;
		}
		return null;
	};

	const [filters, setFilters] = useState({});
	const [search, setSearch] = useState("");
	const [searchText] = useDebounce(search, 1000);
	useEffect(() => {
		axios
			.post("/carousel/search", {
				filters: { ...filters, search: searchText },
			})
			.then((_response) => {
				setCarouselData(_response.data);
			})
			.catch((err) => console.log(err));
	}, [searchText, filters]);

	const handleSearch = (e) => {
		try {
			setSearch(e.target.value);
			// const searchQuery = {
			//   title: e.target.value,
			// };

			// axios
			//   .post("/carousel/search", searchQuery)
			//   .then((_response) => {
			//     if (_response.status === 200) {
			//       setCarouselData(_response.data);
			//     }
			//   })
			//   .catch((err) => console.log(err));
		} catch (err) {
			console.log(err);
		}
	};
	const handleFilterData = (value, key) => {
		try {
			setFilters({ ...filters, [key]: value.toString() });
			// let filteredLogs = [];

			// axios.get("/carousel").then((_response) => {
			//   if (_response.status === 200) {
			//     _response.data.forEach((_data) => {
			//       if (_data[key.toLowerCase()] === value) {
			//         filteredLogs.push(...filteredLogs, _data);
			//       }
			//     });

			//     setCarouselData(filteredLogs);
			//     if (!value) {
			//       setCarouselData(_response.data);
			//     }
			//     setIsDataLoaded(true);
			//   }

			//   if (_response.status === 404) {
			//     history.push("/login");
			//   }
			// });
		} catch (err) {
			console.log(err);
		}
	};

	return (
		<MainLayout>
			<SectionContainerLayout title="CAROUSELS">
				{isDataLoaded ? (
					<Grid container>
						<Grid item xs={12}>
							<ModuleToolbar
								totalSelected={selected.length}
								totalData={carouselData.length}
								onSelectAllClick={handleSelectAllChange}
								onDeleteSelected={handleDeleteSelected}
								addModuleUrl="/carousels/add"
								listViewIcon={false}
								handleSearchChange={handleSearch}
								gridViewIcon={false}
								showCustomFilter
								filterData={filterData}
								handleFilterData={handleFilterData}
								changeStatusAction={false}
							/>
						</Grid>
						<Grid item container xs={12} spacing={2}>
							{years.map((_year, index) => (
								<>
									{carouselData.find(
										(carouselObj) =>
											carouselObj.year === _year,
									) ? (
										<Grid item xs={12}>
											<Typography
												variant="body1"
												color="textPrimary"
											>
												{_year}
											</Typography>
										</Grid>
									) : null}

									{carouselData.map(
										(_carousel, carouselIdx) =>
											_carousel.year === _year ? (
												<Grid item xs={12} md={2}>
													<CarouselCard
														title={_carousel.title}
														image={previewImage(
															_carousel,
														)}
														onClick={() =>
															handleClick(
																_carousel,
															)
														}
														status={
															_carousel.status
														}
														isChecked={isSelected(
															_carousel.code,
														)}
														onItemSelect={(e) =>
															handleItemSelectChange(
																e,
																_carousel.code,
															)
														}
													/>
												</Grid>
											) : null,
									)}
								</>
							))}
						</Grid>
					</Grid>
				) : null}
			</SectionContainerLayout>
		</MainLayout>
	);
};

export default CarouselPage;
