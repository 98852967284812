import React from "react";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
	icon: {
		fontSize: "500%",
		position: "absolute",
		transform: "translate(-50%, -50%)",
	},
}));

export const MapPin = ({ className }) => {
	const classes = useStyles();
	return (
		<LocationOnIcon
			className={clsx(classes.icon, className)}
			color="primary"
		/>
	);
};

export default MapPin;
