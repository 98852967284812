import { useCallback, useState, useEffect } from "react";
import MainLayout from "../../../components/main-layout";
import SectionContainerLayout from "../../../components/section-container-layout";
import { useHistory } from "react-router-dom";
import { getByCode, update } from "../helpers";
import TopicsForm from "./form";
const TopicEditPage = ({ match }) => {
	const history = useHistory();
	const [isLoading, setIsLoading] = useState(true);
	const { code } = match.params;
	const [currentData, setCurrentData] = useState({});
	const [noData, setNoData] = useState(null);

	useEffect(() => {
		getByCode({
			link: "topic",
			code,
			setData: setCurrentData,
			setNoData,
			setIsLoading,
		});
	}, [code]);

	const handleSubmit = useCallback(
		async (values) => {
			const { name, description, startDateTime, endDateTime, location } =
				values;
			setIsLoading(true);
			update({
				link: "topic",
				path: "topic",
				body: {
					code: code,
					name,
					description,
					startDateTime: new Date(startDateTime).toISOString(),
					endDateTime: new Date(endDateTime).toISOString(),
					locationCode: location,
				},
				history,
				setIsLoading,
			});
		},
		[code, history],
	);
	return (
		<MainLayout>
			<SectionContainerLayout title="Edit Topic">
				{currentData && currentData.code ? (
					<TopicsForm
						currentData={currentData}
						submitCallBack={(values) => handleSubmit(values)}
						isLoading={isLoading}
						setIsLoading={setIsLoading}
						type="edit"
					/>
				) : (
					<p>{noData}</p>
				)}
			</SectionContainerLayout>
		</MainLayout>
	);
};

export default TopicEditPage;
