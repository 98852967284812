import { TableHead, TableCell, TableRow, Typography } from "@material-ui/core";
import { map } from "lodash";
const TableCustomHeader = ({ classes, currentUser, theme, headers = [] }) => {
	return (
		<TableHead className={classes.tableHeader}>
			<TableRow>
				{currentUser.user_account_type.type !== "Viewer" ? (
					<TableCell width={1}></TableCell>
				) : null}

				{map(headers, (_item) => {
					return (
						<TableCell align="center">
							<Typography
								variant="body2"
								style={{
									color: theme.palette.common.white,
								}}
							>
								{_item}
							</Typography>
						</TableCell>
					);
				})}
			</TableRow>
		</TableHead>
	);
};

export default TableCustomHeader;
