import {
	Grid,
	TextField,
	FormControlLabel,
	Checkbox,
	Typography,
	Box,
	Button,
	LinearProgress,
	FormHelperText,
} from "@material-ui/core";
import React, { useState } from "react";
import MainLayout from "../../components/main-layout";
import SectionContainerLayout from "../../components/section-container-layout";
import axios from "axios";
import { DropzoneArea } from "material-ui-dropzone";
import CustomTextEditor from "../../components/custom-text-editor";
import { useHistory } from "react-router";
import { addDigitalSeries } from "../../validations/digital-series-validator";
import { generatePageLink } from "../../helpers/common";
import moment from "moment";

const AddDigitalSeriesPage = () => {
	const [datePicked] = useState(moment().format("YYYY-MM-DD"));
	const [jsonFormData, setJsonFormData] = useState({
		date: moment().format("YYYY-MM-DD"),
	});

	const [isOnHomepage, setIsOnHomepage] = useState(false);
	const [videoFile, setVideoFile] = useState();
	const [imageFile, setImageFile] = useState();
	const [validationErrors, setValidationErrors] = useState({});
	const [showProgress, setShowProgress] = useState(false);
	const [progress, setProgress] = useState(0);
	const [serverError, setServerError] = useState("");
	const history = useHistory();
	// const filter = createFilterOptions();

	// useEffect(() => {
	//   axios
	//     .get("/utility/categories")
	//     .then((_response) => {
	//       if (_response) {
	//         setCategories(_response.data);
	//       }
	//     })
	//     .catch((err) => console.log(err));
	// }, []);

	// const handleCategoryChange = (event, newValue) => {
	//   if (typeof newValue === "string") {
	//     setCategoryValue({
	//       name: newValue,
	//     });
	//     setJsonFormData({
	//       ...jsonFormData,
	//       categoryName: newValue,
	//     });
	//   } else if (newValue && newValue.inputValue) {
	//     // Create a new value from the user input
	//     setCategoryValue({
	//       name: newValue.inputValue,
	//     });
	//     setJsonFormData({
	//       ...jsonFormData,
	//       categoryName: newValue.inputValue,
	//     });
	//   } else {
	//     setCategoryValue(newValue);
	//     setJsonFormData({
	//       ...jsonFormData,
	//       categoryName: newValue,
	//     });
	//   }
	// };

	// const filterOptions = (options, params) => {
	//   const filtered = filter(options, params);

	//   // Suggest the creation of a new value
	//   if (params.inputValue !== "") {
	//     filtered.push({
	//       inputValue: params.inputValue,
	//       name: `Add "${params.inputValue}"`,
	//     });
	//   }
	//   return filtered;
	// };

	// const getOptionLabel = (option) => {
	//   // Value selected with enter, right from the input
	//   if (typeof option === "string") {
	//     return option;
	//   }
	//   // Add "xxx" option created dynamically
	//   if (option.inputValue) {
	//     return option.inputValue;
	//   }
	//   // Regular option
	//   return option.name;
	// };

	// const renderInput = (params) => (
	//   <TextField {...params} label="Category" variant="outlined" />
	// );

	const handleFormChange = (e) => {
		if (e.target.id && e.target.id === "isOnHomepage") {
			setIsOnHomepage(e.target.checked);
		}
		setJsonFormData({
			...jsonFormData,
			[e.target.id || e.target.name]: e.target.value || e.target.checked,
		});
	};

	const handleEditorChange = (event, editor) => {
		const data = editor.getData();
		setJsonFormData({
			...jsonFormData,
			details: data,
		});
	};

	const handleImageFile = (file) => {
		setImageFile(file[0]);
	};

	const handleVideoFile = (file) => {
		setVideoFile(file[0]);
	};

	const handleSubmit = async () => {
		try {
			let errorMessages = {};

			addDigitalSeries
				.validate(jsonFormData, { abortEarly: false })
				.then(async () => {
					let formData = new FormData();
					formData.append("videoFile", videoFile);
					formData.append("imageFile", imageFile);

					for (let i in jsonFormData) {
						formData.append(i, jsonFormData[i]);
					}

					if (!jsonFormData.isOnHomepage) {
						formData.append("isOnHomepage", isOnHomepage);
					}

					let urlSlug = generatePageLink(
						jsonFormData.title,
						"digital-series/xxxx/",
					);

					formData.append("urlSlug", urlSlug);
					const _request = await axios.post(
						"/digital-series",
						formData,
						{
							headers: { "Content-Type": "multipart/form-data" },
							onUploadProgress: (data) => {
								// console.log(
								//   "data.loaded = ",
								//   data.loaded,
								//   "time = ",
								//   `${new Date().getMinutes()}:${new Date().getSeconds()}`
								// );
								setShowProgress(true);
								setProgress(
									Math.round(
										(100 * data.loaded) / data.total,
									),
								);
							},
						},
					);

					if (_request) {
						if (_request.status === 200) {
							history.push("/digital-series");
						} else {
							setServerError("Server Error");
						}
						history.push("/digital-series");
					}
					// .then((_response) => {
					//   if (_response.status === 200) {
					//     history.push("/digital-series");
					//   } else {
					//     console.log("error");
					//   }
					// })
					// .catch((err) => console.log(err));
				})
				.catch((err) => {
					err.inner.forEach((e) => {
						errorMessages[e.path] = e.message;
					});
					setValidationErrors(errorMessages);
				});
		} catch (err) {
			console.log(err);
		}
	};
	return (
		<MainLayout>
			<SectionContainerLayout title="ADD DIGITAL SERIES">
				<Grid container spacing={3}>
					<Grid item container xs={12} md={6} spacing={3}>
						<Grid item xs={12} md={6}>
							{/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  required
                  id="date"
                  value={datePicked}
                  label="Date"
                  name="date"
                  autoOk
                  error={validationErrors.date}
                  helperText={validationErrors.date}
                  onChange={handleDatePicker("date")}
                />
              </MuiPickersUtilsProvider> */}
							<TextField
								id="date"
								label="Date"
								type="date"
								defaultValue={datePicked}
								onChange={handleFormChange}
								error={validationErrors.date}
								helperText={validationErrors.date}
								placeholder={datePicked}
								variant="outlined"
								fullWidth
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<TextField
								label="Title"
								name="title"
								variant="outlined"
								required
								onChange={handleFormChange}
								error={validationErrors.title}
								helperText={validationErrors.title}
								fullWidth
							/>
						</Grid>
						{/* <Grid item xs={12} md={6}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Tags</InputLabel>
                <Select name="tags" onChange={handleFormChange}>
                  <MenuItem value={"Tag"}>Tag 1</MenuItem>
                  <MenuItem value={"Tag"}>Tag 2</MenuItem>
                  <MenuItem value={"Tag"}>Tag 3</MenuItem>
                  <MenuItem value={"Tag"}>Tag 4</MenuItem>
                </Select>
              </FormControl>
            </Grid> */}
						{/* <Grid item xs={12} md={6}>
              <Autocomplete
                value={categoryValue}
                onChange={handleCategoryChange}
                filterOptions={filterOptions}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                options={categories}
                getOptionLabel={getOptionLabel}
                renderOption={(option) => option.name}
                freeSolo
                renderInput={renderInput}
              />
            </Grid> */}
						<Grid item xs={12}>
							<TextField
								multiline
								onChange={handleFormChange}
								rows={4}
								required
								variant="outlined"
								error={validationErrors.description}
								helperText={validationErrors.description}
								fullWidth
								name="description"
								label="Description"
							/>
						</Grid>
					</Grid>
					<Grid item container xs={12} md={6}>
						{/* <FormControl
              name="thumbnail"
              style={{ width: "100%" }}
              error={validationErrors.thumbnail ? true : true}
            > */}
						<DropzoneArea
							dropzoneText="Drag and drop or click here to upload video thumbnail *"
							acceptedFiles={["image/*"]}
							filesLimit={1}
							onChange={(file) => handleImageFile(file)}
						/>

						{/* <FormHelperText>
                {validationErrors.thumbnail ? "Thumbnail is required" : ""}
              </FormHelperText> */}
						{/* </FormControl> */}
						<FormHelperText>
							Width: 500px, Height: 300px
						</FormHelperText>
					</Grid>
					<Grid item container xs={12} spacing={3}>
						<Grid item xs={12}>
							{/* <FormControl
                name="video"
                style={{ width: "100%" }}
                error={validationErrors.video ? true : true}
              > */}
							{/* <DropzoneArea
                key={debounceKey}
                dropzoneText="Drag and drop or click here to upload video *"
                acceptedFiles={["video/mp4"]}
                maxFileSize={2147483648}
                onDelete={(e) => {
                  console.log("onDelete : ", e);
                  setDropZoneKey();
                  setVideoFile(null);
                }}
                filesLimit={1}
                onChange={(file) => handleVideoFile(file)}
              /> */}
							{/* <Button variant="contained"> */}

							<label htmlFor="btn-upload">
								<input
									id="btn-upload"
									accept="video/mp4,video/x-m4v,video/*"
									name="btn-upload"
									style={{ display: "none" }}
									type="file"
									onChange={(event) =>
										handleVideoFile(event.target.files)
									}
								/>
								<Button
									className="btn-choose"
									variant="outlined"
									component="span"
								>
									Upload a Video
								</Button>
							</label>
							<Box my={3}>
								{videoFile ? videoFile.name : null}
							</Box>

							{/* </Button> */}
							{/* <FormHelperText>
                  {validationErrors.video ? "Video is required" : ""}
                </FormHelperText>
              </FormControl> */}
							<FormHelperText>Video max size: 2GB</FormHelperText>
						</Grid>
						<Grid item xs={12}>
							<Typography variant="body1" color="textPrimary">
								Details *
							</Typography>
							<CustomTextEditor onChange={handleEditorChange} />
							{validationErrors.details ? (
								<Typography variant="body2" color="error">
									{validationErrors.details}
								</Typography>
							) : null}
						</Grid>
					</Grid>
					<Box clone pt={2}>
						<Grid item container xs={12} align="right">
							<Grid item xs={12} md={6} align="left">
								<FormControlLabel
									control={
										<Checkbox
											id="isOnHomepage"
											onChange={handleFormChange}
											checked={isOnHomepage}
										/>
									}
									label="Add to homepage"
								/>
							</Grid>
							<Grid item xs={12}>
								<Button
									onClick={() =>
										history.push("/digital-series")
									}
									style={{ textTransform: "capitalize" }}
								>
									Cancel
								</Button>
								<Button
									variant="contained"
									onClick={handleSubmit}
									color="primary"
									style={{ textTransform: "capitalize" }}
								>
									Submit
								</Button>
							</Grid>
						</Grid>
					</Box>
					<Box>
						<Typography>{serverError}</Typography>
					</Box>
					<Box clone pt={5}>
						<Grid item xs={12}>
							{showProgress ? (
								<>
									<LinearProgress
										variant="determinate"
										value={progress}
									/>
									<Typography>{progress}%</Typography>
								</>
							) : null}
						</Grid>
					</Box>
				</Grid>
			</SectionContainerLayout>
		</MainLayout>
	);
};

export default AddDigitalSeriesPage;
