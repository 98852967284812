import { createMuiTheme } from "@material-ui/core/styles";
import colors from "./colors";
import { fade } from "@material-ui/core/styles/colorManipulator";

const theme = createMuiTheme({
	palette: {
		background: {
			default: colors.white,
			paper: colors.white,
		},
		primary: {
			main: colors.lightGreen,
		},
		secondary: {
			main: colors.darkBlue,
		},
		error: {
			main: colors.red,
		},
		warning: {
			main: colors.orange,
		},
		info: {
			main: colors.lightBlue,
		},
		success: {
			main: colors.lightGreen,
		},
		text: {
			primary: colors.darkBlue,
			secondary: colors.darkGray,
			disabled: colors.lightGray,
		},
		divider: fade(colors.darkGray, 1),
	},
	overrides: {
		MuiTableCell: {
			root: {
				borderBottom: "1px solid rgb(211 203 203)",
			},
		},
		MuiPaper: {
			root: {
				borderRadius: "5px",
				backgroundColor: colors.darkWhite,
			},
		},
		MuiDropzoneArea: {
			root: {
				backgroundColor: "#ffffff",
				minHeight: "218px",
				height: "96%",
			},
		},
		MuiInputBase: {
			root: {
				backgroundColor: "#ffffff",
			},
			input: {
				padding: "6px 7px 7px",
			},
		},
		MuiButton: {
			containedPrimary: {
				color: "#ffffff",
				fontWeight: "bold",
			},
		},
		MuiTooltip: {
			tooltip: {
				color: colors.darkBlue,
				backgroundColor: colors.darkWhite,
				fontSize: "1rem",
			},
		},
		MuiDropzonePreviewList: {
			removeButton: {
				right: "40px",
			},
		},
	},
});

export default theme;
