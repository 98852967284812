import { searchData, getDataByCode, addData, updateData } from "../axios";
const fetch = async ({
	pathname = "",
	setIsLoading = () => {},
	setNoData = () => {},
	filters = [],
	searchText = "",
	setData,
	isCMS = false,
}) => {
	try {
		setIsLoading(true);
		const response = await searchData({
			link: pathname,
			body: {
				filters: { ...filters, search: searchText },
			},
			isCMS,
		});
		if (response) setData(response.data);
		else {
			setNoData("No data");
		}
	} catch (err) {
		console.log("error");
	} finally {
		setIsLoading(false);
	}
};

const getByCode = async ({
	setIsLoading = () => {},
	setData = () => {},
	code,
	setNoData = () => {},
	link,
}) => {
	try {
		setIsLoading(true);
		const response = await getDataByCode({
			link,
			code,
		});
		if (response) {
			if (response.data) {
				setData(response.data);
			} else {
				setNoData("No data");
			}
		}
	} catch (err) {
		console.log("error");
	} finally {
		setIsLoading(false);
	}
};

const insert = async ({
	body,
	link,
	history,
	setIsLoading = () => {},
	path,
}) => {
	try {
		const response = await addData({
			link,
			body,
		});
		if (response) {
			history.push(`/agendas/${path}`);
		}
	} catch (err) {
		console.log(err);
	} finally {
		setIsLoading(false);
	}
};

const update = async ({
	body,
	link,
	path,
	history,
	setIsLoading = () => {},
}) => {
	try {
		const response = await updateData({
			link,
			body,
		});
		if (response) {
			history.push(`/agendas/${path}`);
		}
	} catch (err) {
		console.log("error", err);
	} finally {
		setIsLoading(false);
	}
};
export { fetch, getByCode, insert, update };
