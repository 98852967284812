import React, { useEffect, useMemo, useState } from "react";
import SectionContainerLayout from "../../components/section-container-layout";
import MainLayout from "../../components/main-layout";
import ModuleToolbar from "../../components/module-toolbar";
import {
	Grid,
	TableContainer,
	Paper,
	TableCell,
	TableHead,
	TableRow,
	Table,
	makeStyles,
	Button,
	useTheme,
	TableBody,
	Typography,
	Checkbox,
	Box,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
} from "@material-ui/core";
import axios from "axios";
import CardImageTitleDescription from "../../components/card-image-title-description";
import moment from "moment";
import { useHistory } from "react-router-dom";
import colors from "../../constants/colors";
import ToolbarLegend from "../../components/toolbar-legend";
import { dataExists } from "../../helpers/common";
import { useDebounce } from "use-debounce";
import { useStoreState } from "easy-peasy";
const useStyles = makeStyles((theme) => ({
	tableHeader: {
		backgroundColor: colors.darkBlue,
	},
	rowHover: {
		"&:hover": {
			cursor: "pointer",
		},
	},
	horizontalList: {
		display: "flex",
		flexDirection: "row",
		padding: 0,
		justifyContent: "flex-end",
	},
}));

const NewsPage = () => {
	const [newsData, setNewsData] = useState([]);
	const [isGridView, setIsGridView] = useState(true);
	const [isListView, setListView] = useState(false);
	const [selected, setSelected] = useState([]);
	const [filterData, setFilterData] = useState({});
	const [isDataLoaded, setIsDataLoaded] = useState(false);
	const [isDialogOpen, setIsDialogOpen] = useState(false);
	const currentUser = useStoreState((state) => state.user.currentUser);
	const history = useHistory();
	const theme = useTheme();
	const classes = useStyles();
	const toggleGridView = () => {
		setIsGridView(true);
		setListView(false);
	};

	const toggleListView = () => {
		setIsGridView(false);
		setListView(true);
	};

	const handleDialogClose = () => {
		setIsDialogOpen(false);
	};

	useEffect(() => {
		try {
			const token = localStorage.getItem("token");

			if (!token) {
				history.push("/login");
			}
			fetchList();
		} catch (err) {
			console.log(err);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const fetchList = () => {
		axios
			.get("/news")
			.then((_response) => {
				if (_response.status === 200) {
					let statusArray = [];
					let yearArray = [];
					_response.data.forEach((_data) => {
						if (!dataExists(_data.status, statusArray)) {
							statusArray.push({
								name: _data.status,
								value: _data.status,
							});
						}

						if (!dataExists(_data.year, yearArray)) {
							yearArray.push({
								name: _data.year,
								value: _data.year,
							});
						}
					});

					let _statusFilter = {
						options: statusArray,
						label: "Status",
						idName: "status",
					};

					let _yearFilter = {
						options: yearArray,
						label: "Year",
						idName: "year",
					};

					let allFilters = {
						_statusFilter,
						_yearFilter,
					};

					setFilterData(allFilters);
					setIsDataLoaded(true);
					setNewsData(_response.data);
				} else if (_response.status === 404) {
					history.push("/login");
				}
			})
			.catch((err) => console.log(err));
	};

	const convertDate = (date) => {
		const newDate = moment(date).format("DD MMMM YYYY");

		return newDate;
	};

	const handleClick = (news) => {
		if (currentUser.user_account_type.type !== "Viewer") {
			history.push({
				pathname: "/news/edit",
				state: {
					news,
				},
			});
		}
	};

	const isSelected = (data) => selected.indexOf(data) !== -1;
	const handleSelectAllChange = (e) => {
		if (e.target.checked) {
			const newSelected = newsData.map((n) => n.code);
			setSelected(newSelected);
			return;
		}
		setSelected([]);
	};
	const handleItemSelectChange = (e, data) => {
		const selectedIndex = selected.indexOf(data);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, data);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1),
			);
		}

		setSelected(newSelected);
	};
	const handleDeleteSelected = (e) => {
		axios
			.delete("/news", { data: { code: selected } })
			.then((_response) => {
				setSelected([]);
				fetchList();
			})
			.catch((err) => console.log(err));
	};

	const handleEditSelected = (e) => {
		setIsDialogOpen(true);
		try {
			const requestBody = {
				codes: selected,
				status: e,
			};

			axios
				.put("/news/status", requestBody)
				.then((_response) => {
					if (_response.status === 200) {
						setSelected([]);
						fetchList();
					}
				})
				.catch((err) => console.log(err));
		} catch (err) {
			console.log(err);
		}
	};

	const [filters, setFilters] = useState({});
	const [filterCatCode, setFilterCatCode] = useState("");
	const [search, setSearch] = useState("");
	const [searchText] = useDebounce(search, 1000);
	useEffect(() => {
		axios
			.post("/news/search", {
				filters: {
					...filters,
					search: searchText,
					category: filterCatCode,
				},
			})
			.then((_response) => {
				let _newsArray = [];

				_response.data.forEach((_news) => {
					if (!_newsArray.includes(_news)) {
						_newsArray.push(_news);
					}
				});

				setNewsData(_newsArray);
			})
			.catch((err) => console.log(err));
	}, [searchText, filters, filterCatCode]);

	const handleSearch = (e) => {
		try {
			setSearch(e.target.value);
			// const searchQuery = {
			//   title: e.target.value,
			//   article: e.target.value,
			//   date: e.target.value,
			// };

			// axios
			//   .post("/news/search", searchQuery)
			//   .then((_response) => {
			//     if (_response.status === 200) {
			//       setNewsData(_response.data);
			//     }
			//   })
			//   .catch((err) => console.log(err));
		} catch (err) {
			console.log(err);
		}
	};

	const handleFilterByCategory = (category) => {
		try {
			setFilterCatCode(category);
		} catch (err) {
			console.log(err);
		}
	};

	const handleFilterData = (value, key) => {
		try {
			setFilters({ ...filters, [key]: value });
		} catch (err) {
			console.log(err);
		}
	};

	const newsDataSortedByDate = useMemo(() => {
		return newsData.sort((newsA, newsB) => {
			const dateA = moment(newsA.date, "YYYY-MM-DD").toDate();
			const dateB = moment(newsB.date, "YYYY-MM-DD").toDate();
			return dateB.valueOf() - dateA.valueOf();
		});
	}, [newsData]);

	return (
		<MainLayout>
			<SectionContainerLayout title="NEWS">
				{isDataLoaded ? (
					<Grid container>
						<Grid item xs={12}>
							<ToolbarLegend />
						</Grid>
						<Grid item xs={12}>
							<ModuleToolbar
								totalSelected={selected.length}
								totalData={newsDataSortedByDate.length}
								onSelectAllClick={handleSelectAllChange}
								onDeleteSelected={handleDeleteSelected}
								onEditSelected={handleEditSelected}
								addModuleUrl="/news/add"
								handleGridView={toggleGridView}
								handleListView={toggleListView}
								handleSearchChange={handleSearch}
								showCustomFilter
								filterData={filterData}
								handleFilterData={handleFilterData}
								showFilterByCategories
								handleCategoryChange={handleFilterByCategory}
							/>
						</Grid>
						<Grid item container xs={12} spacing={3}>
							{isGridView ? (
								newsDataSortedByDate.length > 0 ? (
									newsDataSortedByDate.map((_news, index) => (
										<Grid
											item
											xs={12}
											md={4}
											lg={3}
											key={index}
										>
											<CardImageTitleDescription
												onClick={() =>
													handleClick(_news)
												}
												image={_news.file.code}
												title={convertDate(_news.date)}
												description={_news.title}
												status={_news.status}
												isChecked={isSelected(
													_news.code,
												)}
												onItemSelect={(e) =>
													handleItemSelectChange(
														e,
														_news.code,
													)
												}
											/>
										</Grid>
									))
								) : (
									<Typography
										variant="body1"
										color="textPrimary"
										align="center"
									>
										There are no results
									</Typography>
								)
							) : isListView &&
							  newsDataSortedByDate.length > 0 ? (
								<TableContainer component={Paper}>
									<Table>
										<TableHead
											className={classes.tableHeader}
										>
											<TableRow>
												{currentUser.user_account_type
													.type !== "Viewer" ? (
													<TableCell
														width={1}
													></TableCell>
												) : null}
												<TableCell align="center">
													<Typography
														variant="body2"
														style={{
															color: theme.palette
																.common.white,
														}}
													>
														Image
													</Typography>
												</TableCell>
												<TableCell align="center">
													<Typography
														variant="body2"
														style={{
															color: theme.palette
																.common.white,
														}}
													>
														Title
													</Typography>
												</TableCell>
												<TableCell align="center">
													<Typography
														variant="body2"
														style={{
															color: theme.palette
																.common.white,
														}}
													>
														Status
													</Typography>
												</TableCell>
												<TableCell align="center">
													<Typography
														variant="body2"
														style={{
															color: theme.palette
																.common.white,
														}}
													>
														News Date
													</Typography>
												</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{newsDataSortedByDate.map(
												(_news, index) => (
													<Box
														clone
														className={
															classes.rowHover
														}
														key={index}
													>
														<TableRow
															key={index}
															scope="row"
															hover
														>
															{currentUser
																.user_account_type
																.type !==
															"Viewer" ? (
																<TableCell
																	width={1}
																>
																	<Checkbox
																		checked={isSelected(
																			_news.code,
																		)}
																		onClick={(
																			e,
																		) =>
																			handleItemSelectChange(
																				e,
																				_news.code,
																			)
																		}
																		inputProps={{
																			"aria-labelledby": 1,
																		}}
																	/>
																</TableCell>
															) : null}
															<TableCell
																width={50}
																onClick={() =>
																	handleClick(
																		_news,
																	)
																}
															>
																<img
																	src={`${process.env.REACT_APP_API_URL}/static/${_news.file.code}`}
																	alt=""
																	width="100%"
																/>
															</TableCell>
															<TableCell
																width={100}
																align="center"
																onClick={() =>
																	handleClick(
																		_news,
																	)
																}
															>
																<Typography
																	variant="body1"
																	color="textPrimary"
																>
																	{
																		_news.title
																	}
																</Typography>
															</TableCell>
															<TableCell
																width={100}
																align="center"
																onClick={() =>
																	handleClick(
																		_news,
																	)
																}
															>
																<Typography
																	variant="body1"
																	style={{
																		color:
																			_news.status ===
																			"published"
																				? colors.lightGreen
																				: _news.status ===
																				  "unpublished"
																				? colors.darkGray
																				: _news.status ===
																				  "scheduled"
																				? theme
																						.palette
																						.warning
																						.main
																				: null,
																	}}
																>
																	{_news.status
																		.charAt(
																			0,
																		)
																		.toUpperCase() +
																		_news.status.slice(
																			1,
																		)}
																</Typography>
															</TableCell>
															<TableCell
																width={100}
																align="center"
																onClick={() =>
																	handleClick(
																		_news,
																	)
																}
															>
																<Typography
																	variant="body1"
																	color="textPrimary"
																>
																	{convertDate(
																		_news.date,
																	)}
																</Typography>
															</TableCell>
														</TableRow>
													</Box>
												),
											)}
										</TableBody>
									</Table>
								</TableContainer>
							) : (
								<Typography
									variant="body1"
									color="textPrimary"
									align="center"
								>
									There are no results
								</Typography>
							)}
						</Grid>
					</Grid>
				) : null}
				<Dialog open={isDialogOpen} onClose={handleDialogClose}>
					<DialogTitle>Update Successful</DialogTitle>
					<DialogContent>
						<DialogContentText>
							News status has been successfully updated
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button
							onClick={handleDialogClose}
							color="primary"
							variant="contained"
						>
							Ok
						</Button>
					</DialogActions>
				</Dialog>
			</SectionContainerLayout>
		</MainLayout>
	);
};

export default NewsPage;
