import { useCallback, useState } from "react";
import MainLayout from "../../../components/main-layout";
import SectionContainerLayout from "../../../components/section-container-layout";
import LocationForm from "./form";
import { useHistory } from "react-router-dom";
import { map } from "lodash";
import { insert } from "../helpers";
const GroupAddPage = () => {
	const [isLoading, setIsLoading] = useState(false);
	const history = useHistory();

	const handleSubmit = useCallback(
		async (values) => {
			const { name, topic, speakers } = values;
			const speakerCodes =
				speakers && speakers.length > 0
					? map(speakers, (item) => item.code)
					: [];

			setIsLoading(true);
			insert({
				body: {
					name,
					topicCode: topic,
					speakerCodes,
				},
				link: "group",
				history,
				setIsLoading,
				path: "group",
			});
		},
		[history],
	);
	return (
		<MainLayout>
			<SectionContainerLayout title="Add Group">
				<LocationForm
					submitCallBack={(values) => handleSubmit(values)}
					isLoading={isLoading}
					setIsLoading={setIsLoading}
					type="add"
				/>
			</SectionContainerLayout>
		</MainLayout>
	);
};

export default GroupAddPage;
