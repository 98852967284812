import React, {
	Fragment,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from "react";
import { sortableContainer, sortableElement } from "react-sortable-hoc";
import CardImageTitleDescription from "../../../components/card-image-title-description";
import { Grid, Typography } from "@material-ui/core";
import { useStoreState } from "easy-peasy";
import { useHistory } from "react-router-dom";
import axios from "axios";

const AllTab = ({ data, isChecked, onItemSelect, years }) => {
	const [updatedData, setUpdatedData] = useState(data);

	const reorder = useCallback((list, startIndex, endIndex) => {
		const result = Array.from(list);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);
		result.forEach((_result, index) => (_result.order = index));
		return result;
	}, []);

	const handleDrag = useCallback(
		({ oldIndex, newIndex }) => {
			const speakers = reorder(updatedData, oldIndex, newIndex);
			const _result = [];

			updatedData.forEach((item, index) => {
				if (speakers[index] !== item)
					_result.push({
						index: index,
						code: speakers[index].code,
					});
			});

			try {
				setUpdatedData(speakers);
				axios
					.post("/speaker/current-event", { newOrder: _result })
					.then((_response) => {})
					.catch((err) => {
						setUpdatedData(updatedData);
					});
			} catch (err) {
				console.log(err);
			}
		},
		[reorder, updatedData],
	);

	const sectionData = useMemo(() => {
		return years.reduce((acc, year) => {
			const data = {
				year,
				items: [],
			};

			updatedData.forEach((item, index) => {
				if (item.year === year) {
					data.items.push({ data: item, index });
				}
			});

			if (data.items.length > 0) {
				acc.push(data);
			}
			return acc;
		}, []);
	}, [updatedData, years]);

	useEffect(() => {
		setUpdatedData(data);
	}, [data]);

	return (
		<Grid container spacing={3}>
			{sectionData.map((section) => (
				<Fragment key={section.year}>
					<Grid item xs={12}>
						<Typography variant="h6" color="textPrimary">
							{section.year}
						</Typography>
					</Grid>
					<SortableContainer
						axis="xy"
						pressDelay={100}
						onSortEnd={handleDrag}
					>
						{section.items.map((item) => (
							<SortableItem
								index={item.index}
								key={item.data.code}
								speaker={item.data}
								isChecked={isChecked}
								onItemSelect={onItemSelect}
							/>
						))}
					</SortableContainer>
				</Fragment>
			))}
		</Grid>
	);
};

const SortableItem = sortableElement(({ speaker, isChecked, onItemSelect }) => {
	const currentUser = useStoreState((state) => state.user.currentUser);
	const history = useHistory();
	return (
		<Grid item xs={12} sm={6} lg={3} xl={2}>
			<CardImageTitleDescription
				hasEditIcon
				hasStatus={false}
				handleEditClicked={() => {
					if (currentUser.user_account_type.type !== "Viewer") {
						history.push({
							pathname: "/speakers/edit",
							state: {
								speaker: speaker,
							},
						});
					}
				}}
				image={speaker.file.code}
				title={
					speaker.SalutationId === 7
						? speaker.firstName + " " + speaker.lastName
						: speaker.salutation.name +
						  " " +
						  speaker.firstName +
						  " " +
						  speaker.lastName
				}
				description={speaker.designation + ", " + speaker.organization}
				isChecked={isChecked(speaker.code)}
				onItemSelect={(e) => onItemSelect(e, speaker.code)}
			/>
		</Grid>
	);
});

const SortableContainer = sortableContainer(({ children }) => {
	return (
		<Grid container spacing={3}>
			{children}
		</Grid>
	);
});

export default AllTab;
