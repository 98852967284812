import React, { useEffect, useState } from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from "recharts";
import colors from "../../constants/colors";
import axios from "axios";
import { Typography } from "@material-ui/core";

const CustomBarChart = ({ selectedYear }) => {
	const [digitalSeriesCount, setDigitalSeriesCount] = useState(0);
	const [speakersCount, setSpeakersCount] = useState(0);
	const [partnersCount, setPartnersCount] = useState(0);
	const [albumsCount, setAlbumsCount] = useState(0);
	const [knowledgeHubCount, setKnowledgeHubCount] = useState(0);
	const [newsCount, setNewsCount] = useState(0);
	const [initiativesCount, setInitiativesCount] = useState(0);
	const [isDataLoaded, setIsDataLoaded] = useState(false);

	const data = [
		{
			name: "Knowledge Hub",
			// count: knowledgeHubCount.unpublished + knowledgeHubCount.published,
			Published: knowledgeHubCount.published,
			Unpublished: knowledgeHubCount.unpublished,
		},
		{
			name: "News",
			Published: newsCount.published,
			Unpublished: newsCount.unpublished,
		},
		{
			name: "Digital Series",
			count: digitalSeriesCount,
		},
		{
			name: "Speakers",
			count: speakersCount,
		},
		{
			name: "Albums",
			Videos: albumsCount.videos,
			Images: albumsCount.images,
		},
		{
			name: "Partners",
			count: partnersCount,
		},
		{
			name: "Initiatives",
			Published: initiativesCount.published,
			Unpublished: initiativesCount.unpublished,
		},
	];

	useEffect(() => {
		try {
			Promise.all([
				axios.get("/digital-series"),
				axios.get("/speaker"),
				axios.get("/album"),
				axios.get("/partner"),
				axios.get("/knowledge-hub"),
				axios.get("/news"),
				axios.get("/initiatives"),
			])
				.then((_responses) => {
					let _digitalSeriesCount = 0;
					_responses[0].data.forEach((_digitalSeries) => {
						if (parseInt(_digitalSeries.year) === selectedYear) {
							_digitalSeriesCount++;
						}
					});
					setDigitalSeriesCount(_digitalSeriesCount);

					let _speakersCount = 0;
					_responses[1].data.forEach((_speaker) => {
						if (_speaker.year === selectedYear) {
							_speakersCount++;
						}
					});
					setSpeakersCount(_speakersCount);

					let _albumVideos = 0;
					let _albumImages = 0;
					_responses[2].data.forEach((_album) => {
						if (
							_album.year === selectedYear &&
							_album.albumType === "videos"
						) {
							_albumVideos++;
						} else if (
							_album.year === selectedYear &&
							_album.albumType === "images"
						) {
							_albumImages++;
						}
					});

					setAlbumsCount({
						videos: _albumVideos,
						images: _albumImages,
					});

					setPartnersCount(_responses[3].data.length);

					let _khUnpublishedCount = 0;
					let _khPublishedCount = 0;
					_responses[4].data.forEach((_knowledgeHub) => {
						if (
							_knowledgeHub.status === "unpublished" &&
							_knowledgeHub.year === selectedYear
						) {
							_khUnpublishedCount++;
						} else if (
							_knowledgeHub.status === "published" &&
							_knowledgeHub.year === selectedYear
						) {
							_khPublishedCount++;
						}
					});
					setKnowledgeHubCount({
						published: _khPublishedCount,
						unpublished: _khUnpublishedCount,
					});

					let _newsUnpublishedCount = 0;
					let _newsPublishedCount = 0;
					_responses[5].data.forEach((_news) => {
						if (
							_news.status === "unpublished" &&
							parseInt(_news.year) === selectedYear
						) {
							_newsUnpublishedCount++;
						} else if (
							_news.status === "published" &&
							parseInt(_news.year) === selectedYear
						) {
							_newsPublishedCount++;
						}
					});

					setNewsCount({
						published: _newsPublishedCount,
						unpublished: _newsUnpublishedCount,
					});

					let _initiativesUnpublishedCount = 0;
					let _initiativesPublishedCount = 0;
					_responses[6].data.forEach((_initiatives) => {
						if (_initiatives.status === "unpublished") {
							_initiativesUnpublishedCount++;
						} else _initiativesPublishedCount++;
					});
					setInitiativesCount({
						published: _initiativesPublishedCount,
						unpublished: _initiativesUnpublishedCount,
					});
					setIsDataLoaded(true);
				})
				.catch((err) => console.log(err));
		} catch (err) {
			console.log(err);
		}
	}, [selectedYear]);

	return isDataLoaded ? (
		<>
			<BarChart width={1000} height={300} data={data}>
				<CartesianGrid strokeDasharray="3 3" />
				<XAxis dataKey="name" />
				<YAxis />
				<Tooltip />
				<Bar dataKey="Published" stackId="a" fill={colors.lightGreen} />
				<Bar dataKey="Unpublished" stackId="a" fill={colors.darkGray} />
				<Bar dataKey="count" stackId="a" fill={`${colors.darkBlue}`} />
				<Bar dataKey="Videos" stackId="a" fill={`${colors.darkBlue}`} />
				<Bar dataKey="Images" stackId="a" fill={`${colors.darkBlue}`} />
			</BarChart>
		</>
	) : (
		<Typography variant="body1" color="textPrimary">
			Loading...
		</Typography>
	);
};

export default CustomBarChart;
