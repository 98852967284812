import {
	Grid,
	CardContent,
	Card,
	Typography,
	makeStyles,
	Box,
	TextField,
} from "@material-ui/core";
import React, { useState } from "react";
import GmisLogo from "../../components/gmis-logo";
import SecondaryLayout from "../../components/secondary-layout";
import CustomLink from "../../components/custom-link";
import CustomButton from "../../components/custom-button";
import axios from "axios";
import { useHistory } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
	cardContainer: {
		minWidth: 450,
	},
}));
const ResetPasswordPage = () => {
	const classes = useStyles();
	const [jsonFormData, setJsonFormData] = useState({});
	const [validationErrors, setValidationErrors] = useState("");
	const history = useHistory();
	const formData = {
		fields: [
			{
				label: "E-mail Address",
				placeholder: "Type your email address",
				type: "email",
				name: "email",
				id: "email",
			},
		],
		button: {
			label: "Submit",
			url: "",
		},
	};

	const handleFormChange = (e) => {
		setJsonFormData({
			...jsonFormData,
			[e.target.name || e.target.id]: e.target.value,
		});
		setValidationErrors();
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		try {
			if (!jsonFormData.email) {
				setValidationErrors("Please enter an email address");
			} else {
				axios
					.post("/user/reset-password", jsonFormData)
					.then((_response) => {
						if (_response.status === 200) {
							history.push("/login");
						}
					})
					.catch((err) =>
						setValidationErrors(err.response.data.error),
					);
			}
		} catch (err) {
			console.log(err);
		}
	};

	return (
		<SecondaryLayout>
			<Grid
				container
				alignItems="center"
				justify="center"
				direction="column"
				style={{ minHeight: "100vh" }}
				spacing={3}
			>
				<Grid item>
					<GmisLogo />
				</Grid>
				<Grid item>
					<Card className={classes.cardContainer}>
						<CardContent>
							<form>
								<Grid container direction="column" spacing={4}>
									<Grid item>
										<Typography
											variant="h6"
											color="textPrimary"
										>
											<Box fontWeight="fontWeightBold">
												Reset Password
											</Box>
										</Typography>
										<Typography
											variant="body2"
											color="textSecondary"
										>
											Enter your email address and we will
											send you your new password.
										</Typography>
									</Grid>
									{formData.fields.map((_field, index) => (
										<Grid item key={index}>
											<TextField
												label={_field.label}
												placeholder={_field.placeholder}
												variant="outlined"
												size="small"
												name={_field.name}
												type={_field.type}
												fullWidth
												onChange={handleFormChange}
											/>
										</Grid>
									))}

									{formData.link ? (
										<Grid item align="right">
											<CustomLink
												linkText={formData.link.label}
												linkUrl={formData.link.url}
											/>
										</Grid>
									) : null}
									<Grid item align="center">
										<CustomButton
											onClick={handleSubmit}
											label={formData.button.label}
											type="submit"
										/>
									</Grid>
									{validationErrors ? (
										<Typography
											variant="body2"
											color="error"
											align="center"
										>
											{validationErrors}
										</Typography>
									) : null}
								</Grid>
							</form>
						</CardContent>
					</Card>
				</Grid>
			</Grid>
		</SecondaryLayout>
	);
};

export default ResetPasswordPage;
