import {
	Grid,
	Card,
	CardContent,
	makeStyles,
	Typography,
	TextField,
	Box,
} from "@material-ui/core";
import React, { useState } from "react";
import GmisLogo from "../components/gmis-logo";
import SecondaryLayout from "../components/secondary-layout";
import axios from "axios";
import CustomLink from "../components/custom-link";
import CustomButton from "../components/custom-button";
import { useLocation, useHistory } from "react-router-dom";
import { useStoreActions } from "easy-peasy";

const useStyles = makeStyles((theme) => ({
	cardContainer: {
		minWidth: 450,
	},
}));
const ChangePassword = () => {
	const location = useLocation();
	const history = useHistory();
	const classes = useStyles();
	const [jsonFormData, setJsonFormData] = useState({});
	const [validationErrors, setValidationErrors] = useState("");
	const updateFirstLogin = useStoreActions(
		(actions) => actions.user.updateFirstLogin,
	);

	// useEffect(() => {
	//   try {
	//     axios.get("/user/verify-user").then((_response) => {
	//       console.log(_response.status);
	//       if (_response.status !== 200) {
	//         history.push("/login");
	//       }
	//     });
	//   } catch (err) {
	//     console.log(err);
	//   }
	// }, []);

	const formData = {
		fields: [
			{
				label: "Current Password",
				placeholder: "Type your current password",
				type: "password",
				name: "currentPassword",
			},
			{
				label: "New Password",
				placeholder: "Type your new password",
				type: "password",
				name: "password",
			},
			{
				label: "Confirm New Password",
				placeholder: "Type your new password",
				type: "password",
				name: "passwordConfirm",
			},
		],
		button: {
			label: "Submit",
			url: "",
		},
	};

	const handleFormChange = (e) => {
		setJsonFormData({
			...jsonFormData,
			[e.target.id || e.target.name]: e.target.value,
			email: location.state.email,
		});
		setValidationErrors(null);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		if (
			!jsonFormData.currentPassword ||
			!jsonFormData.password ||
			!jsonFormData.passwordConfirm
		) {
			setValidationErrors("Please make sure all fields are filled in");
		} else if (jsonFormData.password !== jsonFormData.passwordConfirm) {
			setValidationErrors("New passwords do not match");
		} else {
			try {
				axios
					.post("/user/change-password", jsonFormData)
					.then(async (_response) => {
						await updateFirstLogin(false);
						history.push("/dashboard");
					})
					// .catch((err) => setValidationErrors(err.response.data.error));
					.catch((err) => console.log(err));
			} catch (err) {
				console.log(err);
			}
		}
	};

	return (
		<SecondaryLayout>
			<Grid
				container
				alignItems="center"
				justify="center"
				direction="column"
				style={{ minHeight: "100vh" }}
				spacing={3}
			>
				<Grid item>
					<GmisLogo />
				</Grid>
				<Grid item>
					<Card className={classes.cardContainer}>
						<CardContent>
							<form>
								<Grid container direction="column" spacing={4}>
									<Grid item>
										<Typography
											variant="h6"
											color="textPrimary"
										>
											<Box fontWeight="fontWeightBold">
												Change Password
											</Box>
										</Typography>
										<Typography
											variant="body2"
											color="textSecondary"
										>
											You can change your autogenerated
											password here
										</Typography>
									</Grid>
									{formData.fields.map((_field, index) => (
										<Grid item key={index}>
											<TextField
												label={_field.label}
												placeholder={_field.placeholder}
												variant="outlined"
												size="small"
												name={_field.name}
												type={_field.type}
												fullWidth
												onChange={handleFormChange}
											/>
										</Grid>
									))}

									{formData.link ? (
										<Grid item align="right">
											<CustomLink
												linkText={formData.link.label}
												linkUrl={formData.link.url}
											/>
										</Grid>
									) : null}
									<Grid item align="center">
										<CustomButton
											onClick={handleSubmit}
											label={formData.button.label}
											type="submit"
										/>
									</Grid>
									{validationErrors ? (
										<Typography
											variant="body2"
											color="error"
											align="center"
										>
											{validationErrors}
										</Typography>
									) : null}
								</Grid>
							</form>
						</CardContent>
					</Card>
				</Grid>
			</Grid>
		</SecondaryLayout>
	);
};

export default ChangePassword;
