import { useCallback, useState } from "react";
import MainLayout from "../../../components/main-layout";
import SectionContainerLayout from "../../../components/section-container-layout";
import LocationForm from "./form";
import { useHistory } from "react-router-dom";
import { insert } from "../helpers";
const LocationAddPage = () => {
	const history = useHistory();
	const [isLoading, setIsLoading] = useState(false);
	const handleSubmit = useCallback(
		async (values) => {
			const { name } = values;

			setIsLoading(true);
			insert({
				body: {
					name,
				},
				link: "location",
				history,
				setIsLoading,
				path: "location",
			});
		},
		[history],
	);
	return (
		<MainLayout>
			<SectionContainerLayout title="Add Location">
				<LocationForm
					submitCallBack={(values) => handleSubmit(values)}
					isLoading={isLoading}
					setIsLoading={setIsLoading}
					type="add"
				/>
			</SectionContainerLayout>
		</MainLayout>
	);
};

export default LocationAddPage;
