import React, { useMemo } from "react";
import { TextField } from "@material-ui/core";

const CustomTextField = ({
  label,
  placeholder,
  name,
  validationMessage,
  onChange,
  onBlur,
  size = "medium",
  variant = "outlined",
  type = "text",
  isFullWidth = true,
  params,
  InputLabelProps,
  value,
  disabled = false,
  multiline = false,
  rows = 1,
  maxRows = 1,
}) => {
  const memoField = useMemo(() => {
    return (
      <TextField
        {...params}
        fullWidth={isFullWidth}
        label={label}
        placeholder={placeholder}
        size={size}
        disabled={disabled}
        name={name}
        multiline={multiline}
        rows={rows}
        maxRows={maxRows}
        onBlur={onBlur}
        error={validationMessage && validationMessage !== "" ? true : false}
        helperText={validationMessage ? validationMessage : ""}
        variant={variant}
        onChange={onChange}
        type={type}
        value={value ? value : ""}
        InputProps={{
          style: {
            backgroundColor: "white",
          },
        }}
        InputLabelProps={InputLabelProps}
      />
    );
  }, [
    InputLabelProps,
    disabled,
    isFullWidth,
    label,
    name,
    onBlur,
    onChange,
    params,
    placeholder,
    size,
    type,
    validationMessage,
    value,
    variant,
    multiline,
    rows,
    maxRows,
  ]);
  return memoField;
};

export default CustomTextField;
