import { Container } from "@material-ui/core";
import CustomDrawer from "./custom-drawer";
import { useHistory } from "react-router";
import axios from "axios";
import React, { useState, useLayoutEffect } from "react";
import { useStoreActions } from "easy-peasy";

const MainLayout = ({ children }) => {
	const history = useHistory();
	const [isUserLoaded, setIsUserLoaded] = useState(false);
	const setCurrentUser = useStoreActions(
		(actions) => actions.user.setCurrentUser,
	);

	useLayoutEffect(() => {
		try {
			axios.get("/user/verify-user").then((_response) => {
				if (_response.status === 200) {
					setCurrentUser(_response.data);
					setIsUserLoaded(true);

					if (_response.data.firstLogin) {
						history.push({
							pathname: "/change-password",
							state: {
								email: _response.data.email,
							},
						});
					}
				} else {
					history.push("/login");
				}
			});
		} catch (err) {
			console.log(err);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return isUserLoaded ? (
		<Container maxWidth="xl">
			<CustomDrawer />
			{children}
		</Container>
	) : null;
};

export default MainLayout;
