import { Box, Toolbar, Grid, Button } from "@material-ui/core";
import CustomTextField from "../custom-text-field";
import colors from "../../constants/colors";
import CustomSelect from "../custom-select";
import { useMemo } from "react";
export default function Filters({
	filterValues,
	handleFilterChange,
	onClearFiltersClick,
	filters,
}) {
	const FiltersMemo = useMemo(() => {
		return (
			<Box width={1}>
				<Toolbar style={{ padding: 0 }}>
					<Box px={2} my={2} width={1}>
						<Grid container spacing={2}>
							{filters &&
								filters.length > 0 &&
								filters.map((_filter, index) => {
									return _filter.type === "text" ? (
										<Grid item xs={12} md={3} key={index}>
											<CustomSelect
												name="somthing"
												label={_filter.label}
												options={_filter.data}
												isMultiple={false}
												validationMessage=""
												is_publish={
													_filter.label.toLowerCase() ===
														"status" ||
													_filter.label.toLowerCase() ===
														"isPublished"
														? true
														: false
												}
												hasClearOption={true}
												value={
													typeof filterValues !==
													"undefined"
														? filterValues[
																`${_filter.label.toLowerCase()}`
														  ]
														: ""
												}
												onChange={(e) =>
													handleFilterChange(
														e,
														_filter.label.toLowerCase(),
													)
												}
											/>
										</Grid>
									) : _filter.type === "date" ? (
										<Grid item xs={12} md={3} key={index}>
											<CustomTextField
												type="date"
												label={_filter.label}
												InputLabelProps={{
													shrink: true,
												}}
												onChange={(e) => {
													handleFilterChange(
														e,
														_filter.label.toLowerCase(),
													);
												}}
												value={
													filterValues &&
													Object.keys(filterValues)
														.length > 0
														? _filter.label ===
														  "startDate"
															? filterValues.startDate
															: filterValues.endDate
														: ""
												}
											/>
										</Grid>
									) : _filter.type === "dateTime" ? (
										<Grid item xs={12} md={3} key={index}>
											<CustomTextField
												type="datetime-local"
												label={_filter.label}
												InputLabelProps={{
													shrink: true,
												}}
												onChange={(e) => {
													handleFilterChange(
														e,
														_filter.label.toLowerCase(),
													);
												}}
												value={
													filterValues &&
													Object.keys(filterValues)
														.length > 0
														? _filter.label ===
														  "startDateTime"
															? filterValues.startDateTime
															: filterValues.endDateTime
														: ""
												}
											/>
										</Grid>
									) : _filter.type === "boolean" ? (
										<Grid item xs={12} md={3} key={index}>
											<CustomSelect
												name="somthing"
												label={_filter.label}
												options={_filter.data}
												isMultiple={false}
												validationMessage=""
												hasClearOption={true}
												value={
													typeof filterValues !==
													"undefined"
														? filterValues[
																filterValues
																	.name
														  ]
														: ""
												}
												onChange={(e) =>
													handleFilterChange(
														e,
														_filter.label.toLowerCase(),
													)
												}
											/>
										</Grid>
									) : null;
								})}
							{filterValues &&
							Object.keys(filterValues).length > 0 ? (
								<Grid item xs={12} md={3}>
									<Box
										display="flex"
										flexDirection="row"
										justifyContent="center"
										justifyItems="center"
										height={1}
									>
										<Button
											style={{
												color: colors.lightGray,
											}}
											variant="text"
											onClick={onClearFiltersClick}
										>
											Clear filters
										</Button>
									</Box>
								</Grid>
							) : null}
						</Grid>
					</Box>
				</Toolbar>
			</Box>
		);
	}, [filters, filterValues, handleFilterChange, onClearFiltersClick]);
	return FiltersMemo;
}
