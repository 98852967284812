import * as yup from "yup";

export const addNewAlbum = yup.object().shape({
	date: yup.date().required("Date is required"),
	title: yup.string().required("Title is required"),
	albumType: yup.string().required("Type is required"),
});

export const editAlbum = yup.object().shape({
	date: yup.date().required("Date is required"),
	title: yup.string().required("Title is required"),
});
