import { useCallback, useState, useEffect } from "react";
import MainLayout from "../../../components/main-layout";
import SectionContainerLayout from "../../../components/section-container-layout";
import { useHistory } from "react-router-dom";
import { getByCode, update } from "../helpers";
import AgendaForm from "./form";
import { map } from "lodash";
const AgendaEditPage = ({ match }) => {
	const history = useHistory();
	const [isLoading, setIsLoading] = useState(true);
	const { code } = match.params;
	const [currentData, setCurrentData] = useState({});
	const [noData, setNoData] = useState(null);

	useEffect(() => {
		getByCode({
			link: "agenda",
			code,
			setData: setCurrentData,
			setNoData,
			setIsLoading,
		});
	}, [code]);

	const handleSubmit = useCallback(
		async (values) => {
			const { name, description, dateTime, locations } = values;
			const locationCodes =
				locations && locations.length > 0
					? map(locations, (item) => item.code)
					: [];
			setIsLoading(true);
			update({
				link: "agenda",
				path: "agenda",
				body: {
					code: code,
					name,
					description,
					dateTime: new Date(dateTime).toISOString(),
					locationCodes,
				},
				history,
				setIsLoading,
			});
		},
		[code, history],
	);
	return (
		<MainLayout>
			<SectionContainerLayout title="Edit agenda">
				{currentData && currentData.code ? (
					<AgendaForm
						currentData={currentData}
						submitCallBack={(values) => handleSubmit(values)}
						isLoading={isLoading}
						setIsLoading={setIsLoading}
						type="edit"
					/>
				) : (
					<p>{noData}</p>
				)}
			</SectionContainerLayout>
		</MainLayout>
	);
};

export default AgendaEditPage;
