import {
	Grid,
	TextField,
	Box,
	Button,
	FormHelperText,
} from "@material-ui/core";
import { DropzoneArea } from "material-ui-dropzone";
import React, { useEffect, useState } from "react";
import MainLayout from "../../components/main-layout";
import SectionContainerLayout from "../../components/section-container-layout";
import axios from "axios";
import { useHistory, useParams } from "react-router-dom";
import { editInitiativePartner } from "../../validations/initiative-partner-validator";
import { useLocation } from "react-router-dom";

const EditInitiativePartnerPage = () => {
	const { initiativeCode } = useParams();
	const [currentInitiativePartner, setCurrentInitiativePartner] = useState();
	const [jsonFormData, setJsonFormData] = useState();
	const [imageFile, setImageFile] = useState({});
	const [validationErrors, setValidationErrors] = useState([]);
	const [isDataLoaded, setIsDataLoaded] = useState(false);
	const history = useHistory();
	const location = useLocation();

	useEffect(() => {
		const { initiativePartner } = location.state;
		setCurrentInitiativePartner(initiativePartner);

		setIsDataLoaded(true);

		setJsonFormData((jsonFormData) => ({
			...jsonFormData,
			code: initiativePartner.code,
			description: initiativePartner.description,
			title: initiativePartner.title,
			linkedinUrl: initiativePartner.linkedinUrl,
			websitUrl: initiativePartner.websitUrl,
			twitterUrl: initiativePartner.twitterUrl,
			facebookUrl: initiativePartner.facebookUrl,
			instagramUrl: initiativePartner.instagramUrl,
			order: initiativePartner.order,
			file: initiativePartner.file,
		}));

		const _imgFile = initiativePartner.file;
		if (_imgFile.length > 0) {
			const _imgArray = [];
			_imgFile.forEach((p) => {
				_imgArray.push(
					`${process.env.REACT_APP_API_URL}/static/${p.code}`,
				);
			});
		}
	}, [location.state]);

	const handleFormChange = (e) => {
		setJsonFormData({
			...jsonFormData,
			[e.target.id || e.target.name]: e.target.value || e.target.checked,
		});
	};

	const handleImageFile = (file) => {
		setImageFile(file[0]);
		setValidationErrors({
			...validationErrors,
			imageFile: null,
		});
	};

	const handleSubmit = () => {
		try {
			let errorMessages = {};
			editInitiativePartner
				.validate(jsonFormData, { abortEarly: false })
				.then(() => {
					let formData = new FormData();
					formData.append("imageFile", imageFile);

					for (let i in jsonFormData) {
						if (jsonFormData[i] !== null) {
							if (i === "file") {
								formData.append(
									"fileCode",
									jsonFormData[i].code,
								);
							} else {
								formData.append(i, jsonFormData[i]);
							}
						}
					}
					formData.append("initiativeCode", initiativeCode);
					axios
						.put("/initiative-partner", formData, {
							headers: { "Content-Type": "multipart/form-data" },
						})
						.then((_response) => {
							if (_response.status === 200) {
								axios
									.get(`/initiatives/${initiativeCode}`)
									.then((_response) => {
										if (_response.status === 200) {
											history.push({
												pathname: "/initiatives/edit",
												state: {
													initiative: _response.data,
												},
											});
										} else {
											history.push("/initiatives/");
										}
									});
								// history.push("/initiatives/");
							} else {
								console.log("error");
							}
						})
						.catch((err) => console.log(err));
				})
				.catch((err) => {
					err.inner.forEach((e) => {
						errorMessages[e.path] = e.message;
					});
					setValidationErrors(errorMessages);
				});
		} catch (err) {}
	};

	return (
		<MainLayout>
			{isDataLoaded ? (
				<SectionContainerLayout
					title={`EDIT INITIATIVE PARTNERS: ${currentInitiativePartner}`}
				>
					<Grid container spacing={3}>
						<Grid item container xs={12} md={6}>
							<Grid
								item
								xs={12}
								style={{
									paddingTop: "12px",
									paddingBottom: "12px",
								}}
							>
								<TextField
									label="Website URL"
									id="websiteUrl"
									variant="outlined"
									fullWidth
									defaultValue={jsonFormData.websitUrl}
									onChange={handleFormChange}
								/>
							</Grid>
							<Grid
								item
								xs={12}
								style={{
									paddingTop: "12px",
									paddingBottom: "12px",
								}}
							>
								<TextField
									label="Title"
									id="title"
									variant="outlined"
									error={validationErrors.title}
									helperText={validationErrors.title}
									fullWidth
									required
									defaultValue={jsonFormData.title}
									onChange={handleFormChange}
								/>
							</Grid>
							<Grid
								item
								xs={12}
								style={{
									paddingTop: "12px",
									paddingBottom: "12px",
								}}
							>
								<TextField
									label="Description"
									id="description"
									variant="outlined"
									fullWidth
									multiline
									onChange={handleFormChange}
									defaultValue={jsonFormData.description}
									rows={4}
								/>
							</Grid>
						</Grid>
						<Grid
							item
							xs={12}
							md={6}
							style={{
								paddingTop: "12px",
								paddingBottom: "12px",
							}}
						>
							<Box m={1} width={1} style={{ height: "100%" }}>
								<DropzoneArea
									filesLimit={1}
									initialFiles={[
										`${process.env.REACT_APP_API_URL}/static/${jsonFormData.file.code}`,
									]}
									onChange={(file) => handleImageFile(file)}
									acceptedFiles={["image/*"]}
								/>
								<FormHelperText>
									Width: 500px, Height: 300px
								</FormHelperText>
							</Box>
						</Grid>
						<Grid item xs={12} md={6}>
							<TextField
								label="Linkedin"
								placeholder="URL"
								id="linkedinUrl"
								variant="outlined"
								onChange={handleFormChange}
								fullWidth
								defaultValue={jsonFormData.linkedinUrl}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<TextField
								label="Twitter"
								placeholder="URL"
								id="twitterUrl"
								variant="outlined"
								onChange={handleFormChange}
								fullWidth
								defaultValue={jsonFormData.twitterUrl}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<TextField
								label="Facebook"
								id="facebookUrl"
								placeholder="URL"
								variant="outlined"
								onChange={handleFormChange}
								fullWidth
								defaultValue={jsonFormData.facebookUrl}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<TextField
								label="Instagram"
								placeholder="URL"
								id="instagramUrl"
								onChange={handleFormChange}
								variant="outlined"
								fullWidth
								defaultValue={jsonFormData.instagramUrl}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<TextField
								label="Order"
								placeholder="Order"
								id="order"
								onChange={handleFormChange}
								variant="outlined"
								fullWidth
								type="number"
								InputProps={{ inputProps: { min: 0 } }}
								defaultValue={jsonFormData.order}
							/>
						</Grid>

						<Grid item container xs={12}>
							<Grid item xs={12} md={6}></Grid>
							<Grid item xs={12} md={6} align="right">
								<Button
									onClick={() =>
										history.push({
											pathname: "/initiatives/edit",
											state: {
												initiative:
													location.state.initiative,
											},
										})
									}
									style={{ textTransform: "capitalize" }}
								>
									Cancel
								</Button>
								<Button
									variant="contained"
									color="primary"
									onClick={handleSubmit}
									style={{ textTransform: "capitalize" }}
								>
									Submit
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</SectionContainerLayout>
			) : null}
		</MainLayout>
	);
};

export default EditInitiativePartnerPage;
