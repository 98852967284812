import { Grid, TextField, Button, Box } from "@material-ui/core";
import React, { useState } from "react";
import { DropzoneArea } from "material-ui-dropzone";
import MainLayout from "../../components/main-layout";
import SectionContainerLayout from "../../components/section-container-layout";
import axios from "axios";
import { useHistory } from "react-router-dom";
import "date-fns";
import { sendSupport } from "../../validations/support-validator";
import { useStoreState } from "easy-peasy";

const SupportPage = () => {
	const [jsonFormData, setJsonFormData] = useState({});
	const [imageFiles, setImageFiles] = useState({});
	const [validationErrors, setValidationErrors] = useState({});
	const currentUser = useStoreState((state) => state.user.currentUser);

	const history = useHistory();

	const handleFormChange = (e) => {
		setJsonFormData({
			...jsonFormData,
			[e.target.id || e.target.name]: e.target.value,
		});

		setValidationErrors({
			...validationErrors,
			[e.target.id || e.target.name]: null,
		});
	};

	const handleFile = (file) => {
		setImageFiles(file[0]);
	};

	const handleSubmit = async () => {
		let errorMessages = {};
		try {
			sendSupport
				.validate(jsonFormData, { abortEarly: false })
				.then(() => {
					if (!imageFiles) {
						return;
					}

					let formData = new FormData();
					formData.append("imageFiles", imageFiles);
					for (let i in jsonFormData) {
						formData.append(i, jsonFormData[i]);
					}

					formData.append("email", currentUser.email);

					axios
						.post("/support", formData, {
							headers: { "Content-Type": "multipart/form-data" },
						})
						.then((_response) => {
							if (_response.status === 200) {
								history.push("/dashboard");
							} else {
								console.log("error");
							}
						})
						.catch((err) => console.log(err));
				})
				.catch((err) => {
					err.inner.forEach((e) => {
						errorMessages[e.path] = e.message;
					});
					setValidationErrors(errorMessages);
				});
		} catch (err) {
			console.log(err);
		}
	};

	return (
		<MainLayout>
			<SectionContainerLayout title="NEED SUPPORT">
				<Grid container>
					<Grid item container xs={12} md={8}>
						<Grid item container xs={12} spacing={1}>
							<Grid item xs={12}>
								<TextField
									variant="outlined"
									fullWidth
									label="Title"
									placeholder="Title"
									required
									id="title"
									name="title"
									onChange={handleFormChange}
									error={validationErrors.title}
									helperText={validationErrors.title}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									multiline
									rows={10}
									fullWidth
									variant="outlined"
									required
									onChange={handleFormChange}
									label="Type your issue"
									name="issue"
									id="issue"
									error={validationErrors.issue}
									helperText={validationErrors.issue}
								/>
							</Grid>
						</Grid>
					</Grid>
					<Grid item container xs={12} md={4} spacing={1}>
						<Grid item xs={12}>
							<DropzoneArea
								dropzoneText="drag and drop or click here"
								filesLimit={1}
								onChange={(file) => handleFile(file)}
								acceptedFiles={["image/*"]}
							/>
						</Grid>
					</Grid>
					<Box clone pt={2}>
						<Grid item container xs={12} align="right">
							<Grid item xs={12}>
								<Button style={{ textTransform: "capitalize" }}>
									Cancel
								</Button>
								<Button
									variant="contained"
									onClick={handleSubmit}
									color="primary"
									style={{ textTransform: "capitalize" }}
								>
									Submit
								</Button>
							</Grid>
						</Grid>
					</Box>
				</Grid>
			</SectionContainerLayout>
		</MainLayout>
	);
};

export default SupportPage;
