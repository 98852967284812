import {
	Grid,
	TextField,
	Box,
	Button,
	FormHelperText,
} from "@material-ui/core";
import { DropzoneArea } from "material-ui-dropzone";
import React, { useState } from "react";
import MainLayout from "../../components/main-layout";
import SectionContainerLayout from "../../components/section-container-layout";
import axios from "axios";
import { useHistory, useParams } from "react-router-dom";
import { addInitiativePartner } from "../../validations/initiative-partner-validator";
import { useLocation } from "react-router-dom";

const AddInitiativePartnerPage = () => {
	const { initiativeCode } = useParams();
	const [jsonFormData, setJsonFormData] = useState();
	const [imageFile, setImageFile] = useState({});
	const [validationErrors, setValidationErrors] = useState([]);
	const history = useHistory();
	const location = useLocation();

	const handleFormChange = (e) => {
		setJsonFormData({
			...jsonFormData,
			[e.target.id || e.target.name]: e.target.value || e.target.checked,
		});
	};

	const handleImageUpload = (file) => {
		setImageFile(file[0]);
	};

	const handleSubmit = () => {
		try {
			let errorMessages = {};
			addInitiativePartner
				.validate(jsonFormData, { abortEarly: false })
				.then(() => {
					let formData = new FormData();
					formData.append("imageFile", imageFile);

					for (let i in jsonFormData) {
						formData.append(i, jsonFormData[i]);
					}
					formData.append("initiativeCode", initiativeCode);
					axios
						.post("/initiative-partner", formData, {
							headers: { "Content-Type": "multipart/form-data" },
						})
						.then((_response) => {
							if (_response.status === 200) {
								axios
									.get(`/initiatives/${initiativeCode}`)
									.then((_response) => {
										if (_response.status === 200) {
											history.push({
												pathname: "/initiatives/edit",
												state: {
													initiative: _response.data,
												},
											});
										} else {
											history.push("/initiatives/");
										}
									});
								// history.push("/initiatives/");
							} else {
								console.log("error");
							}
						})
						.catch((err) => console.log(err));
				})
				.catch((err) => {
					err.inner.forEach((e) => {
						errorMessages[e.path] = e.message;
					});
					setValidationErrors(errorMessages);
				});
		} catch (err) {}
	};

	return (
		<MainLayout>
			<SectionContainerLayout title="ADD INITIATIVE PARTNER">
				<Grid container spacing={3}>
					<Grid item container xs={12} md={6}>
						<Grid
							item
							xs={12}
							style={{
								paddingTop: "12px",
								paddingBottom: "12px",
							}}
						>
							<TextField
								label="Website URL"
								id="websiteUrl"
								variant="outlined"
								fullWidth
								onChange={handleFormChange}
							/>
						</Grid>
						<Grid
							item
							xs={12}
							style={{
								paddingTop: "12px",
								paddingBottom: "12px",
							}}
						>
							<TextField
								label="Title"
								id="title"
								variant="outlined"
								error={validationErrors.title}
								helperText={validationErrors.title}
								fullWidth
								required
								onChange={handleFormChange}
							/>
						</Grid>
						<Grid
							item
							xs={12}
							style={{
								paddingTop: "12px",
								paddingBottom: "12px",
							}}
						>
							<TextField
								label="Description"
								id="description"
								variant="outlined"
								fullWidth
								multiline
								onChange={handleFormChange}
								rows={4}
							/>
						</Grid>
					</Grid>
					<Grid
						item
						xs={12}
						md={6}
						style={{ paddingTop: "12px", paddingBottom: "12px" }}
					>
						<Box m={1} width={1} style={{ height: "100%" }}>
							<DropzoneArea
								filesLimit={1}
								onChange={(file) => handleImageUpload(file)}
								acceptedFiles={["image/*"]}
							/>
							<FormHelperText>
								Width: 500px, Height: 300px
							</FormHelperText>
						</Box>
					</Grid>
					<Grid item xs={12} md={6}>
						<TextField
							label="Linkedin"
							placeholder="URL"
							id="linkedinUrl"
							variant="outlined"
							onChange={handleFormChange}
							fullWidth
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<TextField
							label="Twitter"
							placeholder="URL"
							id="twitterUrl"
							variant="outlined"
							onChange={handleFormChange}
							fullWidth
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<TextField
							label="Facebook"
							id="facebookUrl"
							placeholder="URL"
							variant="outlined"
							onChange={handleFormChange}
							fullWidth
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<TextField
							label="Instagram"
							placeholder="URL"
							id="instagramUrl"
							onChange={handleFormChange}
							variant="outlined"
							fullWidth
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<TextField
							label="Order"
							placeholder="Order"
							id="order"
							defaultValue="0"
							onChange={handleFormChange}
							variant="outlined"
							fullWidth
							type="number"
							InputProps={{ inputProps: { min: 0 } }}
						/>
					</Grid>

					<Grid item container xs={12}>
						<Grid item xs={12} md={6}></Grid>
						<Grid item xs={12} md={6} align="right">
							<Button
								onClick={() =>
									history.push({
										pathname: "/initiatives/edit",
										state: {
											initiative:
												location.state.initiative,
										},
									})
								}
								style={{ textTransform: "capitalize" }}
							>
								Cancel
							</Button>
							<Button
								variant="contained"
								color="primary"
								onClick={handleSubmit}
								style={{ textTransform: "capitalize" }}
							>
								Submit
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</SectionContainerLayout>
		</MainLayout>
	);
};

export default AddInitiativePartnerPage;
