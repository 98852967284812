import React from "react";
import {
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	makeStyles,
} from "@material-ui/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import colors from "../../constants/colors";

const useStyles = makeStyles((theme) => ({
	horizontalList: {
		display: "flex",
		flexDirection: "row",
		padding: 0,
		justifyContent: "flex-end",
	},
}));

const ToolbarLegend = ({
	showPublished = true,
	showUnpublished = true,
	showGeneral = false,
}) => {
	const classes = useStyles();
	return (
		<List className={classes.horizontalList}>
			{showGeneral ? (
				<ListItem style={{ width: "auto" }}>
					<ListItemIcon style={{ minWidth: "auto" }}>
						<FiberManualRecordIcon
							style={{ color: colors.darkBlue }}
						/>
					</ListItemIcon>
					<ListItemText primary="General" />
				</ListItem>
			) : null}
			{showPublished ? (
				<ListItem style={{ width: "auto" }}>
					<ListItemIcon style={{ minWidth: "auto" }}>
						<FiberManualRecordIcon color="primary" />
					</ListItemIcon>
					<ListItemText primary="Published" />
				</ListItem>
			) : null}
			{showUnpublished ? (
				<ListItem style={{ width: "auto" }}>
					<ListItemIcon style={{ minWidth: "auto" }}>
						<FiberManualRecordIcon
							style={{ color: colors.darkGray }}
						/>
					</ListItemIcon>
					<ListItemText primary="Unpublished" />
				</ListItem>
			) : null}
		</List>
	);
};

export default ToolbarLegend;
