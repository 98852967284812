import React from "react";

import { Box, Grid, Typography, Divider } from "@material-ui/core";

const SectionContainerLayout = ({
	marginTop = 12,
	marginX = 9,
	title,
	children,
}) => {
	return (
		<Box mt={marginTop} mx={marginX}>
			<Grid container>
				<Grid item xs={12}>
					<Typography variant="h6" color="textPrimary" gutterBottom>
						<Box fontWeight="fontWeightBold">{title}</Box>
					</Typography>
					<Box my={2}>
						<Divider orientation="horizontal" light />
					</Box>
				</Grid>
				<Grid item xs={12}>
					{children}
				</Grid>
			</Grid>
		</Box>
	);
};

export default SectionContainerLayout;
