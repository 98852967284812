import { useEffect, useCallback, useState } from "react";
import MainLayout from "../../../components/main-layout";
import SectionContainerLayout from "../../../components/section-container-layout";
import { getByCode, update } from "../helpers";
import LocationForm from "./form";
import { useHistory } from "react-router-dom";

const LocationEditPage = ({ match }) => {
	const history = useHistory();
	const [isLoading, setIsLoading] = useState(true);
	const { code } = match.params;
	const [currentData, setCurrentData] = useState({});
	const [noData, setNoData] = useState(null);
	useEffect(() => {
		getByCode({
			link: "location",
			code,
			setData: setCurrentData,
			setNoData,
			setIsLoading,
		});
	}, [code]);

	const handleSubmit = useCallback(
		async (values) => {
			setIsLoading(true);
			update({
				link: "location",
				path: "location",
				body: {
					code: code,
					name: values.name,
				},
				history,
				setIsLoading,
			});
		},
		[code, history],
	);
	return (
		<MainLayout>
			<SectionContainerLayout title="Edit Location">
				{currentData && currentData.code ? (
					<LocationForm
						currentData={currentData}
						submitCallBack={(values) => handleSubmit(values)}
						isLoading={isLoading}
						setIsLoading={setIsLoading}
						type="edit"
					/>
				) : (
					<p>{noData}</p>
				)}
			</SectionContainerLayout>
		</MainLayout>
	);
};

export default LocationEditPage;
