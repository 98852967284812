import { useState, useCallback, useEffect } from "react";
import MainLayout from "../../../components/main-layout";
import SectionContainerLayout from "../../../components/section-container-layout";
import ScrollableTabsButtonForce from "../components/tabs";
import CardImageTitleDescription from "../../../components/card-image-title-description";
import { useHistory } from "react-router-dom";
import colors from "../../../constants/colors";
import { fetch } from "../helpers";
import TableCustomHeader from "../components/table-header";
import {
	Grid,
	Box,
	TableContainer,
	Paper,
	Table,
	TableRow,
	makeStyles,
	useTheme,
	TableCell,
	Typography,
	TableBody,
	Checkbox,
} from "@material-ui/core";
import ModuleToolbar from "../../../components/module-toolbar";
import { deleteData } from "../axios";
import Spinner from "../../../components/spinner";
import { useDebounce } from "use-debounce/lib";
import { useStoreState } from "easy-peasy";
const useStyles = makeStyles((theme) => ({
	tableHeader: {
		backgroundColor: colors.darkBlue,
	},
	rowHover: {
		"&:hover": {
			cursor: "pointer",
		},
	},
}));

const LocationPage = () => {
	const history = useHistory();
	const [selected, setSelected] = useState([]);
	const [data, setData] = useState([]);
	const [search, setSearch] = useState();
	const [searchText] = useDebounce(search, 1000);
	const [isGridView, setIsGridView] = useState(true);
	const [isListView, setListView] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const currentUser = useStoreState((state) => state.user.currentUser);
	const theme = useTheme();
	const classes = useStyles();

	const toggleGridView = useCallback(() => {
		setIsGridView(true);
		setListView(false);
	}, []);

	const toggleListView = useCallback(() => {
		setIsGridView(false);
		setListView(true);
	}, []);

	const fetchList = useCallback(() => {
		fetch({
			pathname: "location",
			setIsLoading,
			searchText: searchText,
			setData,
		});
	}, [searchText]);

	useEffect(() => {
		fetchList();
	}, [fetchList]);

	const handleSearch = (e) => {
		setSearch(e.target.value);
	};
	const handleSelectAllChange = useCallback(
		(event) => {
			if (event.target.checked) {
				const newSelected = data.map((n) => n.code);
				setSelected(newSelected);
				return;
			}
			setSelected([]);
		},
		[data],
	);

	const handleDeleteSelected = useCallback(async () => {
		const repsonse = await deleteData({ path: "location", selected });
		if (repsonse) {
			setSelected([]);
			fetchList();
		} else console.log("err");
	}, [selected, fetchList]);

	const handleItemSelectChange = useCallback(
		(e, data) => {
			const selectedIndex = selected.indexOf(data);
			let newSelected = [];

			if (selectedIndex === -1) {
				newSelected = newSelected.concat(selected, data);
			} else if (selectedIndex === 0) {
				newSelected = newSelected.concat(selected.slice(1));
			} else if (selectedIndex === selected.length - 1) {
				newSelected = newSelected.concat(selected.slice(0, -1));
			} else if (selectedIndex > 0) {
				newSelected = newSelected.concat(
					selected.slice(0, selectedIndex),
					selected.slice(selectedIndex + 1),
				);
			}

			setSelected(newSelected);
		},
		[selected],
	);

	const isSelected = useCallback(
		(data) => selected.indexOf(data) !== -1,
		[selected],
	);
	return (
		<MainLayout>
			<SectionContainerLayout title="Location">
				<ScrollableTabsButtonForce />

				{!isLoading ? (
					<Box mt={5}>
						<Grid container>
							<Grid item xs={12}>
								<ModuleToolbar
									totalSelected={selected.length}
									onSelectAllClick={handleSelectAllChange}
									onDeleteSelected={handleDeleteSelected}
									addModuleUrl="/agendas/location/add"
									handleGridView={toggleGridView}
									handleSearchChange={handleSearch}
									handleListView={toggleListView}
									filterIcon={false}
									changeStatusAction={false}
								/>
							</Grid>
							<Grid item container xs={12} spacing={3}>
								{isGridView && data && data.length > 0 ? (
									data.map((_item) => (
										<Grid
											item
											xs={12}
											md={4}
											lg={3}
											key={_item.code}
										>
											<CardImageTitleDescription
												hasStatus={false}
												onClick={() =>
													history.push(
														`/agendas/location/edit/${_item.code}`,
													)
												}
												title={
													_item.name ? _item.name : ""
												}
												isChecked={isSelected(
													_item.code,
												)}
												onItemSelect={(e) =>
													handleItemSelectChange(
														e,
														_item.code,
													)
												}
											/>
										</Grid>
									))
								) : isListView && data && data.length > 0 ? (
									<TableContainer component={Paper}>
										<Table>
											<TableCustomHeader
												currentUser={currentUser}
												theme={theme}
												classes={classes}
												headers={["Name"]}
											/>
											<TableBody>
												{data.map((_item) => (
													<Box
														key={_item.code}
														clone
														className={
															classes.rowHover
														}
													>
														<TableRow
															scope="row"
															hover
														>
															{currentUser
																.user_account_type
																.type !==
															"Viewer" ? (
																<TableCell
																	width={1}
																>
																	<Checkbox
																		checked={isSelected(
																			_item.code,
																		)}
																		onClick={(
																			e,
																		) =>
																			handleItemSelectChange(
																				e,
																				_item.code,
																			)
																		}
																		inputProps={{
																			"aria-labelledby": 1,
																		}}
																	/>
																</TableCell>
															) : null}
															<TableCell
																width={100}
																align="center"
																onClick={() =>
																	history.push(
																		`/agendas/location/edit/${_item.code}`,
																	)
																}
															>
																<Typography
																	variant="body1"
																	color="textPrimary"
																>
																	{_item.name
																		? _item.name
																		: ""}
																</Typography>
															</TableCell>
														</TableRow>
													</Box>
												))}
											</TableBody>
										</Table>
									</TableContainer>
								) : (
									<Typography
										variant="body1"
										color="textPrimary"
										align="center"
									>
										There are no results
									</Typography>
								)}
							</Grid>
						</Grid>
					</Box>
				) : (
					<Spinner open={isLoading} />
				)}
			</SectionContainerLayout>
		</MainLayout>
	);
};

export default LocationPage;
