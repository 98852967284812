import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import { useDebounce } from "use-debounce";
import MainLayout from "../../components/main-layout";
import ModuleToolbar from "../../components/module-toolbar";
import SectionContainerLayout from "../../components/section-container-layout";
import axios from "axios";
import CardImage from "../../components/card-image";
import { useHistory } from "react-router-dom";
import { useStoreState } from "easy-peasy";
import * as _ from "lodash";

const PartnersPage = () => {
	const [partners, setPartners] = useState();
	const [partnerCategories, setPartnerCategories] = useState();
	const [years, setYears] = useState();
	const [isDataLoaded, setisDataLoaded] = useState(false);
	const [selected, setSelected] = useState([]);
	const history = useHistory();
	const [filterCategory, setFilterCategory] = useState("");
	const [search, setSearch] = useState("");
	const [filters, setFilters] = useState({});
	const [searchText] = useDebounce(search, 1000);
	const currentUser = useStoreState((state) => state.user.currentUser);
	useEffect(() => {
		try {
			const token = localStorage.getItem("token");
			if (!token) {
				history.push("/login");
			}
			fetchList();
		} catch (err) {
			console.log(err);
		}
		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const fetchList = () => {
		Promise.all([
			axios.get("/partner"),
			axios.get("/utility/partner-categories"),
		])
			.then((_responses) => {
				if (
					_responses[0].status === 200 &&
					_responses[1].status === 200
				) {
					setPartners(_responses[0].data);
					const _years = _.uniq(_.map(_responses[0].data, "year"));
					const fullYears = _years.map((_y) => ({
						name: _y,
						value: _y,
					}));
					setYears({
						idName: "year",
						label: "Year",
						options: fullYears,
					});
					setPartnerCategories(_responses[1].data);
					setisDataLoaded(true);
				} else if (
					_responses[0].status === 404 ||
					_responses[1].status === 404
				) {
					history.push("/login");
				}
			})
			.catch((err) => console.log(err));
	};
	const handleClick = (partner) => {
		if (currentUser.user_account_type.type !== "Viewer") {
			history.push({
				pathname: "/partners/edit",
				state: {
					partner,
				},
			});
		}
	};
	const isSelected = (data) => selected.indexOf(data) !== -1;
	const handleSelectAllChange = (e) => {
		if (e.target.checked) {
			const newSelected = partners.map((n) => n.code);
			setSelected(newSelected);
			return;
		}
		setSelected([]);
	};
	const handleItemSelectChange = (e, data) => {
		const selectedIndex = selected.indexOf(data);
		let newSelected = [];
		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, data);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1),
			);
		}
		setSelected(newSelected);
	};
	const handleDeleteSelected = (e) => {
		axios
			.delete("/partner", { data: { code: selected } })
			.then((_response) => {
				setSelected([]);
				fetchList();
			})
			.catch((err) => console.log(err));
	};
	useEffect(() => {
		axios
			.post("/partner/search", {
				filters: {
					search: searchText,
					category: filterCategory,
					...filters,
				},
			})
			.then((_response) => {
				if (_response.status === 200) {
					setPartners(_response.data);
					let _partnerCatgories = [];
					_response.data.forEach((_partner) => {
						var index =
							_partner &&
							_partner.partnercategory &&
							_partner.partnercategory.name &&
							_partnerCatgories &&
							_partnerCatgories.findIndex(
								(obj) =>
									obj.name &&
									obj.name === _partner.partnercategory.name,
							);
						if (index === -1) {
							_partnerCatgories.push(_partner.partnercategory);
						}
					});
					setPartnerCategories(_partnerCatgories);
				}
			})
			.catch((err) => console.log(err));
	}, [searchText, filterCategory, filters]);
	const handleSearch = (e) => {
		try {
			setSearch(e.target.value);
		} catch (err) {
			console.log(err);
		}
	};
	const handleFilterData = (value, key) => {
		try {
			setFilters({ ...filters, [key]: value.toString() });
		} catch (err) {
			console.log(err);
		}
	};
	const handleFilterByPartnerCategory = (category) => {
		try {
			setFilterCategory(category);
		} catch (err) {
			console.log(err);
		}
	};
	return (
		<MainLayout>
			<SectionContainerLayout title="PARTNERS">
				{isDataLoaded ? (
					<>
						<Grid container>
							<Grid item xs={12}>
								<ModuleToolbar
									totalSelected={selected.length}
									totalData={partners.length}
									onSelectAllClick={handleSelectAllChange}
									onDeleteSelected={handleDeleteSelected}
									showFilterByPartnerCategories
									addModuleUrl="/partners/add"
									listViewIcon={false}
									gridViewIcon={false}
									handleSearchChange={handleSearch}
									handlePartnerCategoryChange={
										handleFilterByPartnerCategory
									}
									changeStatusAction={false}
									showCustomFilter
									filterData={{ years }}
									handleFilterData={handleFilterData}
								/>
							</Grid>
						</Grid>
						<Grid container spacing={3}>
							{partnerCategories &&
								partnerCategories.length > 0 &&
								partnerCategories.map(
									(_partnerCategory, index) => (
										<Grid
											item
											container
											key={index}
											spacing={3}
										>
											<Grid
												item
												container
												xs={12}
												spacing={1}
											>
												<Grid item xs={12}>
													<Typography
														variant="h6"
														color="textPrimary"
														gutterBottom
													>
														{_partnerCategory &&
														_partnerCategory.name
															? _partnerCategory.name
															: ""}
													</Typography>
												</Grid>
											</Grid>
											{partners && partners.length > 0 ? (
												partners.map(
													(_partner, index) =>
														_partnerCategory &&
														_partnerCategory.name &&
														_partner &&
														_partner.partnercategory &&
														_partner.partnercategory
															.name &&
														_partnerCategory.name ===
															_partner
																.partnercategory
																.name ? (
															<Grid
																key={index}
																item
																xs={12}
																md={6}
																lg={4}
															>
																<Typography
																	variant="body1"
																	color="textPrimary"
																>
																	{
																		_partner.title
																	}
																</Typography>
																<CardImage
																	onClick={() =>
																		handleClick(
																			_partner,
																		)
																	}
																	image={
																		_partner
																			.file
																			.code
																	}
																	status={
																		_partner.status
																	}
																	order={
																		_partner.order
																	}
																	isChecked={isSelected(
																		_partner.code,
																	)}
																	onItemSelect={(
																		e,
																	) =>
																		handleItemSelectChange(
																			e,
																			_partner.code,
																		)
																	}
																/>
															</Grid>
														) : null,
												)
											) : (
												<Typography
													variant="body1"
													color="textPrimary"
													align="center"
												>
													There are no results
												</Typography>
											)}
										</Grid>
									),
								)}
						</Grid>
					</>
				) : (
					<p>Loading....</p>
				)}
			</SectionContainerLayout>
		</MainLayout>
	);
};
export default PartnersPage;
