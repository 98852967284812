import React, { useEffect, useState } from "react";

import {
	Grid,
	TextField,
	Button,
	Typography,
	FormControlLabel,
	Checkbox,
} from "@material-ui/core";
import { DropzoneArea } from "material-ui-dropzone";
import { useHistory, useLocation } from "react-router";
import axios from "axios";
import MainLayout from "../../components/main-layout";
import SectionContainerLayout from "../../components/section-container-layout";
import CustomTextEditor from "../../components/custom-text-editor";
import { editInitiative } from "../../validations/initiative-validator";
import ModuleToolbar from "../../components/module-toolbar";
import CardImage from "../../components/card-image";

const EditInitiativePage = () => {
	const [currentInitiative, setCurrentInitiative] = useState();
	const [isDataLoaded, setIsDataLoaded] = useState(false);
	const [jsonFormData, setJsonFormData] = useState();
	const [imageFile, setImageFile] = useState();
	const [selected, setSelected] = useState([]);
	const [validationErrors, setValidationErrors] = useState({});
	const [initiativePartners, setInitiativePartners] = useState([]);

	const history = useHistory();
	const location = useLocation();

	useEffect(() => {
		const { initiative } = location.state;
		setCurrentInitiative(initiative);
		setIsDataLoaded(true);

		setJsonFormData({
			...jsonFormData,
			code: initiative.code,
			description: initiative.description,
			title: initiative.title,
			subtitle: initiative.subtitle,
			link: initiative.link,
			file: initiative.file,
			isOnHomepage: initiative.isOnHomepage,
		});
		fetchList();

		const _imgFile = initiative.file;
		if (_imgFile.length > 0) {
			const _imgArray = [];
			_imgFile.forEach((p) => {
				_imgArray.push(
					`${process.env.REACT_APP_API_URL}/static/${p.code}`,
				);
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const isSelected = (data) => selected.indexOf(data) !== -1;
	const handleSelectAllChange = (e) => {
		if (e.target.checked) {
			const newSelected = currentInitiative.initiativePartners.map(
				(n) => n.code,
			);
			setSelected(newSelected);
			return;
		}
		setSelected([]);
	};
	const handleItemSelectChange = (e, data) => {
		const selectedIndex = selected.indexOf(data);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, data);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1),
			);
		}

		setSelected(newSelected);
	};

	const fetchList = () => {
		axios
			.get(
				`/initiative-partner/initiative/${location.state.initiative.code}`,
			)
			.then((_response) => {
				if (_response.status === 200) {
					setInitiativePartners(_response.data);
					setIsDataLoaded(true);
				} else if (_response.status === 404) {
					history.push("/login");
				}
			})
			.catch((err) => console.log(err));
	};

	const handleDeleteSelected = (e) => {
		axios
			.delete("/initiative-partner", { data: { codes: selected } })
			.then((_response) => {
				setSelected([]);
				fetchList();
			})
			.catch((err) => console.log(err));
	};

	const handleFormChange = (e) => {
		setJsonFormData({
			...jsonFormData,
			[e.target.id || e.target.name]: e.target.value || e.target.checked,
		});
	};

	const handleEditorChange = (event, editor) => {
		const data = editor.getData();
		setJsonFormData({
			...jsonFormData,
			description: data,
		});
	};

	const handleImageFile = (file) => {
		setImageFile(file[0]);
		setValidationErrors({
			...validationErrors,
			imageFile: null,
		});
	};

	const handleSubmit = () => {
		try {
			let errorMessages = {};
			editInitiative
				.validate(jsonFormData, { abortEarly: false })
				.then(() => {
					if (!imageFile) {
						setValidationErrors({
							...validationErrors,
							imageFile: "Image file is required",
						});
						return;
					}
					let formData = new FormData();

					formData.append("imageFile", imageFile);

					for (let i in jsonFormData) {
						if (jsonFormData[i] !== null) {
							if (i === "file") {
								formData.append(
									"fileCode",
									jsonFormData.file.code,
								);
							} else {
								formData.append(i, jsonFormData[i]);
							}
						}
					}

					axios
						.put("/initiatives", formData, {
							headers: {
								"Content-Type": "multipart/form-data",
							},
						})
						.then((_response) => {
							if (_response.status === 200) {
								history.push("/initiatives");
							}
						})
						.catch((err) => console.log(err));
				})
				.catch((err) => {
					console.log(err);
					err.inner.forEach((e) => {
						errorMessages[e.path] = e.message;
					});
					setValidationErrors({
						...validationErrors,
						...errorMessages,
					});
				});
		} catch (err) {
			console.log(err);
		}
	};

	const handleClick = (initiativePartner) => {
		history.push({
			pathname: `/initiatives/edit/${currentInitiative.code}/initiative-partner/edit`,
			state: {
				initiativePartner,
				initiative: currentInitiative,
			},
		});
	};

	return (
		<MainLayout>
			{isDataLoaded ? (
				<SectionContainerLayout
					title={`EDIT INITIATIVES: ${currentInitiative.code}`}
				>
					<Grid container spacing={3}>
						<Grid item container xs={12} md={6} spacing={3}>
							<Grid item xs={12}>
								<TextField
									label="Title"
									variant="outlined"
									fullWidth
									name="title"
									required
									placeholder={
										currentInitiative.title ? "" : "Title"
									}
									defaultValue={currentInitiative.title}
									onChange={handleFormChange}
									error={validationErrors.title}
									helperText={validationErrors.title}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									label="Subtitle"
									variant="outlined"
									fullWidth
									name="subtitle"
									placeholder={
										currentInitiative.subtitle
											? ""
											: "Subtitle"
									}
									defaultValue={
										currentInitiative.subtitle
											? currentInitiative.subtitle
											: ""
									}
									onChange={(e) =>
										setJsonFormData((data) => ({
											...data,
											subtitle: e.target.value,
										}))
									}
									// error={validationErrors.subtitle}
									// helperText={validationErrors.subtitle}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									label="Link"
									variant="outlined"
									fullWidth
									name="link"
									placeholder={
										currentInitiative.link ? "" : "Link"
									}
									defaultValue={currentInitiative.link}
									onChange={(e) =>
										setJsonFormData((data) => ({
											...data,
											link: e.target.value,
										}))
									}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									InputProps={{ inputProps: { min: 1 } }}
									type="number"
									label="order"
									variant="outlined"
									fullWidth
									name="order"
									defaultValue={currentInitiative.order}
									onChange={handleFormChange}
								/>
							</Grid>
						</Grid>
						<Grid item container xs={12} md={6} spacing={3}>
							<DropzoneArea
								dropzoneText="Drag and drop thumbnail image here *"
								filesLimit={1}
								key={1}
								initialFiles={[
									`${process.env.REACT_APP_API_URL}/static/${jsonFormData.file.code}`,
								]}
								acceptedFiles={["image/*"]}
								onChange={(file) => handleImageFile(file)}
							/>
						</Grid>
						<Grid item xs={12}>
							<Typography variant="body1" color="textPrimary">
								Description *
							</Typography>

							<CustomTextEditor
								onChange={handleEditorChange}
								data={currentInitiative.description}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<FormControlLabel
								control={
									<Checkbox
										id="isOnHomepage"
										onChange={handleFormChange}
										defaultChecked={
											currentInitiative.isOnHomepage
										}
									/>
								}
								label="Add to homepage"
							/>
						</Grid>
						<Grid item xs={12}>
							<SectionContainerLayout
								marginTop={2}
								marginX={0}
								title="INITIATIVE PARTNERS"
							>
								<ModuleToolbar
									addHistoryButton
									link={{
										pathName: `/initiatives/edit/${currentInitiative.code}/initiative-partner/add`,
										state: {
											initiative: currentInitiative,
										},
										text: "ADD INITIATIVE PARTNER",
									}}
									addIcon={false}
									gridViewIcon={false}
									listViewIcon={false}
									filterIcon={false}
									changeStatusAction={false}
									onSelectAllClick={handleSelectAllChange}
									totalSelected={selected.length}
									totalData={initiativePartners.length}
									onDeleteSelected={handleDeleteSelected}
									addSearchBar={false}
								/>
								<Grid container spacing={3}>
									{initiativePartners &&
										initiativePartners.length > 0 &&
										initiativePartners.map(
											(_initiativePartner) => (
												<Grid item xs={12} md={3}>
													<CardImage
														image={
															_initiativePartner
																.file?.code
														}
														order={
															_initiativePartner.order
														}
														isChecked={isSelected(
															_initiativePartner.code,
														)}
														onItemSelect={(e) =>
															handleItemSelectChange(
																e,
																_initiativePartner.code,
															)
														}
														onClick={() =>
															handleClick(
																_initiativePartner,
															)
														}
													/>
												</Grid>
											),
										)}
								</Grid>
							</SectionContainerLayout>
						</Grid>
						<Grid item container xs={12}>
							<Grid item xs={12} md={12} align="right">
								<Button
									onClick={() => history.push("/initiatives")}
									style={{ textTransform: "capitalize" }}
								>
									Cancel
								</Button>
								<Button
									onClick={handleSubmit}
									variant="contained"
									color="primary"
									style={{ textTransform: "capitalize" }}
								>
									Submit
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</SectionContainerLayout>
			) : null}
		</MainLayout>
	);
};

export default EditInitiativePage;
