import React from "react";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";

const CustomTextEditor = ({ ...editorProps }) => {
	const editorConfiguration = {
		context: "test",
		toolbar: [
			"Bold",
			"Italic",
			"Underline",
			"Link",
			"BulletedList",
			"NumberedList",
			"Alignment",
		],
	};
	return (
		<CKEditor
			editor={Editor}
			config={editorConfiguration}
			{...editorProps}
		/>
	);
};

export default CustomTextEditor;
