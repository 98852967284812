import * as yup from "yup";

export const addNewPartner = yup.object().shape({
	name: yup.string().required("Partner Category is required"),
	title: yup.string().required("Title is required"),
	year: yup
		.number()
		.typeError("Must be a number")
		.required("Year is required"),
});
