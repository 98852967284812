import React, { useState } from "react";
import MainLayout from "../../components/main-layout";
import SectionContainerLayout from "../../components/section-container-layout";

import {
	Grid,
	TextField,
	Button,
	Typography,
	Checkbox,
	FormControlLabel,
	FormHelperText,
} from "@material-ui/core";
import axios from "axios";
import { DropzoneArea } from "material-ui-dropzone";
import CustomTextEditor from "../../components/custom-text-editor";
import { useHistory } from "react-router";
import { addNewInitiative } from "../../validations/initiative-validator";

const AddInitiativePage = () => {
	const [jsonFormData, setJsonFormData] = useState({ isOnHomepage: false });
	const [imageFile, setImageFile] = useState();
	const [validationErrors, setValidationErrors] = useState({});
	const history = useHistory();

	const handleImageFile = (file) => {
		setImageFile(file[0]);
	};

	const handleFormChange = (e) => {
		setJsonFormData({
			...jsonFormData,
			// ...categoryValue,
			[e.target.id || e.target.name]: e.target.value || e.target.checked,
		});
	};

	const handleEditorChange = (event, editor) => {
		const data = editor.getData();
		setJsonFormData({
			...jsonFormData,
			description: data,
		});

		setValidationErrors({
			...validationErrors,
			description: null,
		});
	};

	const handleSubmit = () => {
		let errorMessages = {};
		try {
			if (
				jsonFormData.isOnHomepage === undefined ||
				jsonFormData.isOnHomepage === null
			) {
				setJsonFormData({
					...jsonFormData,
					isOnHomepage: false,
				});
			}

			setJsonFormData({ ...jsonFormData });
			addNewInitiative
				.validate(jsonFormData, { abortEarly: false })
				.then(() => {
					if (!imageFile) {
						return;
					}

					let formData = new FormData();
					formData.append("imageFile", imageFile);

					for (let i in jsonFormData) {
						formData.append(i, jsonFormData[i]);
					}

					axios
						.post("/initiatives", formData, {
							headers: { "Content-Type": "multipart/form-data" },
						})
						.then((_response) => {
							if (_response.status === 200) {
								history.push("/initiatives");
							}
						})
						.catch((err) => console.log(err));
				})
				.catch((err) => {
					err.inner.forEach((e) => {
						errorMessages[e.path] = e.message;
					});

					console.log("errorMessave", errorMessages);
					setValidationErrors(errorMessages);
				});
		} catch (err) {
			console.log(err);
		}
	};

	return (
		<MainLayout>
			<SectionContainerLayout title="ADD INITIATIVES">
				<Grid container spacing={3}>
					<Grid item container xs={12} md={6} spacing={3}>
						<Grid item xs={12}>
							<TextField
								label="Title"
								required
								variant="outlined"
								fullWidth
								error={validationErrors.title}
								helperText={validationErrors.title}
								name="title"
								onChange={handleFormChange}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								label="Subtitle"
								variant="outlined"
								fullWidth
								// error={validationErrors.subtitle}
								// helperText={validationErrors.subtitle}
								name="subtitle"
								onChange={(e) =>
									setJsonFormData((data) => ({
										...data,
										subtitle: e.target.value,
									}))
								}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								label="Link"
								variant="outlined"
								fullWidth
								name="link"
								onChange={(e) =>
									setJsonFormData((data) => ({
										...data,
										link: e.target.value,
									}))
								}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								InputProps={{ inputProps: { min: 1 } }}
								type="number"
								label="order"
								variant="outlined"
								fullWidth
								name="order"
								onChange={handleFormChange}
							/>
						</Grid>
					</Grid>
					<Grid item container xs={12} md={6}>
						<DropzoneArea
							dropzoneText="Drag and drop thumbnail image here *"
							filesLimit={1}
							key={2}
							acceptedFiles={["image/*"]}
							onChange={(file) => handleImageFile(file)}
						/>
						<FormHelperText>
							Width: 500px, Height: 300px
						</FormHelperText>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="body1" color="textPrimary">
							Description *
						</Typography>

						<CustomTextEditor onChange={handleEditorChange} />
						{validationErrors.descr ? (
							<Typography variant="body2" color="error">
								{validationErrors.description}
							</Typography>
						) : null}
					</Grid>
					<Grid item container xs={12}>
						<Grid item xs={12} md={6}>
							<FormControlLabel
								control={
									<Checkbox
										id="isOnHomepage"
										onChange={handleFormChange}
									/>
								}
								label="Add to homepage"
							/>
						</Grid>
						<Grid item xs={12} md={6} align="right">
							<Button
								onClick={() => history.push("/initiatives")}
								style={{ textTransform: "capitalize" }}
							>
								Cancel
							</Button>
							<Button
								variant="contained"
								color="primary"
								onClick={handleSubmit}
								style={{ textTransform: "capitalize" }}
							>
								Submit
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</SectionContainerLayout>
		</MainLayout>
	);
};

export default AddInitiativePage;
