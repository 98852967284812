import {
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Button,
	FormHelperText,
	Box,
	LinearProgress,
} from "@material-ui/core";
import { DropzoneArea } from "material-ui-dropzone";
import React, { useEffect, useState } from "react";
import MainLayout from "../../components/main-layout";
import SectionContainerLayout from "../../components/section-container-layout";
import axios from "axios";
import { useHistory, useLocation } from "react-router";
const EditCarouselPage = () => {
	const [limits, setLimits] = useState({
		2021: {
			count: 0,
		},
		2020: {
			count: 0,
		},
		2019: {
			count: 0,
		},
		2017: {
			count: 0,
		},
	});
	const [jsonFormData, setJsonFormData] = useState();
	const [carouselLimitErrorMessage, setCarouselLimitErrorMessage] =
		useState();
	const [imageFile, setImageFile] = useState();
	const [videoFile, setVideoFile] = useState();
	const [currentCarousel, setCurrentCarousel] = useState();
	const [isDataLoaded, setIsDataLoaded] = useState(false);
	const [showProgress, setShowProgress] = useState(false);
	const [progress, setProgress] = useState(0);
	const history = useHistory();
	const location = useLocation();

	const [existingFileCodes, setExistingFileCodes] = useState([]);
	const [initVideoFiles, setInitVideoFiles] = useState([]);
	const [initVideoCode, setInitVideoCode] = useState(null);
	const [initImageFiles, setInitImageFiles] = useState([]);
	const [initImageCode, setInitImageCode] = useState(null);

	const handleFormChange = (e) => {
		if (e.target.name === "year") {
			if (limits[e.target.value].count >= 6) {
				setCarouselLimitErrorMessage(
					`You can only have up to 6 carousels in the year ${e.target.value}, please delete one to add a new one`,
				);
			} else {
				setCarouselLimitErrorMessage(null);
				setJsonFormData({
					...jsonFormData,
					[e.target.id || e.target.name]: e.target.value,
				});
			}
		} else {
			setJsonFormData({
				...jsonFormData,
				[e.target.id || e.target.name]: e.target.value,
			});
		}
	};

	const handleImageFile = (file) => {
		setImageFile(file[0]);
	};

	const handleVideoFile = (file) => {
		setVideoFile(file[0]);
	};

	useEffect(() => {
		setCurrentCarousel(location.state.carousel);
		setIsDataLoaded(true);

		try {
			const _existingFileCodes = [];
			if (
				location.state.carousel.file &&
				location.state.carousel.file.length > 0
			) {
				location.state.carousel.file.map((t) =>
					_existingFileCodes.push(t.code),
				);
			}
			setExistingFileCodes(_existingFileCodes);

			if (location.state.carousel.file) {
				const _imgFile = location.state.carousel.file.filter(
					(f) => f.type.includes("image") && f.code !== null,
				);
				if (_imgFile.length > 0) {
					setInitImageCode(_imgFile[0].code);
					setInitImageFiles([
						`${process.env.REACT_APP_API_URL}/static/${_imgFile[0].code}`,
					]);
				}

				const _videoFile = location.state.carousel.file.filter(
					(f) => f.type.includes("video") && f.code !== null,
				);
				if (_videoFile.length > 0) {
					setInitVideoCode(_videoFile[0].code);
					setInitVideoFiles([
						`${process.env.REACT_APP_API_URL}/static/${_videoFile[0].code}`,
					]);
				}
			}

			setJsonFormData({
				...jsonFormData,
				year: location.state.carousel.year,
				title: location.state.carousel.title,
				code: location.state.carousel.code,
			});

			let carouselLimits = {
				2021: {
					count: 0,
				},
				2020: {
					count: 0,
				},
				2019: {
					count: 0,
				},
				2017: {
					count: 0,
				},
			};

			axios.get("/carousel").then((_response) => {
				if (_response.status === 200) {
					_response.data.forEach((_data) => {
						switch (_data.year) {
							case "2021": {
								carouselLimits["2021"].count++;
								break;
							}
							case "2020": {
								carouselLimits["2020"].count++;
								break;
							}
							case "2019": {
								carouselLimits["2019"].count++;
								break;
							}
							case "2017": {
								carouselLimits["2017"].count++;
								break;
							}
							default: {
							}
						}
						setLimits(carouselLimits);
					});
				} else {
					setLimits(carouselLimits);
				}
			});
		} catch (err) {
			console.log(err);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleSubmit = () => {
		try {
			if (!imageFile || !videoFile) return;

			let formData = new FormData();
			if (!existingFileCodes.includes(imageFile.name)) {
				formData.append("imageFile", imageFile);
				formData.append("imageFileCode", initImageCode);
			}

			if (!existingFileCodes.includes(videoFile.name)) {
				formData.append("videoFile", videoFile);
				formData.append("videoFileCode", initVideoCode);
			}

			for (let i in jsonFormData) {
				formData.append(i, jsonFormData[i]);
			}

			// for (var pair of formData.entries()) {
			//   console.log(pair[0] + ", " + pair[1]);
			// }

			axios
				.put("/carousel", formData, {
					headers: {
						"Content-Type": "multipart/form-data",
					},
					onUploadProgress: (data) => {
						setShowProgress(true);
						setProgress(
							Math.round((100 * data.loaded) / data.total),
						);
					},
				})
				.then((_response) => {
					if (_response.status === 200) {
						history.push("/carousels");
					}
				})
				.catch((err) => console.log(err));
		} catch (err) {
			console.log(err);
		}
	};

	return (
		<MainLayout>
			{isDataLoaded ? (
				<SectionContainerLayout
					title={`EDIT CAROUSEL: ${currentCarousel.code}`}
				>
					<Grid container spacing={3}>
						<Grid item container xs={12} md={6} spacing={3}>
							<Grid item xs={12} md={4}>
								<FormControl fullWidth variant="outlined">
									<InputLabel id="year">Year *</InputLabel>
									<Select
										name="year"
										labelId="year"
										value={currentCarousel.year}
										label="Year"
										onChange={handleFormChange}
									>
										<MenuItem value={"2021"}>2021</MenuItem>
										<MenuItem value={"2020"}>2020</MenuItem>
										<MenuItem value={"2019"}>2019</MenuItem>
										<MenuItem value={"2017"}>2017</MenuItem>
									</Select>

									{carouselLimitErrorMessage ? (
										<FormHelperText error>
											{carouselLimitErrorMessage}
										</FormHelperText>
									) : null}
								</FormControl>
							</Grid>
							<Grid item xs={12} md={8}>
								<TextField
									id="title"
									required
									onChange={handleFormChange}
									variant="outlined"
									label="Title"
									placeholder={
										currentCarousel.title ? "" : "Title"
									}
									defaultValue={currentCarousel.title}
									fullWidth
								/>
							</Grid>
							<Grid item xs={12}>
								<DropzoneArea
									dropzoneText="Drag and drop carousel video here *"
									acceptedFiles={["video/mp4"]}
									filesLimit={1}
									maxFileSize={3221225472}
									onChange={(file) => handleVideoFile(file)}
									initialFiles={initVideoFiles}
									key="videoFilesUpload"
								/>
								<FormHelperText>
									Video max size: 2GB
								</FormHelperText>
							</Grid>
						</Grid>
						<Grid item container xs={12} md={6}>
							<Grid item xs={12}>
								<DropzoneArea
									dropzoneText="Drag and drop thumbnail here *"
									acceptedFiles={["image/*"]}
									filesLimit={1}
									initialFiles={initImageFiles}
									onChange={(file) => handleImageFile(file)}
									key="imageFilesUpload"
								/>

								<FormHelperText>
									Width: 540px, Height: 910px
								</FormHelperText>
							</Grid>
						</Grid>
						<Grid item xs={12} align="right">
							<Button
								onClick={() => history.push("/carousels")}
								style={{ textTransform: "capitalize" }}
							>
								Cancel
							</Button>
							<Button
								color="primary"
								variant="contained"
								onClick={handleSubmit}
								style={{ textTransform: "capitalize" }}
							>
								Submit
							</Button>
						</Grid>
						<Box clone pt={5}>
							<Grid item xs={12}>
								{showProgress ? (
									<LinearProgress
										variant="determinate"
										value={progress}
									/>
								) : null}
							</Grid>
						</Box>
					</Grid>
				</SectionContainerLayout>
			) : null}
		</MainLayout>
	);
};

export default EditCarouselPage;
