import { useState, useCallback } from "react";
import { makeStyles } from "@material-ui/core";
import GoogleMapReact from "google-map-react";
import MapPin from "../marker";

export const LocationSelectMap = ({ onChange, absoluteMarker, value }) => {
	const classes = useStyles();
	const [markerValue, setMarkerValue] = useState(value);

	const handleDrag = useCallback(({ center }) => {
		const value = [center.lat(), center.lng()];
		setMarkerValue(value);
	}, []);

	const handleChange = useCallback(
		({ center }) => {
			const value = [center.lat, center.lng];
			setMarkerValue(value);
			onChange?.(value);
		},
		[onChange],
	);

	return (
		<>
			<GoogleMapReact
				options={OPTIONS}
				bootstrapURLKeys={BOOTSTRAP_URL_KEYS}
				center={value}
				onChange={handleChange}
				onDrag={handleDrag}
				defaultZoom={0}
				yesIWantToUseGoogleMapApiInternals
			>
				{!absoluteMarker && (
					<MapPin lat={markerValue[0]} lng={markerValue[1]} />
				)}
			</GoogleMapReact>
			{absoluteMarker && <MapPin className={classes.absoluteMarker} />}
		</>
	);
};

const BOOTSTRAP_URL_KEYS = {
	key: process.env.REACT_APP_API_GOOGLE_API_KEY,
	libraries: ["places"],
	region: "ae",
};

const OPTIONS = {
	mapTypeControl: true,
	scrollwheel: false,
	fullscreenControl: false,
};

const useStyles = makeStyles(() => ({
	absoluteMarker: {
		top: "50%",
		left: "50%",
		fontSize: "370%",
	},
}));
