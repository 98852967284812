import {
	Grid,
	Button,
	Dialog,
	DialogActions,
	DialogTitle,
	DialogContentText,
	DialogContent,
} from "@material-ui/core";
import React, { useState } from "react";
import MainLayout from "../../components/main-layout";
import SectionContainerLayout from "../../components/section-container-layout";
import axios from "axios";
import { useHistory } from "react-router-dom";
const SettingsPage = () => {
	const [isDialogOpen, setIsDialogOpen] = useState(false);
	const history = useHistory();

	const handleButtonClick = () => {
		try {
			axios
				.get("/utility/rebuild")
				.then((_) => {
					setIsDialogOpen(true);
				})
				.catch((err) => console.log(err));
		} catch (err) {
			console.log(err);
		}
	};

	const handleClose = () => {
		setIsDialogOpen(false);
		history.push("/dashboard");
	};

	return (
		<MainLayout>
			<SectionContainerLayout title="SETTINGS">
				<Dialog open={isDialogOpen} onClose={handleClose}>
					<DialogTitle>Website is being rebuilt!</DialogTitle>
					<DialogContent>
						<DialogContentText>
							The website is being rebuilt in the background. This
							could take up to 30 minutes to complete.
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button
							onClick={handleClose}
							color="primary"
							variant="contained"
						>
							Ok
						</Button>
					</DialogActions>
				</Dialog>
				<Grid container direction="column" spacing={2}>
					<Grid item xs={12} md={4}>
						<Button
							onClick={handleButtonClick}
							variant="contained"
							color="primary"
						>
							Rebuild Website
						</Button>
					</Grid>
					<Grid item xs={12} md={4}>
						<Button
							onClick={() =>
								history.push("/settings/manage-categories")
							}
							variant="contained"
							color="primary"
						>
							Manage Categories
						</Button>
					</Grid>
					<Grid item xs={12} md={4}>
						<Button
							onClick={() =>
								history.push("/settings/manage-streaming-url")
							}
							variant="contained"
							color="primary"
						>
							Streaming URL
						</Button>
					</Grid>
				</Grid>
			</SectionContainerLayout>
		</MainLayout>
	);
};

export default SettingsPage;
