import React from "react";
import { Button, makeStyles, Box } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	buttonStyles: {
		backgroundColor: theme.palette.success.main,
		color: "white",
		textTransform: "capitalize",
		"&:hover": {
			backgroundColor: theme.palette.success.main,
			color: "white",
		},
	},
}));

const CustomButton = ({ label, ...buttonProps }) => {
	const classes = useStyles();

	return (
		<Button {...buttonProps} className={classes.buttonStyles}>
			<Box fontWeight="fontWeightBold">{label}</Box>
		</Button>
	);
};

export default CustomButton;
