import {
	Grid,
	TextField,
	Button,
	Box,
	FormHelperText,
	Typography,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { DropzoneArea } from "material-ui-dropzone";
import MainLayout from "../../components/main-layout";
import SectionContainerLayout from "../../components/section-container-layout";
import axios from "axios";
import { useHistory } from "react-router-dom";
import "date-fns";
import { createFilterOptions } from "@material-ui/lab/Autocomplete";
import { addNewNews } from "../../validations/news-validator";
import CustomTextEditor from "../../components/custom-text-editor";
import { Autocomplete } from "@material-ui/lab";
import { generatePageLink } from "../../helpers/common";
import moment from "moment";

const AddNewsPage = () => {
	const [jsonFormData, setJsonFormData] = useState({
		date: moment().format("YYYY-MM-DD"),
	});
	const [imageFile, setImageFile] = useState({});
	const [validationErrors, setValidationErrors] = useState({});
	const [datePicked] = useState(moment().format("YYYY-MM-DD"));
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [selectedCategory, setSelectedCategory] = useState({});
	const [categories, setCategories] = useState([]);

	const filter = createFilterOptions();

	useEffect(() => {
		axios
			.get("/utility/categories/news")
			.then((_response) => {
				if (_response.status === 200) {
					setCategories(_response.data);
				}
			})
			.catch((err) => console.log(err));
	}, [setCategories]);

	const history = useHistory();

	const handleEditorChange = (event, editor) => {
		const data = editor.getData();
		setJsonFormData({
			...jsonFormData,
			article: data,
		});

		setValidationErrors({
			...validationErrors,
			article: null,
		});
	};

	const handleFormChange = (e) => {
		setJsonFormData({
			...jsonFormData,
			[e.target.id || e.target.name]: e.target.value || e.target.checked,
		});

		switch (e.target.id || e.target.name) {
			case "title": {
				setValidationErrors({
					...validationErrors,
					title: null,
				});
				break;
			}
			default: {
			}
		}
	};

	const handleFile = (file) => {
		setImageFile(file[0]);
	};

	const handleSubmit = async () => {
		let errorMessages = {};

		try {
			addNewNews
				.validate(jsonFormData, { abortEarly: false })
				.then(() => {
					if (!imageFile) {
						return;
					}
					let formData = new FormData();
					formData.append("imageFile", imageFile);
					for (let i in jsonFormData) {
						formData.append(i, jsonFormData[i]);
					}
					let urlSlug = generatePageLink(
						jsonFormData.title,
						"news/xxxx/",
					);
					formData.append("urlSlug", urlSlug);

					// let year = jsonFormData.date.toISOString().substring(0, 4);
					let year = moment(jsonFormData.date).format("YYYY");
					formData.append("year", year);
					formData.append(
						"category",
						Object.keys(selectedCategory).length > 0
							? JSON.stringify(selectedCategory)
							: null,
					);
					axios
						.post("/news", formData, {
							headers: { "Content-Type": "multipart/form-data" },
						})
						.then((_response) => {
							history.push("/news");
						})
						.catch((err) => console.log(err));
				})
				.catch((err) => {
					err.inner.forEach((e) => {
						errorMessages[e.path] = e.message;
					});
					setValidationErrors(errorMessages);
					setIsSubmitted(true);
				});
		} catch (err) {
			console.log(err);
		}
	};

	return (
		<MainLayout>
			<SectionContainerLayout title="ADD NEWS">
				<Grid container>
					<Grid item container xs={12} md={6}>
						<Grid item container xs={12} spacing={3}>
							<Grid item xs={12} md={6}>
								{/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    format="dd/MM/yyyy"
                    variant="inline"
                    // margin="normal"
                    id="date"
                    value={datePicked}
                    label="Date"
                    name="date"
                    autoOk
                    required
                    error={validationErrors.date}
                    helperText={validationErrors.date}
                    onChange={handleDatePicker("date")}
                  />
                </MuiPickersUtilsProvider> */}
								<TextField
									id="date"
									label="Date"
									type="date"
									defaultValue={datePicked}
									onChange={handleFormChange}
									error={validationErrors.date}
									helperText={validationErrors.date}
									placeholder={datePicked}
									variant="outlined"
									fullWidth
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<TextField
									variant="outlined"
									fullWidth
									label="Title"
									required
									placeholder="Title"
									id="title"
									onChange={handleFormChange}
									error={validationErrors.title}
									helperText={validationErrors.title}
								/>
							</Grid>
						</Grid>
						<Grid item container xs={12} spacing={3}>
							{/* <Grid item xs={12} md={6}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel>Tags</InputLabel>
                  <Select name="tags" onChange={handleFormChange}>
                    <MenuItem value={"Tag"}>Tag 1</MenuItem>
                    <MenuItem value={"Tag"}>Tag 2</MenuItem>
                    <MenuItem value={"Tag"}>Tag 3</MenuItem>
                    <MenuItem value={"Tag"}>Tag 4</MenuItem>
                  </Select>
                </FormControl>
              </Grid> */}
							<Grid item xs={12} md={6}>
								{/* <Autocomplete
                  value={categoryValue}
                  onChange={handleCategoryChange}
                  filterOptions={filterOptions}
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  options={categories}
                  getOptionLabel={getOptionLabel}
                  renderOption={(option) => option.name}
                  freeSolo
                  renderInput={renderInput}
                /> */}
								<Autocomplete
									value={selectedCategory}
									onChange={(event, newValue) => {
										if (typeof newValue === "string")
											setSelectedCategory({
												name: newValue,
											});
										else if (
											newValue &&
											newValue.inputValue
										)
											setSelectedCategory({
												name: newValue.inputValue,
											});
										else setSelectedCategory(newValue);
									}}
									filterOptions={(options, params) => {
										const filtered = filter(
											options,
											params,
										);
										if (params.inputValue !== "") {
											filtered.push({
												inputValue: params.inputValue,
												name: `Add "${params.inputValue}"`,
											});
										}
										return filtered;
									}}
									selectOnFocus
									clearOnBlur
									handleHomeEndKeys
									options={categories}
									getOptionLabel={(option) => {
										if (Object.keys(option).length > 0)
											return option.name;

										return "";
									}}
									renderOption={(option) =>
										Object.keys(option).length > 0
											? option.name
											: ""
									}
									freeSolo
									renderInput={(params) => (
										<TextField
											{...params}
											label="Category"
											variant="outlined"
										/>
									)}
								/>
							</Grid>
						</Grid>
					</Grid>
					<Grid item container xs={12} md={6} spacing={3}>
						<Grid item xs={12}>
							<DropzoneArea
								dropzoneText="drag and drop or click here *"
								filesLimit={1}
								onChange={(file) => handleFile(file)}
								acceptedFiles={["image/*"]}
							/>
							<FormHelperText>
								Width: 500px, Height: 300px
							</FormHelperText>
							{!imageFile && isSubmitted ? (
								<FormHelperText color="error">
									<Typography variant="body2" color="error">
										Image file is required
									</Typography>
								</FormHelperText>
							) : null}
						</Grid>
					</Grid>
					<Box clone pt={2}>
						<Grid item container xs={12}>
							<Grid item xs={12}>
								<Typography variant="body1" color="textPrimary">
									Article *
								</Typography>
								<CustomTextEditor
									onChange={handleEditorChange}
								/>
								{validationErrors.article ? (
									<Typography variant="body2" color="error">
										{validationErrors.article}
									</Typography>
								) : null}
							</Grid>
						</Grid>
					</Box>
					<Box clone pt={2}>
						<Grid item container xs={12} align="right">
							<Grid item xs={12}>
								<Button
									onClick={() => history.push("/news")}
									style={{ textTransform: "capitalize" }}
								>
									Cancel
								</Button>
								<Button
									variant="contained"
									onClick={handleSubmit}
									color="primary"
									style={{ textTransform: "capitalize" }}
								>
									Submit
								</Button>
							</Grid>
						</Grid>
					</Box>
				</Grid>
			</SectionContainerLayout>
		</MainLayout>
	);
};

export default AddNewsPage;
