import {
	Select,
	Grid,
	TextField,
	InputLabel,
	Button,
	Box,
	Checkbox,
	Typography,
	FormControl,
	FormControlLabel,
	FormHelperText,
	makeStyles,
	MenuItem,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { DropzoneArea } from "material-ui-dropzone";
import CustomTextEditor from "../../components/custom-text-editor";
import MainLayout from "../../components/main-layout";
import SectionContainerLayout from "../../components/section-container-layout";
import { addNewSpeaker } from "../../validations/speaker-validator";
import axios from "axios";
import { useHistory } from "react-router-dom";
// import classes from "*.module.css";

const useStyles = makeStyles((theme) => ({
	greenButton: {
		color: theme.palette.background.default,
		fontWeight: "bold",
	},
}));

const AddSpeakerPage = () => {
	const [jsonFormData, setJsonFormData] = useState({});
	const [imageFile, setImageFile] = useState({});
	const [validationErrors, setValidationErrors] = useState({});
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [salutations, setSalutations] = useState([]);

	const history = useHistory();
	const classesTheme = useStyles();

	const handleEditorChange = (event, editor) => {
		const data = editor.getData();
		setJsonFormData({
			...jsonFormData,
			bio: data,
		});
	};

	useEffect(() => {
		try {
			axios.get(`/utility/salutations`).then((_response) => {
				if (_response.status === 200) {
					setSalutations(_response.data);
				}
			});
		} catch (err) {
			console.log(err);
		}
	}, []);

	const handleFormChange = (e) => {
		setJsonFormData({
			...jsonFormData,
			isOnHomepage: e.target.checked || false,
			[e.target.id || e.target.name]: e.target.value || e.target.checked,
		});

		switch (e.target.id || e.target.name) {
			case "salutationId": {
				setValidationErrors({
					...validationErrors,
					salutationId: null,
				});
				break;
			}
			case "firstName": {
				setValidationErrors({
					...validationErrors,
					firstName: null,
				});
				break;
			}
			case "lastName": {
				setValidationErrors({
					...validationErrors,
					lastName: null,
				});
				break;
			}
			case "designation": {
				setValidationErrors({
					...validationErrors,
					designation: null,
				});
				break;
			}
			case "organization": {
				setValidationErrors({
					...validationErrors,
					organization: null,
				});
				break;
			}
			case "year": {
				setValidationErrors({
					...validationErrors,
					year: null,
				});
				break;
			}
			default: {
			}
		}
	};

	const handleFile = (file) => {
		setImageFile(file[0]);
	};

	const handleSubmit = async () => {
		let errorMessages = {};
		try {
			addNewSpeaker
				.validate(jsonFormData, { abortEarly: false })
				.then(() => {
					if (!imageFile) {
						return;
					}

					let formData = new FormData();
					formData.append("imageFile", imageFile);

					for (let i in jsonFormData) {
						formData.append(i, jsonFormData[i]);
					}

					axios
						.post("/speaker", formData, {
							headers: { "Content-Type": "multipart/form-data" },
						})
						.then((_response) => {
							if (_response.status === 200) {
								history.push("/speakers");
							} else {
								console.log("error");
							}
						})
						.catch((err) => console.log(err));
				})
				.catch((err) => {
					err.inner.forEach((e) => {
						errorMessages[e.path] = e.message;
					});
					setValidationErrors(errorMessages);
					setIsSubmitted(true);
				});
		} catch (err) {
			console.log(err);
		}
	};

	return (
		<MainLayout>
			<SectionContainerLayout title="ADD SPEAKER">
				<Grid container>
					<Grid item container xs={12} md={6}>
						<Grid item container xs={12} spacing={3}>
							<Grid item xs={12} md={6}>
								<FormControl
									error={validationErrors.salutationId}
									fullWidth
									variant="outlined"
								>
									<InputLabel id="salutation">
										Salutation *
									</InputLabel>
									<Select
										name="salutationId"
										label="Salutation"
										labelId="salutation"
										required
										onChange={handleFormChange}
										error={validationErrors.salutationId}
									>
										{salutations.map((_salutation, index) =>
											_salutation.code !== "NULLL" ? (
												<MenuItem
													key={index}
													value={_salutation.id}
												>
													{_salutation.name}
												</MenuItem>
											) : (
												<MenuItem key={index} value={7}>
													None
												</MenuItem>
											),
										)}
									</Select>
									<FormHelperText>
										{validationErrors.salutationId}
									</FormHelperText>
								</FormControl>
							</Grid>
							<Grid item xs={12} md={6}>
								<TextField
									variant="outlined"
									fullWidth
									label="First Name"
									placeholder="First Name"
									required
									id="firstName"
									onChange={handleFormChange}
									error={validationErrors.firstName}
									helperText={validationErrors.firstName}
								/>
							</Grid>
						</Grid>
						<Grid item container xs={12} spacing={3}>
							<Grid item xs={12} md={6}>
								<TextField
									variant="outlined"
									fullWidth
									label="Last name"
									placeholder="Last Name"
									id="lastName"
									required
									onChange={handleFormChange}
									error={validationErrors.lastName}
									helperText={validationErrors.lastName}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<TextField
									variant="outlined"
									fullWidth
									label="Designation"
									required
									placeholder="Designation"
									id="designation"
									onChange={handleFormChange}
									error={validationErrors.designation}
									helperText={validationErrors.designation}
								/>
							</Grid>
						</Grid>
						<Grid item container xs={12} spacing={3}>
							<Grid item xs={12} md={6}>
								<TextField
									variant="outlined"
									fullWidth
									label="Organization"
									required
									placeholder="Organization"
									id="organization"
									onChange={handleFormChange}
									error={validationErrors.organization}
									helperText={validationErrors.organization}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<FormControl
									error={validationErrors.year}
									fullWidth
									variant="outlined"
								>
									<InputLabel id="year">Year *</InputLabel>
									<Select
										name="year"
										label="Year"
										labelId="year"
										required
										onChange={handleFormChange}
										error={validationErrors.year}
									>
										<MenuItem value="2022">2022</MenuItem>
										<MenuItem value="2021">2021</MenuItem>
										<MenuItem value="2020">2020</MenuItem>
										<MenuItem value="2019">2019</MenuItem>
										<MenuItem value="2017">2017</MenuItem>
									</Select>
									<FormHelperText>
										{validationErrors.year}
									</FormHelperText>
								</FormControl>
							</Grid>
						</Grid>
					</Grid>
					<Grid item container xs={12} md={6} spacing={3}>
						<Grid item xs={12}>
							<DropzoneArea
								dropzoneText="Drag and drop image file here *"
								filesLimit={1}
								onChange={(file) => handleFile(file)}
								acceptedFiles={["image/*"]}
							/>
							<FormHelperText>
								Width: 500px, Height: 560px
							</FormHelperText>
							{!imageFile && isSubmitted ? (
								<FormHelperText color="error">
									<Typography variant="body2" color="error">
										Image file is required
									</Typography>
								</FormHelperText>
							) : null}
						</Grid>
					</Grid>
					<Box clone pt={4}>
						<Grid item xs={12}>
							<Typography variant="body1" color="textPrimary">
								Bio *
							</Typography>

							<CustomTextEditor onChange={handleEditorChange} />
						</Grid>
					</Box>
					<Box clone pt={2}>
						<Grid item container xs={12} spacing={3}>
							<Grid item xs={6}>
								<FormControlLabel
									control={
										<Checkbox
											id="isOnHomepage"
											onChange={handleFormChange}
										/>
									}
									label="Add to homepage"
								/>
							</Grid>
							<Grid item container xs={6} align="right">
								<Grid item xs={12}>
									<Button
										onClick={() =>
											history.push("/speakers")
										}
										style={{ textTransform: "capitalize" }}
									>
										Cancel
									</Button>
									<Button
										color="primary"
										variant="contained"
										onClick={handleSubmit}
										className={classesTheme.greenButton}
										style={{ textTransform: "capitalize" }}
									>
										Submit
									</Button>
								</Grid>
							</Grid>
						</Grid>
					</Box>
				</Grid>
			</SectionContainerLayout>
		</MainLayout>
	);
};

export default AddSpeakerPage;
