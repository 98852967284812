import React, { useEffect, useMemo, useState } from "react";
import SectionContainerLayout from "../../components/section-container-layout";
import MainLayout from "../../components/main-layout";
import ModuleToolbar from "../../components/module-toolbar";
import {
	Grid,
	Box,
	TableContainer,
	Paper,
	Table,
	TableHead,
	makeStyles,
	TableRow,
	TableCell,
	Typography,
	useTheme,
	TableBody,
} from "@material-ui/core";
import axios from "axios";
import CardImageTitleDescription from "../../components/card-image-title-description";
import moment from "moment";
import { useHistory } from "react-router-dom";
import colors from "../../constants/colors";
import { dataExists } from "../../helpers/common";
import { useDebounce } from "use-debounce";
import { useStoreState } from "easy-peasy";

const useStyles = makeStyles((theme) => ({
	tableHeader: {
		backgroundColor: colors.darkBlue,
	},
	rowHover: {
		"&:hover": {
			cursor: "pointer",
		},
	},
}));

const AlbumPage = () => {
	const [albumData, setAlbumData] = useState([]);
	const [isGridView, setIsGridView] = useState(true);
	const [isListView, setListView] = useState(false);
	const [selected, setSelected] = useState([]);
	const [filterData, setFilterData] = useState({});
	const [isDataLoaded, setIsDataLoaded] = useState(false);
	const history = useHistory();
	const classes = useStyles();
	const theme = useTheme();
	const currentUser = useStoreState((state) => state.user.currentUser);

	const toggleGridView = () => {
		setIsGridView(true);
		setListView(false);
	};

	const toggleListView = () => {
		setIsGridView(false);
		setListView(true);
	};

	useEffect(() => {
		try {
			const token = localStorage.getItem("token");

			if (!token) {
				history.push("/login");
			}
			fetchList();
		} catch (err) {
			console.log(err);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const fetchList = () => {
		axios
			.get("/album")
			.then((_response) => {
				if (_response.status === 200) {
					let albumTypeArray = [];
					_response.data.forEach((_data) => {
						if (!dataExists(_data.albumType, albumTypeArray)) {
							albumTypeArray.push({
								name: _data.albumType,
								value: _data.albumType,
							});
						}
					});

					let _albumTypeFilter = {
						options: albumTypeArray,
						label: "Album Type",
						idName: "albumType",
					};

					let allFilters = {
						_albumTypeFilter,
					};

					setFilterData(allFilters);
					setIsDataLoaded(true);
					setAlbumData(_response.data);
				} else if (_response.status === 404) {
					history.push("/login");
				}
			})
			.catch((err) => console.log(err));
	};
	const convertDate = (date) => {
		const newDate = moment(date).format("DD MMMM YYYY");

		return newDate;
	};

	const handleClick = (album) => {
		if (currentUser.user_account_type.type !== "Viewer") {
			history.push({
				pathname: "/albums/edit",
				state: {
					album,
				},
			});
		}
	};
	const isSelected = (data) => selected.indexOf(data) !== -1;
	const handleSelectAllChange = (e) => {
		if (e.target.checked) {
			const newSelected = albumData.map((n) => n.code);
			setSelected(newSelected);
			return;
		}
		setSelected([]);
	};
	const handleItemSelectChange = (e, data) => {
		const selectedIndex = selected.indexOf(data);
		let newSelected = [];
		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, data);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1),
			);
		}

		setSelected(newSelected);
	};
	const handleDeleteSelected = (e) => {
		axios
			.delete("/album", { data: { code: selected } })
			.then((_response) => {
				setSelected([]);
				fetchList();
			})
			.catch((err) => console.log(err));
	};

	const [filters, setFilters] = useState({});
	const [search, setSearch] = useState("");
	const [searchText] = useDebounce(search, 1000);
	useEffect(() => {
		axios
			.post("/album/search", {
				filters: { ...filters, search: searchText },
			})
			.then((_response) => {
				setAlbumData(_response.data);
			})
			.catch((err) => console.log(err));
	}, [searchText, filters]);

	const handleSearch = (e) => {
		try {
			setSearch(e.target.value);
			// const searchQuery = {
			//   title: e.target.value,
			//   description: e.target.value,
			//   details: e.target.value,
			// };

			// axios
			//   .post("/album/search", searchQuery)
			//   .then((_response) => {
			//     if (_response.status === 200) {
			//       setAlbumData(_response.data);
			//     }
			//   })
			//   .catch((err) => console.log(err));
		} catch (err) {
			console.log(err);
		}
	};

	const handleFilterData = (value, key) => {
		try {
			setFilters({ ...filters, [key]: value });

			// let filteredLogs = [];

			// axios.get("/album").then((_response) => {
			//   if (_response.status === 200) {
			//     _response.data.forEach((_data) => {
			//       if (_data[key] === value) {
			//         filteredLogs.push(...filteredLogs, _data);
			//       }
			//     });

			//     setAlbumData(filteredLogs);
			//     if (!value) {
			//       setAlbumData(_response.data);
			//     }
			//     setIsDataLoaded(true);
			//   }

			//   if (_response.status === 404) {
			//     history.push("/login");
			//   }
			// });
		} catch (err) {
			console.log(err);
		}
	};

	const sortedAlbumsByDate = useMemo(() => {
		return albumData.sort((newsA, newsB) => {
			const dateA = new Date(newsA.createdAt);
			const dateB = new Date(newsB.createdAt);
			return dateB.valueOf() - dateA.valueOf();
		});
	}, [albumData]);

	return (
		<MainLayout>
			<SectionContainerLayout title="ALBUMS">
				{isDataLoaded ? (
					<Grid container>
						<Grid item xs={12}>
							<ModuleToolbar
								totalSelected={selected.length}
								totalData={sortedAlbumsByDate.length}
								onSelectAllClick={handleSelectAllChange}
								onDeleteSelected={handleDeleteSelected}
								addModuleUrl="/albums/add"
								handleGridView={toggleGridView}
								handleSearchChange={handleSearch}
								handleListView={toggleListView}
								filterIcon
								showCustomFilter
								filterData={filterData}
								handleFilterData={handleFilterData}
							/>
						</Grid>
						<Grid item container xs={12} spacing={3}>
							{isGridView && sortedAlbumsByDate.length > 0 ? (
								sortedAlbumsByDate.map((_album, index) => (
									<Grid
										item
										xs={12}
										md={4}
										lg={3}
										key={index}
									>
										{_album.files.some(
											(_file) =>
												_file.type !== "video/mp4",
										) ? (
											<CardImageTitleDescription
												hasStatus={false}
												onClick={() =>
													handleClick(_album)
												}
												image={
													_album.files.find(
														(_file) =>
															_file.type !==
															"video/mp4",
													).code
												}
												title={convertDate(_album.date)}
												description={_album.title}
												status={_album.status}
												isChecked={isSelected(
													_album.code,
												)}
												onItemSelect={(e) =>
													handleItemSelectChange(
														e,
														_album.code,
													)
												}
											/>
										) : (
											<CardImageTitleDescription
												hasStatus={false}
												onClick={() =>
													handleClick(_album)
												}
												staticImage
												title={convertDate(_album.date)}
												description={_album.title}
												status={_album.status}
												isChecked={isSelected(
													_album.code,
												)}
												onItemSelect={(e) =>
													handleItemSelectChange(
														e,
														_album.code,
													)
												}
											/>
										)}
									</Grid>
								))
							) : isListView && sortedAlbumsByDate.length > 0 ? (
								<TableContainer component={Paper}>
									<Table>
										<TableHead
											className={classes.tableHeader}
										>
											<TableRow>
												<TableCell align="center">
													<Typography
														variant="body2"
														style={{
															color: theme.palette
																.common.white,
														}}
													>
														Image
													</Typography>
												</TableCell>
												<TableCell align="center">
													<Typography
														variant="body2"
														style={{
															color: theme.palette
																.common.white,
														}}
													>
														Type
													</Typography>
												</TableCell>
												<TableCell align="center">
													<Typography
														variant="body2"
														style={{
															color: theme.palette
																.common.white,
														}}
													>
														Title
													</Typography>
												</TableCell>
												<TableCell align="center">
													<Typography
														variant="body2"
														style={{
															color: theme.palette
																.common.white,
														}}
													>
														Date
													</Typography>
												</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{sortedAlbumsByDate.map(
												(_album, index) => (
													<Box
														clone
														className={
															classes.rowHover
														}
													>
														<TableRow
															key={index}
															scope="row"
															hover
														>
															<TableCell
																width={50}
																onClick={() =>
																	handleClick(
																		_album,
																	)
																}
															>
																<img
																	src={`${
																		process
																			.env
																			.REACT_APP_API_URL
																	}/static/${
																		_album.files.find(
																			(
																				_file,
																			) =>
																				_file.type !==
																				"video/mp4",
																		).code
																	}`}
																	alt=""
																	width="60%"
																/>
															</TableCell>
															<TableCell
																width={100}
																align="center"
																onClick={() =>
																	handleClick(
																		_album,
																	)
																}
															>
																<Typography
																	variant="body1"
																	color="textPrimary"
																	style={{
																		textTransform:
																			"capitalize",
																	}}
																>
																	{
																		_album.albumType
																	}
																</Typography>
															</TableCell>
															<TableCell
																width={100}
																align="center"
																onClick={() =>
																	handleClick(
																		_album,
																	)
																}
															>
																<Typography
																	variant="body1"
																	color="textPrimary"
																>
																	{
																		_album.title
																	}
																</Typography>
															</TableCell>
															<TableCell
																width={100}
																align="center"
																onClick={() =>
																	handleClick(
																		_album,
																	)
																}
															>
																<Typography
																	variant="body1"
																	color="textPrimary"
																>
																	{convertDate(
																		_album.date,
																	)}
																</Typography>
															</TableCell>
														</TableRow>
													</Box>
												),
											)}
										</TableBody>
									</Table>
								</TableContainer>
							) : (
								<Typography
									variant="body1"
									color="textPrimary"
									align="center"
								>
									There are no results
								</Typography>
							)}
						</Grid>
					</Grid>
				) : null}
			</SectionContainerLayout>
		</MainLayout>
	);
};

export default AlbumPage;
