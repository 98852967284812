import {
	TextField,
	Grid,
	Button,
	Box,
	Typography,
	FormHelperText,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import MainLayout from "../../components/main-layout";
import SectionContainerLayout from "../../components/section-container-layout";
import { DropzoneArea } from "material-ui-dropzone";
import axios from "axios";
import { useHistory, useLocation } from "react-router";
import { editAlbum } from "../../validations/album-validator";
import moment from "moment";

const EditAlbumPage = () => {
	const [jsonFormData, setJsonFormData] = useState();
	const [newFiles, setNewFiles] = useState([]);
	const [validationErrors, setValidationErrors] = useState({});
	const [selectedAlbumType, setSelectedAlbumType] = useState(null);
	const [currentAlbum, setCurrentAlbum] = useState({});
	const [isDataLoaded, setIsDataLoaded] = useState(false);
	const [deletedFileCodes, setDeletedFileCodes] = useState([]);
	const [existingFiles, setExistingFiles] = useState([]);
	const history = useHistory();
	const location = useLocation();

	const [existingVideoFileCodes, setExistingVideoFileCodes] = useState([]);
	const [existingImageFileCodes, setExistingImageFileCodes] = useState([]);

	useEffect(() => {
		const { album } = location.state;

		setCurrentAlbum(album);
		setSelectedAlbumType(album.albumType);
		setExistingFiles(album.files);
		setJsonFormData({
			...jsonFormData,
			title: album.title,
			date: album.date,
			albumType: album.albumType,
		});

		let initFiles = [];

		album.files.forEach((_file) => {
			initFiles.push(
				`${process.env.REACT_APP_API_URL}/static/${_file.code}`,
			);
		});

		let _initVideoFiles = [];
		let _initImageFiles = [];
		let _initVideoCodes = [];
		let _initImageCodes = [];

		_initVideoFiles = album.files.filter(
			(_file) => _file.type === "video/mp4",
		);
		_initImageFiles = album.files.filter(
			(_file) => _file.type !== "video/mp4",
		);

		_initVideoFiles.forEach((_videoFile) => {
			_initVideoCodes.push(
				`${process.env.REACT_APP_API_URL}/static/${_videoFile.code}`,
			);
		});

		_initImageFiles.forEach((_imageFile) => {
			_initImageCodes.push(
				`${process.env.REACT_APP_API_URL}/static/${_imageFile.code}`,
			);
		});

		setExistingVideoFileCodes(_initVideoCodes);
		setExistingImageFileCodes(_initImageCodes);

		setIsDataLoaded(true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location.state]);

	const handleFormChange = (e) => {
		setJsonFormData({
			...jsonFormData,
			[e.target.id || e.target.name]: e.target.value,
		});

		if (e.target.name === "albumType") {
			setSelectedAlbumType(e.target.value);
		}

		switch (e.target.name || e.target.id) {
			case "title": {
				setValidationErrors({ ...validationErrors, title: null });
				break;
			}
			case "albumType": {
				setValidationErrors({ ...validationErrors, albumType: null });
				break;
			}
			default:
				return;
		}
	};

	const handleFiles = (file) => {
		let uploadedFiles = [];

		file.forEach((_file, index) => {
			uploadedFiles.push(_file);
		});

		setNewFiles([...newFiles, ...uploadedFiles]);
	};

	const handleFileDelete = (file) => {
		let newFilesWithDeletedRemoved = [...newFiles];
		for (let i = 0; i < existingFiles.length; i++) {
			// if deleted file name is present in existing files (already in database)
			if (existingFiles[i].code === file.name) {
				setDeletedFileCodes([...deletedFileCodes, file.name]);
				break;
			} else {
				for (let i = 0; i < newFilesWithDeletedRemoved.length; i++) {
					if (newFilesWithDeletedRemoved[i].name === file.name) {
						newFilesWithDeletedRemoved.splice(i, 1);
						setNewFiles(newFilesWithDeletedRemoved);
						break;
					}
				}
			}
		}
	};

	const handleSubmit = () => {
		try {
			let errorMessages = [];

			editAlbum
				.validate(jsonFormData, { abortEarly: false })
				.then(() => {
					let formData = new FormData();

					formData.append("albumId", currentAlbum.id);
					formData.append("code", currentAlbum.code);
					if (newFiles) {
						for (let i = 0; i < newFiles.length; i++) {
							formData.append("files", newFiles[i]);
						}
					}

					if (deletedFileCodes) {
						for (let i = 0; i < deletedFileCodes.length; i++) {
							formData.append(
								"deletedFileCodes",
								deletedFileCodes[i],
							);
						}
					}

					for (let i in jsonFormData) {
						formData.append(i, jsonFormData[i]);
					}

					axios
						.put("/album", formData, {
							headers: { "Content-Type": "multipart/form-data" },
						})
						.then((_response) => {
							if (_response.status === 200) {
								history.push("/albums");
							} else if (_response.status === 404) {
								history.push("/login");
							} else {
								console.log(
									"Sorry, there was an error. Please try again",
								);
							}
						})
						.catch((err) => console.log(err));
				})
				.catch((err) => {
					err.inner.forEach((e) => {
						errorMessages[e.path] = e.message;
					});
					setValidationErrors(errorMessages);
				});
		} catch (err) {
			console.log(err);
		}
	};

	return (
		<MainLayout>
			{isDataLoaded ? (
				<SectionContainerLayout
					title={`EDIT ALBUM: ${currentAlbum.code}`}
				>
					<Grid container>
						<Grid item container xs={12}>
							<Grid item xs={12} md={5}>
								{/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    fullWidth
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="date"
                    value={datePicked}
                    required
                    label="Date"
                    name="date"
                    error={validationErrors.date}
                    helperText={validationErrors.date}
                    onChange={handleDatePicker("date")}
                    autoOk
                  />
                </MuiPickersUtilsProvider> */}
								<TextField
									id="date"
									label="Date"
									type="date"
									defaultValue={moment(
										currentAlbum.date,
									).format("YYYY-MM-DD")}
									onChange={handleFormChange}
									error={validationErrors.date}
									helperText={validationErrors.date}
									variant="outlined"
									fullWidth
								/>
							</Grid>
							<Grid item xs={12} md={7}>
								<TextField
									variant="outlined"
									fullWidth
									label="Title"
									required
									placeholder={
										currentAlbum.title ? "" : "Title"
									}
									defaultValue={currentAlbum.title}
									id="title"
									error={validationErrors.title}
									helperText={validationErrors.title}
									onChange={handleFormChange}
								/>
							</Grid>
						</Grid>
						<Grid item container xs={12}>
							<Grid item xs={12} md={4}>
								{/* <FormControl fullWidth>
                  <InputLabel>Album Type</InputLabel>
                  <Select
                    name="albumType"
                    variant="outlined"
                    error={validationErrors.albumType}
                    // onChange={handleFormChange}
                  >
                    <MenuItem value="images">Images</MenuItem>
                    <MenuItem value="videos">Videos</MenuItem>
                  </Select>
                  {validationErrors.albumType ? (
                    <FormHelperText color="error">
                      <Typography variant="body2" color="error">
                        Album type is required
                      </Typography>
                    </FormHelperText>
                  ) : null}
                </FormControl> */}
								<Typography variant="body1" color="textPrimary">
									Album type: {selectedAlbumType}
								</Typography>
							</Grid>
							<Box clone pt={2}>
								<Grid item container xs={12}>
									<Grid
										item
										xs={
											selectedAlbumType === "videos"
												? 6
												: 12
										}
									>
										<DropzoneArea
											dropzoneText={
												selectedAlbumType === "images"
													? "Drag and drop images here *"
													: selectedAlbumType ===
													  "videos"
													? "Drag and drop videos here"
													: "Please select an album type"
											}
											filesLimit={300}
											key={1}
											fileObjects={newFiles}
											onDrop={(file) => handleFiles(file)}
											onDelete={(file) =>
												handleFileDelete(file)
											}
											showFileNames={false}
											showPreviewsInDropzone
											acceptedFiles={
												selectedAlbumType === "images"
													? [
															"image/jpg",
															"image/jpeg",
															"image/png",
													  ]
													: selectedAlbumType ===
													  "videos"
													? ["video/mp4"]
													: null
											}
											maxFileSize={
												selectedAlbumType === "videos"
													? 2147483648
													: null
											}
											initialFiles={
												selectedAlbumType === "images"
													? existingImageFileCodes
													: existingVideoFileCodes
											}
											clearOnUnmount={false}
										/>
										{selectedAlbumType === "videos" ? (
											<FormHelperText>
												Video max size: 2GB
											</FormHelperText>
										) : null}
										{selectedAlbumType === "images" ? (
											<FormHelperText>
												Width: 500px, Height: 300px
											</FormHelperText>
										) : null}
										{validationErrors.albumTypeNotSelected ? (
											<FormHelperText>
												<Typography
													variant="body2"
													color="error"
												>
													{
														validationErrors.albumTypeNotSelected
													}
												</Typography>
											</FormHelperText>
										) : null}
									</Grid>
									{selectedAlbumType === "videos" ? (
										<Grid item xs={6}>
											<DropzoneArea
												dropzoneText={
													"Drag and drop video album thumbnail here"
												}
												filesLimit={1}
												key={2}
												fileObjects={newFiles}
												onDrop={(file) =>
													handleFiles(file)
												}
												onDelete={(file) =>
													handleFileDelete(file)
												}
												showFileNames={false}
												showPreviewsInDropzone
												acceptedFiles={[
													"image/jpg",
													"image/jpeg",
													"image/png",
												]}
												initialFiles={
													existingImageFileCodes
												}
												clearOnUnmount={false}
											/>
											<FormHelperText>
												Width: 500px, Height: 300px
											</FormHelperText>
										</Grid>
									) : null}
								</Grid>
							</Box>
						</Grid>
						<Box clone pt={2}>
							<Grid item container xs={12} align="right">
								<Grid item xs={12}>
									<Button
										onClick={() => history.push("/albums")}
										style={{ textTransform: "capitalize" }}
									>
										Cancel
									</Button>
									<Button
										color="primary"
										// disabled={
										//   !jsonFormData?.title ||
										//   !jsonFormData?.date ||
										//   !jsonFormData?.albumType
										// }
										variant="contained"
										onClick={handleSubmit}
										style={{ textTransform: "capitalize" }}
									>
										Submit
									</Button>
								</Grid>
							</Grid>
						</Box>
					</Grid>
				</SectionContainerLayout>
			) : (
				<p>Loading...</p>
			)}
		</MainLayout>
	);
};

export default EditAlbumPage;
