import {
	Grid,
	Button,
	Dialog,
	DialogActions,
	DialogTitle,
	DialogContentText,
	DialogContent,
	Box,
	Table,
	TableHead,
	TableRow,
	TableCell,
	makeStyles,
	TableBody,
	TableContainer,
	Paper,
	Typography,
	useTheme,
	IconButton,
	TextField,
	Checkbox,
	FormControlLabel,
	FormHelperText,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { DropzoneArea } from "material-ui-dropzone";
import MainLayout from "../../components/main-layout";
import SectionContainerLayout from "../../components/section-container-layout";
import axios from "axios";
// import { useHistory } from "react-router-dom";
import SubSectionContainerLayout from "../../components/sub-section-container-layout";
import colors from "../../constants/colors";
import DeleteIcon from "@material-ui/icons/Delete";
import CreateIcon from "@material-ui/icons/Create";
const useStyles = makeStyles((theme) => ({
	tableHeader: {
		backgroundColor: colors.darkBlue,
	},
	rowHover: {
		"&:hover": {
			cursor: "pointer",
		},
	},
}));

const SettingsPageManageCategory = () => {
	const classes = useStyles();
	const theme = useTheme();
	const [isDialogOpen, setIsDialogOpen] = useState(false);
	const [knowledgeHubCategories, setKnowledgeHubCategories] = useState([]);
	const [newsCategories, setNewsCategories] = useState([]);
	const [partnerCategories, setPartnerCategories] = useState([]);
	const [currentCode, setCurrentCode] = useState("");
	const [currentCount, setCurrentCount] = useState(0);
	const [currentType, setCurrentType] = useState("");
	const [currentPartnerToEdit, setCurrentPartnerToEdit] = useState({});
	const [isEditPartnerNameDialog, setIsEditPartnerNameDialog] =
		useState(false);
	const [isEditPartnerOrderDialog, setIsEditPartnerOrderDialog] =
		useState(false);
	const [newPartnerDetails, setNewPartnerDetails] = useState({});
	const [isHomepage, setIsHomepage] = useState(false);

	const handleClose = () => setIsDialogOpen(false);

	useEffect(() => {
		try {
			Promise.all([
				axios.get("/utility/categories/count/knowledge-hub"),
				axios.get("/utility/categories/count/news"),
				axios.get("/utility/partner-categories/count"),
			]).then((_responses) => {
				if (_responses[0].status === 200) {
					setKnowledgeHubCategories(_responses[0].data);
				}

				if (_responses[1].status === 200) {
					setNewsCategories(_responses[1].data);
				}

				if (_responses[2].status === 200) {
					setPartnerCategories(_responses[2].data);
				}
			});
		} catch (err) {
			console.log(err);
		}
	}, []);

	const handleDeleteCategory = (type, code) => {
		try {
			axios
				.delete(`/utility/categories/${type}/${code}`)
				.then((_response) => {
					setIsDialogOpen(false);

					if (type === "knowledge-hub")
						setKnowledgeHubCategories(_response.data);
					else if (type === "news") setNewsCategories(_response.data);
				})
				.catch((err) => console.log(err));
		} catch (err) {
			console.log(err);
		}
	};

	const handleDeletePartnerCategory = (code) => {
		try {
			axios
				.delete(`/utility/partner-categories/${code}`)
				.then((_response) => {
					setIsDialogOpen(false);
					setPartnerCategories(_response.data);
				})
				.catch((err) => console.log(err));
		} catch (err) {
			console.log(err);
		}
	};

	const handleEditPartnerCategoryName = () => {
		try {
			if (Object.keys(newPartnerDetails).length === 0) {
				setIsEditPartnerNameDialog(false);
			} else {
				var formData = new FormData();
				formData.append("code", newPartnerDetails.code);
				if (newPartnerDetails.name)
					formData.append("name", newPartnerDetails.name);

				if (newPartnerDetails.imageFile)
					formData.append("imageFile", newPartnerDetails.imageFile);
				formData.append(
					"isHomepage",
					newPartnerDetails.isHomepage
						? newPartnerDetails.isHomepage
						: false,
				);
				axios
					.patch("/utility/partner-categories/name", formData, {
						headers: {
							"Content-Type": "multipart/form-data",
						},
					})
					.then((_response) => {
						if (_response.status === 200) {
							axios
								.get("/utility/partner-categories/count")
								.then((res) => {
									setPartnerCategories(res.data);
									setCurrentPartnerToEdit({});
									setIsEditPartnerNameDialog(false);
								});
							setNewPartnerDetails({});
							setCurrentPartnerToEdit({});
							setIsEditPartnerNameDialog(false);
						}
					})
					.catch((err) => console.log(err));
			}
		} catch (err) {
			console.log(err);
		}
	};

	const handleEditPartnerCategoryOrder = () => {
		try {
			if (Object.keys(newPartnerDetails).length === 0) {
				setIsEditPartnerOrderDialog(false);
			} else {
				axios
					.patch(
						"/utility/partner-categories/order",
						newPartnerDetails,
					)
					.then((_response) => {
						if (_response.status === 200) {
							setPartnerCategories(_response.data);
							setIsEditPartnerOrderDialog(false);
						}
					})
					.catch((err) => console.log(err));
			}
		} catch (err) {
			console.log(err);
		}
	};

	return (
		<>
			<MainLayout>
				<Dialog open={isDialogOpen} onClose={handleClose}>
					<DialogTitle>Delete Category?</DialogTitle>
					<DialogContent>
						<DialogContentText>
							Deleting this category will remove it from{" "}
							{currentCount}{" "}
							{currentType === "knowledge-hub"
								? "knowledge hub"
								: currentType === "partner"
								? "partner"
								: "news"}
							{currentCount > 1 ? "s" : null}. Are you sure you
							want to delete this category?
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button
							variant="outlined"
							color="primary"
							onClick={handleClose}
						>
							No
						</Button>
						<Button
							variant="contained"
							color="primary"
							onClick={() =>
								currentType === "partner"
									? handleDeletePartnerCategory(currentCode)
									: handleDeleteCategory(
											currentType,
											currentCode,
									  )
							}
						>
							Yes
						</Button>
					</DialogActions>
				</Dialog>

				<Dialog
					open={isEditPartnerNameDialog || isEditPartnerOrderDialog}
					onClose={handleClose}
				>
					<DialogTitle>{`Editing Category: ${currentPartnerToEdit.code}`}</DialogTitle>
					<DialogContent>
						<DialogContentText>
							<TextField
								fullWdith
								variant="outlined"
								label={
									isEditPartnerNameDialog
										? "Edit Partner Name"
										: "Edit Partner Order"
								}
								defaultValue={
									isEditPartnerNameDialog
										? currentPartnerToEdit.name
										: currentPartnerToEdit.order
								}
								placeholder={
									isEditPartnerNameDialog
										? currentPartnerToEdit.name
										: currentPartnerToEdit.order
								}
								onChange={(e) => {
									isEditPartnerNameDialog
										? setNewPartnerDetails((prev) => {
												return {
													...prev,
													code: currentPartnerToEdit.code,
													name: e.target.value,
												};
										  })
										: setNewPartnerDetails({
												code: currentPartnerToEdit.code,
												order: e.target.value,
										  });
								}}
							/>
							{isEditPartnerNameDialog && (
								<Box my={2}>
									<DropzoneArea
										style={{
											height: "140px !important",
											minHeight: "140px !important",
										}}
										dropzoneText="Drag and drop the thumbnail here *"
										acceptedFiles={["image/*"]}
										filesLimit={1}
										maxFileSize={2147489}
										initialFiles={[
											`${process.env.REACT_APP_API_URL}/static/${currentPartnerToEdit.imageFile}`,
										]}
										onDrop={(acceptedFiles) => {
											// do nothing if no files
											if (acceptedFiles.length === 0) {
												return;
											}
											// on drop we add to the existing files
											setNewPartnerDetails((prev) => {
												return {
													...prev,
													code: currentPartnerToEdit.code,
													imageFile: acceptedFiles[0],
												};
											});
										}}
									/>
									<FormHelperText>
										Width: 500px, Height: 300px
									</FormHelperText>

									<FormControlLabel
										control={
											<Checkbox
												checked={isHomepage}
												onChange={(e) => {
													setIsHomepage(
														(prev) => !prev,
													);
													setNewPartnerDetails(
														(prev) => {
															return {
																...prev,
																code: currentPartnerToEdit.code,
																isHomepage:
																	!isHomepage,
															};
														},
													);
												}}
											/>
										}
										label="Add to hompage"
									></FormControlLabel>
								</Box>
							)}
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button
							variant="outlined"
							color="primary"
							onClick={() => {
								setIsEditPartnerNameDialog(false);
								setIsEditPartnerOrderDialog(false);
								setCurrentPartnerToEdit({});
							}}
						>
							Cancel
						</Button>
						<Button
							variant="contained"
							color="primary"
							onClick={() => {
								if (isEditPartnerNameDialog)
									handleEditPartnerCategoryName();
								else handleEditPartnerCategoryOrder();
							}}
						>
							Submit
						</Button>
					</DialogActions>
				</Dialog>

				<SectionContainerLayout title="SETTINGS">
					<SubSectionContainerLayout title="Manage Categories">
						<Grid container spacing={6}>
							<Grid item xs={12}>
								<Typography variant="body1" color="textPrimary">
									Knowledge Hub Categories
								</Typography>
								<TableContainer component={Paper}>
									<Table>
										<TableHead
											className={classes.tableHeader}
										>
											<TableRow>
												<TableCell align="center">
													<Typography
														variant="body2"
														style={{
															color: theme.palette
																.common.white,
														}}
													>
														Category ID
													</Typography>
												</TableCell>
												<TableCell align="center">
													<Typography
														variant="body2"
														style={{
															color: theme.palette
																.common.white,
														}}
													>
														Category Name
													</Typography>
												</TableCell>
												<TableCell align="center">
													<Typography
														variant="body2"
														style={{
															color: theme.palette
																.common.white,
														}}
													>
														# of Knowledge Hub
													</Typography>
												</TableCell>
												<TableCell></TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{knowledgeHubCategories.map(
												(_category, index) => (
													<TableRow key={index}>
														<TableCell
															width={100}
															align="center"
														>
															<Typography
																variant="body1"
																color="textPrimary"
															>
																{_category.code}
															</Typography>
														</TableCell>
														<TableCell
															width={100}
															align="center"
														>
															<Typography
																variant="body1"
																color="textPrimary"
															>
																{_category.name}
															</Typography>
														</TableCell>
														<TableCell
															width={100}
															align="center"
														>
															<Typography
																variant="body1"
																color="textPrimary"
															>
																{
																	_category.total
																}
															</Typography>
														</TableCell>
														<TableCell
															width={100}
															align="center"
														>
															<IconButton
																onClick={() => {
																	setCurrentCode(
																		_category.code,
																	);
																	setCurrentCount(
																		_category.count,
																	);
																	setCurrentType(
																		"knowledge-hub",
																	);
																	setIsDialogOpen(
																		true,
																	);
																}}
															>
																<DeleteIcon color="error" />
															</IconButton>
														</TableCell>
													</TableRow>
												),
											)}
										</TableBody>
									</Table>
								</TableContainer>
							</Grid>
							<Grid item xs={12}>
								<Typography variant="body1" color="textPrimary">
									News Categories
								</Typography>
								<TableContainer component={Paper}>
									<Table>
										<TableHead
											className={classes.tableHeader}
										>
											<TableRow>
												<TableCell align="center">
													<Typography
														variant="body2"
														style={{
															color: theme.palette
																.common.white,
														}}
													>
														Category ID
													</Typography>
												</TableCell>
												<TableCell align="center">
													<Typography
														variant="body2"
														style={{
															color: theme.palette
																.common.white,
														}}
													>
														Category Name
													</Typography>
												</TableCell>
												<TableCell align="center">
													<Typography
														variant="body2"
														style={{
															color: theme.palette
																.common.white,
														}}
													>
														# of News
													</Typography>
												</TableCell>
												<TableCell></TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{newsCategories.map(
												(_category, index) => (
													<TableRow key={index}>
														<TableCell
															width={100}
															align="center"
														>
															<Typography
																variant="body1"
																color="textPrimary"
															>
																{_category.code}
															</Typography>
														</TableCell>
														<TableCell
															width={100}
															align="center"
														>
															<Typography
																variant="body1"
																color="textPrimary"
															>
																{_category.name}
															</Typography>
														</TableCell>
														<TableCell
															width={100}
															align="center"
														>
															<Typography
																variant="body1"
																color="textPrimary"
															>
																{
																	_category.total
																}
															</Typography>
														</TableCell>
														<TableCell
															width={100}
															align="center"
														>
															<IconButton
																onClick={() => {
																	setCurrentCode(
																		_category.code,
																	);
																	setCurrentCount(
																		_category.count,
																	);
																	setCurrentType(
																		"news",
																	);
																	setIsDialogOpen(
																		true,
																	);
																}}
															>
																<DeleteIcon color="error" />
															</IconButton>
														</TableCell>
													</TableRow>
												),
											)}
										</TableBody>
									</Table>
								</TableContainer>
							</Grid>
							<Grid item xs={12}>
								<Typography variant="body1" color="textPrimary">
									Partner Categories
								</Typography>
								<TableContainer component={Paper}>
									<Table>
										<TableHead
											className={classes.tableHeader}
										>
											<TableRow>
												<TableCell align="center">
													<Typography
														variant="body2"
														style={{
															color: theme.palette
																.common.white,
														}}
													>
														Order #
													</Typography>
												</TableCell>
												<TableCell align="center">
													<Typography
														variant="body2"
														style={{
															color: theme.palette
																.common.white,
														}}
													>
														Category ID
													</Typography>
												</TableCell>
												<TableCell align="center">
													<Typography
														variant="body2"
														style={{
															color: theme.palette
																.common.white,
														}}
													>
														Category Name
													</Typography>
												</TableCell>
												<TableCell align="center">
													<Typography
														variant="body2"
														style={{
															color: theme.palette
																.common.white,
														}}
													>
														Category Thumbnail
													</Typography>
												</TableCell>
												<TableCell align="center">
													<Typography
														variant="body2"
														style={{
															color: theme.palette
																.common.white,
														}}
													>
														# Home Page
													</Typography>
												</TableCell>
												<TableCell align="center">
													<Typography
														variant="body2"
														style={{
															color: theme.palette
																.common.white,
														}}
													>
														# of Partners
													</Typography>
												</TableCell>

												<TableCell></TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{partnerCategories &&
												partnerCategories.length > 0 &&
												partnerCategories.map(
													(_category, index) => {
														return (
															<TableRow
																key={index}
															>
																<TableCell
																	width={10}
																	align="center"
																>
																	<Box
																		display="flex"
																		width={
																			1
																		}
																		alignItems="center"
																	>
																		<Box
																			flexGrow={
																				1
																			}
																		>
																			<Typography
																				variant="body1"
																				color="textPrimary"
																				align="center"
																			>
																				{
																					_category
																						.category
																						.order
																				}
																			</Typography>
																		</Box>
																		<Box>
																			<IconButton
																				onClick={() => {
																					setCurrentPartnerToEdit(
																						{
																							code: _category
																								.category
																								.code,
																							order: _category
																								.category
																								.order,
																						},
																					);
																					setIsEditPartnerOrderDialog(
																						true,
																					);
																				}}
																			>
																				<CreateIcon />
																			</IconButton>
																		</Box>
																	</Box>
																</TableCell>
																<TableCell
																	width={100}
																	align="center"
																>
																	<Typography
																		variant="body1"
																		color="textPrimary"
																	>
																		{
																			_category
																				.category
																				.code
																		}
																	</Typography>
																</TableCell>
																<TableCell
																	width={100}
																>
																	<Box
																		display="flex"
																		width={
																			1
																		}
																		alignItems="center"
																	>
																		<Box
																			flexGrow={
																				1
																			}
																		>
																			<Typography
																				variant="body1"
																				color="textPrimary"
																				align="center"
																			>
																				{
																					_category
																						.category
																						.name
																				}
																			</Typography>
																		</Box>
																		<Box>
																			<IconButton
																				onClick={() => {
																					setIsHomepage(
																						_category
																							.category
																							.isHomepage,
																					);
																					setCurrentPartnerToEdit(
																						{
																							code: _category
																								.category
																								.code,
																							name: _category
																								.category
																								.name,
																							isHomepage:
																								_category
																									.category
																									.isHomepage,
																							imageFile:
																								_category
																									.category
																									.file &&
																								_category
																									.category
																									.file
																									.code
																									? _category
																											.category
																											.file
																											.code
																									: "",
																						},
																					);
																					setIsEditPartnerNameDialog(
																						true,
																					);
																				}}
																			>
																				<CreateIcon />
																			</IconButton>
																		</Box>
																	</Box>
																</TableCell>
																<TableCell
																	width={100}
																>
																	<Box
																		display="flex"
																		width={
																			1
																		}
																		alignItems="center"
																	>
																		<Box
																			flexGrow={
																				1
																			}
																		>
																			<img
																				style={{
																					width: "60px",
																					height: "60px",
																					borderRadius:
																						"50%",
																				}}
																				alt="category"
																				src={
																					_category
																						.category
																						.file
																						? `${process.env.REACT_APP_API_URL}/static/${_category.category.file.code}`
																						: ""
																				}
																			/>
																		</Box>
																	</Box>
																</TableCell>
																<TableCell
																	width={100}
																>
																	<Box
																		display="flex"
																		width={
																			1
																		}
																		alignItems="center"
																	>
																		<Box
																			flexGrow={
																				1
																			}
																		>
																			<Typography
																				variant="body1"
																				color="textPrimary"
																				align="center"
																			>
																				{_category
																					.category
																					.isHomepage
																					? "Added"
																					: "Not added"}
																			</Typography>
																		</Box>
																	</Box>
																</TableCell>
																<TableCell
																	width={100}
																	align="center"
																>
																	<Typography
																		variant="body1"
																		color="textPrimary"
																	>
																		{
																			_category.count
																		}
																	</Typography>
																</TableCell>
																<TableCell
																	width={100}
																	align="center"
																>
																	<IconButton
																		onClick={() => {
																			setCurrentCode(
																				_category
																					.category
																					.code,
																			);
																			setCurrentCount(
																				_category.count,
																			);
																			setCurrentType(
																				"partner",
																			);
																			setIsDialogOpen(
																				true,
																			);
																		}}
																	>
																		<DeleteIcon color="error" />
																	</IconButton>
																</TableCell>
															</TableRow>
														);
													},
												)}
										</TableBody>
									</Table>
								</TableContainer>
							</Grid>
						</Grid>
					</SubSectionContainerLayout>
				</SectionContainerLayout>
			</MainLayout>
		</>
	);
};

export default SettingsPageManageCategory;
