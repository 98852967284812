import {
	Grid,
	TextField,
	Button,
	FormHelperText,
	Box,
	Typography,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { DropzoneArea } from "material-ui-dropzone";
import MainLayout from "../../components/main-layout";
import SectionContainerLayout from "../../components/section-container-layout";
import axios from "axios";
import { useHistory, useLocation } from "react-router-dom";

import "date-fns";
import { createFilterOptions } from "@material-ui/lab/Autocomplete";
import { addNewNews } from "../../validations/news-validator";
import { Autocomplete } from "@material-ui/lab";

import CustomTextEditor from "../../components/custom-text-editor";
import moment from "moment";

const EditNewsPage = () => {
	const [jsonFormData, setJsonFormData] = useState({});
	const [imageFile, setImageFile] = useState({});
	const [validationErrors, setValidationErrors] = useState({});
	const [currentNews, setCurrentNews] = useState({});
	const [isDataLoaded, setIsDataLoaded] = useState(false);
	const [categories, setCategories] = useState({});
	const [selectedCategory, setSelectedCategory] = useState({});
	const filter = createFilterOptions();

	const location = useLocation();

	useEffect(() => {
		axios
			.get("/utility/categories/news")
			.then((_response) => {
				if (_response) {
					setCategories(_response.data);
				}
			})
			.catch((err) => console.log(err));

		setCurrentNews(location.state.news);

		const { news } = location.state;

		if (news.category.length > 0) {
			setSelectedCategory(news.category[0]);
		}

		setJsonFormData({
			...jsonFormData,
			date: moment(news.date).format("YYYY-MM-DD"),
			title: news.title,
			article: news.article,
			categoryName:
				news.category && news.category.name ? news.category.name : null,
		});

		setIsDataLoaded(true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const history = useHistory();

	const handleEditorChange = (event, editor) => {
		const data = editor.getData();
		setJsonFormData({
			...jsonFormData,
			article: data,
		});
	};

	const handleFormChange = (e) => {
		setJsonFormData({
			...jsonFormData,
			[e.target.id || e.target.name]: e.target.value || e.target.checked,
		});
	};

	const handleFile = (file) => {
		setImageFile(file[0]);
	};

	const handleSubmit = async () => {
		let errorMessages = {};

		try {
			addNewNews
				.validate(jsonFormData, { abortEarly: false })
				.then(() => {
					if (!imageFile) {
						return;
					}
					let formData = new FormData();
					formData.append("imageFile", imageFile);
					jsonFormData["code"] = currentNews.code;
					jsonFormData["fileCode"] = currentNews.file.code;
					for (let i in jsonFormData) {
						formData.append(i, jsonFormData[i]);
					}

					if (
						selectedCategory &&
						Object.keys(selectedCategory).length > 0
					) {
						formData.append(
							"category",
							Object.keys(selectedCategory).length > 0
								? JSON.stringify(selectedCategory)
								: null,
						);
					}

					axios
						.put("/news", formData, {
							headers: { "Content-Type": "multipart/form-data" },
						})
						.then((_response) => {
							if (_response.status === 200) {
								history.push("/news");
							} else {
								console.log("error");
							}
						})
						.catch((err) => console.log(err));
				})
				.catch((err) => {
					err.inner.forEach((e) => {
						errorMessages[e.path] = e.message;
					});
					setValidationErrors(errorMessages);
				});
		} catch (err) {
			console.log(err);
		}
	};

	return (
		<MainLayout>
			{isDataLoaded ? (
				<SectionContainerLayout
					title={`EDIT NEWS: ${currentNews.code}`}
				>
					<Grid container>
						<Grid item container xs={12} md={6}>
							<Grid item container xs={12} spacing={3}>
								<Grid item xs={12} md={6}>
									{/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      id="date"
                      value={currentNews.date}
                      label="Date"
                      required
                      name="date"
                      autoOk
                      error={validationErrors.date}
                      helperText={validationErrors.date}
                      onChange={handleDatePicker("date")}
                    />
                  </MuiPickersUtilsProvider> */}
									<TextField
										id="date"
										label="Date"
										type="date"
										defaultValue={moment(
											currentNews.date,
										).format("YYYY-MM-DD")}
										onChange={handleFormChange}
										error={validationErrors.date}
										helperText={validationErrors.date}
										variant="outlined"
										fullWidth
									/>
								</Grid>
								<Grid item xs={12} md={6}>
									<TextField
										variant="outlined"
										fullWidth
										label="Title"
										required
										placeholder={
											currentNews.title ? "" : "Title"
										}
										defaultValue={currentNews.title}
										id="title"
										onChange={handleFormChange}
										error={validationErrors.title}
										helperText={validationErrors.title}
									/>
								</Grid>
							</Grid>
							<Grid item container xs={12} spacing={3}>
								{/* <Grid item xs={12} md={6}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel>Tags</InputLabel>
                    <Select name="tags" onChange={handleFormChange}>
                      <MenuItem value={"Tag"}>Tag 1</MenuItem>
                      <MenuItem value={"Tag"}>Tag 2</MenuItem>
                      <MenuItem value={"Tag"}>Tag 3</MenuItem>
                      <MenuItem value={"Tag"}>Tag 4</MenuItem>
                    </Select>
                  </FormControl>
                </Grid> */}
								<Grid item xs={12} md={6}>
									<Autocomplete
										value={selectedCategory}
										onChange={(event, newValue) => {
											if (typeof newValue === "string")
												setSelectedCategory({
													name: newValue,
												});
											else if (
												newValue &&
												newValue.inputValue
											)
												setSelectedCategory({
													name: newValue.inputValue,
												});
											else setSelectedCategory(newValue);
										}}
										filterOptions={(options, params) => {
											const filtered = filter(
												options,
												params,
											);
											if (params.inputValue !== "") {
												filtered.push({
													inputValue:
														params.inputValue,
													name: `Add "${params.inputValue}"`,
												});
											}
											return filtered;
										}}
										selectOnFocus
										clearOnBlur
										handleHomeEndKeys
										options={categories}
										getOptionLabel={(option) => {
											if (Object.keys(option).length > 0)
												return option.name;

											return "";
										}}
										renderOption={(option) =>
											Object.keys(option).length > 0
												? option.name
												: ""
										}
										freeSolo
										renderInput={(params) => (
											<TextField
												{...params}
												label="Category"
												variant="outlined"
											/>
										)}
									/>
								</Grid>
							</Grid>
						</Grid>
						<Grid item container xs={12} md={6} spacing={3}>
							<Grid item xs={12}>
								<DropzoneArea
									dropzoneText="drag and drop or click here *"
									filesLimit={1}
									onChange={(file) => handleFile(file)}
									acceptedFiles={["image/*"]}
									initialFiles={[
										`${process.env.REACT_APP_API_URL}/static/${currentNews.file.code}`,
									]}
								/>
								<FormHelperText>
									Width: 500px, Height: 300px
								</FormHelperText>
							</Grid>
						</Grid>
						<Box clone pt={2}>
							<Grid item container xs={12}>
								<Grid item xs={12}>
									<Typography
										variant="body2"
										color="textPrimary"
									>
										Article *
									</Typography>
									<CustomTextEditor
										data={currentNews.article}
										onChange={handleEditorChange}
									/>
								</Grid>
							</Grid>
						</Box>
						<Box clone pt={2}>
							<Grid item container xs={12} align="right">
								<Grid item xs={12}>
									<Button
										onClick={() => history.push("/news")}
										style={{ textTransform: "capitalize" }}
									>
										Cancel
									</Button>
									<Button
										variant="contained"
										onClick={handleSubmit}
										color="primary"
										style={{ textTransform: "capitalize" }}
									>
										Submit
									</Button>
								</Grid>
							</Grid>
						</Box>
					</Grid>
				</SectionContainerLayout>
			) : (
				<p>loading</p>
			)}
		</MainLayout>
	);
};

export default EditNewsPage;
