import React, { useEffect, useState } from "react";
import {
	AppBar,
	Toolbar,
	InputBase,
	Checkbox,
	Grid,
	makeStyles,
	Box,
	Divider,
	IconButton,
	Hidden,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Button,
} from "@material-ui/core";
import Filters from "./Filters";
import PostAddIcon from "@material-ui/icons/PostAdd";
import FilterListIcon from "@material-ui/icons/FilterList";
import AppsIcon from "@material-ui/icons/Apps";
import ListIcon from "@material-ui/icons/List";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { Link } from "react-router-dom";
import axios from "axios";
import { useStoreState } from "easy-peasy";
import GetAppIcon from "@material-ui/icons/GetApp";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
	ButtonIcon: {
		color: theme.palette.background.default,
		backgroundColor: theme.palette.primary.main,
		borderRadius: "5px",
	},
	searchBar: {
		border: "1px solid #D1D1D1",
	},
	formControl: {
		width: "100%",
	},
}));
const ModuleToolbar = ({
	children,
	addModuleUrl,
	handleGridView,
	handleListView,
	gridViewIcon = true,
	listViewIcon = true,
	filterIcon = true,
	addIcon = true,
	totalSelected = 0,
	totalData = 0,
	onSelectAllClick,
	onEditSelected,
	onEditUserStatusSelected,
	onEditUserResetPasswordSelected,
	onDeleteSelected,
	showFilterByCategories = false,
	showFilterByTags = false,
	handleCategoryChange,
	handlePartnerCategoryChange,
	showFilterByPartnerCategories = false,
	handleSearchChange,
	showCustomFilter = false,
	changeUserStatus = false,
	filterData = {},
	handleFilterData,
	resetPasswordButton = false,
	isAdmin,
	handleExport,
	exportIcon = false,
	changeStatusAction = true,
	hideDeleteIcon = false,
	addHistoryButton = false,
	link = {},
	addSearchBar = true,
	filterChildren,
	showFilters = false,
	filterDataTabs = [],
	handleFilterChange = () => {},
	onClearFiltersClick = () => {},
	theFilters = [],
	isFilterToggled = false,
	handleToggleFilter = () => {},
}) => {
	const classes = useStyles();
	const [isAdvanceFilter, setIsAdvanceFilter] = useState(false);
	const [categories] = useState();
	const [partnerCategories, setPartnerCategories] = useState();
	const [isCategoryLoaded] = useState(false);
	const [isPartnerCategoriesLoaded, setIsPartnerCategoriesLoaded] =
		useState(false);
	const [open, setOpen] = useState(false);
	const [filterKeys, setFilterKeys] = useState([]);
	const [filterKeyData, setFilterKeyData] = useState([]);

	const currentUser = useStoreState((state) => state.user.currentUser);

	// const handleChange = (key, value) => {
	//   switch (key) {
	//     case "category":
	//       // setAdvancedFilter({ ...advancedFilter, category: value });
	//       break;
	//     default:
	//       break;
	//   }
	// };
	useEffect(() => {
		axios
			.get("/utility/partner-categories")
			.then((_response) => {
				if (_response.status === 200) {
					setPartnerCategories(_response.data);
					setIsPartnerCategoriesLoaded(true);
				}
			})
			.catch((err) => console.log(err));

		let filterDataKeys = Object.keys(filterData);

		setFilterKeys(filterDataKeys);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleSelectAllChange = (e) => {
		if (onSelectAllClick) onSelectAllClick(e);
	};
	const handleDeleteSelected = (e) => {
		setOpen(false);
		if (onDeleteSelected) onDeleteSelected(e);
	};

	const handleEditSelected = (status) => {
		if (onEditSelected) onEditSelected(status);
	};
	const handleEditUserStatusSelected = (status) => {
		if (onEditUserStatusSelected) onEditUserStatusSelected(status);
	};

	const handleEditUserResetPasswordSelected = () => {
		if (onEditUserResetPasswordSelected) onEditUserResetPasswordSelected();
	};

	const handleClickOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};

	// ADVANCED FILTERS
	const [partnerCategory, setPartnerCategory] = useState("");
	const [category, setCategory] = useState("");
	const history = useHistory();

	return (
		<div>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					DELETE{" "}
					{totalSelected > 1
						? totalSelected + " ITEMS"
						: totalSelected + " ITEM"}
				</DialogTitle>
				<Divider />
				<Box clone mt={2}>
					<DialogContent>
						<DialogContentText id="alert-dialog-description">
							Are you sure you want to delete selected{" "}
							{totalSelected > 1
								? totalSelected + " items"
								: totalSelected + " item"}
							.
						</DialogContentText>
					</DialogContent>
				</Box>
				<DialogActions>
					<Button
						onClick={handleClose}
						color="primary"
						autoFocus
						variant="contained"
					>
						CANCEL
					</Button>
					<Button onClick={handleDeleteSelected} color="secondary">
						DELETE
					</Button>
				</DialogActions>
			</Dialog>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<AppBar
						position="static"
						style={{ backgroundColor: "transparent" }}
					>
						<Toolbar disableGutters>
							<Grid container alignItems="center">
								<Grid
									item
									container
									xs={12}
									md={8}
									alignItems="center"
									spacing={2}
								>
									{currentUser.user_account_type.type !==
										"Viewer" && !hideDeleteIcon ? (
										<Grid item>
											<Checkbox
												indeterminate={
													totalSelected > 0 &&
													totalSelected < totalData
												}
												checked={
													totalSelected ===
														totalData &&
													totalSelected !== 0
												}
												onChange={handleSelectAllChange}
											/>
										</Grid>
									) : null}
									{addSearchBar && (
										<Grid
											item
											style={{
												marginLeft:
													currentUser
														.user_account_type
														.type !== "Viewer"
														? "10px"
														: "10px",
											}}
										>
											<Box
												clone
												p={0.3}
												borderRadius="borderRadius"
											>
												<InputBase
													className={
														classes.searchBar
													}
													fullWidth
													placeholder="Search.."
													onChange={
														handleSearchChange
													}
												/>
											</Box>
										</Grid>
									)}
									{addIcon &&
									(currentUser.user_account_type.type ===
										"Admin" ||
										(currentUser.user_account_type.type ===
											"Editor" &&
											addModuleUrl !==
												"/users-accounts/add")) ? (
										<Grid item>
											<Link to={addModuleUrl}>
												<PostAddIcon
													className={
														classes.ButtonIcon
													}
													fontSize="large"
													backgroundColor="primary"
												/>
											</Link>
										</Grid>
									) : null}
									{addHistoryButton && (
										<Grid item>
											<Button
												variant="contained"
												color="primary"
												onClick={() =>
													history.push({
														pathname: link.pathName,
														state: link.state,
													})
												}
											>
												{link.text}
											</Button>
										</Grid>
									)}
									<Box clone hidden={totalSelected === 0}>
										<Grid item>
											<IconButton
												onClick={handleClickOpen}
											>
												<DeleteOutlineIcon
													fontSize="large"
													color="error"
												/>
											</IconButton>
										</Grid>
									</Box>
									{changeStatusAction ? (
										<Box clone hidden={totalSelected === 0}>
											<Grid item xs={4}>
												<FormControl
													fullWidth
													variant="outlined"
													size="small"
												>
													<InputLabel id="change-card-status-label">
														Change Status
													</InputLabel>
													<Select
														id="status"
														labelId="change-card-status-label"
														name="statusCode"
														label="Change Status"
														required
														onChange={(e) =>
															handleEditSelected(
																e.target.value,
															)
														}
													>
														<MenuItem
															value="unpublished"
															key="1"
														>
															Unpublished
														</MenuItem>
														<MenuItem
															value="published"
															key="2"
														>
															Published
														</MenuItem>
													</Select>
												</FormControl>
											</Grid>
										</Box>
									) : null}
									{changeUserStatus ? (
										<Box clone hidden={totalSelected === 0}>
											<Grid item xs={4}>
												<FormControl
													fullWidth
													variant="outlined"
													size="small"
												>
													<InputLabel id="change-status-label">
														Change Status
													</InputLabel>
													<Select
														id="status"
														name="statusCode"
														label="Status"
														labelId="change-status-label"
														required
														onChange={(e) =>
															handleEditUserStatusSelected(
																e.target.value,
															)
														}
													>
														<MenuItem
															value="active"
															key="1"
														>
															Active
														</MenuItem>
														<MenuItem
															value="inactive"
															key="2"
														>
															Inactive
														</MenuItem>
													</Select>
												</FormControl>
											</Grid>
										</Box>
									) : null}
									{resetPasswordButton ? (
										<Box clone hidden={totalSelected === 0}>
											<Grid item xs={4}>
												<Box p={1}>
													<Button
														color="primary"
														onClick={
															handleEditUserResetPasswordSelected
														}
														style={{
															textTransform:
																"capitalize",
														}}
														variant="contained"
													>
														Reset Password
													</Button>
												</Box>
											</Grid>
										</Box>
									) : null}
								</Grid>
								<Grid
									item
									container
									xs={12}
									md={4}
									justify="flex-end"
									spacing={1}
								>
									{filterIcon ? (
										<Grid item>
											<IconButton
												onClick={() => {
													setIsAdvanceFilter(
														!isAdvanceFilter,
													);

													handleToggleFilter();
												}}
											>
												<FilterListIcon color="secondary" />
											</IconButton>
										</Grid>
									) : null}
									{!gridViewIcon && !listViewIcon ? null : (
										<Grid item>
											<Divider orientation="vertical" />
										</Grid>
									)}
									{gridViewIcon ? (
										<Grid item>
											<IconButton
												onClick={handleGridView}
											>
												<AppsIcon color="secondary" />
											</IconButton>
										</Grid>
									) : null}
									{listViewIcon ? (
										<Hidden smDown>
											<Grid item>
												<IconButton
													onClick={handleListView}
												>
													<ListIcon color="secondary" />
												</IconButton>
											</Grid>
										</Hidden>
									) : null}
									{exportIcon ? (
										<Grid item>
											<IconButton onClick={handleExport}>
												<GetAppIcon color="secondary" />
											</IconButton>
										</Grid>
									) : null}
								</Grid>
							</Grid>
						</Toolbar>
						{filterIcon && isAdvanceFilter && !isFilterToggled ? (
							<div className="pl-3 pr-5">
								{filterChildren ? (
									filterChildren
								) : (
									<form noValidate autoComplete="off">
										<Box m={3} width={1}>
											<Grid
												item
												container
												spacing={1}
												xs={12}
											>
												{showFilterByCategories ? (
													<Grid
														item
														xs={12}
														sm={6}
														md={2}
													>
														<FormControl
															fullWidth
															variant="outlined"
															size="small"
														>
															<InputLabel id="category-label">
																Category
															</InputLabel>
															<Select
																labelId="category-label"
																id="category"
																name="categoryCode"
																label="Category"
																value={category}
																onChange={(
																	e,
																) => {
																	handleCategoryChange(
																		e.target
																			.value
																			.code,
																	);
																	setCategory(
																		e.target
																			.value,
																	);
																}}
																fullWidth
																variant="outlined"
																required
															>
																{isCategoryLoaded
																	? categories.map(
																			(
																				data,
																				index,
																			) => {
																				return (
																					<MenuItem
																						value={
																							data
																						}
																						id={
																							data.id
																						}
																						key={
																							index
																						}
																					>
																						{
																							data.name
																						}
																					</MenuItem>
																				);
																			},
																	  )
																	: null}
																<MenuItem value="">
																	Show All
																</MenuItem>
															</Select>
														</FormControl>
													</Grid>
												) : null}
												{showFilterByPartnerCategories ? (
													<Grid
														item
														xs={12}
														sm={6}
														md={2}
													>
														<FormControl
															fullWidth
															variant="outlined"
															size="small"
														>
															<InputLabel id="parter-category-label">
																Partner Category
															</InputLabel>
															<Select
																labelId="partner-category-label"
																id="partner-category"
																label="Partner Category"
																name="partnerCategoryCode"
																onChange={(
																	e,
																) => {
																	handlePartnerCategoryChange(
																		e.target
																			.value
																			.code,
																	);
																	setPartnerCategory(
																		e.target
																			.value,
																	);
																}}
																fullWidth
																required
																variant="outlined"
																value={
																	partnerCategory
																}
															>
																{isPartnerCategoriesLoaded
																	? partnerCategories.map(
																			(
																				data,
																				index,
																			) => {
																				return (
																					<MenuItem
																						value={
																							data
																						}
																						id={
																							data.id
																						}
																						key={
																							index
																						}
																					>
																						{
																							data.name
																						}
																					</MenuItem>
																				);
																			},
																	  )
																	: null}
																<MenuItem value="">
																	Show All
																</MenuItem>
															</Select>
														</FormControl>
													</Grid>
												) : null}
												{showCustomFilter
													? filterKeys.map(
															(_key, index) => (
																<Grid
																	item
																	xs={12}
																	sm={6}
																	md={2}
																	key={index}
																>
																	<FormControl
																		fullWidth
																		variant="outlined"
																		size="small"
																	>
																		<InputLabel
																			id={
																				filterData[
																					_key
																				]
																					.idName
																			}
																		>
																			{
																				filterData[
																					_key
																				]
																					.label
																			}
																		</InputLabel>
																		<Select
																			id={
																				filterData[
																					_key
																				]
																					.idName
																			}
																			name={
																				filterData[
																					_key
																				]
																					.idName
																			}
																			labelId={
																				filterData[
																					_key
																				]
																					.idName
																			}
																			label={
																				filterData[
																					_key
																				]
																					.label
																			}
																			required
																			onChange={(
																				e,
																			) => {
																				handleFilterData(
																					e
																						.target
																						.value,
																					filterData[
																						_key
																					]
																						.idName,
																				);
																				const _fdata =
																					[
																						...filterKeyData,
																					];
																				_fdata[
																					filterData[
																						_key
																					].idName
																				] =
																					e.target.value;
																				setFilterKeyData(
																					_fdata,
																				);
																			}}
																			value={
																				filterKeyData[
																					filterData[
																						_key
																					]
																						.idName
																				]
																					? filterKeyData[
																							filterData[
																								_key
																							]
																								.idName
																					  ]
																					: ""
																			}
																		>
																			{filterData[
																				_key
																			].options.map(
																				(
																					_option,
																					index,
																				) => (
																					<MenuItem
																						key={
																							index
																						}
																						value={
																							_option.value
																						}
																					>
																						{
																							_option.name
																						}
																					</MenuItem>
																				),
																			)}
																			<MenuItem
																				key=""
																				value=""
																			>
																				Show
																				all
																			</MenuItem>
																		</Select>
																	</FormControl>
																</Grid>
															),
													  )
													: null}
											</Grid>
										</Box>
									</form>
								)}
							</div>
						) : null}

						{filterIcon && isFilterToggled ? (
							<div className="pl-3 pr-5">
								{filterChildren ? (
									filterChildren
								) : (
									<form noValidate autoComplete="off">
										<Box m={3} width={1}>
											<Grid
												item
												container
												spacing={1}
												xs={12}
											>
												{showFilters && (
													<Filters
														filterValues={
															theFilters
														}
														handleFilterChange={
															handleFilterChange
														}
														onClearFiltersClick={
															onClearFiltersClick
														}
														filters={filterDataTabs}
													/>
												)}
											</Grid>
										</Box>
									</form>
								)}
							</div>
						) : null}
					</AppBar>
				</Grid>
				<Grid item>{children}</Grid>
			</Grid>
		</div>
	);
};

export default ModuleToolbar;
