import {
	Grid,
	Typography,
	TextField,
	Box,
	Card,
	CardContent,
	makeStyles,
} from "@material-ui/core";
import React from "react";
import CustomButton from "../custom-button";
import CustomLink from "../custom-link";
const useStyles = makeStyles((theme) => ({
	cardContainer: {
		minWidth: 450,
	},
}));

const Form = ({ title, helpText = "", formData, links }) => {
	const classes = useStyles();
	return (
		<Card className={classes.cardContainer}>
			<CardContent>
				<Grid container direction="column" spacing={4}>
					<Grid item>
						<Typography variant="h6" color="textPrimary">
							<Box fontWeight="fontWeightBold">{title}</Box>
						</Typography>
						<Typography variant="body2" color="textSecondary">
							{helpText}
						</Typography>
					</Grid>
					{formData.fields.map((_field, index) => (
						<Grid item key={index}>
							<TextField
								label={_field.label}
								placeholder={_field.placeholder}
								variant="outlined"
								size="small"
								type={_field.type}
								fullWidth
							/>
						</Grid>
					))}

					{formData.link ? (
						<Grid item align="right">
							<CustomLink
								linkText={formData.link.label}
								linkUrl={formData.link.url}
							/>
						</Grid>
					) : null}
					<Grid item align="center">
						<CustomButton label={formData.button.label} />
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
};

export default Form;
