import {
	Grid,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	makeStyles,
	Typography,
	useTheme,
	TableRow,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import MainLayout from "../../components/main-layout";
import ModuleToolbar from "../../components/module-toolbar";
import SectionContainerLayout from "../../components/section-container-layout";
import colors from "../../constants/colors";
import axios from "axios";
import { useHistory } from "react-router";
import moment from "moment";
import { dataExists } from "../../helpers/common";
import { jsonToCSV } from "react-papaparse";
import { saveAs } from "file-saver";

const useStyles = makeStyles((theme) => ({
	tableHeader: {
		backgroundColor: colors.darkBlue,
	},
}));
const UserLogsPage = () => {
	const classes = useStyles();
	const theme = useTheme();
	const history = useHistory();
	const [userLogs, setUserLogs] = useState();
	const [isDataLoaded, setIsDataLoaded] = useState(false);
	const [filterData, setFilterData] = useState({});
	const [csvFile, setCsvFile] = useState();
	const [selected, setSelected] = useState([]);

	const convertDate = (date) => {
		const newDate = moment(date).format("DD MMM YYYY hh:mm A");

		return newDate;
	};

	useEffect(() => {
		try {
			axios.get("/user/user-logs").then((_response) => {
				if (_response.status === 200) {
					let usersArray = [];
					let actionsArray = [];
					let sectionsArray = [];
					_response.data.forEach((_data) => {
						if (
							!dataExists(
								_data.user.firstName +
									" " +
									_data.user.lastName,
								usersArray,
							)
						) {
							usersArray.push({
								name:
									_data.user.firstName +
									" " +
									_data.user.lastName,
								value:
									_data.user.firstName +
									" " +
									_data.user.lastName,
							});
						}

						if (!dataExists(_data.action, actionsArray)) {
							actionsArray.push({
								name: _data.action,
								value: _data.action,
							});
						}

						if (!dataExists(_data.section, sectionsArray)) {
							sectionsArray.push({
								name: _data.section,
								value: _data.section,
							});
						}
					});

					let _csvDataArray = [];
					_response.data.forEach((_data) => {
						_csvDataArray.push({
							Name:
								_data.user.firstName +
								" " +
								_data.user.lastName,
							Section: _data.section,
							ID: _data.code,
							Action: _data.action,
							Date: convertDate(_data.createdAt),
						});
					});

					const userLog_CSV = jsonToCSV(_csvDataArray);

					let _csvFile = new Blob([userLog_CSV], {
						type: "text/csv;charset=utf-8",
					});

					setCsvFile(_csvFile);

					// setCsvFile()
					let _userFilter = {
						options: usersArray,
						label: "Name",
						idName: "name",
					};

					let _actionFilter = {
						options: actionsArray,
						label: "Action",
						idName: "Action",
					};

					let _sectionFilter = {
						options: sectionsArray,
						label: "Section",
						idName: "Section",
					};

					let allFilters = {
						_userFilter,
						_actionFilter,
						_sectionFilter,
					};

					setFilterData(allFilters);
					setUserLogs(_response.data);
					setIsDataLoaded(true);
				}

				if (_response.status === 404) {
					history.push("/login");
				}
			});
		} catch (err) {}
	}, [history]);

	const handleFilterData = (value, key) => {
		try {
			axios.get("/user/user-logs").then((_response) => {
				if (_response.status === 200) {
					// _response.data.forEach((_data) => {
					//   if (_data[key.toLowerCase()] === value) {
					//     filteredLogs.push(...filteredLogs, _data);
					//   }
					// });

					setUserLogs(
						_response.data.filter(
							(_data) => _data[key.toLowerCase()] === value,
						),
					);
					if (!value) {
						setUserLogs(_response.data);
					}
					setIsDataLoaded(true);
				}

				if (_response.status === 404) {
					history.push("/login");
				}
			});
		} catch (err) {
			console.log(err);
		}
	};
	const handleSelectAllChange = (e) => {
		if (e.target.checked) {
			const newSelected = userLogs.map((n) => n.code);
			setSelected(newSelected);
			return;
		}
		setSelected([]);
	};

	const handleSearch = (e) => {
		try {
			const { value } = e.target;

			axios
				.post("/user/user-logs/search", { searchQuery: value })
				.then((_response) => {
					if (_response.status === 200) {
						const { data } = _response;

						if (data && data.length > 0) {
							setUserLogs(data);
						} else {
							setUserLogs([]);
						}
					}
				})
				.catch((err) => console.log(err));
		} catch (err) {
			console.log(err);
		}
	};

	return (
		<MainLayout>
			<SectionContainerLayout title="USERS LOG">
				{isDataLoaded ? (
					<Grid container>
						<Grid item xs={12}>
							<ModuleToolbar
								gridViewIcon={false}
								listViewIcon={false}
								filterIcon
								addIcon={false}
								showCustomFilter
								filterData={filterData}
								handleFilterData={handleFilterData}
								exportIcon
								handleExport={() =>
									saveAs(csvFile, "userlog.csv")
								}
								totalSelected={selected.length}
								totalData={userLogs.length}
								onSelectAllClick={handleSelectAllChange}
								changeStatusAction={false}
								hideDeleteIcon
								handleSearchChange={handleSearch}
							/>
						</Grid>
						<Grid item xs={12}>
							<TableContainer component={Paper}>
								<Table>
									<TableHead className={classes.tableHeader}>
										<TableRow>
											<TableCell align="center">
												<Typography
													variant="body2"
													style={{
														color: theme.palette
															.common.white,
													}}
												>
													Name
												</Typography>
											</TableCell>
											<TableCell align="center">
												<Typography
													variant="body2"
													style={{
														color: theme.palette
															.common.white,
													}}
												>
													Section
												</Typography>
											</TableCell>
											<TableCell align="center">
												<Typography
													variant="body2"
													style={{
														color: theme.palette
															.common.white,
													}}
												>
													Card ID
												</Typography>
											</TableCell>
											<TableCell align="center">
												<Typography
													variant="body2"
													style={{
														color: theme.palette
															.common.white,
													}}
												>
													Action
												</Typography>
											</TableCell>
											<TableCell align="center">
												<Typography
													variant="body2"
													style={{
														color: theme.palette
															.common.white,
													}}
												>
													Date and Time
												</Typography>
											</TableCell>
										</TableRow>
									</TableHead>
									{userLogs.length > 0 ? (
										<TableBody>
											{userLogs.map((_userLog, index) => (
												<TableRow
													key={index}
													align="center"
													scope="row"
												>
													<TableCell>
														<Typography
															variant="body2"
															color="textPrimary"
															align="center"
														>
															{_userLog.user
																.firstName +
																" " +
																_userLog.user
																	.lastName}
														</Typography>
													</TableCell>
													<TableCell>
														<Typography
															variant="body2"
															color="textPrimary"
															align="center"
														>
															{_userLog.section}
														</Typography>
													</TableCell>
													<TableCell>
														<Typography
															variant="body2"
															color="textPrimary"
															align="center"
														>
															{_userLog.cardCode}
														</Typography>
													</TableCell>
													<TableCell>
														<Typography
															variant="body2"
															color="textPrimary"
															align="center"
														>
															{_userLog.action}
														</Typography>
													</TableCell>
													<TableCell>
														<Typography
															variant="body2"
															color="textPrimary"
															align="center"
														>
															{convertDate(
																_userLog.createdAt,
															)}
														</Typography>
													</TableCell>
												</TableRow>
											))}
										</TableBody>
									) : (
										<Typography
											variant="body1"
											color="textPrimary"
											align="center"
										>
											There are no results
										</Typography>
									)}
								</Table>
							</TableContainer>
						</Grid>
					</Grid>
				) : (
					<Typography
						variant="body1"
						color="textPrimary"
						align="center"
					>
						There are no results
					</Typography>
				)}
			</SectionContainerLayout>
		</MainLayout>
	);
};
export default UserLogsPage;
