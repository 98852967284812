import * as yup from "yup";

export const addNewSpeaker = yup.object().shape({
	salutationId: yup.number().required("Salutation is required"),
	firstName: yup.string().required("First name is required"),
	lastName: yup.string().required("Last name is required"),
	designation: yup.string().required("Designation is required"),
	organization: yup.string().required("Organization is required"),
	year: yup.string().required("Year is required"),
});

export const editSpeaker = yup.object().shape({
	salutationId: yup.number().required("Salutation is required"),
	firstName: yup.string().required("First name is required"),
	lastName: yup.string().required("Last name is required"),
	designation: yup.string().required("Designation is required"),
	organization: yup.string().required("Organization is required"),
	year: yup.string().required("Year is required"),
});
