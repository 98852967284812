import {
	Button,
	FormControl,
	Grid,
	InputLabel,
	Typography,
	Select,
	TextField,
	Box,
	MenuItem,
} from "@material-ui/core";
import React, { useState } from "react";

import MainLayout from "../../components/main-layout";
import SectionContainerLayout from "../../components/section-container-layout";
import axios from "axios";
import { useHistory } from "react-router";
import { addNewUser } from "../../validations/user-validator";
const AddUserPage = () => {
	const [jsonFormData, setJsonFormData] = useState();
	const [validationErrors, setValidationErrors] = useState({});
	const [responseErrors, setResponseErrors] = useState({});
	const history = useHistory();
	const handleFormChange = (e) => {
		setJsonFormData({
			...jsonFormData,
			[e.target.id || e.target.name]: e.target.value,
		});
	};

	const handleSubmit = () => {
		try {
			let errorMessages = [];

			addNewUser
				.validate(jsonFormData, { abortEarly: false })
				.then(() => {
					axios
						.post("/user", jsonFormData)
						.then((_response) => {
							if (_response.status === 200) {
								history.push("/users-accounts");
							} else if (_response.status === 404) {
								history.push("/login");
							}
						})
						.catch((err) => setResponseErrors(err.response.data));
				})
				.catch((err) => {
					err.inner.forEach((e) => {
						errorMessages[e.path] = e.message;
					});
					setValidationErrors(errorMessages);
				});
		} catch (err) {
			console.log(err);
		}
	};
	return (
		<MainLayout>
			<SectionContainerLayout title="ADD USER">
				<Grid container spacing={3}>
					<Grid item xs={12} md={4}>
						<TextField
							name="firstName"
							onChange={handleFormChange}
							label="First Name"
							required
							fullWidth
							variant="outlined"
							error={validationErrors.firstName}
							helperText={validationErrors.firstName}
						/>
					</Grid>
					<Grid item xs={12} md={4}>
						<TextField
							name="lastName"
							onChange={handleFormChange}
							label="Last Name"
							fullWidth
							required
							variant="outlined"
							error={validationErrors.lastName}
							helperText={validationErrors.lastName}
						/>
					</Grid>
					<Grid item xs={12} md={4}>
						<TextField
							name="email"
							onChange={handleFormChange}
							label="Email"
							required
							fullWidth
							variant="outlined"
							error={validationErrors.email}
							helperText={validationErrors.email}
						/>
					</Grid>
					<Grid item xs={12} md={4}>
						<FormControl fullWidth variant="outlined">
							<InputLabel id="account-status">
								Account Status *
							</InputLabel>
							<Select
								name="status"
								labelId="account-status"
								required
								label="Account Status"
								onChange={handleFormChange}
							>
								<MenuItem value="Active">Active</MenuItem>
								<MenuItem value="Inactive">Inactive</MenuItem>
							</Select>
						</FormControl>
					</Grid>
					<Grid item xs={12} md={4}>
						<FormControl fullWidth variant="outlined">
							<InputLabel id="account-type">
								Account Type *
							</InputLabel>
							<Select
								labelId="account-type"
								label="Account Type"
								name="userAccountTypeId"
								required
								onChange={handleFormChange}
							>
								<MenuItem value={1}>Admin</MenuItem>
								<MenuItem value={2}>Editor</MenuItem>
								<MenuItem value={3}>Viewer</MenuItem>
							</Select>
						</FormControl>
					</Grid>
				</Grid>
				<Grid container>
					<Grid item xs={12} align="right">
						<Box clone mr={2}>
							<Button
								onClick={() => history.push("/users-accounts")}
								style={{ textTransform: "capitalize" }}
							>
								Cancel
							</Button>
						</Box>
						<Button
							onClick={handleSubmit}
							variant="contained"
							color="primary"
							style={{ textTransform: "capitalize" }}
						>
							Submit
						</Button>
					</Grid>
				</Grid>
				{responseErrors ? (
					<Grid container>
						<Grid item xs={12}>
							<Typography variant="body2" color="error">
								<Box fontWeight="fontWeightBold">
									{responseErrors.error}
								</Box>
							</Typography>
						</Grid>
					</Grid>
				) : null}
			</SectionContainerLayout>
		</MainLayout>
	);
};

export default AddUserPage;
