import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";

function a11yProps(index) {
	return {
		id: `scrollable-force-tab-${index}`,
		"aria-controls": `scrollable-force-tabpanel-${index}`,
	};
}

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		width: "100%",
		backgroundColor: theme.palette.background.paper,
	},
}));

export default function ScrollableTabsButtonForce() {
	const history = useHistory();
	const location = useLocation();
	const classes = useStyles();
	const [value, setValue] = useState(0);

	useEffect(() => {
		const path = location.pathname.replace("/agendas/", "");
		switch (path) {
			case "agenda":
				setValue(0);
				break;

			case "location":
				setValue(1);
				break;

			case "topic":
				setValue(2);
				break;

			case "group":
				setValue(3);
				break;

			default:
				break;
		}
	}, [location.pathname]);

	const goTo = (link) => {
		history.push(`/agendas/${link}`);
	};
	const handleChange = (event, newValue) => {
		setValue(newValue);
		switch (newValue) {
			case 0:
				goTo("agenda");
				break;

			case 1:
				goTo("location");
				break;

			case 2:
				goTo("topic");
				break;

			case 3:
				goTo("group");
				break;

			default:
				break;
		}
	};

	return (
		<div className={classes.root}>
			<AppBar position="static" color="default">
				<Tabs
					value={value}
					onChange={handleChange}
					variant="scrollable"
					scrollButtons="on"
					indicatorColor="primary"
					textColor="primary"
					aria-label="scrollable force tabs example"
				>
					<Tab label="Agenda" {...a11yProps(0)}></Tab>
					<Tab label="Location" {...a11yProps(1)} />
					<Tab label="Topic" {...a11yProps(2)} />
					<Tab label="Group" {...a11yProps(3)} />
				</Tabs>
			</AppBar>
		</div>
	);
}
