import React from "react";
import { Autocomplete } from "@material-ui/lab";
import { FormControl, FormHelperText, TextField, Box } from "@material-ui/core";

const CustomAutocomplete = ({
	value,
	onChange,
	options,
	label,
	isMultiple = false,
	validationMessage,
}) => {
	return (
		<FormControl fullWidth error={validationMessage ? true : false}>
			<Autocomplete
				options={options}
				getOptionLabel={(option) =>
					option.name
						? option.name
						: option.firstName
						? option.firstName + " " + option.lastName
						: "dummy dummy dummy dummy dummy"
				}
				onChange={onChange}
				multiple={isMultiple}
				getOptionSelected={(option, value) =>
					option.code === value.code
				}
				value={value}
				renderInput={(params) => (
					<TextField
						error={validationMessage ? true : false}
						{...params}
						label={label}
						variant="outlined"
					/>
				)}
			/>
			<Box ml={2}>
				<FormHelperText>
					{validationMessage ? validationMessage : ""}
				</FormHelperText>
			</Box>
		</FormControl>
	);
};

export default CustomAutocomplete;
