import React, { useEffect, useState } from "react";
import SectionContainerLayout from "../../components/section-container-layout";
import MainLayout from "../../components/main-layout";
import ModuleToolbar from "../../components/module-toolbar";
import Clamp from "react-multiline-clamp";
import {
	Grid,
	TableContainer,
	Paper,
	Table,
	TableHead,
	TableBody,
	makeStyles,
	TableRow,
	TableCell,
	Typography,
	useTheme,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Button,
	Checkbox,
	Box,
} from "@material-ui/core";
import axios from "axios";
import CardImageTitleDescription from "../../components/card-image-title-description";
import moment from "moment";
import { useHistory } from "react-router-dom";
import colors from "../../constants/colors";
import { dataExists } from "../../helpers/common";
import { useDebounce } from "use-debounce";
import ToolbarLegend from "../../components/toolbar-legend";
import { useStoreState } from "easy-peasy";
const useStyles = makeStyles((theme) => ({
	tableHeader: {
		backgroundColor: colors.darkBlue,
	},
	rowHover: {
		"&:hover": {
			cursor: "pointer",
		},
	},
}));
const KnowledgeHubPage = () => {
	const [knowledgeHubData, setKnowledgeHubData] = useState([]);
	const [isGridView, setIsGridView] = useState(true);
	const [isListView, setListView] = useState(false);
	const [selected, setSelected] = useState([]);
	const [filterData, setFilterData] = useState({});
	const [isDataLoaded, setIsDataLoaded] = useState(false);
	const [isDialogOpen, setIsDialogOpen] = useState(false);
	const history = useHistory();
	const currentUser = useStoreState((state) => state.user.currentUser);

	const theme = useTheme();
	const classes = useStyles();
	useEffect(() => {
		try {
			const token = localStorage.getItem("token");

			if (!token) {
				history.push("/login");
			}
			fetchList();
		} catch (err) {
			console.log(err);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [setKnowledgeHubData]);

	const fetchList = () => {
		axios
			.get("/knowledge-hub")
			.then((_response) => {
				if (_response.status === 200) {
					let yearArray = [];
					let typeArray = [];
					_response.data.forEach((_data) => {
						if (!dataExists(_data.type, typeArray)) {
							typeArray.push({
								name: _data.type,
								value: _data.type,
							});
						}

						if (!dataExists(_data.year, yearArray)) {
							yearArray.push({
								name: _data.year,
								value: _data.year,
							});
						}
					});

					let _yearFilter = {
						options: yearArray,
						label: "Year",
						idName: "year",
					};
					let _typeFilter = {
						options: typeArray,
						label: "Type",
						idName: "type",
					};

					let allFilters = {
						_yearFilter,
						_typeFilter,
					};

					setFilterData(allFilters);
					setKnowledgeHubData(_response.data);
					setIsDataLoaded(true);
				} else if (_response.status === 404) {
					history.push("/login");
				}
			})
			.catch((err) => console.log(err));
	};

	const toggleGridView = () => {
		setIsGridView(true);
		setListView(false);
	};

	const toggleListView = () => {
		setIsGridView(false);
		setListView(true);
	};

	const convertDate = (date) => {
		const newDate = moment(date).format("DD MMMM YYYY");

		return newDate;
	};

	const handleClick = (knowledgeHub) => {
		if (currentUser.user_account_type.type !== "Viewer") {
			history.push({
				pathname: "/knowledge-hub/edit",
				state: {
					knowledgeHub,
				},
			});
		}
	};

	const isSelected = (data) => selected.indexOf(data) !== -1;
	const handleSelectAllChange = (e) => {
		if (e.target.checked) {
			const newSelected = knowledgeHubData.map((n) => n.code);
			setSelected(newSelected);
			return;
		}
		setSelected([]);
	};
	const handleItemSelectChange = (e, data) => {
		const selectedIndex = selected.indexOf(data);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, data);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1),
			);
		}

		setSelected(newSelected);
	};
	const handleDeleteSelected = (e) => {
		axios
			.delete("/knowledge-hub", { data: { code: selected } })
			.then((_response) => {
				setSelected([]);
				fetchList();
			})
			.catch((err) => console.log(err));
	};

	const handleEditSelected = (e) => {
		setIsDialogOpen(true);
		try {
			const requestBody = {
				codes: selected,
				status: e,
			};

			axios
				.put("/knowledge-hub/status", requestBody)
				.then((_response) => {
					if (_response.status === 200) {
						setSelected([]);
						fetchList();
					}
				})
				.catch((err) => console.log(err));
		} catch (err) {
			console.log(err);
		}
	};

	const [filters, setFilters] = useState({});
	const [search, setSearch] = useState("");
	const [searchText] = useDebounce(search, 1000);
	useEffect(() => {
		axios
			.post("/knowledge-hub/search", {
				filters: { ...filters, search: searchText },
			})
			.then((_response) => {
				setKnowledgeHubData(_response.data);
			})
			.catch((err) => console.log(err));
	}, [searchText, filters]);
	const handleSearch = (e) => {
		try {
			setSearch(e.target.value);
			// const searchQuery = {
			//   title: e.target.value,
			//   article: e.target.value,
			//   date: e.target.value,
			// };

			// axios
			//   .post("/knowledge-hub/search", searchQuery)
			//   .then((_response) => {
			//     if (_response.status === 200) {
			//       setKnowledgeHubData(_response.data);
			//     }
			//   })
			//   .catch((err) => console.log(err));
		} catch (err) {
			console.log(err);
		}
	};
	const handleFilterData = (value, key) => {
		try {
			setFilters({ ...filters, [key]: value.toString() });
			// let filteredLogs = [];

			// axios.get("/knowledge-hub").then((_response) => {
			//   if (_response.status === 200) {
			//     _response.data.forEach((_data) => {
			//       if (_data[key.toLowerCase()] === value) {
			//         filteredLogs.push(...filteredLogs, _data);
			//       }
			//     });

			//     setKnowledgeHubData(filteredLogs);
			//     if (!value) {
			//       setKnowledgeHubData(_response.data);
			//     }
			//     setIsDataLoaded(true);
			//   }

			//   if (_response.status === 404) {
			//     history.push("/login");
			//   }
			// });
		} catch (err) {
			console.log(err);
		}
	};

	const handleDialogClose = () => setIsDialogOpen(false);

	return (
		<MainLayout>
			<SectionContainerLayout title="KNOWLEDGE HUB">
				{isDataLoaded ? (
					<Grid container>
						<Grid item xs={12}>
							<ToolbarLegend />
						</Grid>
						<Grid item xs={12}>
							<ModuleToolbar
								totalSelected={selected.length}
								totalData={knowledgeHubData.length}
								addModuleUrl="/knowledge-hub/add"
								onSelectAllClick={handleSelectAllChange}
								onDeleteSelected={handleDeleteSelected}
								handleGridView={toggleGridView}
								handleSearchChange={handleSearch}
								handleListView={toggleListView}
								onEditSelected={handleEditSelected}
								filterIcon
								showCustomFilter
								filterData={filterData}
								handleFilterData={handleFilterData}
							/>
						</Grid>
						<Grid item container xs={12} spacing={3}>
							{isGridView && knowledgeHubData.length > 0 ? (
								knowledgeHubData.map((_knowledgeHub, index) => (
									<Grid
										item
										xs={12}
										md={4}
										lg={3}
										key={index}
									>
										<CardImageTitleDescription
											onClick={() =>
												handleClick(_knowledgeHub)
											}
											image={
												_knowledgeHub.file[0].type !==
												"application/pdf"
													? _knowledgeHub.file[0].code
													: _knowledgeHub.file[1].code
											}
											title={_knowledgeHub.title}
											description={
												_knowledgeHub.description
											}
											status={_knowledgeHub.status}
											isChecked={isSelected(
												_knowledgeHub.code,
											)}
											onItemSelect={(e) =>
												handleItemSelectChange(
													e,
													_knowledgeHub.code,
												)
											}
										/>
									</Grid>
								))
							) : isListView && knowledgeHubData.length > 0 ? (
								<TableContainer component={Paper}>
									<Table>
										<TableHead
											className={classes.tableHeader}
										>
											<TableRow>
												{currentUser.user_account_type
													.type !== "Viewer" ? (
													<TableCell
														width={1}
													></TableCell>
												) : null}
												<TableCell align="center">
													<Typography
														variant="body2"
														style={{
															color: theme.palette
																.common.white,
														}}
													>
														Image
													</Typography>
												</TableCell>
												<TableCell align="center">
													<Typography
														variant="body2"
														style={{
															color: theme.palette
																.common.white,
														}}
													>
														Title
													</Typography>
												</TableCell>
												<TableCell align="center">
													<Typography
														variant="body2"
														style={{
															color: theme.palette
																.common.white,
														}}
													>
														Description
													</Typography>
												</TableCell>
												<TableCell align="center">
													<Typography
														variant="body2"
														style={{
															color: theme.palette
																.common.white,
														}}
													>
														Type
													</Typography>
												</TableCell>
												<TableCell align="center">
													<Typography
														variant="body2"
														style={{
															color: theme.palette
																.common.white,
														}}
													>
														Status
													</Typography>
												</TableCell>
												<TableCell align="center">
													<Typography
														variant="body2"
														style={{
															color: theme.palette
																.common.white,
														}}
													>
														Date
													</Typography>
												</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{knowledgeHubData.map(
												(_knowledgeHub, index) => (
													<Box
														clone
														className={
															classes.rowHover
														}
													>
														<TableRow
															key={index}
															scope="row"
															hover
														>
															{currentUser
																.user_account_type
																.type !==
															"Viewer" ? (
																<TableCell
																	width={1}
																>
																	<Checkbox
																		checked={isSelected(
																			_knowledgeHub.code,
																		)}
																		onClick={(
																			e,
																		) =>
																			handleItemSelectChange(
																				e,
																				_knowledgeHub.code,
																			)
																		}
																		inputProps={{
																			"aria-labelledby": 1,
																		}}
																	/>
																</TableCell>
															) : null}
															<TableCell
																width={50}
																onClick={() =>
																	handleClick(
																		_knowledgeHub,
																	)
																}
															>
																<img
																	src={`${process.env.REACT_APP_API_URL}/static/${_knowledgeHub.file[0].code}`}
																	alt=""
																	width="100%"
																/>
															</TableCell>
															<TableCell
																width={100}
																align="center"
																onClick={() =>
																	handleClick(
																		_knowledgeHub,
																	)
																}
															>
																<Typography
																	variant="body1"
																	color="textPrimary"
																>
																	{
																		_knowledgeHub.title
																	}
																</Typography>
															</TableCell>
															<TableCell
																width={100}
																align="center"
																onClick={() =>
																	handleClick(
																		_knowledgeHub,
																	)
																}
															>
																<Typography
																	variant="body1"
																	color="textPrimary"
																>
																	<Clamp
																		lines={
																			2
																		}
																	>
																		{
																			_knowledgeHub.description
																		}
																	</Clamp>
																</Typography>
															</TableCell>
															<TableCell
																width={100}
																align="center"
																onClick={() =>
																	handleClick(
																		_knowledgeHub,
																	)
																}
															>
																<Typography
																	variant="body1"
																	color="textPrimary"
																>
																	{_knowledgeHub.article
																		? "Article"
																		: "PDF"}
																</Typography>
															</TableCell>
															<TableCell
																width={100}
																align="center"
																onClick={() =>
																	handleClick(
																		_knowledgeHub,
																	)
																}
															>
																<Typography
																	variant="body1"
																	style={{
																		color:
																			_knowledgeHub.status ===
																			"published"
																				? colors.lightGreen
																				: _knowledgeHub.status ===
																				  "unpublished"
																				? colors.darkGray
																				: _knowledgeHub.status ===
																				  "scheduled"
																				? theme
																						.palette
																						.warning
																						.main
																				: null,
																	}}
																>
																	{_knowledgeHub.status
																		.charAt(
																			0,
																		)
																		.toUpperCase() +
																		_knowledgeHub.status.slice(
																			1,
																		)}
																</Typography>
															</TableCell>
															<TableCell
																width={100}
																align="center"
																onClick={() =>
																	handleClick(
																		_knowledgeHub,
																	)
																}
															>
																<Typography
																	variant="body1"
																	color="textPrimary"
																>
																	{convertDate(
																		_knowledgeHub.date,
																	)}
																</Typography>
															</TableCell>
														</TableRow>
													</Box>
												),
											)}
										</TableBody>
									</Table>
								</TableContainer>
							) : (
								<Typography
									variant="body1"
									color="textPrimary"
									align="center"
								>
									There are no results
								</Typography>
							)}
						</Grid>
					</Grid>
				) : null}
				<Dialog open={isDialogOpen} onClose={handleDialogClose}>
					<DialogTitle>Update Successful</DialogTitle>
					<DialogContent>
						<DialogContentText>
							Knowledge Hub status has been successfully updated
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button
							onClick={handleDialogClose}
							color="primary"
							variant="contained"
						>
							Ok
						</Button>
					</DialogActions>
				</Dialog>
			</SectionContainerLayout>
		</MainLayout>
	);
};

export default KnowledgeHubPage;
