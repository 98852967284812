import {
	Grid,
	TextField,
	Box,
	Button,
	FormHelperText,
	Typography,
	FormControlLabel,
	Checkbox,
	LinearProgress,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import MainLayout from "../../components/main-layout";
import SectionContainerLayout from "../../components/section-container-layout";
// import { Autocomplete } from "@material-ui/lab";
import axios from "axios";
// import { createFilterOptions } from "@material-ui/lab/Autocomplete";
import { DropzoneArea } from "material-ui-dropzone";
import CustomTextEditor from "../../components/custom-text-editor";
// import { FileCopySharp } from "@material-ui/icons";
import { useHistory, useLocation } from "react-router";
import moment from "moment";

const EditDigitalSeriesPage = () => {
	// const [categoryValue, setCategoryValue] = useState(null);
	// const [categories, setCategories] = useState({});
	const [datePicked] = useState(new Date());
	const [jsonFormData, setJsonFormData] = useState();
	const [videoFile, setVideoFile] = useState();
	const [imageFile, setImageFile] = useState();
	const [isOnHomepage, setIsOnHomepage] = useState(false);
	const [currentDigitalSeries, setCurrentDigitalSeries] = useState();
	const [isDataLoaded, setIsDataLoaded] = useState(false);
	const history = useHistory();
	const location = useLocation();
	const [showProgress, setShowProgress] = useState(false);
	const [progress, setProgress] = useState(0);
	const [videoName, setVideoName] = useState("");
	// const filter = createFilterOptions();
	const [existingFileCodes, setExistingFileCodes] = useState([]);
	const [initVideoFiles, setInitVideoFiles] = useState([]);
	const [initVideoCode, setInitVideoCode] = useState(null);
	const [initImageFiles, setInitImageFiles] = useState([]);
	const [initImageCode, setInitImageCode] = useState(null);

	useEffect(() => {
		const { digitalSeries } = location.state;
		setCurrentDigitalSeries(digitalSeries);
		setIsDataLoaded(true);
		setJsonFormData({
			...jsonFormData,
			date: digitalSeries.date,
			title: digitalSeries.title,
		});

		if (digitalSeries.file && digitalSeries.file.length > 0) {
			const _existingFileCodes = [];
			digitalSeries.file.map((t) => _existingFileCodes.push(t.code));
			setExistingFileCodes(_existingFileCodes);
			const _imgFile = digitalSeries.file.filter(
				(f) => f.type.includes("image") && f.code !== null,
			);
			if (_imgFile.length > 0) {
				setInitImageCode(_imgFile[0].code);
				setInitImageFiles([
					`${process.env.REACT_APP_API_URL}/static/${_imgFile[0].code}`,
				]);
			}
			setIsOnHomepage(digitalSeries.isOnHomepage);

			const _videoFile = digitalSeries.file.filter(
				(f) => f.type.includes("video") && f.code !== null,
			);
			if (_videoFile.length > 0) {
				// setVideoFile(_videoFile[0]);
				setVideoName(_videoFile[0].name);
				setInitVideoCode(_videoFile[0].code);
				setInitVideoFiles([
					`${process.env.REACT_APP_API_URL}/static/${_videoFile[0].code}`,
				]);
			}
		}

		// if (digitalSeries.category && digitalSeries.category.name) {
		//   setCategoryValue({ name: digitalSeries.category.name });
		// }
		// // setCategoryValue(location.state.digitalSeries.category.name);

		// axios
		//   .get("/utility/categories")
		//   .then((_response) => {
		//     if (_response) {
		//       setCategories(_response.data);
		//     }
		//   })
		//   .catch((err) => console.log(err));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// const handleCategoryChange = (event, newValue) => {
	//   if (typeof newValue === "string") {
	//     setCategoryValue({
	//       name: newValue,
	//     });
	//     setJsonFormData({
	//       ...jsonFormData,
	//       categoryName: newValue,
	//     });
	//   } else if (newValue && newValue.inputValue) {
	//     // Create a new value from the user input
	//     setCategoryValue({
	//       name: newValue.inputValue,
	//     });
	//     setJsonFormData({
	//       ...jsonFormData,
	//       categoryName: newValue.inputValue,
	//     });
	//   } else {
	//     setCategoryValue(newValue);
	//     setJsonFormData({
	//       ...jsonFormData,
	//       categoryName: newValue,
	//     });
	//   }
	// };

	// const filterOptions = (options, params) => {
	//   const filtered = filter(options, params);

	//   // Suggest the creation of a new value
	//   if (params.inputValue !== "") {
	//     filtered.push({
	//       inputValue: params.inputValue,
	//       name: `Add "${params.inputValue}"`,
	//     });
	//   }
	//   return filtered;
	// };

	// const getOptionLabel = (option) => {
	//   // Value selected with enter, right from the input
	//   if (typeof option === "string") {
	//     return option;
	//   }
	//   // Add "xxx" option created dynamically
	//   if (option.inputValue) {
	//     return option.inputValue;
	//   }
	//   // Regular option
	//   return option.name;
	// };

	// const renderInput = (params) => (
	//   <TextField {...params} label="Category" variant="outlined" />
	// );

	const handleFormChange = (e) => {
		if (e.target.id && e.target.id === "isOnHomepage") {
			setIsOnHomepage(e.target.checked);
		}

		setJsonFormData({
			...jsonFormData,
			[e.target.id || e.target.name]: e.target.value || e.target.checked,
		});
	};
	const handleEditorChange = (event, editor) => {
		const data = editor.getData();
		setJsonFormData({
			...jsonFormData,
			details: data,
		});
	};

	const handleImageFile = (file) => {
		setImageFile(file[0]);
	};

	const handleVideoFile = (file) => {
		setVideoFile(file[0]);
		setVideoName(file[0].name);
	};

	const handleSubmit = () => {
		let finalData = {};
		try {
			for (let i in currentDigitalSeries) {
				if (
					jsonFormData[i] === "undefined" ||
					jsonFormData[i] === undefined
				) {
					finalData[i] = currentDigitalSeries[i];
				}
			}

			// console.log("initVideoFiles : ", initVideoFiles);

			if (!imageFile || !initVideoFiles) return;

			let formData = new FormData();
			if (!existingFileCodes.includes(imageFile.name)) {
				formData.append("imageFile", imageFile);
				formData.append("imageFileCode", initImageCode);
			}

			// console.log("videoFile : ", videoFile);
			if (videoFile && !existingFileCodes.includes(videoFile.name)) {
				formData.append("videoFile", videoFile);
				formData.append("videoFileCode", initVideoCode);
			}

			formData.append("code", currentDigitalSeries.code);
			let data = { ...jsonFormData, ...finalData };

			for (let i in data) {
				formData.append(i, data[i]);
			}

			// for (var pair of formData.entries()) {
			//   console.log(pair[0] + ": " + pair[1]);
			// }

			axios
				.put("/digital-series", formData, {
					headers: { "Content-Type": "multipart/form-data" },
					onUploadProgress: (data) => {
						setShowProgress(true);
						setProgress(
							Math.round((100 * data.loaded) / data.total),
						);
					},
				})
				.then((_response) => {
					if (_response.status === 200) {
						history.push("/digital-series");
					} else {
						console.log("error");
					}
				})
				.catch((err) => console.log(err));
		} catch (err) {
			console.log(err);
		}
	};

	return (
		<MainLayout>
			{isDataLoaded ? (
				<SectionContainerLayout
					title={`EDIT DIGITAL SERIES: ${currentDigitalSeries.code}`}
				>
					<Grid container spacing={3}>
						<Grid item container xs={12} md={6} spacing={3}>
							<Grid item xs={12} md={6}>
								{/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="date"
                    value={datePicked}
                    required
                    label="Date"
                    name="date"
                    autoOk
                    //   error={validationErrors.date}
                    //   helperText={validationErrors.date}
                    onChange={handleDatePicker("date")}
                  />
                </MuiPickersUtilsProvider> */}
								<TextField
									id="date"
									label="Date"
									type="date"
									defaultValue={moment(
										currentDigitalSeries.date,
									).format("YYYY-MM-DD")}
									onChange={handleFormChange}
									// error={validationErrors.date}
									// helperText={validationErrors.date}
									placeholder={datePicked}
									variant="outlined"
									fullWidth
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<TextField
									label="Title"
									name="title"
									variant="outlined"
									required
									placeholder={
										currentDigitalSeries.title
											? ""
											: "Title"
									}
									defaultValue={currentDigitalSeries.title}
									onChange={handleFormChange}
									fullWidth
								/>
							</Grid>
							{/* <Grid item xs={12} md={6}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel>Tags</InputLabel>
                  <Select name="tags" onChange={handleFormChange}>
                    <MenuItem value={"Tag"}>Tag 1</MenuItem>
                    <MenuItem value={"Tag"}>Tag 2</MenuItem>
                    <MenuItem value={"Tag"}>Tag 3</MenuItem>
                    <MenuItem value={"Tag"}>Tag 4</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  value={categoryValue}
                  onChange={handleCategoryChange}
                  filterOptions={filterOptions}
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  options={categories}
                  getOptionLabel={getOptionLabel}
                  renderOption={(option) => option.name}
                  freeSolo
                  renderInput={renderInput}
                />
              </Grid> */}
							<Grid item xs={12}>
								<TextField
									multiline
									onChange={handleFormChange}
									rows={4}
									required
									variant="outlined"
									defaultValue={
										currentDigitalSeries.description
									}
									placeholder={
										currentDigitalSeries.description
											? ""
											: "Description"
									}
									fullWidth
									name="description"
									label="Description"
								/>
							</Grid>
						</Grid>
						<Grid item container xs={12} md={6}>
							<DropzoneArea
								dropzoneText="Drag and drop or click here to upload video thumbnail *"
								acceptedFiles={["image/*"]}
								onChange={(file) => handleImageFile(file)}
								filesLimit={1}
								initialFiles={initImageFiles}
							/>
							<FormHelperText>
								Width: 500px, Height: 300px
							</FormHelperText>
						</Grid>
						<Grid item container xs={12} spacing={3}>
							<Grid item xs={12}>
								{/* <DropzoneArea
                  dropzoneText="Drag and drop or click here to upload video *"
                  acceptedFiles={["video/mp4"]}
                  maxFileSize={2147483648}
                  onChange={(file) => handleVideoFile(file)}
                  filesLimit={1}
                  initialFiles={initVideoFiles}
                /> */}

								<label htmlFor="btn-upload">
									<input
										id="btn-upload"
										accept="video/mp4,video/x-m4v,video/*"
										name="videoFile"
										style={{ display: "none" }}
										type="file"
										onChange={(event) =>
											handleVideoFile(event.target.files)
										}
									/>
									<Button
										className="btn-choose"
										variant="outlined"
										component="span"
									>
										Upload a Video
									</Button>
								</label>
								<Box my={3}>{videoName ? videoName : null}</Box>
								<FormHelperText>
									Video max size: 2GB
								</FormHelperText>
							</Grid>
							<Grid item xs={12}>
								<Typography variant="body1" color="textPrimary">
									Details *
								</Typography>
								<CustomTextEditor
									data={currentDigitalSeries.details}
									onChange={handleEditorChange}
								/>
							</Grid>
						</Grid>
						<Box clone pt={2}>
							<Grid item container xs={12} align="right">
								<Grid item xs={12} md={6} align="left">
									<FormControlLabel
										control={
											<Checkbox
												id="isOnHomepage"
												onChange={handleFormChange}
												checked={isOnHomepage}
											/>
										}
										label="Add to homepage"
									/>
								</Grid>
								<Grid item xs={12} md={6}>
									<Button
										onClick={() =>
											history.push("/digital-series")
										}
										style={{ textTransform: "capitalize" }}
									>
										Cancel
									</Button>
									<Button
										variant="contained"
										onClick={handleSubmit}
										color="primary"
										style={{ textTransform: "capitalize" }}
									>
										Submit
									</Button>
								</Grid>
							</Grid>
						</Box>
						<Box clone pt={5}>
							<Grid item xs={12}>
								{showProgress ? (
									<>
										<LinearProgress
											variant="determinate"
											value={progress}
										/>
										<Typography>{progress}%</Typography>
									</>
								) : null}
							</Grid>
						</Box>
					</Grid>
				</SectionContainerLayout>
			) : null}
		</MainLayout>
	);
};

export default EditDigitalSeriesPage;
