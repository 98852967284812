import {
	Grid,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	makeStyles,
	Typography,
	useTheme,
	TableRow,
	Checkbox,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Button,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import MainLayout from "../../components/main-layout";
import ModuleToolbar from "../../components/module-toolbar";
import SectionContainerLayout from "../../components/section-container-layout";
import colors from "../../constants/colors";
import axios from "axios";
import { useHistory } from "react-router";
const useStyles = makeStyles((theme) => ({
	tableHeader: {
		backgroundColor: colors.darkBlue,
	},
}));
const UserAccountsPage = () => {
	const classes = useStyles();
	const theme = useTheme();
	const history = useHistory();

	const [selected, setSelected] = useState([]);
	const [userAccounts, setUserAccounts] = useState();
	const [isDataLoaded, setIsDataLoaded] = useState(false);
	const [isStatusChangedDialogOpen, setStatusChangedDialogOpen] =
		useState(false);
	const [isResetPqsswordDialogOpen, setResetPasswordDialogOpen] =
		useState(false);

	useEffect(() => {
		try {
			fetchList();
		} catch (err) {}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const fetchList = () => {
		axios.get("/user").then((_response) => {
			if (_response.status === 200) {
				setUserAccounts(_response.data);
				setIsDataLoaded(true);
			}

			if (_response.status === 404) {
				history.push("/login");
			}
		});
	};
	const isSelected = (data) => selected.indexOf(data) !== -1;
	const handleSelectAllChange = (e) => {
		if (e.target.checked) {
			const newSelected = userAccounts.map((n) => n.code);
			setSelected(newSelected);
			return;
		}
		setSelected([]);
	};
	const handleItemSelectChange = (e, data) => {
		const selectedIndex = selected.indexOf(data);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, data);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1),
			);
		}

		setSelected(newSelected);
	};
	const handleDeleteSelected = (e) => {
		axios
			.delete("/user", { data: { code: selected } })
			.then((_response) => {
				setSelected([]);
				fetchList();
			})
			.catch((err) => console.log(err));
	};

	const handleEditUserStatusSelected = (e) => {
		setStatusChangedDialogOpen(true);
		try {
			const requestBody = {
				codes: selected,
				status: e,
			};

			axios
				.put("/user/status", requestBody)
				.then((_response) => {
					if (_response.status === 200) {
						setSelected([]);
						fetchList();
					}
				})
				.catch((err) => console.log(err));
		} catch (err) {
			console.log(err);
		}
	};

	const handleEditUserResetPasswordSelected = () => {
		setResetPasswordDialogOpen(true);
		try {
			const requestBody = {
				codes: selected,
			};

			axios
				.put("/user/bulk-reset-password", requestBody)
				.then((_response) => {
					if (_response.status === 200) {
						setSelected([]);
						fetchList();
					}
				})
				.catch((err) => console.log(err));
		} catch (err) {
			console.log(err);
		}
	};

	const handleDialogClose = () => {
		setStatusChangedDialogOpen(false);
		setResetPasswordDialogOpen(false);
	};

	return (
		<MainLayout>
			<SectionContainerLayout title="USERS ACCOUNTS">
				{isDataLoaded ? (
					<Grid container>
						<Grid item xs={12}>
							<ModuleToolbar
								totalSelected={selected.length}
								onEditUserStatusSelected={
									handleEditUserStatusSelected
								}
								onEditUserResetPasswordSelected={
									handleEditUserResetPasswordSelected
								}
								totalData={userAccounts.length}
								onSelectAllClick={handleSelectAllChange}
								onDeleteSelected={handleDeleteSelected}
								gridViewIcon={false}
								filterIcon={false}
								listViewIcon={false}
								addModuleUrl="/users-accounts/add"
								changeStatusAction={false}
								changeUserStatus
								resetPasswordButton
							/>
						</Grid>
						<Grid item xs={12}>
							<TableContainer component={Paper}>
								<Table>
									<TableHead className={classes.tableHeader}>
										<TableRow>
											<TableCell></TableCell>
											<TableCell align="center">
												<Typography
													variant="body2"
													style={{
														color: theme.palette
															.common.white,
													}}
												>
													Name
												</Typography>
											</TableCell>
											<TableCell align="center">
												<Typography
													variant="body2"
													style={{
														color: theme.palette
															.common.white,
													}}
												>
													Email
												</Typography>
											</TableCell>
											<TableCell align="center">
												<Typography
													variant="body2"
													style={{
														color: theme.palette
															.common.white,
													}}
												>
													Status
												</Typography>
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{userAccounts.map(
											(_userAccount, index) => (
												<TableRow
													key={index}
													align="center"
													scope="row"
												>
													<TableCell width={1}>
														<Checkbox
															checked={isSelected(
																_userAccount.code,
															)}
															onClick={(e) =>
																handleItemSelectChange(
																	e,
																	_userAccount.code,
																)
															}
															inputProps={{
																"aria-labelledby": 1,
															}}
														/>
													</TableCell>
													<TableCell>
														<Typography
															variant="body2"
															color="textPrimary"
															align="center"
														>
															{_userAccount.firstName +
																" " +
																_userAccount.lastName}
														</Typography>
													</TableCell>
													<TableCell>
														<Typography
															variant="body2"
															color="textPrimary"
															align="center"
														>
															{_userAccount.email}
														</Typography>
													</TableCell>
													<TableCell>
														<Typography
															variant="body2"
															align="center"
															style={{
																color:
																	_userAccount.status ===
																	"Active"
																		? colors.lightGreen
																		: colors.red,
															}}
														>
															{
																_userAccount.status
															}
														</Typography>
													</TableCell>
												</TableRow>
											),
										)}
									</TableBody>
								</Table>
							</TableContainer>
						</Grid>
					</Grid>
				) : null}
				<Dialog
					open={isStatusChangedDialogOpen}
					onClose={handleDialogClose}
				>
					<DialogTitle>Update Successful</DialogTitle>
					<DialogContent>
						<DialogContentText>
							News status has been successfully updated
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button
							onClick={handleDialogClose}
							color="primary"
							variant="contained"
							style={{ textTransform: "capitalize" }}
						>
							Ok
						</Button>
					</DialogActions>
				</Dialog>
				<Dialog
					open={isResetPqsswordDialogOpen}
					onClose={handleDialogClose}
				>
					<DialogTitle>Reset Password</DialogTitle>
					<DialogContent>
						<DialogContentText>
							Password has been reset for the selected accounts.
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button
							onClick={handleDialogClose}
							color="primary"
							variant="contained"
							style={{ textTransform: "capitalize" }}
						>
							Ok
						</Button>
					</DialogActions>
				</Dialog>
			</SectionContainerLayout>
		</MainLayout>
	);
};
export default UserAccountsPage;
