import { Grid } from "@material-ui/core";
import React from "react";
import GmisLogo from "../../components/gmis-logo";
import SecondaryLayout from "../../components/secondary-layout";
import Form from "../../components/form";

const EmailConfirmedResetPassword = () => {
	const formData = {
		fields: [
			{
				label: "Password",
				placeholder: "Type your new password",
				type: "password",
			},
			{
				label: "Confirm Password",
				placeholder: "Confirm your new password",
			},
		],
		button: {
			label: "Reset Password",
			url: "",
		},
	};

	return (
		<SecondaryLayout>
			<Grid
				container
				alignItems="center"
				justify="center"
				direction="column"
				style={{ minHeight: "100vh" }}
				spacing={3}
			>
				<Grid item>
					<GmisLogo />
				</Grid>
				<Grid item>
					<Form title="Reset Password" formData={formData} />
				</Grid>
			</Grid>
		</SecondaryLayout>
	);
};

export default EmailConfirmedResetPassword;
