import {
	MenuItem,
	Select,
	Grid,
	TextField,
	InputLabel,
	Button,
	Box,
	Checkbox,
	FormControl,
	FormControlLabel,
	FormHelperText,
	Typography,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { DropzoneArea } from "material-ui-dropzone";
import CustomTextEditor from "../../components/custom-text-editor";
import MainLayout from "../../components/main-layout";
import SectionContainerLayout from "../../components/section-container-layout";
import { editSpeaker } from "../../validations/speaker-validator";
import axios from "axios";
import { useHistory, useLocation } from "react-router-dom";
const EditSpeakerPage = () => {
	const location = useLocation();

	const [jsonFormData, setJsonFormData] = useState({});
	const [imageFile, setImageFile] = useState({});
	const [validationErrors, setValidationErrors] = useState({});
	const [isDataLoaded, setIsDataLoaded] = useState(false);
	const [salutations, setSalutations] = useState([]);
	const [salutationId, setSelectedSalutationId] = useState(
		location.state.speaker.salutation.id.toString(),
	);

	console.log("location.state.speaker", location.state.speaker);

	const handleEditorChange = (event, editor) => {
		const data = editor.getData();
		setJsonFormData({
			...jsonFormData,
			bio: data,
		});
	};
	useEffect(() => {
		try {
			let speaker = { ...location.state.speaker };
			speaker.salutationId = speaker.salutation.id;
			setJsonFormData(speaker);
			axios
				.get("/utility/salutations")
				.then((_response) => {
					if (_response.status === 200) {
						setSalutations(_response.data);
					}
				})
				.catch((err) => console.log(err));

			setIsDataLoaded(true);
		} catch (err) {
			console.log(err);
		}
	}, [location.state.speaker, setJsonFormData]);

	const history = useHistory();

	const handleFormChange = (e) => {
		try {
			switch (e.target.name) {
				case "isOnHomepage": {
					setJsonFormData({
						...jsonFormData,
						[e.target.name]: e.target.checked,
					});
					break;
				}
				case "salutationId": {
					setSelectedSalutationId(e.target.value);
					setJsonFormData({
						...jsonFormData,
						[e.target.name]: e.target.value,
					});
					break;
				}
				default: {
					setJsonFormData({
						...jsonFormData,
						[e.target.name]: e.target.value,
					});
					setValidationErrors({
						...validationErrors,
						[e.target.name]: null,
					});
					break;
				}
			}
		} catch (err) {
			console.log(err);
		}
	};

	const handleFile = (file) => {
		setImageFile(file[0]);
		setValidationErrors({
			...validationErrors,
			imageFile: null,
		});
	};

	const handleSubmit = async () => {
		try {
			let errorMessages = {};

			editSpeaker
				.validate(jsonFormData, { abortEarly: false })
				.then(() => {
					if (!imageFile) {
						setValidationErrors({
							...validationErrors,
							imageFile: "Image file is required",
						});
						return;
					}

					let formData = new FormData();

					formData.append("fileCode", jsonFormData.file.code);
					formData.append("imageFile", imageFile);
					formData.append("code", jsonFormData.code);
					if (salutationId) {
						formData.append("salutationId", salutationId);
					} else {
						formData.append(
							"salutationId",
							jsonFormData.salutationId,
						);
					}
					formData.append("firstName", jsonFormData.firstName);
					formData.append("lastName", jsonFormData.lastName);
					formData.append("designation", jsonFormData.designation);
					formData.append("organization", jsonFormData.organization);
					formData.append("year", jsonFormData.year);

					const bioValue =
						jsonFormData.bio &&
						jsonFormData.bio !== "null" &&
						jsonFormData.bio !== "NULL"
							? jsonFormData.bio
							: "";

					formData.append("bio", bioValue);
					formData.append("isOnHomepage", jsonFormData.isOnHomepage);

					axios
						.put("/speaker", formData, {
							headers: { "Content-Type": "multipart/form-data" },
						})
						.then((_response) => {
							if (_response.status === 200) {
								history.push("/speakers");
							} else {
								console.log("Error");
							}
						})
						.catch((err) => console.log(err));
				})
				.catch((err) => {
					err.inner.forEach((e) => {
						errorMessages[e.path] = e.message;
					});
					setValidationErrors({
						...validationErrors,
						...errorMessages,
					});
				});
		} catch (err) {
			console.log(err);
		}
	};

	return (
		<MainLayout>
			{isDataLoaded ? (
				<SectionContainerLayout
					title={`EDIT SPEAKER: ${jsonFormData.code}`}
				>
					<Grid container>
						<Grid item container xs={12} md={6}>
							<Grid item container xs={12} spacing={3}>
								<Grid item xs={12} md={6}>
									<FormControl fullWidth variant="outlined">
										<InputLabel id="salutation">
											Salutation *
										</InputLabel>
										<Select
											name="salutationId"
											label="Salutation"
											labelId="salutation"
											required
											value={salutationId}
											onChange={handleFormChange}
											error={
												validationErrors.salutationId
											}
										>
											{salutations.map(
												(_salutation, index) =>
													_salutation.code !==
													"NULLL" ? (
														<MenuItem
															key={index}
															value={
																_salutation.id
															}
														>
															{_salutation.name}
														</MenuItem>
													) : (
														<MenuItem
															key={index}
															value={7}
														>
															None
														</MenuItem>
													),
											)}
										</Select>
									</FormControl>
								</Grid>
								<Grid item xs={12} md={6}>
									<TextField
										variant="outlined"
										fullWidth
										required
										label="First Name"
										placeholder={
											jsonFormData.firstName
												? ""
												: "First Name"
										}
										name="firstName"
										defaultValue={jsonFormData.firstName}
										onChange={handleFormChange}
										error={validationErrors.firstName}
										helperText={validationErrors.firstName}
									/>
								</Grid>
							</Grid>
							<Grid item container xs={12} spacing={3}>
								<Grid item xs={12} md={6}>
									<TextField
										variant="outlined"
										fullWidth
										required
										label="Last name"
										placeholder={
											jsonFormData.lastName
												? ""
												: "Last Name"
										}
										name="lastName"
										defaultValue={jsonFormData.lastName}
										onChange={handleFormChange}
										error={validationErrors.lastName}
										helperText={validationErrors.lastName}
									/>
								</Grid>
								<Grid item xs={12} md={6}>
									<TextField
										variant="outlined"
										fullWidth
										label="Designation"
										required
										placeholder={
											jsonFormData.designation
												? ""
												: "Designation"
										}
										defaultValue={jsonFormData.designation}
										name="designation"
										onChange={handleFormChange}
										error={validationErrors.designation}
										helperText={
											validationErrors.designation
										}
									/>
								</Grid>
							</Grid>
							<Grid item container xs={12} spacing={3}>
								<Grid item xs={12} md={6}>
									<TextField
										variant="outlined"
										fullWidth
										label="Organization"
										required
										placeholder={
											jsonFormData.organization
												? ""
												: "Organizatoin"
										}
										defaultValue={jsonFormData.organization}
										name="organization"
										onChange={handleFormChange}
										error={validationErrors.organization}
										helperText={
											validationErrors.organization
										}
									/>
								</Grid>
								<Grid item xs={12} md={6}>
									<FormControl fullWidth variant="outlined">
										<InputLabel id="year">
											Year *
										</InputLabel>
										<Select
											name="year"
											labelId="year"
											label="Year"
											onChange={handleFormChange}
											required
											value={jsonFormData.year}
											error={validationErrors.year}
										>
											<MenuItem value={2022}>
												2022
											</MenuItem>
											<MenuItem value={2021}>
												2021
											</MenuItem>
											<MenuItem value={2020}>
												2020
											</MenuItem>
											<MenuItem value={2019}>
												2019
											</MenuItem>
											<MenuItem value={2017}>
												2017
											</MenuItem>
										</Select>
									</FormControl>
								</Grid>
							</Grid>
						</Grid>
						<Grid item container xs={12} md={6} spacing={3}>
							<Grid item xs={12}>
								<DropzoneArea
									dropzoneText="drag and drop or click here *"
									initialFiles={[
										`${process.env.REACT_APP_API_URL}/static/${jsonFormData.file.code}`,
									]}
									filesLimit={1}
									onChange={(file) => handleFile(file)}
									acceptedFiles={["image/*"]}
								/>
								<FormHelperText>
									Width: 500px, Height: 560px
								</FormHelperText>
								{validationErrors.imageFile ? (
									<FormHelperText>
										<Typography
											variant="body2"
											color="error"
										>
											{validationErrors.imageFile}
										</Typography>
									</FormHelperText>
								) : null}
							</Grid>
						</Grid>
						<Box clone pt={4}>
							<Grid item xs={12}>
								<Typography variant="body1" color="textPrimary">
									Bio
								</Typography>

								<CustomTextEditor
									data={
										jsonFormData.bio ? jsonFormData.bio : ""
									}
									onChange={handleEditorChange}
								/>
							</Grid>
						</Box>
						<Box clone pt={2}>
							<Grid item container xs={12}>
								<Grid item xs={6}>
									<FormControlLabel
										control={
											<Checkbox
												defaultChecked={
													jsonFormData.isOnHomepage
												}
												name="isOnHomepage"
												onChange={handleFormChange}
											/>
										}
										label="Add to homepage"
									/>
								</Grid>
								<Grid item container xs={6} align="right">
									<Grid item xs={12}>
										<Button
											onClick={() =>
												history.push("/speakers")
											}
											style={{
												textTransform: "capitalize",
											}}
										>
											Cancel
										</Button>
										<Button
											color="primary"
											variant="contained"
											onClick={handleSubmit}
											style={{
												textTransform: "capitalize",
											}}
										>
											Submit
										</Button>
									</Grid>
								</Grid>
							</Grid>
						</Box>
					</Grid>
				</SectionContainerLayout>
			) : (
				<p>Loading....</p>
			)}
		</MainLayout>
	);
};

export default EditSpeakerPage;
