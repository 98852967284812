import React from "react";
import { Typography, makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
	root: {
		textDecoration: "none",
		"&:hover": {
			textDecoration: `underline ${theme.palette.text.primary}`,
		},
	},
}));

const CustomLink = ({ linkText, linkUrl }) => {
	const classes = useStyles();
	return (
		<Link to={linkUrl} className={classes.root}>
			<Typography variant="body2" color="textPrimary">
				{linkText}
			</Typography>
		</Link>
	);
};

export default CustomLink;
