import React from "react";
import {
	Card,
	CardActionArea,
	CardContent,
	Typography,
	Checkbox,
	CardMedia,
	CardActions,
} from "@material-ui/core";
import { useStoreState } from "easy-peasy";
const CarouselCard = ({
	title,
	image,
	isChecked = false,
	onItemSelect,
	...carouselCardProps
}) => {
	const handleItemChecked = (e) => {
		if (onItemSelect) onItemSelect(e);
	};
	const currentUser = useStoreState((state) => state.user.currentUser);

	return (
		// <Card
		//   style={{
		//     width: "100%",
		//     height: "100%",
		//     backgroundPosition: "center center",
		//     backgroundRepeat: "no-repeat",
		//   }}
		// >
		//   <CardActionArea {...carouselCardProps}>
		//     {image && (
		//       <img
		//         src={`${process.env.REACT_APP_API_URL}/static/${image}`}
		//         alt=""
		//         style={{ width: "100%" }}
		//       />
		//     )}
		//   </CardActionArea>
		//   <CardContent>
		//     <Grid container>
		//       <Grid item xs={12}>
		//         <Typography variant="body2" color="textPrimary" align="center">
		//           {title}
		//         </Typography>
		//       </Grid>
		//       {currentUser.user_account_type.type !== "Viewer" ? (
		//         <Grid item xs={12} align="right">
		//           <Checkbox checked={isChecked} onChange={handleItemChecked} />
		//         </Grid>
		//       ) : null}
		//     </Grid>
		//   </CardContent>
		// </Card>
		<Card style={{ height: "100%" }}>
			<CardActionArea {...carouselCardProps}>
				<CardMedia
					component="img"
					height="500"
					image={
						image &&
						`${process.env.REACT_APP_API_URL}/static/${image}`
					}
				/>
			</CardActionArea>
			<CardContent
				style={{
					minHeight: "50px",
					maxHeight: "50px",
				}}
			>
				<Typography variant="body2" color="textPrimary">
					{title}
				</Typography>
			</CardContent>
			{currentUser.user_account_type.type !== "Viewer" ? (
				<CardActions>
					<Checkbox
						checked={isChecked}
						onChange={handleItemChecked}
					/>
				</CardActions>
			) : null}
		</Card>
	);
};

export default CarouselCard;
