import axios from "axios";

const searchData = (payload) => {
	const { link, body, isCMS = false } = payload;
	return axios.post(`/${link}/search${isCMS ? "-cms" : ""}`, body);
};

const getDataByCode = (payload) => {
	const { link, code } = payload;
	return axios.get(`/${link}/${code}`);
};

const addData = (payload) => {
	const { link, body } = payload;
	return axios.post(`/${link}`, body);
};

const updateData = (payload) => {
	const { link, body } = payload;
	return axios.put(`/${link}`, body);
};

const deleteData = (payload) => {
	const { path, selected } = payload;
	return axios.delete(`/${path}`, {
		headers: {},
		data: {
			codes: selected,
		},
	});
};

export { searchData, getDataByCode, addData, updateData, deleteData };
