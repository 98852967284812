import {
	Box,
	Grid,
	Tab,
	Tabs,
	Table,
	TableRow,
	TableHead,
	TableCell,
	TableBody,
	Typography,
	useTheme,
	TableContainer,
	makeStyles,
	Paper,
	Checkbox,
	Select,
	MenuItem,
	FormControl,
	InputLabel,
	CircularProgress,
} from "@material-ui/core";
import React, { useCallback, useEffect, useState, useMemo } from "react";
import MainLayout from "../../components/main-layout";
import SectionContainerLayout from "../../components/section-container-layout";
import ModuleToolbar from "../../components/module-toolbar";
import axios from "axios";
import { chromeTabsStylesHook } from "@mui-treasury/styles/tabs";
import PreviousTab from "./tabs/previous";
import AllTab from "./tabs/all";
import { useStoreState } from "easy-peasy";
import colors from "../../constants/colors";
import { useHistory } from "react-router-dom";
import { useDebounce } from "use-debounce";

const useStyles = makeStyles((theme) => ({
	tableHeader: {
		backgroundColor: colors.darkBlue,
	},
	rowHover: {
		"&:hover": {
			cursor: "pointer",
		},
	},
}));

const SpeakersPage = () => {
	const [filteredSpeakers, setFilteredSpeakers] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [tabIndex, setTabIndex] = useState(0);
	const tabsStyles = chromeTabsStylesHook.useTabs();
	const tabItemStyles = chromeTabsStylesHook.useTabItem();
	const [isGridView, setIsGridView] = useState(true);
	const theme = useTheme();
	const classes = useStyles();
	const currentUser = useStoreState((state) => state.user.currentUser);
	const [selected, setSelected] = useState([]);
	const [searchText, setSearchText] = useState("");
	const [filters, setFilters] = useState({});
	const [selectedYear, setSelectedYear] = useState("");
	const [years, setYears] = useState([]);

	const [value] = useDebounce(searchText, 1000);
	const history = useHistory();

	useEffect(() => {
		try {
			const token = localStorage.getItem("token");

			if (!token) {
				history.push("/login");
			}
		} catch (err) {
			console.log(err);
		}
	}, [history]);

	useEffect(() => {
		setIsLoading(true);
		axios
			.post("/speaker/search", {
				filters: { ...filters, search: value, year: selectedYear },
			})
			.then((_response) => {
				if (_response.data) {
					setFilteredSpeakers(_response.data);
				}
			})
			.catch((err) => console.log(err))
			.finally(() => {
				setIsLoading(false);
			});
	}, [value, filters, selectedYear, tabIndex]);

	const isSelected = (data) => selected.indexOf(data) !== -1;

	useEffect(() => {
		if (filteredSpeakers && filteredSpeakers.length > 0) {
			axios.get("/speaker").then((_response) => {
				const years = _response.data.reduce((acc, item) => {
					if (!acc.includes(item.year)) {
						acc.push(item.year);
					}
					return acc;
				}, []);

				setYears(years.sort((a, b) => b - a));
			});

			setIsLoading(false);
		}
	}, [filteredSpeakers]);

	const handleSelectedYearChange = useCallback((e) => {
		setSelectedYear(e.target.value);
	}, []);

	const handleItemSelectChange = (e, data) => {
		const selectedIndex = selected.indexOf(data);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, data);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1),
			);
		}

		setSelected(newSelected);
	};

	const handleGoToEdit = (speaker) => {
		if (currentUser.user_account_type.type !== "Viewer") {
			history.push({
				pathname: "/speakers/edit",
				state: {
					speaker,
				},
			});
		}
	};

	const handleSelectAllChange = (e) => {
		if (e.target.checked) {
			const newSelected = filteredSpeakers.map((n) => n.code);
			setSelected(newSelected);
			return;
		}
		setSelected([]);
	};

	const handleDeleteSelected = (e) => {
		axios
			.delete("/speaker", { data: { code: selected } })
			.then((_response) => {
				setSelected([]);
			})
			.catch((err) => console.log(err));
	};

	const homepageSpeakers = useMemo(() => {
		return filteredSpeakers
			.filter((item) => item.isOnHomepage)
			.sort((a, b) => a.homepageOrder - b.homepageOrder);
	}, [filteredSpeakers]);

	const allSpeakers = useMemo(() => {
		return [...filteredSpeakers].sort((a, b) => {
			return a.order - b.order;
		});
	}, [filteredSpeakers]);

	return (
		<MainLayout>
			<SectionContainerLayout title="SPEAKERS">
				<Grid container>
					<Grid item xs={12}>
						<ModuleToolbar
							handleListView={() => setIsGridView(false)}
							handleGridView={() => setIsGridView(true)}
							addModuleUrl="/speakers/add"
							handleSearchChange={(e) =>
								setSearchText(e.target.value)
							}
							handleFilterData={(value, key) =>
								setFilters({
									...filters,
									[key]: value.toString(),
								})
							}
							filterIcon
							changeStatusAction={false}
							totalSelected={selected.length}
							totalData={filteredSpeakers.length}
							onSelectAllClick={handleSelectAllChange}
							onDeleteSelected={handleDeleteSelected}
							filterChildren={
								<Box width={295} p={1}>
									<FormControl
										variant="outlined"
										size="small"
										fullWidth
									>
										<InputLabel>Year</InputLabel>
										<Select
											value={selectedYear}
											onChange={handleSelectedYearChange}
										>
											<MenuItem value="">All</MenuItem>
											{years.map((year) => (
												<MenuItem
													key={year}
													value={year}
												>
													{String(year)}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Box>
							}
						/>
					</Grid>
					{isGridView ? (
						<Grid item xs={12}>
							<Box display="flex" alignItems="center">
								<Tabs
									classes={tabsStyles}
									value={tabIndex}
									onChange={(e, index) => {
										setTabIndex(index);
										setSelected([]);
									}}
								>
									<Tab
										classes={tabItemStyles}
										label="All Speakers"
									/>
									<Tab
										classes={tabItemStyles}
										label="Previous Speakers"
									/>
								</Tabs>
								{isLoading && (
									<Box px={2}>
										<CircularProgress size={30} />
									</Box>
								)}
							</Box>
						</Grid>
					) : null}
					{isGridView ? (
						<Grid item xs={12}>
							<Box pt={2}>
								{tabIndex === 0 ? (
									<AllTab
										data={allSpeakers}
										onItemSelect={(e, code) =>
											handleItemSelectChange(e, code)
										}
										years={years}
										isChecked={(code) => isSelected(code)}
									/>
								) : tabIndex === 1 ? (
									<PreviousTab
										data={homepageSpeakers}
										onItemSelect={(e, code) =>
											handleItemSelectChange(e, code)
										}
										isChecked={(code) => isSelected(code)}
									/>
								) : null}
							</Box>
						</Grid>
					) : (
						<TableContainer component={Paper}>
							<Table>
								<TableHead className={classes.tableHeader}>
									<TableRow>
										{currentUser.user_account_type.type !==
										"Viewer" ? (
											<TableCell width={1}></TableCell>
										) : null}
										<TableCell align="center">
											<Typography
												variant="body2"
												style={{
													color: theme.palette.common
														.white,
												}}
											>
												Image
											</Typography>
										</TableCell>
										<TableCell align="center">
											<Typography
												variant="body2"
												style={{
													color: theme.palette.common
														.white,
												}}
											>
												Speaker Name
											</Typography>
										</TableCell>
										<TableCell align="center">
											<Typography
												variant="body2"
												style={{
													color: theme.palette.common
														.white,
												}}
											>
												Speaker Details
											</Typography>
										</TableCell>
										<TableCell align="center">
											<Typography
												variant="body2"
												style={{
													color: theme.palette.common
														.white,
												}}
											>
												Is on Homepage?
											</Typography>
										</TableCell>
										<TableCell align="center">
											<Typography
												variant="body2"
												style={{
													color: theme.palette.common
														.white,
												}}
											>
												Year
											</Typography>
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{filteredSpeakers.map((_speaker, index) => (
										<Box clone className={classes.rowHover}>
											<TableRow
												key={index}
												scope="row"
												hover
											>
												{currentUser.user_account_type
													.type !== "Viewer" ? (
													<TableCell width={1}>
														<Checkbox
															checked={isSelected(
																_speaker.code,
															)}
															onClick={(e) =>
																handleItemSelectChange(
																	e,
																	_speaker.code,
																)
															}
															inputProps={{
																"aria-labelledby": 1,
															}}
														/>
													</TableCell>
												) : null}
												<TableCell
													width={50}
													onClick={() =>
														handleGoToEdit(_speaker)
													}
												>
													<img
														src={`${process.env.REACT_APP_API_URL}/static/${_speaker.file.code}`}
														alt=""
														width="100%"
													/>
												</TableCell>
												<TableCell
													width={100}
													align="center"
													onClick={() =>
														handleGoToEdit(_speaker)
													}
												>
													<Typography
														variant="body1"
														color="textPrimary"
													>
														{_speaker.firstName +
															" " +
															_speaker.lastName}
													</Typography>
												</TableCell>
												<TableCell
													width={100}
													align="center"
													onClick={() =>
														handleGoToEdit(_speaker)
													}
												>
													<Typography
														variant="body1"
														color="textPrimary"
													>
														{_speaker.designation +
															", " +
															_speaker.organization}
													</Typography>
												</TableCell>
												<TableCell
													width={100}
													align="center"
													onClick={() =>
														handleGoToEdit(_speaker)
													}
												>
													<Typography
														variant="body1"
														color="textPrimary"
													>
														{_speaker.isOnHomepage
															? "Yes"
															: "No"}
													</Typography>
												</TableCell>
												<TableCell
													width={100}
													align="center"
													onClick={() =>
														handleGoToEdit(_speaker)
													}
												>
													<Typography
														variant="body1"
														color="textPrimary"
													>
														{_speaker.year}
													</Typography>
												</TableCell>
											</TableRow>
										</Box>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					)}
				</Grid>
			</SectionContainerLayout>
		</MainLayout>
	);
};
export default SpeakersPage;
