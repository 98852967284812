import {
	TextField,
	Grid,
	Button,
	Box,
	FormControl,
	InputLabel,
	Typography,
	Select,
	MenuItem,
	FormHelperText,
	LinearProgress,
} from "@material-ui/core";
import React, { useState } from "react";
import MainLayout from "../../components/main-layout";
import SectionContainerLayout from "../../components/section-container-layout";

import { DropzoneAreaBase } from "material-ui-dropzone";
import axios from "axios";
import { useHistory } from "react-router";
import { addNewAlbum } from "../../validations/album-validator";
import { generatePageLink } from "../../helpers/common";
import moment from "moment";

const AddAlbumPage = () => {
	const [jsonFormData, setJsonFormData] = useState({
		date: moment().format("YYYY-MM-DD"),
		albumType: "images",
	});
	const [datePicked] = useState(moment().format("YYYY-MM-DD"));
	const [files, setFiles] = useState([]);
	const [validationErrors, setValidationErrors] = useState({});
	const [selectedAlbumType, setSelectedAlbumType] = useState("images");
	const [progress, setProgress] = useState(0);
	const [showProgress, setShowProgress] = useState(false);
	const history = useHistory();

	const handleFormChange = (e) => {
		setJsonFormData({
			...jsonFormData,
			[e.target.id || e.target.name]: e.target.value,
		});

		if (e.target.name === "albumType") {
			setSelectedAlbumType(e.target.value);
			setFiles([]);
		}

		switch (e.target.name || e.target.id) {
			case "title": {
				setValidationErrors({ ...validationErrors, title: null });
				break;
			}
			case "albumType": {
				setValidationErrors({ ...validationErrors, albumType: null });
				break;
			}
			default:
				return;
		}
	};

	const handleFiles = (file) => {
		if (selectedAlbumType === null) {
			setValidationErrors({
				...validationErrors,
				albumTypeNotSelected:
					"You must select an album type before adding files",
			});
			setFiles([]);
		} else {
			let uploadedFiles = [];

			file.forEach((_file, index) => {
				uploadedFiles.push(_file);
			});

			setFiles([...files, ...uploadedFiles]);
		}
	};

	const handleDeleteFile = (file) => {
		let _files = [...files];

		_files.splice(files.indexOf(file), 1);

		setFiles(_files);
	};
	const handleSubmit = () => {
		try {
			let errorMessages = [];

			addNewAlbum
				.validate(jsonFormData, { abortEarly: false })
				.then(() => {
					let formData = new FormData();

					for (let i = 0; i < files.length; i++) {
						formData.append("files", files[i].file);
					}

					for (let i in jsonFormData) {
						formData.append(i, jsonFormData[i]);
					}

					let urlSlug = generatePageLink(
						jsonFormData.title,
						"gallery/xxxx/",
					);
					formData.append("urlSlug", urlSlug);

					axios
						.post("/album", formData, {
							headers: { "Content-Type": "multipart/form-data" },
							onUploadProgress: (data) => {
								setShowProgress(true);
								setProgress(
									Math.round(
										(100 * data.loaded) / data.total,
									),
								);
							},
						})
						.then((_response) => {
							if (_response.status === 200) {
								history.push("/albums");
							} else {
								console.log("error");
							}
						})
						.catch((err) => console.log(err));
				})
				.catch((err) => {
					err.inner.forEach((e) => {
						errorMessages[e.path] = e.message;
					});
					setValidationErrors(errorMessages);
				});
		} catch (err) {
			console.log(err);
		}
	};

	return (
		<MainLayout>
			<SectionContainerLayout title="ADD ALBUM">
				<Grid container spacing={3}>
					<Grid item container xs={12} spacing={3}>
						<Grid item xs={12} md={5}>
							{/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  fullWidth
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  id="date"
                  required
                  value={datePicked}
                  label="Date"
                  name="date"
                  error={validationErrors.date}
                  helperText={validationErrors.date}
                  onChange={handleDatePicker("date")}
                  autoOk
                />
              </MuiPickersUtilsProvider> */}
							<TextField
								id="date"
								label="Date"
								type="date"
								defaultValue={datePicked}
								onChange={handleFormChange}
								error={validationErrors.date}
								helperText={validationErrors.date}
								placeholder={datePicked}
								variant="outlined"
								fullWidth
							/>
						</Grid>
						<Grid item xs={12} md={7}>
							<TextField
								variant="outlined"
								fullWidth
								label="Title"
								id="title"
								error={validationErrors.title}
								required
								helperText={validationErrors.title}
								onChange={handleFormChange}
							/>
						</Grid>
					</Grid>
					<Grid item container xs={12}>
						<Grid item xs={12} md={4}>
							<FormControl fullWidth variant="outlined">
								<InputLabel id="album-type">
									Album Type
								</InputLabel>
								<Select
									name="albumType"
									label="Album Type"
									labelId="album-type"
									required
									variant="outlined"
									error={validationErrors.albumType}
									onChange={handleFormChange}
									defaultValue="images"
								>
									<MenuItem value="images">Images</MenuItem>
									<MenuItem value="videos">Videos</MenuItem>
								</Select>
								{validationErrors.albumType ? (
									<FormHelperText color="error">
										<Typography
											variant="body2"
											color="error"
										>
											Album type is required
										</Typography>
									</FormHelperText>
								) : null}
							</FormControl>
						</Grid>
						<Box clone pt={2}>
							<Grid item xs={12}>
								<DropzoneAreaBase
									dropzoneText={
										selectedAlbumType === "images"
											? "Drag and drop images here *"
											: selectedAlbumType === "videos"
											? "Drag and drop videos here *"
											: "Please select an album type *"
									}
									filesLimit={300}
									key={1}
									fileObjects={files}
									onAdd={(file) => handleFiles(file)}
									onDelete={(file) => handleDeleteFile(file)}
									showFileNames={false}
									showPreviewsInDropzone
									maxFileSize={
										selectedAlbumType === "videos"
											? 2147483648
											: null
									}
									acceptedFiles={
										selectedAlbumType === "images"
											? [
													"image/jpg",
													"image/jpeg",
													"image/png",
											  ]
											: selectedAlbumType === "videos"
											? ["video/mp4"]
											: null
									}
									clearOnUnmount={false}
								/>
								{selectedAlbumType === "videos" ? (
									<FormHelperText>
										Video max size: 2GB
									</FormHelperText>
								) : null}
								{selectedAlbumType === "images" ? (
									<FormHelperText>
										Width: 500px, Height: 300px
									</FormHelperText>
								) : null}
								{validationErrors.albumTypeNotSelected ? (
									<FormHelperText>
										<Typography
											variant="body2"
											color="error"
										>
											{
												validationErrors.albumTypeNotSelected
											}
										</Typography>
									</FormHelperText>
								) : null}
							</Grid>
						</Box>
					</Grid>
					<Box clone pt={2}>
						<Grid item container xs={12} align="right">
							<Grid item xs={12}>
								<Button
									onClick={() => history.push("/albums")}
									style={{ textTransform: "capitalize" }}
								>
									Cancel
								</Button>
								<Button
									color="primary"
									variant="contained"
									onClick={handleSubmit}
									style={{ textTransform: "capitalize" }}
								>
									Submit
								</Button>
							</Grid>
						</Grid>
					</Box>
					<Box clone pt={5}>
						<Grid item xs={12}>
							{showProgress ? (
								<LinearProgress
									variant="determinate"
									value={progress}
								/>
							) : null}
						</Grid>
					</Box>
				</Grid>
			</SectionContainerLayout>
		</MainLayout>
	);
};

export default AddAlbumPage;
