import React, { useEffect, useRef, useState } from "react";

import {
	Grid,
	TextField,
	ButtonGroup,
	Button,
	Typography,
	FormControlLabel,
	Checkbox,
	FormHelperText,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { createFilterOptions } from "@material-ui/lab/Autocomplete";
import { DropzoneArea } from "material-ui-dropzone";
import { useHistory, useLocation } from "react-router";
import axios from "axios";
import MainLayout from "../../components/main-layout";
import SectionContainerLayout from "../../components/section-container-layout";
import CustomTextEditor from "../../components/custom-text-editor";
import moment from "moment";

const filter = createFilterOptions();
const EditKnowledgeHubPage = () => {
	const [currentKnowledgeHub, setCurrentKnowledgeHub] = useState();
	// const [categoryValue, setCategoryValue] = useState(null);
	const [categories, setCategories] = useState({});
	const [isDataLoaded, setIsDataLoaded] = useState(false);
	const [isOnHomepage, setIsOnHomepage] = useState(false);
	const [isImageFile, setIsImageFile] = useState([]);
	const [isPdfFile, setIsPdfFile] = useState([]);
	const [datePicked, setDatePicked] = useState(new Date());
	const [jsonFormData, setJsonFormData] = useState();
	const [imageFile, setImageFile] = useState();
	const [pdfFile, setPdfFile] = useState();
	const [activeTab, setActiveTab] = useState("articleButton");
	const [deletedFileCodes, setDeletedFilesCodes] = useState([]);
	const [isPdfChanged, setIsPdfChanged] = useState(false);
	const [isImageChanged, setIsImageChanged] = useState(false);
	const [imageIsRequired, setImageIsRequired] = useState(false);
	const [pdfIsRequired, setPdfFileIsRequired] = useState(false);
	const [selectedCategory, setSelectedCategory] = useState({});
	const history = useHistory();
	const location = useLocation();

	const pdfRef = useRef();
	const imgRef = useRef();

	useEffect(() => {
		const { knowledgeHub } = location.state;
		setCurrentKnowledgeHub(knowledgeHub);

		if (knowledgeHub.category.length > 0) {
			setSelectedCategory(knowledgeHub.category[0]);
		}

		setIsDataLoaded(true);
		setDatePicked(knowledgeHub.date);
		setIsOnHomepage(knowledgeHub.isOnHomepage);

		// if (knowledgeHub.category && knowledgeHub.category.name) {
		//   setCategoryValue({ name: knowledgeHub.category.name });
		// }

		if (knowledgeHub.type === "pdf") {
			setActiveTab("pdfButton");
		} else {
			setActiveTab("articleButton");
		}

		setJsonFormData({
			...jsonFormData,
			code: knowledgeHub.code,
			// categoryName:
			//   knowledgeHub.category && knowledgeHub.category.name
			//     ? knowledgeHub.category.name
			//     : null,
			isOnHomepage: knowledgeHub.isOnHomepage,
			date: knowledgeHub.date,
			article: knowledgeHub.article,
			description: knowledgeHub.description,
			title: knowledgeHub.title,
			seoTitle: knowledgeHub.seoTitle ? knowledgeHub.seoTitle : null,
			seoDescription: knowledgeHub.seoDescription
				? knowledgeHub.seoDescription
				: null,
			seoKeywords: knowledgeHub.seoKeywords
				? knowledgeHub.seoKeywords
				: null,
		});
		const _pdfFile = knowledgeHub.file.filter(
			(f) => f.type === "application/pdf",
		);

		if (_pdfFile.length > 0) {
			setActiveTab("pdfButton");
			const _pdfArray = [];
			_pdfFile.forEach((p) => {
				_pdfArray.push(
					`${process.env.REACT_APP_API_URL}/static/${p.code}`,
				);
			});
			setIsPdfFile(_pdfArray);
		}

		const _imgFile = knowledgeHub.file.filter(
			(f) => f.type !== "application/pdf",
		);
		if (_imgFile.length > 0) {
			const _imgArray = [];
			_imgFile.forEach((p) => {
				_imgArray.push(
					`${process.env.REACT_APP_API_URL}/static/${p.code}`,
				);
			});
			setIsImageFile(_imgArray);
		}

		axios
			.get("/utility/categories/knowledge-hub")
			.then((_response) => {
				if (_response) {
					setCategories(_response.data);
				}
			})
			.catch((err) => console.log(err));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleFormChange = (e) => {
		if (e.target.id && e.target.id === "isOnHomepage") {
			setIsOnHomepage(e.target.checked);
		}

		setJsonFormData({
			...jsonFormData,
			// ...categoryValue,
			[e.target.id || e.target.name]: e.target.value || e.target.checked,
		});
	};

	const handleImageFile = (file) => {
		setImageFile(file[0]);
		if (imgRef.current) {
			setIsImageChanged(true);
		} else {
			imgRef.current = true;
		}
	};
	const handleTabChange = (buttonId) => {
		switch (buttonId) {
			case "articleButton": {
				setActiveTab(buttonId);
				setPdfFile();
				break;
			}
			case "pdfButton": {
				setActiveTab(buttonId);
				setJsonFormData({
					...jsonFormData,
					article: "",
				});
				break;
			}
			case "seoButton": {
				setActiveTab(buttonId);
				break;
			}
			default: {
				setActiveTab("articleButton");
			}
		}
	};
	const handleEditorChange = (event, editor) => {
		const data = editor.getData();
		setJsonFormData({
			...jsonFormData,
			article: data,
		});
	};
	const handlePdfFile = (file) => {
		setPdfFile(file[0]);
		if (pdfRef.current) {
			setIsPdfChanged(true);
		} else {
			pdfRef.current = true;
		}
	};

	const handleSubmit = () => {
		try {
			if (!imageFile) {
				setImageIsRequired(true);
				return;
			}
			if (currentKnowledgeHub.type === "pdf" && !pdfFile) {
				setPdfFileIsRequired(true);
				return;
			}
			let formData = new FormData();

			formData.append("code", jsonFormData.code);
			formData.append("title", jsonFormData.title);
			formData.append("description", jsonFormData.description);
			formData.append("date", jsonFormData.date);
			formData.append(
				"seoTitle",
				jsonFormData.seoTitle ? jsonFormData.seoTitle : null,
			);
			formData.append(
				"seoDescription",
				jsonFormData.seoDescription
					? jsonFormData.seoDescription
					: null,
			);
			formData.append(
				"seoKeywords",
				jsonFormData.seoKeywords ? jsonFormData.seoKeywords : null,
			);

			if (selectedCategory && Object.keys(selectedCategory).length > 0) {
				formData.append(
					"category",
					Object.keys(selectedCategory).length > 0
						? JSON.stringify(selectedCategory)
						: null,
				);
			}

			if (
				jsonFormData.isOnHomepage === undefined ||
				jsonFormData.isOnHomepage === null
			) {
				formData.append("isOnHomepage", false);
			} else {
				formData.append("isOnHomepage", jsonFormData.isOnHomepage);
			}

			if (currentKnowledgeHub.type === "article")
				formData.append("article", jsonFormData.article);
			else formData.append("article", "");

			if (deletedFileCodes.length > 0) {
				deletedFileCodes.forEach((_code) => {
					formData.append("deletedFileCodes", _code);
				});
			}

			if (isPdfChanged) {
				formData.append("pdfFile", pdfFile);
			}
			if (isImageChanged) {
				formData.append("imageFile", imageFile);
			}

			axios
				.put("/knowledge-hub", formData, {
					headers: {
						"Content-Type": "multipart/form-data",
					},
				})
				.then((_response) => {
					if (_response.status === 200) {
						history.push("/knowledge-hub");
					}
				})
				.catch((err) => console.log(err));
		} catch (err) {
			console.log(err);
		}
	};

	return (
		<MainLayout>
			{isDataLoaded ? (
				<SectionContainerLayout
					title={`EDIT KNOWLEDGE HUB: ${currentKnowledgeHub.code}`}
				>
					<Grid container spacing={3}>
						<Grid item container xs={12} md={6} spacing={3}>
							<Grid item xs={12} md={6}>
								<TextField
									id="date"
									label="Date"
									type="date"
									defaultValue={moment(
										currentKnowledgeHub.date,
									).format("YYYY-MM-DD")}
									onChange={handleFormChange}
									placeholder={datePicked}
									variant="outlined"
									fullWidth
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<TextField
									label="Title"
									variant="outlined"
									fullWidth
									name="title"
									required
									placeholder={
										currentKnowledgeHub.title ? "" : "Title"
									}
									defaultValue={currentKnowledgeHub.title}
									onChange={handleFormChange}
								/>
							</Grid>
							<Grid item xs={6}>
								<Autocomplete
									value={selectedCategory}
									onChange={(event, newValue) => {
										if (typeof newValue === "string")
											setSelectedCategory({
												name: newValue,
											});
										else if (
											newValue &&
											newValue.inputValue
										)
											setSelectedCategory({
												name: newValue.inputValue,
											});
										else setSelectedCategory(newValue);
									}}
									filterOptions={(options, params) => {
										const filtered = filter(
											options,
											params,
										);
										if (params.inputValue !== "") {
											filtered.push({
												inputValue: params.inputValue,
												name: `Add "${params.inputValue}"`,
											});
										}
										return filtered;
									}}
									selectOnFocus
									clearOnBlur
									handleHomeEndKeys
									options={categories}
									getOptionLabel={(option) => {
										if (Object.keys(option).length > 0)
											return option.name;

										return "";
									}}
									renderOption={(option) =>
										Object.keys(option).length > 0
											? option.name
											: ""
									}
									freeSolo
									renderInput={(params) => (
										<TextField
											{...params}
											label="Category"
											variant="outlined"
										/>
									)}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									multiline
									rows={4}
									label="Description"
									variant="outlined"
									name="description"
									required
									fullWidth
									placeholder={
										currentKnowledgeHub.description
											? ""
											: "Description"
									}
									defaultValue={
										currentKnowledgeHub.description
									}
									onChange={handleFormChange}
								/>
							</Grid>
						</Grid>
						<Grid item container xs={12} md={6}>
							<DropzoneArea
								dropzoneText="Drag and drop thumbnail image here *"
								filesLimit={1}
								key={1}
								acceptedFiles={["image/*"]}
								onChange={(file) => handleImageFile(file)}
								initialFiles={isImageFile}
								onDelete={(file) =>
									setDeletedFilesCodes([
										...deletedFileCodes,
										file.name,
									])
								}
							/>
							<FormHelperText>
								Width: 500px, Height: 300px
							</FormHelperText>
							{imageIsRequired ? (
								<Typography variant="body1" color="error">
									Please upload an image File
								</Typography>
							) : null}
						</Grid>
						<Grid
							item
							container
							xs={12}
							spacing={3}
							alignItems="center"
						>
							<Grid item xs={12} md={6}>
								<ButtonGroup>
									<Button
										color={
											activeTab === "articleButton"
												? "primary"
												: null
										}
										onClick={(e) =>
											handleTabChange(e.currentTarget.id)
										}
										id="articleButton"
										variant="contained"
										disabled={
											currentKnowledgeHub.type === "pdf"
												? true
												: false
										}
									>
										Article
									</Button>
									<Button
										color={
											activeTab === "pdfButton"
												? "primary"
												: null
										}
										onClick={(e) =>
											handleTabChange(e.currentTarget.id)
										}
										id="pdfButton"
										variant="contained"
										disabled={
											currentKnowledgeHub.type ===
											"article"
												? true
												: false
										}
									>
										PDF
									</Button>
									<Button
										color={
											activeTab === "seoButton"
												? "primary"
												: null
										}
										onClick={(e) =>
											handleTabChange(e.currentTarget.id)
										}
										id="seoButton"
										variant="contained"
									>
										SEO
									</Button>
								</ButtonGroup>
							</Grid>
							<Grid item xs={12} md={6} align="right">
								<Typography
									variant="body2"
									color="textSecondary"
								>
									You can either upload a PDF or add an
									article (not both)
								</Typography>
							</Grid>
							<Grid item xs={12}>
								{activeTab === "articleButton" ? (
									<CustomTextEditor
										onChange={handleEditorChange}
										data={currentKnowledgeHub.article}
									/>
								) : activeTab === "pdfButton" ? (
									<>
										<DropzoneArea
											dropzoneText="Drag and drop PDF file here"
											filesLimit={1}
											key={2}
											maxFileSize={10485760}
											onChange={(file) =>
												handlePdfFile(file)
											}
											acceptedFiles={["application/pdf"]}
											initialFiles={isPdfFile}
											onDelete={(file) =>
												setDeletedFilesCodes([
													...deletedFileCodes,
													file.name,
												])
											}
										/>
										{pdfIsRequired ? (
											<Typography
												variant="body1"
												color="error"
											>
												Please upload a PDF File
											</Typography>
										) : null}
									</>
								) : (
									<Grid container spacing={3}>
										<Grid item xs={12}>
											<TextField
												label="SEO Title"
												variant="outlined"
												defaultValue={
													currentKnowledgeHub.seoTitle
												}
												fullWidth
												// error={validationErrors.seoTitle}
												// helperText={validationErrors.seoTitle}
												name="seoTitle"
												onChange={handleFormChange}
											/>
										</Grid>
										<Grid item xs={12}>
											<TextField
												multiline
												rows={4}
												label="SEO Description"
												variant="outlined"
												defaultValue={
													currentKnowledgeHub.seoDescription
												}
												// helperText={validationErrors.seoDescription}
												// error={validationErrors.seoDescription}
												name="seoDescription"
												fullWidth
												onChange={handleFormChange}
											/>
										</Grid>
										<Grid item xs={12}>
											<TextField
												multiline
												rows={4}
												label="SEO Keywords"
												variant="outlined"
												defaultValue={
													currentKnowledgeHub.seoKeywords
												}
												helperText="Seperate each set of keywords with a ','"
												// error={validationErrors.seoKeywords}
												name="seoKeywords"
												fullWidth
												onChange={handleFormChange}
											/>
										</Grid>
									</Grid>
								)}
							</Grid>
						</Grid>
						<Grid item container xs={12}>
							<Grid item xs={12} md={6}>
								<FormControlLabel
									control={
										<Checkbox
											id="isOnHomepage"
											onChange={handleFormChange}
											checked={isOnHomepage}
										/>
									}
									label="Add to homepage"
								/>
							</Grid>
							<Grid item xs={12} md={6} align="right">
								<Button
									onClick={() =>
										history.push("/knowledge-hub")
									}
									style={{ textTransform: "capitalize" }}
								>
									Cancel
								</Button>
								<Button
									onClick={handleSubmit}
									variant="contained"
									color="primary"
									style={{ textTransform: "capitalize" }}
								>
									Submit
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</SectionContainerLayout>
			) : null}
		</MainLayout>
	);
};

export default EditKnowledgeHubPage;
