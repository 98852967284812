import React, { useEffect, useState } from "react";
import SectionContainerLayout from "../../components/section-container-layout";
import MainLayout from "../../components/main-layout";
import ModuleToolbar from "../../components/module-toolbar";
import {
	Grid,
	Box,
	TableContainer,
	Paper,
	Table,
	TableHead,
	TableRow,
	makeStyles,
	useTheme,
	TableCell,
	Typography,
	Checkbox,
	Dialog,
	DialogContent,
	DialogActions,
	DialogContentText,
	Button,
	DialogTitle,
	TableBody,
} from "@material-ui/core";
import axios from "axios";
import CardImageTitleDescription from "../../components/card-image-title-description";
import moment from "moment";
import { useHistory } from "react-router-dom";
import colors from "../../constants/colors";
import Clamp from "react-multiline-clamp";
import { dataExists } from "../../helpers/common";
import { useDebounce } from "use-debounce";
import { useStoreState } from "easy-peasy";

const useStyles = makeStyles((theme) => ({
	tableHeader: {
		backgroundColor: colors.darkBlue,
	},
	rowHover: {
		"&:hover": {
			cursor: "pointer",
		},
	},
}));

const DigitalSeriesPage = () => {
	const [digitalSeriesData, setDigitalSeriesData] = useState([]);
	const [isGridView, setIsGridView] = useState(true);
	const [isListView, setListView] = useState(false);
	const [selected, setSelected] = useState([]);
	const [filterData, setFilterData] = useState({});
	const [isDialogOpen, setIsDialogOpen] = useState(false);
	const [isDataLoaded, setIsDataLoaded] = useState(false);
	const history = useHistory();
	const classes = useStyles();
	const theme = useTheme();
	const currentUser = useStoreState((state) => state.user.currentUser);

	const handleDialogClose = () => setIsDialogOpen(false);

	const toggleGridView = () => {
		setIsGridView(true);
		setListView(false);
	};

	const toggleListView = () => {
		setIsGridView(false);
		setListView(true);
	};

	useEffect(() => {
		try {
			const token = localStorage.getItem("token");

			if (!token) {
				history.push("/login");
			}
			fetchList();
		} catch (err) {
			console.log(err);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [setDigitalSeriesData]);

	const fetchList = () => {
		axios
			.get("/digital-series")
			.then((_response) => {
				if (_response.status === 200) {
					let yearArray = [];
					_response.data.forEach((_data) => {
						if (!dataExists(_data.year, yearArray)) {
							yearArray.push({
								name: _data.year,
								value: _data.year,
							});
						}
					});

					let _yearFilter = {
						options: yearArray,
						label: "Year",
						idName: "year",
					};

					let allFilters = {
						_yearFilter,
					};

					setFilterData(allFilters);
					setIsDataLoaded(true);
					setDigitalSeriesData(_response.data);
				} else if (_response.status === 404) {
					history.push("/login");
				}
			})
			.catch((err) => console.log(err));
	};

	const convertDate = (date) => {
		const newDate = moment(date).format("DD MMMM YYYY");

		return newDate;
	};

	const handleClick = (digitalSeries) => {
		if (currentUser.user_account_type.type !== "Viewer") {
			history.push({
				pathname: "/digital-series/edit",
				state: {
					digitalSeries,
				},
			});
		}
	};
	const isSelected = (data) => selected.indexOf(data) !== -1;
	const handleSelectAllChange = (e) => {
		if (e.target.checked) {
			const newSelected = digitalSeriesData.map((n) => n.code);
			setSelected(newSelected);
			return;
		}
		setSelected([]);
	};
	const handleItemSelectChange = (e, data) => {
		const selectedIndex = selected.indexOf(data);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, data);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1),
			);
		}
		setSelected(newSelected);
	};
	const handleDeleteSelected = (e) => {
		axios
			.delete("/digital-series", { data: { code: selected } })
			.then((_response) => {
				setSelected([]);
				fetchList();
			})
			.catch((err) => console.log(err));
	};

	const previewImage = (data) => {
		if (data.file && data.file.length > 0) {
			const _img = data.file.filter((f) => f.type.includes("image"));
			return _img.length > 0 ? _img[0].code : null;
		}
		return null;
	};

	const checkVideo = (data) => {
		if (data.file && data.file.length > 0) {
			const _vid = data.file.filter((f) => f.type.includes("video"));
			return _vid.length > 0 ? _vid[0].isTranscoded : false;
		}
		return null;
	};

	const [filters, setFilters] = useState({});
	const [search, setSearch] = useState("");
	const [searchText] = useDebounce(search, 1000);
	useEffect(() => {
		axios
			.post("/digital-series/search", {
				filters: { ...filters, search: searchText },
			})
			.then((_response) => {
				setDigitalSeriesData(_response.data);
			})
			.catch((err) => console.log(err));
	}, [searchText, filters]);

	const handleSearch = (e) => {
		try {
			setSearch(e.target.value);
			// const searchQuery = {
			//   title: e.target.value,
			//   description: e.target.value,
			//   details: e.target.value,
			// };

			// axios
			//   .post("/digital-series/search", searchQuery)
			//   .then((_response) => {
			//     if (_response.status === 200) {
			//       setDigitalSeriesData(_response.data);
			//     }
			//   })
			//   .catch((err) => console.log(err));
		} catch (err) {
			console.log(err);
		}
	};
	const handleFilterData = (value, key) => {
		try {
			setFilters({ ...filters, [key]: value.toString() });
			// let filteredLogs = [];

			// axios.get("/digital-series").then((_response) => {
			//   if (_response.status === 200) {
			//     _response.data.forEach((_data) => {
			//       if (_data[key.toLowerCase()] === value) {
			//         filteredLogs.push(...filteredLogs, _data);
			//       }
			//     });

			//     setDigitalSeriesData(filteredLogs);
			//     if (!value) {
			//       setDigitalSeriesData(_response.data);
			//     }
			//     setIsDataLoaded(true);
			//   }

			//   if (_response.status === 404) {
			//     history.push("/login");
			//   }
			// });
		} catch (err) {
			console.log(err);
		}
	};

	const handleEditSelected = (e) => {
		setIsDialogOpen(true);
		try {
			const requestBody = {
				codes: selected,
				status: e,
			};

			axios
				.put("/digital-series/status", requestBody)
				.then((_response) => {
					if (_response.status === 200) {
						setSelected([]);
						fetchList();
					}
				})
				.catch((err) => console.log(err));
		} catch (err) {
			console.log(err);
		}
	};

	return (
		<MainLayout>
			<SectionContainerLayout title="DIGITAL SERIES">
				{isDataLoaded ? (
					<Grid container>
						<Grid item xs={12}>
							<ModuleToolbar
								totalSelected={selected.length}
								totalData={digitalSeriesData.length}
								onSelectAllClick={handleSelectAllChange}
								onDeleteSelected={handleDeleteSelected}
								addModuleUrl="/digital-series/add"
								handleGridView={toggleGridView}
								onEditSelected={handleEditSelected}
								handleListView={toggleListView}
								filterIcon
								showCustomFilter
								filterData={filterData}
								handleFilterData={handleFilterData}
								handleSearchChange={handleSearch}
							/>
						</Grid>
						<Grid item container xs={12} spacing={3}>
							{isGridView && digitalSeriesData.length > 0 ? (
								digitalSeriesData.map(
									(_digitalSeries, index) => {
										// console.log("_digitalSeries : ", _digitalSeries);
										return (
											<Grid
												item
												xs={12}
												md={4}
												lg={3}
												key={index}
											>
												<CardImageTitleDescription
													onClick={() =>
														handleClick(
															_digitalSeries,
														)
													}
													image={previewImage(
														_digitalSeries,
													)}
													title={_digitalSeries.title}
													description={
														_digitalSeries.description
													}
													status={
														_digitalSeries.status
													}
													isChecked={isSelected(
														_digitalSeries.code,
													)}
													isTranscoded={checkVideo(
														_digitalSeries,
													)}
													onItemSelect={(e) =>
														handleItemSelectChange(
															e,
															_digitalSeries.code,
														)
													}
												/>
											</Grid>
										);
									},
								)
							) : isListView && digitalSeriesData.length > 0 ? (
								<TableContainer component={Paper}>
									<Table>
										<TableHead
											className={classes.tableHeader}
										>
											<TableRow>
												{currentUser.user_account_type
													.type !== "Viewer" ? (
													<TableCell
														width={1}
													></TableCell>
												) : null}
												<TableCell align="center">
													<Typography
														variant="body2"
														style={{
															color: theme.palette
																.common.white,
														}}
													></Typography>
												</TableCell>
												<TableCell align="center">
													<Typography
														variant="body2"
														style={{
															color: theme.palette
																.common.white,
														}}
													>
														Title
													</Typography>
												</TableCell>
												<TableCell align="center">
													<Typography
														variant="body2"
														style={{
															color: theme.palette
																.common.white,
														}}
													>
														Description
													</Typography>
												</TableCell>
												<TableCell align="center">
													<Typography
														variant="body2"
														style={{
															color: theme.palette
																.common.white,
														}}
													>
														Date
													</Typography>
												</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{digitalSeriesData.map(
												(_digitalSeries, index) => (
													<Box
														clone
														className={
															classes.rowHover
														}
													>
														<TableRow
															key={index}
															scope="row"
															hover
														>
															{currentUser
																.user_account_type
																.type !==
															"Viewer" ? (
																<TableCell
																	width={1}
																>
																	<Checkbox
																		checked={isSelected(
																			_digitalSeries.code,
																		)}
																		onClick={(
																			e,
																		) =>
																			handleItemSelectChange(
																				e,
																				_digitalSeries.code,
																			)
																		}
																		inputProps={{
																			"aria-labelledby": 1,
																		}}
																	/>
																</TableCell>
															) : null}
															<TableCell
																width={50}
																onClick={() =>
																	handleClick(
																		_digitalSeries,
																	)
																}
															>
																<img
																	src={`${
																		process
																			.env
																			.REACT_APP_API_URL
																	}/static/${previewImage(
																		_digitalSeries,
																	)}`}
																	alt=""
																	width="100%"
																/>
															</TableCell>
															<TableCell
																width={100}
																align="center"
																onClick={() =>
																	handleClick(
																		_digitalSeries,
																	)
																}
															>
																<Typography
																	variant="body1"
																	color="textPrimary"
																>
																	{
																		_digitalSeries.title
																	}
																</Typography>
															</TableCell>
															<TableCell
																width={100}
																align="center"
																onClick={() =>
																	handleClick(
																		_digitalSeries,
																	)
																}
															>
																<Typography
																	variant="body1"
																	color="textPrimary"
																>
																	<Clamp
																		lines={
																			2
																		}
																	>
																		{
																			_digitalSeries.description
																		}
																	</Clamp>
																</Typography>
															</TableCell>
															{/* <TableCell width={100} align="center">
                          <Typography
                          variant="body1"
                          style={{
                            color:
                            _digitalSeries.status === "published"
                                  ? colors.lightGreen
                                  : _digitalSeries.status === "unpublished"
                                  ? colors.darkGray
                                  : _digitalSeries.status === "scheduled"
                                  ? theme.palette.warning.main
                                  : null,
                                }}
                                >
                            {_digitalSeries.status.charAt(0).toUpperCase() +
                              _digitalSeries.status.slice(1)}
                              </Typography>
                        </TableCell> */}
															<TableCell
																width={100}
																align="center"
																onClick={() =>
																	handleClick(
																		_digitalSeries,
																	)
																}
															>
																<Typography
																	variant="body1"
																	color="textPrimary"
																>
																	{convertDate(
																		_digitalSeries.date,
																	)}
																</Typography>
															</TableCell>
														</TableRow>
													</Box>
												),
											)}
										</TableBody>
									</Table>
								</TableContainer>
							) : (
								<Typography
									variant="body1"
									color="textPrimary"
									align="center"
								>
									There are no results
								</Typography>
							)}
						</Grid>
					</Grid>
				) : null}
				<Dialog open={isDialogOpen} onClose={handleDialogClose}>
					<DialogTitle>Update Successful</DialogTitle>
					<DialogContent>
						<DialogContentText>
							Knowledge Hub status has been successfully updated
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button
							onClick={handleDialogClose}
							color="primary"
							variant="contained"
						>
							Ok
						</Button>
					</DialogActions>
				</Dialog>
			</SectionContainerLayout>
		</MainLayout>
	);
};

export default DigitalSeriesPage;
