import { useCallback, useState } from "react";
import MainLayout from "../../../components/main-layout";
import SectionContainerLayout from "../../../components/section-container-layout";
import TopicForm from "./form";
import { useHistory } from "react-router-dom";
import { insert } from "../helpers";
const TopicAddPage = () => {
	const [isLoading, setIsLoading] = useState(false);
	const history = useHistory();

	const handleSubmit = useCallback(
		async (values) => {
			const { name, description, startDateTime, endDateTime, location } =
				values;

			setIsLoading(true);
			insert({
				body: {
					name,
					description,
					startDateTime: new Date(startDateTime).toISOString(),
					endDateTime: new Date(endDateTime).toISOString(),
					locationCode: location,
				},
				link: "topic",
				history,
				setIsLoading,
				path: "topic",
			});
		},
		[history],
	);
	return (
		<MainLayout>
			<SectionContainerLayout title="Add topic">
				<TopicForm
					submitCallBack={(values) => handleSubmit(values)}
					isLoading={isLoading}
					setIsLoading={setIsLoading}
					type="add"
				/>
			</SectionContainerLayout>
		</MainLayout>
	);
};

export default TopicAddPage;
