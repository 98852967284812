import React, { useEffect, useState, useCallback } from "react";
import ModuleActionBar from "../../../../components/module-action-bar";
import { Grid, Button, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CustomTextField from "../../../../components/custom-text-field";
import * as yup from "yup";
import { useFormik } from "formik";
import Spinner from "../../../../components/spinner";
import CustomSelect from "../../../../components/custom-select";
import { fetch } from "../../helpers";
import CustomAutocomplete from "../../../../components/custom-autocomplete";
import axios from "axios";
const validationSchema = yup.object().shape({
	name: yup.string().required("required"),
	topic: yup.string().required("required"),
	speakers: yup.array().min(1).required("required"),
});

const initialValues = {
	name: "",
	topic: "",
	speakers: [],
};

const GroupForm = ({
	currentData,
	submitCallBack,
	setIsLoading,
	isLoading = false,
	type,
}) => {
	const history = useHistory();
	const [allTopics, setAllTopics] = useState([]);
	const [allSpeakers, setAllSpeakers] = useState([]);

	const fetchSpeakers = useCallback(async () => {
		try {
			axios
				.post("/speaker/search", {
					filters: {},
				})
				.then((_response) => {
					if (_response.data) {
						setAllSpeakers(_response.data);
					}
				})
				.catch((err) => console.log(err));
		} catch (err) {
			console.log("error");
		}
	}, []);

	useEffect(() => {
		fetchSpeakers();
	}, [fetchSpeakers]);

	const fetchTopics = useCallback(async () => {
		fetch({
			pathname: "topic",
			setData: setAllTopics,
			setIsLoading,
		});
	}, [setIsLoading]);

	useEffect(() => {
		fetchTopics();
	}, [fetchTopics]);

	const formik = useFormik({
		initialValues,
		validationSchema,
		enableReinitialize: false,
		onSubmit: (values) => {
			submitCallBack(values);
		},
	});

	useEffect(() => {
		if (currentData && type === "edit") {
			setIsLoading(true);
			formik.setValues({
				name: currentData.name ? currentData.name : "",
				topic: currentData.topic ? currentData.topic.code : "",
				speakers:
					currentData.speakers && currentData.speakers.length > 0
						? currentData.speakers
						: [],
			});
			setTimeout(() => {
				setIsLoading(false);
			}, 300);
		}
		//eslint-disable-next-line
	}, [currentData]);

	return (
		<>
			{!isLoading &&
				((type === "edit" && currentData) || type === "add") && (
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<Button
								color="primary"
								onClick={() => history.push("/agendas/group")}
							>
								<ArrowBackIcon />
								<Typography component="span" color="primary">
									{"back"}
								</Typography>
							</Button>
						</Grid>

						<Grid item xs={12} sm={6}>
							<CustomTextField
								label="Name"
								name="name"
								validationMessage={
									formik.touched.name && formik.errors.name
										? formik.errors.name
										: ""
								}
								value={formik.values.name}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<CustomSelect
								fullWidth
								name="topic"
								label="Topic"
								options={allTopics}
								validationMessage={
									formik.errors.topic && formik.touched.topic
										? formik.errors.topic
										: ""
								}
								onChange={formik.handleChange}
								value={formik.values.topic}
							/>
						</Grid>

						<Grid item xs={12}>
							<CustomAutocomplete
								label="Speakers"
								name="speakers"
								isMultiple={true}
								validationMessage={
									formik.touched.speakers &&
									formik.errors.speakers
										? formik.errors.speakers
										: ""
								}
								onChange={async (e, value) => {
									formik.setFieldValue("speakers", value);
								}}
								options={allSpeakers}
								// defaultValue={formik.values.speakers}
								value={formik.values.speakers}
								onBlur={formik.handleBlur}
							/>
						</Grid>

						<Grid item xs={12}>
							<ModuleActionBar
								isDelete={false}
								submitLabel={
									type === "add" ? "Add" : "Apply changes"
								}
								cancelLabel="cancel"
								cancelOnClick={() =>
									history.push("/agendas/group")
								}
								submitOnClick={formik.handleSubmit}
							/>
						</Grid>
					</Grid>
				)}
			<Spinner open={isLoading} />
		</>
	);
};

export default GroupForm;
