import React, { useState } from "react";
import {
	AppBar,
	Toolbar,
	IconButton,
	Drawer,
	Divider,
	List,
	ListItem,
	ListItemText,
	ListItemIcon,
	makeStyles,
	Typography,
	Popper,
	Grow,
	Grid,
	Paper,
	Box,
	ClickAwayListener,
	MenuList,
	Button,
	MenuItem,
} from "@material-ui/core";
import clsx from "clsx";
import MenuIcon from "@material-ui/icons/Menu";
import EventNoteIcon from "@material-ui/icons/EventNote";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import DashboardRoundedIcon from "@material-ui/icons/DashboardRounded";
import DescriptionIcon from "@material-ui/icons/Description";
import ViewCarouselRoundedIcon from "@material-ui/icons/ViewCarouselRounded";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import VideoLibraryIcon from "@material-ui/icons/VideoLibrary";
import PeopleIcon from "@material-ui/icons/People";
import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary";
import GroupWorkIcon from "@material-ui/icons/GroupWork";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import WatchLaterIcon from "@material-ui/icons/WatchLater";
import HeadsetMicIcon from "@material-ui/icons/HeadsetMic";
import SettingsIcon from "@material-ui/icons/Settings";
import EmojiObjectsIcon from "@material-ui/icons/EmojiObjects";
import GmisLogo from "../gmis-logo";
import { NavLink, useHistory } from "react-router-dom";
import { useStoreState } from "easy-peasy";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
	appBar: {
		backgroundColor: theme.palette.background.default,
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	appBarShift: {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	menuButton: {
		marginRight: 36,
	},
	hide: {
		display: "none",
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
		whiteSpace: "nowrap",

		"& .MuiDrawer-paper": {
			backgroundColor: theme.palette.secondary.main,
		},
	},
	drawerOpen: {
		width: drawerWidth,
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	drawerClose: {
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		overflowX: "hidden",
		[theme.breakpoints.up("sm")]: {
			width: theme.spacing(8) + 1,
		},
	},
	toolbar: {
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-end",
		padding: theme.spacing(0, 1),
		// necessary for content to be below app bar
		...theme.mixins.toolbar,
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
	},
	navigationIcons: {
		color: theme.palette.primary.main,
	},
	navigationLabels: {
		color: theme.palette.common.white,
		"&:hover": {
			textDecoration: "underline",
		},
	},

	activeLink: {
		fill: theme.palette.common.white,
		"& path": {
			fill: theme.palette.common.white,
		},
		"& .MuiTypography-root": {
			color: theme.palette.common.white,
		},
	},
}));

const CustomDrawer = () => {
	const classes = useStyles();
	const [open, setOpen] = useState(false);
	const history = useHistory();
	const currentUser = useStoreState((state) => state.user.currentUser);
	const userType = currentUser.user_account_type.type;

	const [anchorEl, setAnchorEl] = useState(null);

	const handleDrawerOpen = () => {
		setOpen(true);
	};

	const handleDrawerClose = () => {
		setOpen(false);
	};

	const navigationItems = [
		{
			iconName: "DashboardIcon",
			label: "Dashboard",
			link: "/dashboard",
			admin: true,
			viewer: false,
			editor: false,
		},
		{
			iconName: "ViewCarouselIcon",
			label: "Carousels",
			link: "/carousels",
			admin: true,
			viewer: true,
			editor: true,
		},
		{
			iconName: "DescriptionIcon",
			label: "News",
			link: "/news",
			admin: true,
			viewer: true,
			editor: true,
		},
		{
			iconName: "MenuBookIcon",
			label: "Knowledge Hub",
			link: "/knowledge-hub",
			admin: true,
			viewer: true,
			editor: true,
		},
		{
			iconName: "EventNoteIcon",
			label: "Agendas",
			link: "/agendas/agenda",
			admin: true,
			viewer: true,
			editor: true,
		},
		{
			iconName: "EmojiObjectsIcon",
			label: "Initiatives",
			link: "/initiatives",
			admin: true,
			viewer: true,
			editor: true,
		},
		{
			iconName: "VideoLibraryIcon",
			label: "Digital Series",
			link: "/digital-series",
			admin: true,
			viewer: true,
			editor: true,
		},
		{
			iconName: "PeopleIcon",
			label: "Speakers",
			link: "/speakers",
			admin: true,
			viewer: true,
			editor: true,
		},
		{
			iconName: "PhotoLibraryIcon",
			label: "Albums",
			link: "/albums",
			admin: true,
			viewer: true,
			editor: true,
		},
		{
			iconName: "GroupWorkIcon",
			label: "Partners",
			link: "/partners",
			admin: true,
			viewer: true,
			editor: true,
		},
		{
			iconName: "AccountBoxIcon",
			label: "User Accounts",
			link: "/users-accounts",
			admin: true,
			viewer: false,
			editor: false,
		},
		{
			iconName: "WatchLaterIcon",
			label: "Users Log",
			link: "/user-logs",
			admin: true,
			viewer: false,
			editor: false,
		},
		{
			iconName: "SettingsIcon",
			label: "Settings",
			link: "/settings",
			admin: true,
			viewer: false,
			editor: false,
		},
		{
			iconName: "HeadsetMicIcon",
			label: "Support",
			link: "/support",
			admin: true,
			viewer: true,
			editor: true,
		},
	];

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleLogout = () => {
		localStorage.removeItem("token");
		history.push("/login");
	};

	return (
		<>
			<AppBar
				elevation={0}
				position="fixed"
				className={clsx(classes.appBar, {
					[classes.appBarShift]: open,
				})}
			>
				<Toolbar>
					<IconButton
						color="primary"
						aria-label="open drawer"
						onClick={handleDrawerOpen}
						edge="start"
						className={clsx(classes.menuButton, {
							[classes.hide]: open,
						})}
					>
						<MenuIcon />
					</IconButton>
					<Box mr={9} clone>
						<Grid container alignItems="center">
							<Grid item xs={9}>
								<GmisLogo viewBox="0 0 90 50" width="90px" />
							</Grid>
							<Grid item container xs={3}>
								<Box clone textAlign="end">
									<Grid item xs={12}>
										<Button
											color="primary"
											onClick={handleClick}
										>{`Hi, ${currentUser.firstName}`}</Button>

										<Popper
											open={Boolean(anchorEl)}
											anchorEl={anchorEl}
											role={undefined}
											transition
											disablePortal
										>
											{({
												TransitionProps,
												placement,
											}) => (
												<Grow
													{...TransitionProps}
													style={{
														width: "150px",
														transformOrigin:
															placement ===
															"bottom"
																? "center top"
																: "center bottom",
													}}
												>
													<Paper>
														<ClickAwayListener
															onClickAway={
																handleClose
															}
														>
															<MenuList
																autoFocusItem={
																	open
																}
																id="menu-list-grow"
															>
																<Typography
																	align="center"
																	variant="body2"
																>
																	{
																		currentUser.firstName
																	}
																</Typography>
																<MenuItem
																	onClick={
																		handleLogout
																	}
																>
																	<Button
																		style={{
																			margin: "auto",
																		}}
																		size="small"
																		variant="contained"
																		color="primary"
																	>
																		Sign Out
																	</Button>
																</MenuItem>
															</MenuList>
														</ClickAwayListener>
													</Paper>
												</Grow>
											)}
										</Popper>

										{/* 
                    <Menu
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClick={handleClose}
                    >
                      <MenuItem onClick={handleLogout}>Logout</MenuItem>
                    </Menu> */}
									</Grid>
								</Box>
							</Grid>
						</Grid>
					</Box>
				</Toolbar>
			</AppBar>
			<Drawer
				variant="permanent"
				className={clsx(classes.drawer, {
					[classes.drawerOpen]: open,
					[classes.drawerClose]: !open,
				})}
				classes={{
					paper: clsx({
						[classes.drawerOpen]: open,
						[classes.drawerClose]: !open,
					}),
				}}
			>
				<div className={classes.toolbar}>
					{open ? (
						<IconButton onClick={handleDrawerClose}>
							<ChevronLeftIcon color="primary" />
						</IconButton>
					) : null}
				</div>
				<Divider />
				<List>
					{navigationItems.map((_item, index) =>
						_item[userType.toLowerCase()] ? (
							<Box
								key={index}
								mt={
									_item.iconName === "HeadsetMicIcon" ? 10 : 1
								}
							>
								<ListItem button>
									<ListItemIcon>
										<NavLink
											to={_item.link}
											activeClassName={classes.activeLink}
										>
											{_item.iconName ===
											"DashboardIcon" ? (
												<DashboardRoundedIcon
													className={
														classes.navigationIcons
													}
												/>
											) : _item.iconName ===
											  "ViewCarouselIcon" ? (
												<ViewCarouselRoundedIcon
													className={
														classes.navigationIcons
													}
												/>
											) : _item.iconName ===
											  "DescriptionIcon" ? (
												<DescriptionIcon
													className={
														classes.navigationIcons
													}
												/>
											) : _item.iconName ===
											  "EventNoteIcon" ? (
												<EventNoteIcon
													className={
														classes.navigationIcons
													}
												/>
											) : _item.iconName ===
											  "MenuBookIcon" ? (
												<MenuBookIcon
													className={
														classes.navigationIcons
													}
												/>
											) : _item.iconName ===
											  "VideoLibraryIcon" ? (
												<VideoLibraryIcon
													className={
														classes.navigationIcons
													}
												/>
											) : _item.iconName ===
											  "PeopleIcon" ? (
												<PeopleIcon
													className={
														classes.navigationIcons
													}
												/>
											) : _item.iconName ===
											  "PhotoLibraryIcon" ? (
												<PhotoLibraryIcon
													className={
														classes.navigationIcons
													}
												/>
											) : _item.iconName ===
											  "GroupWorkIcon" ? (
												<GroupWorkIcon
													className={
														classes.navigationIcons
													}
												/>
											) : _item.iconName ===
											  "AccountBoxIcon" ? (
												<AccountBoxIcon
													className={
														classes.navigationIcons
													}
												/>
											) : _item.iconName ===
													"WatchLaterIcon" &&
											  currentUser.user_account_type
													.type === "Admin" ? (
												<WatchLaterIcon
													className={
														classes.navigationIcons
													}
												/>
											) : _item.iconName ===
											  "HeadsetMicIcon" ? (
												<HeadsetMicIcon
													className={
														classes.navigationIcons
													}
												/>
											) : _item.iconName ===
											  "SettingsIcon" ? (
												<SettingsIcon
													className={
														classes.navigationIcons
													}
												/>
											) : _item.iconName ===
											  "EmojiObjectsIcon" ? (
												<EmojiObjectsIcon
													className={
														classes.navigationIcons
													}
												/>
											) : null}
										</NavLink>
									</ListItemIcon>
									<ListItemText
										disableTypography
										primary={
											<NavLink
												to={_item.link}
												activeClassName={
													classes.activeLink
												}
												style={{
													textDecoration: "none",
												}}
											>
												<Typography
													variant="body2"
													className={
														classes.navigationLabels
													}
												>
													{_item.label}
												</Typography>
											</NavLink>
										}
									/>
								</ListItem>
							</Box>
						) : null,
					)}
				</List>
			</Drawer>
		</>
	);
};

export default CustomDrawer;
