import * as yup from "yup";

export const addNewInitiative = yup.object().shape({
	title: yup.string().required("Title is required"),
	// subtitle: yup.string().required("Subtitle is required"),
	description: yup.string().required("Description is required"),
});

export const editInitiative = yup.object().shape({
	title: yup.string().required("Title is required"),
	// subtitle: yup.string().required("Subtitle is required"),
	description: yup.string().required("Description is required"),
});
