import React, { useState, useEffect } from "react";
import MainLayout from "../../components/main-layout";
import SectionContainerLayout from "../../components/section-container-layout";

import {
	Grid,
	TextField,
	ButtonGroup,
	Button,
	Typography,
	Checkbox,
	FormControlLabel,
	FormHelperText,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { createFilterOptions } from "@material-ui/lab/Autocomplete";
import axios from "axios";
import { DropzoneArea } from "material-ui-dropzone";
import CustomTextEditor from "../../components/custom-text-editor";
import { useHistory } from "react-router";
import { generatePageLink } from "../../helpers/common";
import moment from "moment";
import { addNewKnowledgeHub } from "../../validations/knowledge-hub-validator";

const filter = createFilterOptions();
const AddKnowledgeHubPage = () => {
	const [jsonFormData, setJsonFormData] = useState({
		date: moment().format("YYYY-MM-DD"),
		isOnHomepage: false,
	});
	const [datePicked] = useState(moment().format("YYYY-MM-DD"));
	const [activeTab, setActiveTab] = useState("articleButton");
	const [imageFile, setImageFile] = useState();
	const [pdfFile, setPdfFile] = useState();
	const [validationErrors, setValidationErrors] = useState({});
	const [selectedCategory, setSelectedCategory] = useState({});
	const [categories, setCategories] = useState([]);
	const history = useHistory();

	useEffect(() => {
		try {
			axios
				.get("/utility/categories/knowledge-hub")
				.then((_response) => {
					if (_response.status === 200) setCategories(_response.data);
				})
				.catch((err) => console.log(err));
		} catch (err) {
			console.log(err);
		}
	}, [setCategories]);

	const handleEditorChange = (event, editor) => {
		const data = editor.getData();
		setJsonFormData({
			...jsonFormData,
			article: data,
		});
	};

	const handleImageFile = (file) => {
		setImageFile(file[0]);
	};

	const handlePdfFile = (file) => {
		setPdfFile(file[0]);
	};

	const handleTabChange = (buttonId) => {
		console.log(buttonId);
		switch (buttonId) {
			case "articleButton": {
				setActiveTab(buttonId);
				setPdfFile();
				break;
			}
			case "pdfButton": {
				setActiveTab(buttonId);
				setJsonFormData({
					...jsonFormData,
					article: "",
				});
				break;
			}
			case "seoButton": {
				setActiveTab(buttonId);
				break;
			}
			default: {
				setActiveTab("articleButton");
			}
		}
	};

	const handleFormChange = (e) => {
		setJsonFormData({
			...jsonFormData,
			// ...categoryValue,
			[e.target.id || e.target.name]: e.target.value || e.target.checked,
		});
	};

	const handleSubmit = () => {
		let errorMessages = {};
		try {
			if (
				jsonFormData.isOnHomepage === undefined ||
				jsonFormData.isOnHomepage === null
			) {
				setJsonFormData({
					...jsonFormData,
					isOnHomepage: false,
				});
			}

			addNewKnowledgeHub
				.validate(jsonFormData, { abortEarly: false })
				.then(() => {
					if (!imageFile) {
						return;
					}

					let formData = new FormData();
					formData.append("imageFile", imageFile);

					if (pdfFile) {
						formData.append("pdfFile", pdfFile);
					}

					for (let i in jsonFormData) {
						formData.append(i, jsonFormData[i]);
					}

					let urlSlug = generatePageLink(
						jsonFormData.title,
						"knowledge-hub/xxxx/",
					);

					formData.append("urlSlug", urlSlug);
					formData.append(
						"category",
						Object.keys(selectedCategory).length > 0
							? JSON.stringify(selectedCategory)
							: null,
					);

					axios
						.post("/knowledge-hub", formData, {
							headers: { "Content-Type": "multipart/form-data" },
						})
						.then((_response) => {
							if (_response.status === 200) {
								history.push("/knowledge-hub");
							}
						})
						.catch((err) => console.log(err));
				})
				.catch((err) => {
					err.inner.forEach((e) => {
						errorMessages[e.path] = e.message;
					});
					setValidationErrors(errorMessages);
				});
		} catch (err) {
			console.log(err);
		}
	};

	return (
		<MainLayout>
			<SectionContainerLayout title="ADD KNOWLEDGE HUB">
				<Grid container spacing={3}>
					<Grid item container xs={12} md={6} spacing={3}>
						<Grid item xs={12} md={6}>
							{/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  id="date"
                  value={datePicked}
                  required
                  label="Date"
                  name="date"
                  autoOk
                  // error={validationErrors.date}
                  // helperText={validationErrors.date}
                  onChange={handleDatePicker("date")}
                />
              </MuiPickersUtilsProvider> */}
							<TextField
								id="date"
								label="Date"
								type="date"
								defaultValue={datePicked}
								onChange={handleFormChange}
								error={validationErrors.date}
								helperText={validationErrors.date}
								placeholder={datePicked}
								variant="outlined"
								fullWidth
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<TextField
								label="Title"
								required
								variant="outlined"
								fullWidth
								error={validationErrors.title}
								helperText={validationErrors.title}
								name="title"
								onChange={handleFormChange}
							/>
						</Grid>
						<Grid item xs={6}>
							<Autocomplete
								value={selectedCategory}
								onChange={(event, newValue) => {
									if (typeof newValue === "string")
										setSelectedCategory({ name: newValue });
									else if (newValue && newValue.inputValue)
										setSelectedCategory({
											name: newValue.inputValue,
										});
									else setSelectedCategory(newValue);
								}}
								filterOptions={(options, params) => {
									const filtered = filter(options, params);
									if (params.inputValue !== "") {
										filtered.push({
											inputValue: params.inputValue,
											name: `Add "${params.inputValue}"`,
										});
									}
									return filtered;
								}}
								selectOnFocus
								clearOnBlur
								handleHomeEndKeys
								options={categories}
								getOptionLabel={(option) => {
									if (Object.keys(option).length > 0)
										return option.name;

									return "";
								}}
								renderOption={(option) =>
									Object.keys(option).length > 0
										? option.name
										: ""
								}
								freeSolo
								renderInput={(params) => (
									<TextField
										{...params}
										label="Category"
										variant="outlined"
									/>
								)}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								multiline
								rows={4}
								label="Description"
								variant="outlined"
								required
								helperText={validationErrors.description}
								error={validationErrors.description}
								name="description"
								fullWidth
								onChange={handleFormChange}
							/>
						</Grid>
					</Grid>
					<Grid item container xs={12} md={6}>
						<DropzoneArea
							dropzoneText="Drag and drop thumbnail image here *"
							filesLimit={1}
							key={2}
							// onChange={(file) => handleFile(file)}
							acceptedFiles={["image/*"]}
							onChange={(file) => handleImageFile(file)}
						/>
						<FormHelperText>
							Width: 500px, Height: 300px
						</FormHelperText>
					</Grid>
					<Grid
						item
						container
						xs={12}
						spacing={3}
						alignItems="center"
					>
						<Grid item xs={12} md={6}>
							<ButtonGroup>
								<Button
									color={
										activeTab === "articleButton"
											? "primary"
											: null
									}
									onClick={(e) =>
										handleTabChange(e.currentTarget.id)
									}
									id="articleButton"
									variant="contained"
								>
									Article
								</Button>
								<Button
									color={
										activeTab === "pdfButton"
											? "primary"
											: null
									}
									onClick={(e) =>
										handleTabChange(e.currentTarget.id)
									}
									id="pdfButton"
									variant="contained"
								>
									PDF
								</Button>
								<Button
									color={
										activeTab === "seoButton"
											? "primary"
											: null
									}
									onClick={(e) =>
										handleTabChange(e.currentTarget.id)
									}
									id="seoButton"
									variant="contained"
								>
									SEO
								</Button>
							</ButtonGroup>
						</Grid>
						<Grid item xs={12} md={6} align="right">
							<Typography variant="body2" color="textSecondary">
								You can either upload a PDF or add an article
								(not both)
							</Typography>
						</Grid>
						<Grid item xs={12}>
							{activeTab === "articleButton" ? (
								<CustomTextEditor
									onChange={handleEditorChange}
								/>
							) : activeTab === "pdfButton" ? (
								<>
									<DropzoneArea
										dropzoneText="Drag and drop PDF file here"
										filesLimit={1}
										key={1}
										onChange={(file) => handlePdfFile(file)}
										acceptedFiles={["application/pdf"]}
										maxFileSize={10485760}
									/>
									<FormHelperText>
										PDF Max Size: 10MB
									</FormHelperText>
								</>
							) : (
								<Grid container spacing={3}>
									<Grid item xs={12}>
										<TextField
											label="SEO Title"
											variant="outlined"
											fullWidth
											// error={validationErrors.seoTitle}
											// helperText={validationErrors.seoTitle}
											name="seoTitle"
											onChange={handleFormChange}
										/>
									</Grid>
									<Grid item xs={12}>
										<TextField
											multiline
											rows={4}
											label="SEO Description"
											variant="outlined"
											// helperText={validationErrors.seoDescription}
											// error={validationErrors.seoDescription}
											name="seoDescription"
											fullWidth
											onChange={handleFormChange}
										/>
									</Grid>
									<Grid item xs={12}>
										<TextField
											multiline
											rows={4}
											label="SEO Keywords"
											variant="outlined"
											helperText="Seperate each set of keywords with a ','"
											// error={validationErrors.seoKeywords}
											name="seoKeywords"
											fullWidth
											onChange={handleFormChange}
										/>
									</Grid>
								</Grid>
							)}
						</Grid>
					</Grid>
					<Grid item container xs={12}>
						<Grid item xs={12} md={6}>
							<FormControlLabel
								control={
									<Checkbox
										id="isOnHomepage"
										onChange={handleFormChange}
									/>
								}
								label="Add to homepage"
							/>
						</Grid>
						<Grid item xs={12} md={6} align="right">
							<Button
								onClick={() => history.push("/knowledge-hub")}
								style={{ textTransform: "capitalize" }}
							>
								Cancel
							</Button>
							<Button
								variant="contained"
								color="primary"
								onClick={handleSubmit}
								style={{ textTransform: "capitalize" }}
							>
								Submit
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</SectionContainerLayout>
		</MainLayout>
	);
};

export default AddKnowledgeHubPage;
