import { CircularProgress, Box } from "@material-ui/core";
import React, { useMemo } from "react";

const Spinner = ({ open = true }) => {
	const SpinnerMemo = useMemo(() => {
		return (
			<Box
				position="fixed"
				top="0"
				left="0"
				zIndex="2000"
				width={1}
				height={1}
				style={{
					backgroundColor: "#00000008",
				}}
			>
				<Box
					style={{
						background: "#1717171",
						borderRadius: "5px",
						width: "200px",
						height: "200px",
						padding: "10px",
						position: "absolute",
						top: "50%",
						left: "50%",
						transform: "translate(-50% , -50%)",
					}}
				>
					<Box textAlign="center" className="absolute-m-t-l" p={3}>
						<CircularProgress color="primary" />
					</Box>
				</Box>
			</Box>
		);
	}, []);
	return open && SpinnerMemo;
};

export default Spinner;
