import React, { useEffect } from "react";
import ModuleActionBar from "../../../../components/module-action-bar";
import { Grid, Button, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CustomTextField from "../../../../components/custom-text-field";
import * as yup from "yup";
import { useFormik } from "formik";
import Spinner from "../../../../components/spinner";

const validationSchema = yup.object().shape({
	name: yup.string().required("required"),
});

const initialValues = {
	name: "",
};
const LocationForm = ({
	currentData,
	submitCallBack,
	setIsLoading,
	isLoading = false,
	type,
}) => {
	const history = useHistory();

	const formik = useFormik({
		initialValues,
		validationSchema,
		enableReinitialize: false,
		onSubmit: (values) => {
			submitCallBack(values);
		},
	});

	useEffect(() => {
		if (currentData && type === "edit") {
			setIsLoading(true);
			formik.setValues({
				name: currentData.name ? currentData.name : "",
			});
			setTimeout(() => {
				setIsLoading(false);
			}, 300);
		}
		//eslint-disable-next-line
	}, [currentData]);

	return (
		<>
			{!isLoading &&
				((type === "edit" && currentData) || type === "add") && (
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<Button
								color="primary"
								onClick={() =>
									history.push("/agendas/location")
								}
							>
								<ArrowBackIcon />
								<Typography component="span" color="primary">
									{"back"}
								</Typography>
							</Button>
						</Grid>

						<Grid item xs={12} sm={6}>
							<CustomTextField
								label="Name"
								name="name"
								validationMessage={
									formik.touched.name && formik.errors.name
										? formik.errors.name
										: ""
								}
								value={formik.values.name}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
							/>
						</Grid>

						<Grid item xs={12}>
							<ModuleActionBar
								isDelete={false}
								submitLabel={
									type === "add" ? "Add" : "Apply changes"
								}
								cancelLabel="Cancel"
								cancelOnClick={() =>
									history.push("/agendas/location")
								}
								submitOnClick={formik.handleSubmit}
							/>
						</Grid>
					</Grid>
				)}
			<Spinner open={isLoading} />
		</>
	);
};

export default LocationForm;
