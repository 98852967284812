import { createStore, action } from "easy-peasy";

const store = createStore({
	user: {
		currentUser: {},
		setCurrentUser: action((state, payload) => {
			state.currentUser = payload;
		}),
		updateFirstLogin: action((state, payload) => {
			state.currentUser = {
				...state.currentUser,
				firstLogin: false,
			};
		}),
	},
});

export default store;
