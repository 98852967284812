import {
	Grid,
	makeStyles,
	Card,
	CardContent,
	Typography,
	TextField,
	Box,
} from "@material-ui/core";
import React, { useState } from "react";
import GmisLogo from "../components/gmis-logo";
import SecondaryLayout from "../components/secondary-layout";
import CustomButton from "../components/custom-button";
import CustomLink from "../components/custom-link";
import axios from "axios";
import { useHistory } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
	cardContainer: {
		minWidth: 450,
	},
}));
const LoginPage = () => {
	const [jsonFormData, setJsonFormData] = useState();
	const classes = useStyles();
	const history = useHistory();
	const [validationErrors, setValidationErrors] = useState("");

	const handleFormChange = (e) => {
		setJsonFormData({
			...jsonFormData,
			[e.target.id || e.target.name]: e.target.value,
		});
		setValidationErrors("");
	};

	const handleSubmit = (e) => {
		try {
			e.preventDefault();

			if (!jsonFormData.email || !jsonFormData.password) {
				setValidationErrors(
					"Please make sure all fields are filled up",
				);
				return;
			}

			axios
				.post("/user/login", jsonFormData)
				.then((_response) => {
					if (_response.status === 200) {
						const { authenticationToken } = _response.data;
						localStorage.setItem("token", authenticationToken);

						history.push("/dashboard");
					} else {
						console.log("Invalid credentials");
					}
				})
				.catch((err) =>
					setValidationErrors(
						err.response &&
							err.response.data &&
							err.response.data.error
							? err.response.data.error
							: "error",
					),
				);
		} catch (err) {
			console.log(err);
		}
	};

	return (
		<SecondaryLayout>
			<Grid
				container
				alignItems="center"
				justify="center"
				direction="column"
				style={{ minHeight: "100vh" }}
				spacing={3}
			>
				<Grid item>
					<GmisLogo />
				</Grid>
				<Grid item>
					<Card className={classes.cardContainer}>
						<CardContent>
							<form onSubmit={handleSubmit}>
								<Grid container direction="column" spacing={4}>
									<Grid item>
										<Typography
											variant="h6"
											color="textPrimary"
										>
											<Box fontWeight="fontWeightBold">
												Login Form
											</Box>
										</Typography>
										<Typography
											variant="body2"
											color="textSecondary"
										>
											Enter your email and password
										</Typography>
									</Grid>
									<Grid item>
										<TextField
											label="Email"
											name="email"
											placeholder="Type your email"
											variant="outlined"
											size="small"
											onChange={(e) =>
												handleFormChange(e)
											}
											type="email"
											fullWidth
										/>
									</Grid>
									<Grid item>
										<TextField
											label="Password"
											placeholder="Type your password"
											name="password"
											variant="outlined"
											size="small"
											onChange={(e) =>
												handleFormChange(e)
											}
											type="password"
											fullWidth
										/>
									</Grid>
									<Grid item align="right">
										<CustomLink
											linkText="Forgot password?"
											linkUrl="/reset-password"
										/>
									</Grid>
									<Grid item align="center">
										<CustomButton
											type="submit"
											label="Login"
										/>
									</Grid>
									{validationErrors ? (
										<Typography
											variant="body2"
											color="error"
											align="center"
										>
											{validationErrors}
										</Typography>
									) : null}
								</Grid>
							</form>
						</CardContent>
					</Card>
				</Grid>
			</Grid>
		</SecondaryLayout>
	);
};

export default LoginPage;
