import {
	Grid,
	TextField,
	Typography,
	Button,
	FormHelperText,
	FormControlLabel,
	Checkbox,
} from "@material-ui/core";
import { DropzoneArea } from "material-ui-dropzone";
import React, { useState, useEffect } from "react";
import MainLayout from "../../components/main-layout";
import SectionContainerLayout from "../../components/section-container-layout";
import { Autocomplete } from "@material-ui/lab";
import { createFilterOptions } from "@material-ui/lab/Autocomplete";
import axios from "axios";
import { useHistory, useLocation } from "react-router-dom";

import { LocationSelectMap } from "../../components/location-map";

const DEFAULT_POSITION = {
	lat: 25.276987,
	lng: 55.296249,
};

const EditPartnerPage = () => {
	const [jsonFormData, setJsonFormData] = useState({});
	const [imageFile, setImageFile] = useState({});
	const [point, setPoint] = useState([
		DEFAULT_POSITION.lat,
		DEFAULT_POSITION.lng,
	]);
	const [partnerCategory, setPartnerCategory] = useState();
	const [partnerCategoryOptions, setPartnerCategoryOptions] = useState();
	const [currentPartner, setCurrentPartner] = useState({});
	const [isDataLoaded, setIsDataLoaded] = useState(false);
	const filter = createFilterOptions();
	const history = useHistory();
	const location = useLocation();

	useEffect(() => {
		axios
			.get("/utility/partner-categories")
			.then((_response) => {
				if (_response.status === 200) {
					let options = [];

					_response.data.forEach((_data) => {
						options.push({
							code: _data.code,
							name: _data.name,
						});
					});
					setPartnerCategoryOptions(options);
				}
			})
			.catch((err) => console.log(err));
		if (location.state.partner.point) {
			setPoint(location.state.partner.point?.coordinates);
		}
		setCurrentPartner(location.state.partner);
		setPartnerCategory({
			code: location.state.partner.partnercategory.code,
			name: location.state.partner.partnercategory.name,
		});

		setIsDataLoaded(true);
	}, [setCurrentPartner, location.state.partner]);

	const renderInput = (params) => (
		<TextField {...params} label="Partner Category" variant="outlined" />
	);

	const getOptionLabel = (option) => {
		// Value selected with enter, right from the input
		if (typeof option === "string") {
			return option;
		}
		// Add "xxx" option created dynamically
		if (option.inputValue) {
			return option.inputValue;
		}
		// Regular option
		return option.name;
	};

	const filterOptions = (options, params) => {
		const filtered = filter(options, params);

		// Suggest the creation of a new value
		if (params.inputValue !== "") {
			filtered.push({
				inputValue: params.inputValue,
				name: `Add "${params.inputValue}"`,
			});
		}
		return filtered;
	};

	const handleFormChange = (e) => {
		setJsonFormData({
			...jsonFormData,
			[e.target.id || e.target.name]: e.target.value || e.target.checked,
		});
	};

	const handleImageUpload = (file) => {
		setImageFile(file[0]);
	};

	const handlePartnerCategoryChange = (event, newValue) => {
		if (typeof newValue === "string") {
			setPartnerCategory({
				name: newValue,
			});
		} else if (newValue && newValue.inputValue) {
			setPartnerCategory({
				name: newValue.inputValue,
			});
		} else {
			setPartnerCategory(newValue);
		}
	};

	const handleSubmit = () => {
		try {
			let formData = new FormData();
			formData.append("imageFile", imageFile);

			formData.append("fileCode", currentPartner.file.code);
			formData.append("code", currentPartner.code);
			formData.append("category", JSON.stringify(partnerCategory));
			for (const value of point) {
				formData.append("point[]", value);
			}
			// formData.append("point[]", point);
			console.log(currentPartner);
			let data = { ...currentPartner, ...jsonFormData };

			for (let i in data) {
				if (i !== "point") {
					formData.append(i, data[i]);
				}
			}

			axios
				.put("/partner", formData, {
					headers: { "Content-Type": "multipart/form-data" },
				})
				.then((_response) => {
					if (_response.status === 200) {
						history.push("/partners");
					} else {
						console.log("error");
					}
				})
				.catch((err) => console.log(err));
		} catch (err) {}
	};
	return (
		<MainLayout>
			{isDataLoaded ? (
				<SectionContainerLayout
					title={`EDIT PARTNER: ${currentPartner.code}`}
				>
					<Grid container spacing={3}>
						<Grid item container xs={12} md={6} spacing={2}>
							<Grid item xs={12}>
								<Autocomplete
									value={partnerCategory}
									onChange={handlePartnerCategoryChange}
									options={partnerCategoryOptions}
									renderInput={renderInput}
									renderOption={(option) => option.name}
									getOptionLabel={getOptionLabel}
									filterOptions={filterOptions}
									selectOnFocus
									clearOnBlur
									handleHomeEndKeys
									freeSolo
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									label="Website URL"
									id="websiteUrl"
									variant="outlined"
									defaultValue={currentPartner.websiteUrl}
									placeholder={
										currentPartner.websiteUrl
											? ""
											: "Website URL"
									}
									fullWidth
									onChange={handleFormChange}
								/>
							</Grid>
							<Grid item xs={12}>
								<Typography variant="body1" color="textPrimary">
									Details
								</Typography>
								<Typography
									variant="body2"
									color="textSecondary"
								>
									Filling up those information will generate
									an inner page
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<TextField
									label="Title"
									defaultValue={currentPartner.title}
									placeholder={
										currentPartner.title ? "" : "Title"
									}
									id="title"
									variant="outlined"
									fullWidth
									onChange={handleFormChange}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									label="Description"
									id="description"
									variant="outlined"
									defaultValue={currentPartner.description}
									placeholder={
										currentPartner.description
											? ""
											: "Description"
									}
									fullWidth
									multiline
									onChange={handleFormChange}
									rows={4}
								/>
							</Grid>
						</Grid>
						<Grid item container xs={12} md={6}>
							<Grid item xs={12}>
								<DropzoneArea
									filesLimit={1}
									onChange={(file) => handleImageUpload(file)}
									acceptedFiles={["image/*"]}
									initialFiles={[
										`${process.env.REACT_APP_API_URL}/static/${currentPartner.file.code}`,
									]}
								/>
							</Grid>
							<FormHelperText>
								Width: 500px, Height: 300px
							</FormHelperText>
						</Grid>
						<Grid item container xs={12} spacing={3}>
							<Grid item container xs={12} md={6} spacing={2}>
								<Grid item xs={12}>
									<TextField
										label="Twitter"
										id="twitterUrl"
										defaultValue={currentPartner.twitterUrl}
										placeholder={
											currentPartner.twitterUrl
												? ""
												: "URL"
										}
										variant="outlined"
										onChange={handleFormChange}
										fullWidth
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										label="Instagram"
										defaultValue={
											currentPartner.instagramUrl
										}
										placeholder={
											currentPartner.instagramUrl
												? ""
												: "URL"
										}
										id="instagramUrl"
										onChange={handleFormChange}
										variant="outlined"
										fullWidth
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										label="Order"
										id="order"
										defaultValue={currentPartner.order}
										placeholder={
											currentPartner.order ? "" : "Order"
										}
										variant="outlined"
										onChange={handleFormChange}
										fullWidth
										type="number"
										InputProps={{ inputProps: { min: 0 } }}
									/>
								</Grid>
							</Grid>
							<Grid item container xs={12} md={6} spacing={2}>
								<Grid item xs={12}>
									<TextField
										label="Linkedin"
										id="linkedinUrl"
										defaultValue={
											currentPartner.linkedinUrl
										}
										placeholder={
											currentPartner.linkedinUrl
												? ""
												: "URL"
										}
										variant="outlined"
										onChange={handleFormChange}
										fullWidth
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										label="Facebook"
										id="facebookUrl"
										defaultValue={
											currentPartner.facebookUrl
										}
										placeholder={
											currentPartner.facebookUrl
												? ""
												: "URL"
										}
										variant="outlined"
										onChange={handleFormChange}
										fullWidth
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										label="Year"
										id="year"
										defaultValue={currentPartner.year}
										placeholder={
											currentPartner.year ? "" : "Year"
										}
										variant="outlined"
										onChange={handleFormChange}
										fullWidth
									/>
								</Grid>
							</Grid>
							<Grid item xs={12}>
								<div
									style={{
										height: "600px",
										width: "100%",
										borderRadius: "5px",
										overflow: "hidden",
										position: "relative",
									}}
								>
									<LocationSelectMap
										onChange={setPoint}
										absoluteMarker
										value={point}
									/>
								</div>
							</Grid>
							<Grid item container xs={12} md={6} spacing={2}>
								<FormControlLabel
									control={
										<Checkbox
											id="isOnHomepage"
											onChange={handleFormChange}
											defaultChecked={
												currentPartner.isOnHomepage
											}
										/>
									}
									label="Add to homepage"
								/>
							</Grid>
							<Grid item container xs={12} align="right">
								<Grid item xs={12}>
									<Button
										onClick={() =>
											history.push("/partners")
										}
										style={{ textTransform: "capitalize" }}
									>
										Cancel
									</Button>
									<Button
										onClick={handleSubmit}
										variant="contained"
										color="primary"
										style={{ textTransform: "capitalize" }}
									>
										Submit
									</Button>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</SectionContainerLayout>
			) : (
				<p>Loading...</p>
			)}
		</MainLayout>
	);
};

export default EditPartnerPage;
