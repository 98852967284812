import { ThemeProvider } from "@material-ui/core/styles";
import { Container } from "@material-ui/core";
import theme from "../constants/theme";
import CssBaseline from "@material-ui/core/CssBaseline";

const SecondaryLayout = ({ children }) => {
	return (
		<ThemeProvider theme={theme}>
			<CssBaseline>
				<Container maxWidth="xl">{children}</Container>
			</CssBaseline>
		</ThemeProvider>
	);
};

export default SecondaryLayout;
