import { useState, useCallback, useEffect } from "react";
import MainLayout from "../../../components/main-layout";
import SectionContainerLayout from "../../../components/section-container-layout";
import ScrollableTabsButtonForce from "../components/tabs";
import CardImageTitleDescription from "../../../components/card-image-title-description";
import { useHistory } from "react-router-dom";
import colors from "../../../constants/colors";
import Spinner from "../../../components/spinner";
import { map } from "lodash";
import TableCustomHeader from "../components/table-header";
import {
	Grid,
	Box,
	TableContainer,
	Paper,
	Table,
	TableRow,
	makeStyles,
	useTheme,
	TableCell,
	Typography,
	Button,
	TableBody,
	Checkbox,
} from "@material-ui/core";
import ModuleToolbar from "../../../components/module-toolbar";
import { useStoreState } from "easy-peasy";
import { deleteData } from "../axios";
import { fetch } from "../helpers";
import { useDebounce } from "use-debounce/lib";

const useStyles = makeStyles((theme) => ({
	tableHeader: {
		backgroundColor: colors.darkBlue,
	},
	rowHover: {
		"&:hover": {
			cursor: "pointer",
		},
	},
}));

const GroupPage = () => {
	const history = useHistory();
	const [selected, setSelected] = useState([]);
	const [data, setData] = useState([]);
	const [search, setSearch] = useState();
	const [searchText] = useDebounce(search, 1000);
	const [isGridView, setIsGridView] = useState(true);
	const [isListView, setListView] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [filters, setFilters] = useState([]);
	const [filterDataTabs, setFilterDataTabs] = useState({});
	const [isFilterToggled, setIsFilterToggled] = useState(false);
	const [topics, setAllTopics] = useState([]);
	const currentUser = useStoreState((state) => state.user.currentUser);
	const theme = useTheme();
	const classes = useStyles();

	console.log("data", data);

	const toggleGridView = useCallback(() => {
		setIsGridView(true);
		setListView(false);
	}, []);

	const toggleListView = useCallback(() => {
		setIsGridView(false);
		setListView(true);
	}, []);

	const fetchList = useCallback(() => {
		fetch({
			pathname: "group",
			filters: filters,
			setIsLoading,
			searchText: searchText,
			setData,
		});
	}, [searchText, filters]);

	useEffect(() => {
		fetchList();
	}, [fetchList]);

	const fetchTopics = useCallback(() => {
		fetch({
			pathname: "topic",
			searchText: "",
			setData: setAllTopics,
			setIsLoading: () => {},
		});
	}, []);

	useEffect(() => {
		fetchTopics();
	}, [fetchTopics]);

	const handleSearch = (e) => {
		setSearch(e.target.value);
	};
	const handleSelectAllChange = useCallback(
		(event) => {
			if (event.target.checked) {
				const newSelected = map(data, (n) => n.code);
				setSelected(newSelected);
				return;
			}
			setSelected([]);
		},
		[data],
	);

	const handleDeleteSelected = useCallback(async () => {
		const repsonse = await deleteData({ path: "group", selected });
		if (repsonse) {
			setSelected([]);
			fetchList();
		} else console.log("err");
	}, [selected, fetchList]);

	const handleItemSelectChange = useCallback(
		(e, data) => {
			const selectedIndex = selected.indexOf(data);
			let newSelected = [];

			if (selectedIndex === -1) {
				newSelected = newSelected.concat(selected, data);
			} else if (selectedIndex === 0) {
				newSelected = newSelected.concat(selected.slice(1));
			} else if (selectedIndex === selected.length - 1) {
				newSelected = newSelected.concat(selected.slice(0, -1));
			} else if (selectedIndex > 0) {
				newSelected = newSelected.concat(
					selected.slice(0, selectedIndex),
					selected.slice(selectedIndex + 1),
				);
			}

			setSelected(newSelected);
		},
		[selected],
	);

	const isSelected = useCallback(
		(data) => selected.indexOf(data) !== -1,
		[selected],
	);

	useEffect(() => {
		if (data && data.length > 0) {
			setFilterDataTabs([
				{
					type: "text",
					label: "topic",
					data: topics,
				},
			]);
		}
		//eslint-disable-next-line
	}, [data]);

	const handleFilterChange = useCallback(
		(e, module) => {
			switch (module) {
				case "topic":
					setFilters({ ...filters, topic: e.target.value });
					break;

				default: {
				}
			}
		},
		[filters],
	);
	return (
		<MainLayout>
			<SectionContainerLayout title="Group">
				<ScrollableTabsButtonForce />

				<Box mt={5}>
					<Grid container>
						<Grid item xs={12}>
							<ModuleToolbar
								// handleFilterData={handleFilterData}
								totalSelected={selected.length}
								onSelectAllClick={handleSelectAllChange}
								onDeleteSelected={handleDeleteSelected}
								addModuleUrl="/agendas/group/add"
								handleGridView={toggleGridView}
								handleSearchChange={handleSearch}
								handleListView={toggleListView}
								filterIcon={true}
								showCustomFilter={false}
								showFilters={true}
								filterDataTabs={filterDataTabs}
								changeStatusAction={false}
								isFilterToggled={isFilterToggled}
								handleFilterChange={handleFilterChange}
								onClearFiltersClick={() => setFilters({})}
								theFilters={filters}
								handleToggleFilter={() => {
									if (isFilterToggled) {
										setFilters({});
									}
									setIsFilterToggled(!isFilterToggled);
								}}
							/>
						</Grid>

						{!isLoading ? (
							<Grid item container xs={12} spacing={3}>
								{isGridView && data && data.length > 0 ? (
									map(data, (_item) => (
										<Grid
											item
											xs={12}
											md={4}
											lg={3}
											key={_item.code}
										>
											<CardImageTitleDescription
												hasStatus={false}
												onClick={() =>
													history.push(
														`/agendas/group/edit/${_item.code}`,
													)
												}
												title={_item.name}
												description={`Topic: ${
													_item.topic &&
													_item.topic.name
														? _item.topic.name
														: ""
												}`}
												isChecked={isSelected(
													_item.code,
												)}
												onItemSelect={(e) =>
													handleItemSelectChange(
														e,
														_item.code,
													)
												}
											/>
										</Grid>
									))
								) : isListView && data && data.length > 0 ? (
									<TableContainer component={Paper}>
										<Table>
											<TableCustomHeader
												currentUser={currentUser}
												theme={theme}
												classes={classes}
												headers={[
													"Name",
													"Topic",
													"Speakers",
												]}
											/>

											<TableBody>
												{map(data, (_item) => (
													<Box
														key={_item.code}
														clone
														className={
															classes.rowHover
														}
													>
														<TableRow
															scope="row"
															hover
														>
															{currentUser
																.user_account_type
																.type !==
															"Viewer" ? (
																<TableCell
																	width={1}
																>
																	<Checkbox
																		checked={isSelected(
																			_item.code,
																		)}
																		onClick={(
																			e,
																		) =>
																			handleItemSelectChange(
																				e,
																				_item.code,
																			)
																		}
																		inputProps={{
																			"aria-labelledby": 1,
																		}}
																	/>
																</TableCell>
															) : null}
															<TableCell
																width={100}
																align="center"
																onClick={() =>
																	history.push(
																		`/agendas/group/edit/${_item.code}`,
																	)
																}
															>
																<Typography
																	variant="body1"
																	color="textPrimary"
																>
																	{_item.name}
																</Typography>
															</TableCell>

															<TableCell
																width={100}
																align="center"
																onClick={() =>
																	history.push(
																		`/agendas/group/edit/${_item.code}`,
																	)
																}
															>
																<Typography
																	variant="body1"
																	color="textPrimary"
																>
																	{_item.topic &&
																	_item.topic
																		.name
																		? _item
																				.topic
																				.name
																		: ""}
																</Typography>
															</TableCell>

															<TableCell
																width={100}
																align="center"
																onClick={() =>
																	history.push(
																		`/agendas/group/edit/${_item.code}`,
																	)
																}
															>
																{map(
																	_item.speakers,
																	(_sp) => (
																		<Box
																			clone
																			p={
																				1
																			}
																			m={
																				1
																			}
																		>
																			<Button
																				size="small"
																				variant="outlined"
																				color="secondary"
																			>
																				{`${
																					_sp.firstName
																						? _sp.firstName
																						: ""
																				}
																			
																				${_sp.lastName ? _sp.lastName : ""}`}
																			</Button>
																		</Box>
																	),
																)}
															</TableCell>
														</TableRow>
													</Box>
												))}
											</TableBody>
										</Table>
									</TableContainer>
								) : (
									<Typography
										variant="body1"
										color="textPrimary"
										align="center"
									>
										There are no results
									</Typography>
								)}
							</Grid>
						) : (
							<Spinner open={isLoading} />
						)}
					</Grid>
				</Box>
			</SectionContainerLayout>
		</MainLayout>
	);
};

export default GroupPage;
