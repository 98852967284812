import * as yup from "yup";

export const addDigitalSeries = yup.object().shape({
	date: yup.date().required("Date is required"),
	title: yup.string().required("Title is required"),
	description: yup.string().required("Description is required"),
	details: yup.string().required("Details is required"),
	// thumbnail: yup.mixed().required("Thumbnail is required"),
	// video: yup.mixed().required("Video is required"),
});
